





























































































































































































































































import {
  computed,
  ComputedRef,
  defineComponent,
  onBeforeMount,
  ref,
  Ref,
  watch,
} from "@vue/composition-api";

import Title from "../title/Title.vue";
import DialogRight from "@/components/core/DialogRight.vue";
import RentalAgreementModal from "@/components/core/rentalAgreements/RentalAgreementModal.vue";
import RentalAgreementsFilterModal from "./RentalAgreementsFilter.vue";
import {
  productsStore,
  realEstateAssetsStore,
  rentalAgreementsStore,
  rentIndexationsStore,
  subscriptionsStore,
  tasksStore,
  tenantsStore,
  usersStore,
} from "@/store";
import {
  getMoment,
  SubscriptionsModel,
  RealEstateAsset,
  RentalAgreement,
  TenantTypeEnum,
  TaskCode,
  DPE,
  RentIndexationTypeParamEnum,
} from "@edmp/api";
import { ROUTE_NAMES } from "@/router/routes";
import Dialog from "@/components/atom/Dialog.vue";
import YesNoSwitch from "@/components/atom/switch/YesNoSwitch.vue";
import { IndexationError } from "@/components/core/rentalAgreements/indexationError.usable";
// import dayjs from "dayjs";
import { dispatchIndexationEvent, IndexationEventCode } from "@/events";

export default defineComponent({
  name: "RentalAgreementCard",
  computed: {
    DPE() {
      return DPE;
    },
  },
  components: {
    YesNoSwitch,
    Dialog,
    DialogRight,
    Title,
    RentalAgreementsFilterModal,
    RentalAgreementModal,
  },
  props: {
    rentalAgreementId: {
      type: String,
      required: true,
    },
  },

  setup(props, context) {
    const isOpenFilterRentalAgreements: Ref<boolean> = ref(false);
    const selectedRentalAgreement: Ref<RentalAgreement | undefined> = ref();
    const selectedRentalAgreementRealEstateAsset: Ref<
      RealEstateAsset | undefined
    > = ref();
    const stepSelected: Ref<Number | undefined> = ref(0);
    const createRentalAgreementModal: Ref<boolean | undefined> = ref();
    const rentalAgreement = computed(() =>
      rentalAgreementsStore.getRentalAgreement(props.rentalAgreementId)
    );
    const realEstateAsset: ComputedRef<RealEstateAsset | undefined> = computed(
      () => {
        if (rentalAgreement.value?.product.realEstateAsset?.id)
          return realEstateAssetsStore.getRealEstateAsset(
            rentalAgreement.value?.product.realEstateAsset?.id
          );
      }
    );
    const tenants = computed(() => {
      return tenantsStore.getTenantsByRentalAgreementId(
        props.rentalAgreementId
      );
    });
    const indexData: Ref<String[]> = ref([]);

    const canIndexRent = computed(() => {
      if (realEstateAsset.value) {
        return (
          (subscriptionsStore.isPremium || subscriptionsStore.isOptimum) &&
          realEstateAsset.value.dpe?.dpeGrade !== DPE.F &&
          realEstateAsset.value.dpe?.dpeGrade !== DPE.G &&
          !!rentalAgreement.value?.financialConditions.indexation &&
          rentalAgreement.value?.financialConditions.indexation?.index
        );
      }
    });
    const expiredRent = ref(false);
    const beNotified = ref(false);

    const openModal = ref(true);
    const modalErrorValue = ref<IndexationError | undefined>();
    const onCloseModal = () => {
      openModal.value = false;
    };

    watch(beNotified, (newValue) => {
      if (newValue) {
        onCloseModal();
      }
    });

    watch(
      rentalAgreement,
      (newValue) => {
        if (newValue) {
          const endDate = newValue.endAt;
          if (endDate && getMoment(endDate).isBefore(getMoment())) {
            expiredRent.value = true;
          }
        }
      },
      { immediate: true }
    );

    const getStatusOfRentalAgreement = () => {
      const today = getMoment();
      if (
        rentalAgreement.value &&
        rentalAgreement.value.endAt &&
        getMoment(rentalAgreement.value.endAt).isBefore(today)
      ) {
        return "expired";
      } else if (
        rentalAgreement.value &&
        rentalAgreement.value.startAt &&
        getMoment(rentalAgreement.value.startAt).isAfter(today)
      ) {
        return "inactive";
      } else {
        return "active";
      }
    };

    function openDetails(ra: RentalAgreement, step: Number) {
      selectedRentalAgreement.value = ra;
      stepSelected.value = step;
      if (ra?.product?.realEstateAsset?.id) {
        selectedRentalAgreementRealEstateAsset.value =
          realEstateAssetsStore.getRealEstateAsset(
            ra.product.realEstateAsset.id
          );
        createRentalAgreementModal.value = true;
      }
    }

    const closeDetails = () => {
      createRentalAgreementModal.value = false;
      selectedRentalAgreement.value = undefined;
    };

    const bankCorrect = computed(() => {
      const stepBankCorrect = tasksStore.userNotificationsTasks.find(
        (task) => task.code === TaskCode.BankCorrect
      );

      return !stepBankCorrect;
    });

    const goToIndexation = () => {
      if (indexData !== undefined) {
        if (props.rentalAgreementId) {
          switch (context.root.$route.name) {
            case ROUTE_NAMES.RealEstate:
              if (realEstateAsset.value)
                return context.root.$router.push({
                  name: ROUTE_NAMES.Indexation,
                  params: {
                    productId: productsStore.currentId,
                    realEstateAssetId: realEstateAsset.value.id,
                    rentalAgreementId: props.rentalAgreementId,
                  },
                });
              break;
            case ROUTE_NAMES.RentalAgreements:
              if (realEstateAsset.value)
                return context.root.$router.push({
                  name: ROUTE_NAMES.Indexation,
                  params: {
                    productId: productsStore.currentId,
                    realEstateAssetId: realEstateAsset.value.id,
                    rentalAgreementId: props.rentalAgreementId,
                  },
                });
              break;
            case ROUTE_NAMES.RealEstateLMNP:
              if (realEstateAsset.value) {
                context.root.$router.push({
                  name: ROUTE_NAMES.IndexationLMNP,
                  params: {
                    productId: productsStore.currentId,
                    realEstateAssetId: realEstateAsset.value.id,
                    rentalAgreementId: props.rentalAgreementId,
                  },
                });
              }
              break;
          }
        }
        if (realEstateAsset.value) {
          context.root.$router.push({
            name: ROUTE_NAMES.Indexation,
            params: {
              productId: productsStore.currentId,
              realEstateAssetId: realEstateAsset.value.id,
              rentalAgreementId: props.rentalAgreementId,
            },
          });
          dispatchIndexationEvent({
            userId: usersStore.loggedInUser.id,
            productId: productsStore.currentId,
            date: getMoment().toISOString(),
            code: IndexationEventCode.ENTER_INDEXATION_INTERFACE,
          });
        }
      } else {
        // const now = new Date();
        // const currentMonth = now.getMonth() + 1;
        // const nextRevisionMonth =
        //   currentMonth < 3
        //     ? 3
        //     : currentMonth < 6
        //     ? 6
        //     : currentMonth < 9
        //     ? 9
        //     : 12;
        // const nextRevisionDate = dayjs(
        //   new Date(now.getFullYear(), nextRevisionMonth - 1, 1)
        // ).format("DD/MM/YYYY");
        // openModal.value = true;

        // modalErrorValue.value = {
        //   title:
        //     "Vous tentez de réviser votre loyer avant la date de référence indiquée dans votre bail. Merci de patienter jusqu'à cette date pour procéder à la révision de votre loyer.",
        //   hasAlert: true,
        //   message: `La prochaine révision pourra avoir lieu maximum à partir du ${nextRevisionDate}`,
        // };
        openModal.value = true;

        modalErrorValue.value = {
          title: "Connexion avec l'INSEE temporairement interrompue.",
          hasAlert: false,
          message:
            "Le service de récupération automatique du nouvel IRL est actuellement indisponible. Veuillez réessayer ultérieurement.",
        };
        dispatchIndexationEvent({
          userId: usersStore.loggedInUser.id,
          productId: productsStore.currentId,
          date: getMoment().toISOString(),
          code: IndexationEventCode.ENTER_INDEXATION_INTERFACE_ERROR,
        });
      }
    };

    const goToIndexationHistory = () => {
      switch (context.root.$route.name) {
        case ROUTE_NAMES.RealEstate:
          if (realEstateAsset.value) {
            context.root.$router.push({
              name: ROUTE_NAMES.Indexation,
              params: {
                productId: productsStore.currentId,
                realEstateAssetId: realEstateAsset.value.id,
                rentalAgreementId: props.rentalAgreementId,
              },
              query: {
                tabIndex: "1",
              },
            });
          }
          break;
        case ROUTE_NAMES.RentalAgreements:
          if (realEstateAsset.value) {
            context.root.$router.push({
              name: ROUTE_NAMES.Indexation,
              params: {
                productId: productsStore.currentId,
                realEstateAssetId: realEstateAsset.value.id,
                rentalAgreementId: props.rentalAgreementId,
              },
              query: {
                tabIndex: "1",
              },
            });
          }
          break;
        case ROUTE_NAMES.RealEstateLMNP:
          if (realEstateAsset.value) {
            context.root.$router.push({
              name: ROUTE_NAMES.Indexation,
              params: {
                productId: productsStore.currentId,
                realEstateAssetId: realEstateAsset.value.id,
                rentalAgreementId: props.rentalAgreementId,
              },
              query: {
                tabIndex: "1",
              },
            });
          }
          break;
      }
    };

    const goToRegularization = () => {
      switch (context.root.$route.name) {
        case ROUTE_NAMES.RealEstate:
          if (realEstateAsset.value) {
            context.root.$router.push({
              name: ROUTE_NAMES.Regularization,
              params: {
                productId: productsStore.currentId,
                realEstateAssetId: realEstateAsset.value.id,
                rentalAgreementId: props.rentalAgreementId,
              },
            });
          }
          break;
        case ROUTE_NAMES.RentalAgreements:
          if (realEstateAsset.value) {
            context.root.$router.push({
              name: ROUTE_NAMES.Regularization,
              params: {
                productId: productsStore.currentId,
                realEstateAssetId: realEstateAsset.value.id,
                rentalAgreementId: props.rentalAgreementId,
              },
            });
          }
          break;
        case ROUTE_NAMES.RealEstateLMNP:
          if (realEstateAsset.value) {
            if (realEstateAsset.value) {
              context.root.$router.push({
                name: ROUTE_NAMES.RegularizationLMNP,
                params: {
                  productId: productsStore.currentId,
                  realEstateAssetId: realEstateAsset.value.id,
                  rentalAgreementId: props.rentalAgreementId,
                },
              });
            }
          }
          break;
      }
    };

    const goToRegularizationHistory = () => {
      switch (context.root.$route.name) {
        case ROUTE_NAMES.RealEstate:
          if (realEstateAsset.value) {
            context.root.$router.push({
              name: ROUTE_NAMES.RegularizationHistory,
              params: {
                productId: productsStore.currentId,
                realEstateAssetId: realEstateAsset.value.id,
                rentalAgreementId: props.rentalAgreementId,
              },
            });
          }
          break;
        case ROUTE_NAMES.RentalAgreements:
          if (realEstateAsset.value) {
            context.root.$router.push({
              name: ROUTE_NAMES.RegularizationHistory,
              params: {
                productId: productsStore.currentId,
                realEstateAssetId: realEstateAsset.value.id,
                rentalAgreementId: props.rentalAgreementId,
              },
            });
          }
          break;
        case ROUTE_NAMES.RealEstateLMNP:
          if (realEstateAsset.value) {
            context.root.$router.push({
              name: ROUTE_NAMES.RegularizationHistoryLMNP,
              params: {
                productId: productsStore.currentId,
                realEstateAssetId: realEstateAsset.value.id,
                rentalAgreementId: props.rentalAgreementId,
              },
            });
          }
          break;
      }
    };

    const redirectToRents = () => {
      switch (context.root.$route.name) {
        case ROUTE_NAMES.RealEstate:
          context.root.$router.push({
            name: ROUTE_NAMES.RentalAgreementFlow,
            params: {
              rentalAgreementId: props.rentalAgreementId,
            },
          });
          break;
        case ROUTE_NAMES.RentalAgreements:
          if (realEstateAsset.value?.id)
            context.root.$router.push({
              name: ROUTE_NAMES.RentalAgreementFlow,
              params: {
                productId: productsStore.currentId,
                rentalAgreementId: props.rentalAgreementId,
                realEstateAssetId: realEstateAsset.value?.id,
              },
            });
          break;
        case ROUTE_NAMES.RealEstateLMNP:
          context.emit("showDetails", rentalAgreement.value);
          break;
      }
    };

    onBeforeMount(async () => {
      const arrayData = await rentIndexationsStore.getListIndexations(
        rentalAgreement.value?.financialConditions.indexation?.type ??
          RentIndexationTypeParamEnum.IRL_metro
      );
      const indexes: String[] = [];
      for (const data of arrayData) {
        indexes.push(data.indexData);
      }
      indexData.value = indexes;
    });
    return {
      bankCorrect,
      createRentalAgreementModal,
      subscriptionsStore,
      stepSelected,
      selectedRentalAgreementRealEstateAsset,
      selectedRentalAgreement,
      openDetails,
      closeDetails,
      rentalAgreement,
      realEstateAsset,
      isOpenFilterRentalAgreements,
      getStatusOfRentalAgreement,
      tenants,
      PlanType: SubscriptionsModel.PlanType,
      getMoment,
      TenantTypeEnum,
      goToIndexation,
      goToIndexationHistory,
      goToRegularization,
      goToRegularizationHistory,
      canIndexRent,
      redirectToRents,
      expiredRent,
      openModal,
      modalErrorValue,
      onCloseModal,
      beNotified,
    };
  },
});
