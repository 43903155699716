



















































































import {
  computed,
  ComputedRef,
  defineComponent,
  PropType,
} from "@vue/composition-api";
import SectionTitle from "@/components/atom/SectionTitle.vue";
import { YesNoSwitch } from "@/components/atom/switch";
import DatePicker from "@/components/atom/DatePicker.vue";
import {
  getMoment,
  PropertyTypeEnum,
  NewRegularization,
  RentalAgreement,
} from "@edmp/api";
import { VForm } from "@/models";
import { rentalAgreementsStore } from "@/store";

export default defineComponent({
  name: "RegularizationInitial",
  components: { DatePicker, YesNoSwitch, SectionTitle },
  props: {
    regularization: {
      type: Object as PropType<NewRegularization>,
      required: true,
    },
  },
  setup(props, context) {
    const propertyTypeItems = [
      { value: PropertyTypeEnum.CO_OWNERSHIP, text: "Copropriété" },
      { value: PropertyTypeEnum.SINGLE_OWNERSHIP, text: "Monopropriété" },
      {
        value: PropertyTypeEnum.WITHOUT_COLLECTIVITY,
        text: "Sans collectivité",
      },
    ];

    const rentalAgreement: ComputedRef<RentalAgreement> = computed(
      () =>
        rentalAgreementsStore.getRentalAgreement(
          props.regularization.rentalAgreementId
        ) as RentalAgreement
    );

    const minDate = computed(() => rentalAgreement.value.startAt);

    const maxDate = computed(() => {
      if (rentalAgreement.value.endAt) {
        return rentalAgreement.value.endAt;
      } else {
        return getMoment().format("YYYY-MM-DD");
      }
    });

    const goBack = () => {
      context.emit("goBack");
    };

    const goNext = () => {
      if ((context.refs.form as VForm).validate()) {
        context.emit("goNext");
      }
    };

    return {
      propertyTypeItems,
      minDate,
      maxDate,
      goBack,
      goNext,
    };
  },
});
