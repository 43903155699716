





















































































































































































































































































































































































































































































































import Tag from "@/components/atom/Tag.vue";
import TaxDeclarationValidate from "@/components/core/events/yearEnd/TaxDeclarationValidate.vue";
import TaskGeneralAssemblyDialog from "@/components/core/tasks/TaskGeneralAssemblyDialog.vue";
import TaskPickGeneralAssemblyDialog from "@/components/core/tasks/TaskPickGeneralAssemblyDialog.vue";
import eventBus from "@/events/eventBus";
import { TaskLocal } from "@/models";
import router from "@/router";
import { ROUTE_NAMES } from "@/router/routes";
import {
  accountingBalanceSheetsStore,
  accountingPeriodsStore,
  realEstateAssetsStore,
  rentalBuildingsStore,
  subscriptionsStore,
  tasksStore,
  transactionsStore,
} from "@/store";
import { FilterKeys, isSmallestAccountingPeriod } from "@/utils";
import {
  getMoment,
  getReferredIdByTypeWithReferences,
  TaskBehavior,
  TaskCode,
  TaskGroupCode,
  TasksService,
  TaskStatus,
  TaskTypeReference,
  TypeTask,
} from "@edmp/api";
import { OmitField } from "@edmp/api";
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  PropType,
  reactive,
  ref,
} from "@vue/composition-api";
import { cloneDeep } from "lodash";
import AccrualsInventory from "../../operations/accruals/inventory/AccrualsInventory.vue";

export default defineComponent({
  name: "TaskAction",
  methods: {},
  components: {
    Tag,
    TaxDeclarationValidate,
    TaskGeneralAssemblyDialog,
    TaskPickGeneralAssemblyDialog,
    AccrualsInventory,
  },
  props: {
    taskLocal: {
      type: Object as PropType<TaskLocal>,
      required: true,
    },
    resolveTask: {
      type: Function as PropType<
        (
          task: TaskLocal,
          data?: OmitField<TasksService.ValidateIn, "id">
        ) => Promise<void>
      >,
      required: false,
    },
  },
  setup(props) {
    const generateActionMessage = (task: TaskLocal) => {
      return task.status === TaskStatus.COMPLETED ? "Voir" : "À faire";
    };
    const navigateToTransactions = (filterCode: FilterKeys) => {
      transactionsStore.resetFilter();
      transactionsStore.addFilter({ code: filterCode });
      router.push({ name: ROUTE_NAMES.Transactions });
    };
    const isIR = computed(() => accountingPeriodsStore.isIR);
    const isIS = computed(() => accountingPeriodsStore.isIS);
    const isLMNP = computed(() => accountingPeriodsStore.isLMNP);
    const isFirstAccountingPeriod = computed(
      () => accountingPeriodsStore.currentAccountingPeriod?.firstYear
    );
    const isPremium = computed(() => subscriptionsStore.isPremium);
    const isOptimum = computed(() => subscriptionsStore.isOptimum);
    const isLmnpOptimum = computed(() => subscriptionsStore.isLMNPOptimum);
    const isLmnpEssentiel = computed(() => subscriptionsStore.isLMNPEssential);

    const transactions = reactive({
      transactionsToBeCategorizedNumber: computed(
        () => transactionsStore.numberOfTransactionsToBeCategorized
      ),
      transactionsAnomalyNumber: computed(
        () => transactionsStore.numberOfTransactionsToBeAnomalized
      ),
    });

    const realEstateAssetsNotAssign = computed(() =>
      realEstateAssetsStore.realEstateAssets.filter((realEstateAsset) => {
        for (const rentalBuilding of rentalBuildingsStore.getRentalBuildingByAccountingPeriodId(
          accountingPeriodsStore.currentId
        )) {
          if (rentalBuilding.realEstateAssetIds.includes(realEstateAsset.id)) {
            return false;
          }
        }
        return true;
      })
    );

    const startWellTaskGroup = computed(() => {
      return tasksStore.startWellTaskGroup;
    });
    const accountingBalanceSheetRecoveryNotOpen = computed(() =>
      isIS.value
        ? !!startWellTaskGroup.value
            .find(
              (taskGroup) =>
                taskGroup.groupCode === TaskGroupCode.StartWellAccounting
            )
            ?.tasksLocal.find(
              (taskLocal) =>
                (taskLocal.code === TaskCode.PartnerCreate ||
                  taskLocal.code === TaskCode.AssetUpdate ||
                  taskLocal.code === TaskCode.BankAccountLink ||
                  taskLocal.code === TaskCode.AmortisationUpdate) &&
                taskLocal.status === TaskStatus.PENDING
            )
        : isLMNP.value
        ? !!startWellTaskGroup.value
            .find(
              (taskGroup) =>
                taskGroup.groupCode === TaskGroupCode.StartWellAccountingLMNP ||
                taskGroup.groupCode === TaskGroupCode.StartWellLMNP
            )
            ?.tasksLocal.find(
              (taskLocal) =>
                (taskLocal.code === TaskCode.DeferrableAmortisationsAdd ||
                  taskLocal.code === TaskCode.DeferrableDeficitsAdd ||
                  taskLocal.code === TaskCode.AssetUpdate ||
                  taskLocal.code === TaskCode.AmortisationUpdate ||
                  taskLocal.code === TaskCode.LoanAdd) &&
                taskLocal.status === TaskStatus.PENDING
            )
        : true
    );
    const accountingBalanceSheetRecoveryNotValidate = computed(
      () =>
        !!startWellTaskGroup.value
          .find((taskGroup) =>
            isIS.value
              ? taskGroup.groupCode === TaskGroupCode.StartWellAccounting
              : isLMNP.value
              ? taskGroup.groupCode === TaskGroupCode.StartWellAccountingLMNP
              : false
          )
          ?.tasksLocal.find(
            (taskLocal) =>
              taskLocal.code === TaskCode.BalanceSheetRecovery &&
              taskLocal.status === TaskStatus.PENDING
          )
    );
    const navigateToBalanceSheetRecovery = () => {
      if (accountingBalanceSheetRecoveryNotOpen.value) {
        router.push({ name: ROUTE_NAMES.Tasks });
        return;
      }
      if (accountingBalanceSheetRecoveryNotValidate.value) {
        router.push({ name: ROUTE_NAMES.AccountingBalanceSheetRecovery });
        return;
      }
    };

    const accountingBalanceSheetRecovery = computed(() =>
      cloneDeep(
        accountingBalanceSheetsStore
          .getAccountingBalanceSheetByType("recovery")
          .find(
            (accountingBalanceSheet) =>
              accountingBalanceSheet.type === "recovery" &&
              getMoment(accountingBalanceSheet.endAt).isSame(
                getMoment(
                  accountingPeriodsStore.firstAccountingPeriodNotClosed.endAt
                ).subtract(1, "year")
              )
          )
      )
    );
    const isValidateAccountingBalanceSheetRecovery = computed(
      () =>
        !tasksStore.notCompletedTasks.find((task) => {
          const accountingPeriodId = getReferredIdByTypeWithReferences(
            task.references,
            TaskTypeReference.accountingPeriod
          );
          return (
            task.groupCode === TaskGroupCode.AccountingPeriod &&
            (task.code === TaskCode.BalanceSheetRecovery ||
              task.code === TaskCode.InjectBalance) &&
            accountingPeriodId &&
            isSmallestAccountingPeriod(accountingPeriodId)
          );
        })
    );
    const skipAccountingBalanceSheetRecovery = async () => {
      if (accountingBalanceSheetRecovery.value?.type === "recovery") {
        accountingBalanceSheetRecovery.value.isSkipped =
          !accountingBalanceSheetRecovery.value.isSkipped;
        await accountingBalanceSheetsStore.updateAccountingBalanceSheet(
          accountingBalanceSheetRecovery.value
        );
      }
    };

    const accountingBalanceSheet = computed(() =>
      cloneDeep(
        accountingBalanceSheetsStore
          .getAccountingBalanceSheetByType("closure")
          .find(
            (accountingBalanceSheet) =>
              accountingBalanceSheet.type === "closure" &&
              accountingBalanceSheet.accountingPeriodId ===
                accountingPeriodsStore.currentId
          )
      )
    );
    const isValidateAccountingBalanceSheet = computed(
      () => accountingBalanceSheet.value?.isValidated
    );

    const previousTaskFlow = computed(() => {
      const previousTask = [...tasksStore.taskGroups]
        .flatMap((t) => t.tasksLocal)
        .find(
          (t) =>
            t.type === TypeTask.FLOW &&
            t.references?.every((r) =>
              props.taskLocal.references
                ?.flatMap((r) => r.referredId)
                .includes(r.referredId)
            ) &&
            !!(props.taskLocal.behaviors as TaskBehavior<TypeTask.FLOW>[]).find(
              (b) =>
                accountingPeriodsStore.currentAccountingPeriod &&
                b.taxRegime ===
                  accountingPeriodsStore.currentAccountingPeriod?.taxRegime &&
                b.isBlockedByTaskCodes?.includes(t.code)
            )
        );

      return previousTask;
    });

    const isOpenTaxDeclarationValidateModal = ref(false);
    const isOpenAccrualsInventoryModal = ref(false);

    const isOpenGeneralAssemblyDialog = ref(false);

    const handleGeneralAssemblyDialog = (boolean) => {
      if (boolean) isOpenGeneralAssemblyDialog.value = boolean;
    };

    const isOpenPickGeneralAssemblyDialog = ref(false);

    const taskReferencesHasAssembly = (taskLocal) => {
      console.log(taskLocal);
      return taskLocal.references.some(
        (reference) => reference.type === TaskTypeReference.assembly
      );
    };

    onMounted(() => {
      if (props.taskLocal.code === TaskCode.InviteYearEnd)
        eventBus.$on("openGeneralAssemblyDialog", handleGeneralAssemblyDialog);
    });

    onUnmounted(() => {
      if (props.taskLocal.code === TaskCode.InviteYearEnd)
        eventBus.$off("openGeneralAssemblyDialog");
    });

    return {
      generateActionMessage,
      navigateToTransactions,
      navigateToBalanceSheetRecovery,
      isValidateAccountingBalanceSheetRecovery,
      skipAccountingBalanceSheetRecovery,
      accountingBalanceSheetRecoveryNotOpen,
      accountingBalanceSheetRecoveryNotValidate,
      FilterKeys,
      TaskCode,
      TaskGroupCode,
      isIR,
      isIS,
      isLMNP,
      isFirstAccountingPeriod,
      isPremium,
      isOptimum,
      isLmnpOptimum,
      isLmnpEssentiel,
      transactions,
      realEstateAssetsNotAssign,
      ROUTE_NAMES,
      isValidateAccountingBalanceSheet,
      isOpenTaxDeclarationValidateModal,
      isOpenAccrualsInventoryModal,
      TaskStatus,
      previousTaskFlow,
      isOpenGeneralAssemblyDialog,
      isOpenPickGeneralAssemblyDialog,
      taskReferencesHasAssembly,
      tasksStore,
    };
  },
});
