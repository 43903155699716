





































































import { defineComponent, onBeforeMount, ref, Ref } from "@vue/composition-api";
import GenericTable, {
  TableHeaderItem,
} from "@/components/atom/table/GenericTable.vue";
import {
  accountingPeriodsStore,
  documentsStore,
  regularizationsStore,
} from "@/store";
import { ROUTE_NAMES } from "@/router/routes";
import PageHeader from "@/components/atom/PageHeader.vue";
import { Regularization } from "@edmp/api";

export default defineComponent({
  name: "RegularizationHistory",
  components: { PageHeader, GenericTable },
  setup(props, context) {
    const regularizationsArray: Ref<Regularization[]> = ref([]);

    const historyTableHeaders: TableHeaderItem[] = [
      { text: "Date", value: "date" },
      { text: "Provisions pour charges", value: "provisions" },
      { text: "Charges réelles", value: "charges" },
      { text: "Résultat", value: "result" },
    ];

    const deleteRegularization = (id) =>
      regularizationsStore.removeRegularization(id);

    function getNoticeRegularizationFile(id: string) {
      return documentsStore.getDocument(id);
    }
    // Init
    async function init() {
      const regularizations = await regularizationsStore.fetchRegularizations(
        context.root.$route.params.rentalAgreementId
      );
      regularizationsArray.value = regularizations.reverse();
    }

    onBeforeMount(() => init());

    return {
      historyTableHeaders,
      regularizationsArray,
      deleteRegularization,
      ROUTE_NAMES,
      accountingPeriodsStore,
      getNoticeRegularizationFile,
    };
  },
});
