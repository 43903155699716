




















































































































































import { VForm } from "@/models";
import { taxDeclarationsService } from "@/services/TaxDeclarations.service";
import {
  accountingPeriodsStore,
  accountingResultsStore,
  coreStore,
  documentsStore,
  assembliesStore,
  tasksStore,
} from "@/store";
import { FeedbackTypeEnum } from "@/store/modules/Core.store";
import { downloadFileFromUrl } from "@/utils";
import {
  AccountingResultLineType,
  Direction,
  GeneralAssemblyEvent,
  getDefaultAgendas,
  getDefaultResolutions,
  getMoment,
  Partner,
  PartnerTypeEnum,
  TaxDeclaration2033,
} from "@edmp/api";
import {
  computed,
  ComputedRef,
  defineComponent,
  onBeforeMount,
  ref,
  Ref,
} from "@vue/composition-api";
import moment from "moment";

export default defineComponent({
  name: "EventsGeneralAssemblyPVModal",
  props: {
    event: {
      type: Object as () => GeneralAssemblyEvent,
      required: true,
    },
    hour: String,
    partnersList: {
      type: Array as () => Array<Partner>,
    },
  },
  setup(props, context) {
    const partnersListRepresent: Ref<string[]> = ref([]);
    const partnersListPresent: Ref<string[]> = ref([]);
    const currentPeriod = computed(
      () => accountingPeriodsStore.currentAccountingPeriod
    );

    const isLoadingAccountingResult: Ref<boolean> = ref(false);
    const isLoadingTaxResult: Ref<boolean> = ref(false);
    const agHourEnd: Ref<string> = ref("");
    const agendas = computed(() => {
      if (currentPeriod.value) {
        const taxRegime = currentPeriod.value.taxRegime;
        const endAt = getMoment(currentPeriod.value.endAt).format("DD/MM/YYYY");

        const defaultAgendas = getDefaultAgendas(endAt)[taxRegime];
        return defaultAgendas || ["", "", ""];
      }
      return ["", "", ""];
    });
    const resolutions = computed(() => {
      if (currentPeriod.value) {
        const taxRegime = currentPeriod.value.taxRegime;
        const startAt = getMoment(currentPeriod.value.startAt).format(
          "DD/MM/YYYY"
        );
        const hourEnd = agHourEnd.value;

        const defaultResolutions = getDefaultResolutions(startAt, hourEnd)[
          taxRegime
        ];
        return defaultResolutions || ["", "", ""];
      }

      return ["", "", ""];
    });

    const totalBenef: Ref<string> = ref("");
    const accountingResult: ComputedRef<string> = computed(() => {
      if (accountingPeriodsStore.isIS) {
        const accountingResultLine =
          accountingResultsStore.getCurrentAccountingResult?.lines.find(
            (line) => {
              return line.type === AccountingResultLineType.ACCOUNTING_RESULT;
            }
          );
        const amount = accountingResultLine?.amount;
        if (amount) {
          return accountingResultLine.direction === Direction.debit
            ? "-" + amount.toString()
            : amount.toString();
        }
      }
      return "";
    });
    const taxDeclaration2033: Ref<TaxDeclaration2033 | undefined> =
      ref(undefined);
    const initTaxDeclaration2033 = async () => {
      taxDeclaration2033.value = await taxDeclarationsService.get2033({
        accountingPeriodId: accountingPeriodsStore.currentId,
      });
    };
    const taxResult: ComputedRef<string> = computed(() => {
      if (taxDeclaration2033.value) {
        const taxResultLine =
          taxDeclaration2033.value.lines["372"].amount != 0
            ? taxDeclaration2033.value.lines["372"]
            : taxDeclaration2033.value.lines["370"];
        return taxResultLine.amountDirection === Direction.debit
          ? "-" + taxResultLine.amount.toString()
          : taxResultLine.amount.toString();
      }
      return "";
    });

    function closeDialog() {
      context.emit("close");
    }

    function validateFormat(generalAssemblyHour: string) {
      return moment(generalAssemblyHour, "HH:mm", true).isValid();
    }

    async function updateGeneralAssembly(): Promise<void> {
      // Update AG Report
      const assemblyUpdated = await assembliesStore.report({
        id: props.event.id,
        report: {
          partnersPresent: partnersListPresent.value,
          partnersRepresent: partnersListRepresent.value,
          totalBilan: totalBenef.value,
          totalBenefice: accountingResult.value,
          totalBeneficeFiscal: taxResult.value,
          agHourBegin: props.hour ? props.hour : "",
          agHourEnd: agHourEnd.value,
          agendas: agendas.value,
          resolutions: resolutions.value,
        },
      });

      tasksStore.fetchTaskGroups({});
      await documentsStore.fetchDocuments();
      coreStore.displayFeedback({
        type: FeedbackTypeEnum.FEEDBACK,
        message: `Un nouveau document a été généré par Ownily et est disponible dans l'espace Mes documents ou dans la section document du détail de votre Assemblée Générale`,
        timeout: 8000,
      });

      const document = assemblyUpdated.document;
      if (document?.href) {
        downloadFileFromUrl(document.href, document.name ?? "PV");
      }

      context.emit("validate");
    }

    async function validate() {
      if ((context.refs.form as VForm).validate()) {
        // Update AG Report
        await updateGeneralAssembly();
      }
    }

    const presentPartners = computed(() => {
      if (props.partnersList) {
        return props.partnersList
          .filter((p) => !partnersListRepresent.value.includes(p.id))
          .map((p) => ({
            text:
              p.type === PartnerTypeEnum.LEGAL_PERSON
                ? `${p.denomination}`
                : `${p.firstName} ${p.lastName.toUpperCase()}`,
            value: p.id,
          }));
      } else {
        return [];
      }
    });

    const representPartners = computed(() => {
      if (props.partnersList) {
        return props.partnersList
          .filter((p) => !partnersListPresent.value.includes(p.id))
          .map((p) => ({
            text:
              p.type === PartnerTypeEnum.LEGAL_PERSON
                ? `${p.denomination}`
                : `${p.firstName} ${p.lastName.toUpperCase()}`,
            value: p.id,
          }));
      } else {
        return [];
      }
    });

    onBeforeMount(async () => {
      if (accountingPeriodsStore.isIS) {
        isLoadingAccountingResult.value = true;
        isLoadingTaxResult.value = true;
        await accountingResultsStore.fetchAccountingResults();
        isLoadingAccountingResult.value = false;
        await initTaxDeclaration2033();
        isLoadingTaxResult.value = false;
      }
    });

    return {
      currentPeriod,
      isLoadingAccountingResult,
      isLoadingTaxResult,
      accountingPeriodsStore,
      partnersListRepresent,
      partnersListPresent,
      presentPartners,
      representPartners,
      totalBenef,
      accountingResult,
      taxResult,
      agHourEnd,
      agendas,
      resolutions,
      validate,
      closeDialog,
      validateFormat,
      updateGeneralAssembly,
    };
  },
});
