
















































































































































































































import router from "@/router";
import { ROUTE_NAMES } from "@/router/routes";
import { usersService } from "@/services";
import {
  productsStore,
  coreStore,
  tasksStore,
  usersStore,
  accountingPeriodsStore,
  realEstateAssetsStore,
  subscriptionsStore,
} from "@/store";
import { isClosureOpen } from "@/utils";
import {
  ProductsModel,
  TaskStatus,
  TaskCode,
  TaskGroupCode,
  TaxRegime,
} from "@edmp/api";
import { computed, defineComponent } from "@vue/composition-api";
export default defineComponent({
  name: "WelcomeBack",
  setup(_, context) {
    const taskGroups = computed(() => {
      return tasksStore.startWellTaskGroup;
    });

    const vAlertInfoText = computed(() => {
      if (
        accountingPeriodsStore.currentAccountingPeriod?.taxRegime ===
        TaxRegime.LMNP_2031
      ) {
        return 'Consulter la rubrique "Commencer sur Ownily en LMNP" du centre d\'aide';
      } else {
        return 'Consulter la rubrique "Premiers pas" du centre d\'aide';
      }
    });

    const vAlertInfoLink = computed(() => {
      if (
        accountingPeriodsStore.currentAccountingPeriod?.taxRegime ===
        TaxRegime.LMNP_2031
      ) {
        return "https://ownily.crisp.help/fr/article/lmnp-commencer-sur-ownily-en-lmnp-qv2v62/";
      } else {
        return "https://ownily.crisp.help/fr/category/premiers-pas-sur-ownily-n5g064/";
      }
    });

    const sciOrLmnpText = computed(() => {
      if (
        accountingPeriodsStore.currentAccountingPeriod?.taxRegime ===
        TaxRegime.LMNP_2031
      ) {
        return "activité LMNP";
      } else {
        return "SCI";
      }
    });

    const linkBankAccountNotCompleted = computed(
      () =>
        !!taskGroups.value
          .find(
            (taskGroup) =>
              taskGroup.groupCode === TaskGroupCode.StartWell ||
              taskGroup.groupCode === TaskGroupCode.StartWellAccounting
          )
          ?.tasksLocal.filter(
            (task) =>
              task.code === TaskCode.BankAccountLink &&
              task.status !== TaskStatus.COMPLETED
          ).length
    );

    const acceptCGU = computed(
      () =>
        !usersStore.loggedInUser.tos.find(
          (tosItem) =>
            tosItem.version === coreStore.config.user.tosVersion &&
            tosItem.accepted
        )
    );

    const navigateToClosure = () =>
      router.push({ name: ROUTE_NAMES.EventsYearEnd });
    const navigateToSubscriptions = () =>
      router.push({ name: ROUTE_NAMES.Subscriptions });
    const closureModal = computed(() => isClosureOpen());
    const firstModal = computed(() => linkBankAccountNotCompleted.value);
    const secondModal = computed(() => {
      if (
        !taskGroups.value.every(
          (taskGroup) => taskGroup.status === TaskStatus.COMPLETED
        )
      ) {
        return true;
      }
      return false;
    });

    const goToCgu = () => {
      window.open(
        `https://www.ownily.fr/legal/conditions-generales-utilisation`,
        "_blank"
      );
    };
    const validateCgu = async () => {
      await usersService.acceptTos({
        id: "me",
        tos: { version: coreStore.config.user.tosVersion, accepted: true },
      });
      await usersStore.fetchLoggedInUser();
    };

    const goToTasks = () => {
      context.root.$router.push({ name: ROUTE_NAMES.Tasks });
      tasksStore.fetchTaskGroups({});
    };

    const goToRealEstateFlow = () => {
      context.root.$router.push({
        name: ROUTE_NAMES.RealEstateAssets,
      });
    };

    const goToNavigate = () => {
      context.emit("close");
      if (accountingPeriodsStore.isLMNP) {
        if (realEstateAssetsStore.realEstateAssets.length === 0) {
          goToRealEstateFlow();
        } else {
          context.root.$router.push({ name: ROUTE_NAMES.Transactions });
        }
      } else {
        if (closureModal.value) {
          context.root.$router.push({ name: ROUTE_NAMES.Transactions });
        } else {
          goToTasks();
        }
      }
    };

    const stopSync = async () => {
      // Delete query param welcome
      const product = {
        ...productsStore.currentProduct,
      } as ProductsModel.Product;
      await productsStore.updateProduct(product);
      goToNavigate();
    };

    return {
      acceptCGU,
      closureModal,
      navigateToClosure,
      navigateToSubscriptions,
      firstModal,
      secondModal,
      goToCgu,
      validateCgu,
      stopSync,
      goToNavigate,
      vAlertInfoText,
      vAlertInfoLink,
      sciOrLmnpText,
      isFree: computed(() => subscriptionsStore.isFree),
    };
  },
});
