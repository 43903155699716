


























































































import router from "@/router";
import { ROUTE_NAMES } from "@/router/routes";
import { productsStore } from "@/store";
import { ProductsModel } from "@edmp/api";
import {
  computed,
  defineComponent,
  onBeforeMount,
  ref,
  Ref,
} from "@vue/composition-api";

export default defineComponent({
  name: "BankAccountsLmnpModal",
  setup(_, context) {
    const product = computed(
      () =>
        productsStore.currentProduct as
          | ProductsModel.Product<ProductsModel.ProductTypes.LMNP>
          | undefined
    );

    const isDedicatedBankAccount: Ref<boolean | undefined> = ref();
    const panel = 0;

    const validate = () => {
      if (isDedicatedBankAccount !== undefined) {
        const data = {
          ...product.value,
          id: productsStore.currentId,
          dedicatedBankAccount: isDedicatedBankAccount.value,
        };
        productsStore.updateProduct(data);
        context.emit("validate");
        if (isDedicatedBankAccount.value) {
          router.push({
            name: ROUTE_NAMES.MyBusiness,
            query: {
              tabIndex: "2",
            },
          });
        }
      }
    };

    const init = () => {
      isDedicatedBankAccount.value = product.value?.dedicatedBankAccount;
    };

    onBeforeMount(async () => {
      await init();
    });

    return {
      panel,
      isDedicatedBankAccount,
      validate,
    };
  },
});
