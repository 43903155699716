
















































































import {
  DocumentModelType,
  DocumentFileType,
  RentalAgreement,
} from "@edmp/api";
import { computed, defineComponent, ref } from "@vue/composition-api";
// import { rentalAgreementsStore } from "@/store";
import { rentalsService } from "@/services";
import { regularizationsStore } from "@/store";

export default defineComponent({
  name: "RentalAgreementsPreviewMail",
  props: {
    rentalAgreement: {
      type: Object as () => RentalAgreement &
        Required<Pick<RentalAgreement, "options">>,
      required: true,
    },
    typeOfMail: {
      type: String,
      required: true,
    },
    titleType: {
      type: String,
      required: true,
    },
    index: {
      type: Object,
      required: false,
    },
    regularizationIsFullYear: {
      type: Boolean,
      required: false,
    },
  },
  setup(props) {
    const mailText = ref("");

    const index = computed(() => {
      return props.index
        ? props.index
        : { denomination: "Indice de Référence des Loyers", type: "IRL" };
    });
    const text = ref({
      subject: {
        rentReceipt: "Votre quittance de [Mois concerné + Année en cours]",
        rentCall: "Votre appel de loyer de [Mois concerné + Année en cours]",
        unpaidRentCall: "Relance de loyer impayé",
        regularization: "Votre avis de régularisation des charges locatives",
        indexation: "Préavis de révision de votre loyer",
      },
      html: {
        rentReceipt:
          "Bonjour [Prénom du locataire],<br><br>Nous vous adressons en pièce jointe la <strong>quittance de loyer</strong> pour la période <strong>[Mois concerné + Année en cours]</strong> d'un montant de [Montant]€ pour votre location située au [Adresse du bien loué].<br><br>Cette notification signifie que votre propriétaire a bien reçu le règlement.<br><br>Bonne journée et à bientôt",
        rentCall:
          "Bonjour [Prénom du locataire],<br><br>Nous vous adressons en pièce jointe l'<strong>appel de loyer</strong> pour la période de <strong>[Mois concerné + Année en cours]</strong> d'un montant de <strong>[Montant]</strong>€ pour votre location située au [Adresse du bien loué].<br><br>Vous avez jusqu'au [Date de paiement] pour régler cette somme à votre propriétaire.<br><br>Bonne journée et à bientôt",
        unpaidRentCall:
          "Bonjour [Prénom et nom du locataire],<br><br>Votre loyer d'un montant de <strong>[Montant]</strong>€ pour la période de <strong>[Mois concerné + Année en cours]</strong> n'a pas été réglé dans les temps, conformément au bail signé à l'entrée dans les lieux.Merci de régulariser rapidement votre situation par virement (sous 8 jours à compter de la réception de cet email).<br><br>Nous vous adressons en pièce jointe <strong>l'appel de loyer</strong> de cette location pour la période.<br><br>Cordialement,",
        indexation: `Bonjour [Prénom du locataire],<br><br>Vous trouverez en pièce jointe le préavis de révision de loyer relatif à votre location située au [Adresse du bien].<br><br> Conformément aux termes de notre contrat de location et aux dispositions réglementaires, le loyer a été révisé en fonction de l'${
          index.value.denomination
        } (${index.value.type}) ${
          props.rentalAgreement.financialConditions.indexation?.type === "metro"
            ? "Métropole"
            : props.rentalAgreement.financialConditions.indexation?.type ===
              "corsica"
            ? "Corse"
            : props.rentalAgreement.financialConditions.indexation?.type ===
              "overseas"
            ? "Outre-Mer"
            : ""
        } du trimestre [Numéro de trimestre] publié sur le site de l’INSEE. À compter du [Date du premier paiement actualisé], le montant du loyer applicable tiendra compte de cette révision. Veuillez en prendre connaissance afin de préparer les ajustements nécessaires pour vos futurs paiements.<br><br> Restant à votre disposition pour toute question, <br><br>Bonne journée et à bientôt`,
        regularization:
          "Bonjour [Prénom du locataire],<br><br>Nous vous informons que la régularisation des charges locatives pour votre logement situé au [Adresse du bien] a été réalisée.<br><br>Vous trouverez en pièce jointe le détail de cette régularisation, calculée conformément aux dispositions prévues dans notre contrat de location et en application des règles légales en vigueur. Cette régularisation prend en compte les dépenses réelles engagées au cours de la période du [Date de début de la période] au [Date de fin de la période].<br><br> Le montant ajusté sera pris en compte lors de votre prochain paiement prévu pour le [Date du paiement ajusté].<br><br>Nous restons à votre disposition pour toute question ou précision supplémentaire.<br><br>Bonne journée et à bientôt,",
      },
    });

    const regularization = regularizationsStore
      .getRegularizationsByRentalAgreementId(props.rentalAgreement.id)
      .at(-1);

    // function formatMessage(text: string): string {
    //   return text
    //     .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>")
    //     .replace(/\n/g, "<br>")
    //     .replace("Votre quittance", "<strong>Objet</strong> : Votre quittance")
    //     .replace("Votre appel", "<strong>Objet</strong> : Votre appel")
    //     .replace("Relance", "<strong>Objet </strong>: Relance");
    // }
    const downloadModel = async (type: string) => {
      if (type === "tenant.rent-receipt") {
        await rentalsService.agreements.downloadDocumentModel({
          modelType: DocumentModelType.RENT_RECEIPT,
          fileType: DocumentFileType.PDF,
        });
      } else if (type === "tenant.rent-call") {
        await rentalsService.agreements.downloadDocumentModel({
          modelType: DocumentModelType.RENT_CALL,
          fileType: DocumentFileType.PDF,
        });
      } else if (type === "tenant.unpaid-rent-call") {
        await rentalsService.agreements.downloadDocumentModel({
          modelType: DocumentModelType.REMINDER_OF_UNPAID_RENT,
          fileType: DocumentFileType.PDF,
        });
      } else if (type === "tenant.indexation") {
        switch (index.value.type) {
          case "ILC":
            rentalsService.agreements.downloadDocumentModel({
              modelType: DocumentModelType.NOTICE_RENT_INDEXATION_ILC,
              fileType: DocumentFileType.PDF,
            });
            break;
          case "ICC":
            rentalsService.agreements.downloadDocumentModel({
              modelType: DocumentModelType.NOTICE_RENT_INDEXATION_ICC,
              fileType: DocumentFileType.PDF,
            });
            break;
          case "ILAT":
            rentalsService.agreements.downloadDocumentModel({
              modelType: DocumentModelType.NOTICE_RENT_INDEXATION_ILAT,
              fileType: DocumentFileType.PDF,
            });
            break;
          case "overseas":
            rentalsService.agreements.downloadDocumentModel({
              modelType: DocumentModelType.NOTICE_RENT_INDEXATION_IRL_OVERSEAS,
              fileType: DocumentFileType.PDF,
            });
            break;
          case "corsica":
            rentalsService.agreements.downloadDocumentModel({
              modelType: DocumentModelType.NOTICE_RENT_INDEXATION_IRL_CORSICA,
              fileType: DocumentFileType.PDF,
            });
            break;
          default:
            rentalsService.agreements.downloadDocumentModel({
              modelType:
                DocumentModelType.NOTICE_RENT_INDEXATION_IRL_METROPOLIS,
              fileType: DocumentFileType.PDF,
            });
            break;
        }
      } else if (type === "tenant.regularization") {
        if (
          regularization &&
          regularization.provisions &&
          regularization.charges &&
          Number(regularization.provisions) > Number(regularization.charges)
        ) {
          if (props.regularizationIsFullYear) {
            rentalsService.agreements.downloadDocumentModel({
              modelType:
                DocumentModelType.NOTICE_REGULARIZATION_TENANT_FULL_YEAR,
              fileType: DocumentFileType.PDF,
            });
          } else {
            rentalsService.agreements.downloadDocumentModel({
              modelType:
                DocumentModelType.NOTICE_REGULARIZATION_TENANT_PARTIAL_YEAR,
              fileType: DocumentFileType.PDF,
            });
          }
        } else {
          if (props.regularizationIsFullYear) {
            rentalsService.agreements.downloadDocumentModel({
              modelType:
                DocumentModelType.NOTICE_REGULARIZATION_LESSOR_FULL_YEAR,
              fileType: DocumentFileType.PDF,
            });
          } else {
            rentalsService.agreements.downloadDocumentModel({
              modelType:
                DocumentModelType.NOTICE_REGULARIZATION_LESSOR_PARTIAL_YEAR,
              fileType: DocumentFileType.PDF,
            });
          }
        }
      }
    };

    /**
    async function init() {
        mailText.value = formatMessage(
          await rentalAgreementsStore.getPreviewMail({
            rentalAgreementId: rentalAgreement.id,
            typeOfMail,
          })
        );
    } 
    */

    // watch(
    //   () => [typeOfMail],
    //   // () => init(),
    //   { deep: true }
    // );
    // onMounted(() => {
    //   init();
    // });

    return {
      mailText,
      downloadModel,
      text,
    };
  },
});
