export namespace TaxDeclarationProviderTeledecModel {
  export namespace API {
    export namespace Auth {
      export enum SCOPE {
        LIASSE = "liasse",
        MARQUE_BLANCHE = "marque-blanche",
        LISTE_CR = "liste-cr",
        ENVOI_LIASSE_EXISTANTE = "envoi-liasse-existante",
        CREATION_ENTREPRISE = "creation-entreprise",
        LISTE_DECLARATIONS = "liste-declarations",
        LIASSE_PDF = "liasse-pdf",
        EXTRAIT_LIASSE = "extrait-liasse",
        CHIFFRES_CLES = "chiffres-cles",
        IMPORT_TVA = "import-tva",
        RENVOI_ACCUSE = "renvoi-accuse",
        END_USER_ACCESS_TOKEN = "end-user-access-token",
      }

      export type TokenIn = {
        grant_type: "client_credentials";
        scope: string;
      };
      export type TokenOut = {
        access_token: string;
        expire_in: 3600;
        token_type: "Bearer";
      };
    }
  }

  export enum LEGAL_FORM {
    Association = "ASS",
    EARL = "ARL",
    Entreprise_Individuelle = "EIR",
    EIRL = "ERL",
    EURL = "GEC",
    GAEC = "SA",
    S_A = "SAS",
    S_A_Simplifiée = "SASU",
    SAS_Unipersonnelle = "SRL",
    SARL = "SEA",
    SCEA = "SCI",
    SCI = "SCM",
    SELARL = "SLR",
    SNC = "SNC",
    Autre = "ZZZ",
  }

  export enum ROF {
    IS_2065 = "IS1", // Déclaration 2065 normale ou simplifiée (IS)
    COM_2031 = "BIC1", // Déclaration 2031 normale ou simplifiée (commerçant)
    PRO_LIB_2035 = "BNC1", // Déclaration 2035 (profession libérale)
    TVA = "TVA1", // Déclaration de TVA
    CFE = "CFE1", // Cotisation foncière des entreprises (CFE)
    CVAE = "CVAE1", // Déclaration 1330-CVAE ou CVAE
    AGRI = "BA1", // Déclaration 2342, 2143 ou 2139 (agriculteurs)
    SCI_LOC = "RF1", // Déclaration normale ou simplifiée d'une SCI de location (revenus fonciers)
    CAP_MOB = "RCM1", // Déclarations 2777, 2561 et plus généralement, toutes les déclarations de revenus de capitaux mobiliers
    FPC = "FPC1", // Formation professionnelle continue
    TA = "TA1", // Taxe d'apprentissage
    SAL_TAX = "TPS1", // Taxe sur les salaires
    UNKNOWN = "1", // ! Validé par Thomas BRETHIOT<tbrethiot@teledec.fr>
  }

  // * Tax package - for `service.tax-declarations-provider-teledec.sendWhiteLabelDeclaration` params -> `/service/declaration-marque-blanche`
  export namespace TaxPackage {
    export type Auth = {
      email: string; // correspond à l'email du compte Partenaire
      timestamp: string; // correspond à l'instant génération de la chaîne JSON.  Il doit être au format "YYYY-MM-DDThh:mm:ss" ; sera interprété comme une heure française et stringr de moins d'une heure que l'heure à laquelle Teledec reçoit la requête.
      url: string; // ! URL de callback; feature activable sur demande auprès de Teledec. Paramètrer par défaut par teledec sur prod: `https://app.ownily.fr/webhook/teledec`, stage: `https://ownily-sta.cleverapps.io/webhook/teledec`. Peut être modifié sur l'env de `stage` pour tester en review ou en dev (en redirigant l'url `https://dev.ownily.fr/webhook/teledec` vers le routeur du développeur).
      bloquerSiIncoherence?: boolean; // Paramètre optionnel permettant de ne pas envoyer la déclaration de TVA si elle comporte des erreurs ou des incohérences. Valeur par défaut: false. Applicable uniquement aux déclarations de TVA
      retournerLien?: boolean; // ! Add true for not send to DGFIP. Permet de récuperer un lien vers la webview de la liasse. Booléen optionnel. Si ce champ est spécifié à true pour un partenaire qui est configuré pour fonctionner normalement en envoi direct de déclarations sans affichage d'écrans Teledec, alors il est possible de récupérer dans la payload de retour un lien qui conduira directement à l'écran Teledec de la déclaration sans ce que celle-ci n'ait été envoyée.
      retournerPdf?: boolean; // ! Add true for not send to DGFIP. Boolean permettant d'avoir une URL dynamique et temporaire du PDF de la liasse envoyée. (Annexe D) Ne transmet pas la déclaration directement à la DGFIP; nécessite une action via le web pour la transmettre
      test?: boolean; // est à positionner à true pour un envoi en mode test; et à false pour un envoi en mode réel.
    };

    // Correspond aux éléments identifiants la société ou le professionnel déclaré.
    export type Identity<EdiRequest extends "subscribe" | undefined = undefined> = {
      rangROF: ROF;
      siret: string;
      name: string;
      yearEndMonth: number;
      yearEndDay: number;
      addressStreet: string;
      addressComplement: string;
      addressNeighborhood: string;
      addressPostalCode: string;
      addressCity: string;
      addressCountry: string;
      legalForm: LEGAL_FORM;
      legalRepresentative: string;
      legalRepresentativeAs: string;
      legalRepresentativeTitle: string;
      telephone: string;
      email: string;
      ediRequete?: EdiRequest; // Champ optionnel qui permet de souscrire à l'option EDI Requete (récupération des DECLOYER). Ne pas resneigner sinon.
      ogaName?: string; // Champ optionnel permettant d'associer un OGA; utiliser la clé (en relation avec le champ ogaNumeroAdherent ci dessous). Se référer à la page listant les OGA
      ogaNumeroAdherent?: string; // Champ optionnel permettant de renseigner le numéro d'adhérent pour un OGA (en relation avec le champ ogaName ci dessus)
      ogaNumeroAgrement?: string;
    } & (EdiRequest extends "subscribe"
      ? {
          fullRegimeFiscal: string; // A ne renseigner que si l'option EDI Requete est souscrite; sinon le régime fiscal est déterminé selon les formulaires envoyés // ? Je ne comprends pas quoi mettre ici
        }
      : Record<never, never>);

    export type Period<FirstYear extends boolean = boolean> = {
      begin: string; // YYYY-MM-DD
      end: string; // YYYY-MM-DD
      cessation: string; // YYYY-MM-DD
      millesime: number; // 2022; 2023; 2024; ...
      suspension: boolean;
      reference: string; // ! Taille (lenght) limité, Champ personalisable par le partenaire
      echeance: string; // YYYY-MM-DD
      firstYear: FirstYear;
      ogaEnvoi?: boolean; // Champ optionnel permettant de spécifier si la déclaration doit être envoyé à l'OGA décrit dans la partie identity
    } & (FirstYear extends false
      ? {
          firstYear: false;
          previousEnd: string; // YYYY-MM-DD
          previousDuration: number;
        }
      : Record<never, never>);

    export type TaxPackageIn = {
      auth: Auth;
      identity: Identity;
      period: Period;
      [Forms.TYPES.FORM_2065]?: Forms.FORM_2065;
      [Forms.TYPES.FORM_2033A]?: Forms.FORM_2033A;
      [Forms.TYPES.FORM_2033B]?: Forms.FORM_2033B;
      [Forms.TYPES.FORM_2033C]?: Forms.FORM_2033C;
      [Forms.TYPES.FORM_2033D]?: Forms.FORM_2033D;
      [Forms.TYPES.FORM_2033E]?: Forms.FORM_2033E;
      [Forms.TYPES.FORM_2033F]?: Forms.FORM_2033F;
      [Forms.TYPES.FORM_2033G]?: Forms.FORM_2033G;
      [Forms.TYPES.FORM_2069RCI]?: Forms.FORM_2069RCI;
      [Forms.TYPES.FORM_2031]?: Forms.FORM_2031;
      [Forms.TYPES.FORM_SUIV39C]?: Forms.FORM_SUIV39C;
      [Forms.TYPES.FORM_2072S]?: Forms.FORM_2072S;
      [Forms.TYPES.FORM_2072SA1]?: Forms.FORM_2072SA1;
      [Forms.TYPES.FORM_2072SA2]?: Forms.FORM_2072SA2;
    };
    export type TaxPackageOut = {
      // ! C'est bien la combinaison du code HTTP 200 plus le status ok dans la payload JSON qui attestent que la liasse a bien été envoyée. La prise en compte du code HTTP 200 OK seul ne suffit pas à confirmer que la liasse a été envoyée car certains messages d'erreur peuvent être renvoyés avec un code HTTP 200.
      status: "ok" | "ERREUR"; // comprend un message qui prend comme valeur `ok` si tout s'est bien passé; sinon un message d'erreur en français (champ message).
    } & (
      | {
          status: "ok";
          lien?: string; // Si `auth.retournerLien = true`. URL dynamique et temporaire de la webview de la liasse envoyée. Feature activable par Teledec sur demande par partenaire.
          lienPdf?: string; // Si `auth.retournerPdf = true`. URL dynamique et temporaire du PDF de la liasse envoyée. Feature activable par Teledec sur demande par partenaire. Téléchargez le PDF après consultation afin de garder un historique.
        }
      | { status: "ERREUR"; message: string }
    );
  }

  export namespace WebHook {
    export enum STATUS {
      OK = "OK",
      ERROR = "ERREUR",
    }

    export type ErrorDeclarationErreur<FORMULAIRE extends Forms.TYPES = Forms.TYPES> = {
      formulaire: FORMULAIRE;
      champ:
        | (FORMULAIRE extends Forms.TYPES.FORM_2065
            ? keyof Forms.FORM_2065
            : FORMULAIRE extends Forms.TYPES.FORM_2033A
            ? keyof Forms.FORM_2033A
            : FORMULAIRE extends Forms.TYPES.FORM_2033B
            ? keyof Forms.FORM_2033B
            : never)
        | string;
      champLibelle: string;
      champValeur: string;
      code: string;
      libelle: string;
    };

    export type Event<EventStatus extends STATUS = STATUS, FormType extends Forms.TYPES = Forms.TYPES> = {
      declarationId: number;
      status: EventStatus;
      declarationStatus: EventStatus;
      reference: string;
      siren: string;
      nom: string;
      dateHeureDGFiP: string; // string TZ
      dateDebut: string; // YYYY-MM-DD
      dateFin: string; // YYYY-MM-DD
      statusLibelle: string;
      compte: string;
      referenceDGFiP: string;
      numeroTraitementDGFiP: string;
      rof: ROF;
    } & (
      | {
          status: STATUS.OK;
          pdf: string; // base64
        }
      | {
          status: STATUS.ERROR;
          stringEcheance: string; // YYYY-MM-DD
          libelle: string;
          declarationType: string;
          formulaire: FormType;
          declarationErreurs: ErrorDeclarationErreur<FormType>[];
        }
    );
  }

  export namespace Forms {
    // `ISRS` Impôts sur les sociétés Réel Simplifié
    export enum TYPES {
      FORM_2065 = "2065",
      FORM_2033A = "2033A",
      FORM_2033B = "2033B",
      FORM_2033C = "2033C",
      FORM_2033D = "2033D",
      FORM_2033E = "2033E",
      FORM_2033F = "2033F",
      FORM_2033G = "2033G",
      FORM_2069RCI = "2069RCI",
      FORM_2031 = "2031",
      FORM_SUIV39C = "SUIV39C",
      FORM_2072C = "2072C",
      FORM_2072CA1 = "2072CA1",
      FORM_2072CA2 = "2072CA2",
      FORM_2072E = "2072E",
      FORM_2072S = "2072S",
      FORM_2072SA1 = "2072SA1",
      FORM_2072SA2 = "2072SA2",
    }

    export type FORM_2065 = {
      AA_2065?: boolean; // Si vous êtes l'entreprise; tête de groupe; soumise au dépôt de la déclaration n° 2258-SD (art. 223 quinquies C-I-1); cocher la case ci-contre
      AB_2065?: boolean; // Si vous êtes l'entreprise désignée au dépôt de la déclaration n° 2258-SD par la société tête de groupe (art. 223 quinquies C-I-2); cocher la case ci-contre
      AC_2065_3042_1?: string; // Adresse du siège social; numéro; type et nom voie
      AC_2065_3042_2?: string; // Adresse du siège social; complément de distribution
      AC_2065_3042_3?: string; // Adresse du siège social; lieu-dit hameau
      AC_2065_3164_1?: string; // Adresse du siège social; ville
      AC_2065_3251_1?: string; // Adresse du siège social; code postal
      AC_2065_3207_1?: string; // Adresse du siège social; pays // Code ISO (FR)
      AD_2065_3036_1?: string; // Si une autre entité située en France ou dans un pays ou territoire soumis au dépôt de la déclaration; désignée pour le dépôt de la déclaration pays par pays n°2258; indiquer le nom et la localisation; Dénomination
      AD_2065_3036_2?: string; // Complément de dénomination de l'autre entreprise qui a été désignée pour le dépôt de la déclaration pays par pays n°2258
      AD_2065_3036_3?: string; // Complément 2 de dénomination de l'autre entreprise qui a été désignée pour le dépôt de la déclaration pays par pays n°2258
      AD_2065_3039_1?: string; // Numéro d'identification fiscale de l'entité désignée
      AD_2065_3042_1?: string; // Numéro; type et nom voie de l'autre entreprise a été désignée pour le dépôt de la déclaration pays par pays n°2258
      AD_2065_3042_2?: string; // Complément de distribution de l'autre entreprise qui a été désignée pour le dépôt de la déclaration pays par pays n°2258
      AD_2065_3042_3?: string; // Lieu-dit; hameau de l'autre entreprise qui a été désignée pour le dépôt de la déclaration pays par pays n°2258
      AD_2065_3164_1?: string; // Ville de l'autre entreprise qui a été désignée pour le dépôt de la déclaration pays par pays n°2258
      AD_2065_3207_1?: string; // Pays de l'autre entreprise qui a été désignée pour le dépôt de la déclaration pays par pays n°2258
      AD_2065_3251_1?: string; // Code postal de l'autre entreprise qui a été désignée pour le dépôt de la déclaration pays par pays n°2258
      AK_2065_3042_2?: string; // Ancienne adresse en cas de changement; complément de distribution
      AK_2065_3042_1?: string; // Ancienne adresse en cas de changement; numéro; type et nom voie
      AK_2065_3042_3?: string; // Ancienne adresse en cas de changement; lieu-dit; hameau
      AK_2065_3164_1?: string; // Ancienne adresse en cas de changement; ville
      AK_2065_3207_1?: string; // Ancienne adresse en cas de changement; pays // Code ISO (FR)
      AK_2065_3251_1?: string; // Ancienne adresse en cas de changement; code postal
      AP_2065?: boolean; // Si vous avez changé d'activité?
      AQ_2065_4440_1?: string; // Activités exercées; libellé 1
      AQ_2065_4440_2?: string; // Activités exercées; libellé 2
      HA_2065?: number; // Bénéfice imposable au taux normal
      AT_2065?: number; // Résultat fiscal; déficit
      LC_2065?: number; // Bénéfice imposable à 15%
      AU_2065?: number; // Plus-values à long terme imposables au taux de 15%
      AV_2065_3036_1?: string; // Désignation de l'entité du groupe qui souscrit la déclaration pays par pays
      AV_2065_3036_2?: string; // Complément de désignation de l'entité du groupe qui souscrit la déclaration pays par pays
      AV_2065_3036_3?: string; // Forme juridique de l'entité du groupe qui souscrit la déclaration pays par pays
      AV_2065_3030_1?: string; // Identifiant numérique de l'entité du groupe qui souscrit la déclaration pays par pays
      AV_2065_3042_1?: string; // Numéro; type et nom voie de l'entité du groupe qui souscrit la déclaration pays par pays
      AV_2065_3042_2?: string; // Complément de distribution de l'entité du groupe qui souscrit la déclaration pays par pays
      AV_2065_3042_3?: string; // Lieu-dit; hameau de l'entité du groupe qui souscrit la déclaration pays par pays
      AV_2065_3164_1?: string; // Ville de l'entité du groupe qui souscrit la déclaration pays par pays
      AV_2065_3207_1?: string; // État de résidence de l'entité du groupe qui souscrit la déclaration pays par pays
      AV_2065_3251_1?: string; // Code postal de l'entité du groupe qui souscrit la déclaration pays par pays
      AX_2065?: boolean; // Entreprises nouvelles CGI article 44 sexies
      AY_2065?: boolean; // Reprise d'entreprises en difficultés; article 44 septies
      AW_2065?: boolean; // Zone de revitalisation rurale; art. 44 quindecies
      BA_2065?: boolean; // Bassins d'emploi à redynamiser; art.44 duodecies
      BB_2065?: number; // Bénéfice ou déficit exonéré
      BE_2065?: number; // Au titre des revenus mobiliers de source française ou étrangères ayant donné lieu à un certificat de crédit d'impôt
      BF_2065?: number; // Imputation au titre des revenus étrangers auxquels est attaché un crédit d'impôt représentatif de l'impôt de cet état; territoire ou collectivité
      BN_2065?: string; // Comptable indépendant ou salarié?
      BQ_2065?: string; // Conseil indépendant ou salarié?
      CA_2065_3036_1?: string; // Nom et adresse du comptable; nom et prénom ou dénomination
      CA_2065_3036_2?: string; // Nom et adresse du comptable; qualité et fonction ou complément de dénomination
      CA_2065_3036_3?: string; // Nom et adresse du comptable; forme juridique ou titre
      CA_2065_3042_2?: string; // Nom et adresse du comptable; complément de distribution
      CA_2065_3042_1?: string; // Nom et adresse du comptable; numéro; type et nom voie
      CA_2065_3042_3?: string; // Nom et adresse du comptable; lieu-dit hameau
      CA_2065_3148_1_TE?: string; // Nom et adresse du comptable; numéro de téléphone
      CA_2065_3164_1?: string; // Nom et adresse du comptable; ville
      CA_2065_3207_1?: string; // Nom et adresse du comptable; pays // Code ISO (FR)
      CA_2065_3251_1?: string; // Nom et adresse du comptable; code postal
      DA_2065_3036_1?: string; // Nom et adresse du CGA de l'OMGA ou du viseur conventionné; dénomination
      DA_2065_3036_2?: string; // Nom et adresse du CGA de l'OMGA ou du viseur conventionné; complément de désignation
      DA_2065_3036_3?: string; // Nom et adresse du CGA de l'OMGA ou du viseur conventionné; forme juridique
      DA_2065_3039_1?: string; // Nom et adresse du CGA de l'OMGA ou du viseur conventionné; numéro d'agrément
      DA_2065_3042_2?: string; // Nom et adresse du CGA de l'OMGA ou du viseur conventionné; complément de distribution
      DA_2065_3042_1?: string; // Nom et adresse du CGA de l'OMGA ou du viseur conventionné; numéro; type et nom voie
      DA_2065_3042_3?: string; // Nom et adresse du CGA de l'OMGA ou du viseur conventionné; lieu-dit hameau
      DA_2065_3164_1?: string; // Nom et adresse du CGA de l'OMGA ou du viseur conventionné; ville
      DA_2065_3207_1?: string; // Nom et adresse du CGA de l'OMGA ou du viseur conventionné; pays // Code ISO (FR)
      DA_2065_3251_1?: string; // Nom et adresse du CGA de l'OMGA ou du viseur conventionné; code postal
      EA_2065_3036_1?: string; // Nom et adresse du conseil; nom et prénom ou dénomination
      EA_2065_3036_2?: string; // Nom et adresse du conseil; qualité et profession ou complément de dénomination
      EA_2065_3036_3?: string; // Nom et adresse du conseil; forme juridique ou titre
      EA_2065_3042_2?: string; // Nom et adresse du conseil; complément de distribution
      EA_2065_3042_1?: string; // Nom et adresse du conseil; numéro; type et nom voie
      EA_2065_3042_3?: string; // Nom et adresse du conseil; lieu-dit; hameau
      EA_2065_3148_1_TE?: string; // Nom et adresse du conseil; téléphone
      EA_2065_3164_1?: string; // Nom et adresse du conseil; ville
      EA_2065_3207_1?: string; // Nom et adresse du conseil; pays // Code ISO (FR)
      EA_2065_3251_1?: string; // Nom et adresse du conseil; code postal
      HD_2065?: boolean; // Zone franche Urbaine – Territoire entrepreneur; art 44 octies A
      HJ_2065?: boolean; // Entreprises nouvelles ou zones franches ou zones d'entreprises; autres dispositifs ?
      BY_2065?: boolean; // France Ruralités Revitalisation FRR art. 44 quindecies A
      JA_2065?: number; // Contribution représentative du droit au bail; recettes nettes soumises à la contribution de 2;5% ou CRL à compter de 2004
      LL_2065?: boolean; // Société d'investissement immobilier cotées?
      LN_2065?: number; // Plus-values à long terme imposables à 0%
      LQ_2065?: boolean; // Jeune entreprise innovante ?
      LT_2065?: number; // Plus-values exonérées art 238 quindecies
      LV_2065?: number; // Abattements et exonerations notamment en faveur des entreprises nouvelles ou implantées en zones d'entreprises ou zones franches - Plus values exonérées relevant du taux de 15%
      LW_2065?: number; // Plus value a long terme imposable a 19%
      LX_2065?: number; // Autres plus values imposables a 19%
      LY_2065?: boolean; // Zone franche d'activité ?
      MA_2065?: boolean; // Zone de restructuration de la défense art.44 terdecies ?
      ME_2065?: boolean; // Visa CGA/AGA
      MF_2065?: boolean; // Visa viseur conventionné
      PA_2065?: string; // string d'entrée dans le groupe de la société déclarante
      PD_2065_3036_1?: string; // Désignation société mère
      PD_2065_3036_2?: string; // Complément de désignation société mère
      PD_2065_3036_3?: string; // Forme juridique société mère
      PD_2065_3039_1?: string; // SIRET société mère
      PD_2065_3042_1?: string; // Numéro; type et nom voie société mère
      PD_2065_3042_2?: string; // Complément de distribution société mère
      PD_2065_3042_3?: string; // Lieu-dit; hameau société mère
      PD_2065_3164_1?: string; // Ville société mère
      PD_2065_3207_1?: string; // Pays société mère // Code ISO (FR)
      PD_2065_3251_1?: string; // Code postal société mère
      PE_2065?: boolean; // Si option pour le régime optionnel de taxation au tonnage; art. 209-0 B (entreprises de transport maritime)
      PF_2065?: boolean; // Option pour le crédit d'impôt outre-mer dans le secteur productif; art. 244 quater W
      PH_2065?: boolean; // Si PME innovantes
      AF_2065?: boolean; // Bassins urbains à dynamiser (BUD); art.44 sexdecies
      AG_2065_3039_1?: string; // Identification fiscale de la société tête de groupe
      AG_2065_3036_1?: string; // Dénomination de la société tête de groupe
      AG_2065_3036_2?: string; // Complément de dénomination de la société tête de groupe
      AG_2065_3036_3?: string; // Complément 2 de dénomination de la société tête de groupe
      AG_2065_3042_1?: string; // Numéro; type et nom voie de la société tête de groupe
      AG_2065_3042_2?: string; // Complément de distribution de la société tête de groupe
      AG_2065_3042_3?: string; // Lieu-dit; hameau de la société tête de groupe
      AG_2065_3164_1?: string; // Ville de la société tête de groupe
      AG_2065_3207_1?: string; // Pays de la société tête de groupe // Code ISO (FR)
      AG_2065_3251_1?: string; // Code postal de la société tête de groupe
      AH_2065?: boolean; // L'entreprise dispose-t-elle d'une comptabilité informatisée ?
      AJ_2065?: string; // Si oui; indication du logiciel utilisé
      AM_2065?: boolean; // Zone de développement prioritaire; art. 44 septdecies
      AL_2065?: number; // Résultat net de cession; de concession ou de sous-concession des brevets et droits de propriété industrielle assimilés imposable au taux de 10 %
      AR_2065?: boolean; // ECF
      AS_2065_3036_1?: string; // Dénomination du prestataire
      AS_2065_3036_2?: string; // Complément de dénomination du prestataire
      AS_2065_3036_3?: string; // Complément 2 de dénomination du prestataire
      AS_2065_3039_1?: string; // Siren du prestataire
      AS_2065_3042_1?: string; // Numéro; type et nom voie du prestataire
      AS_2065_3042_2?: string; // Complément de distribution du prestataire
      AS_2065_3042_3?: string; // Lieu-dit; hameau du prestataire
      AS_2065_3164_1?: string; // Ville du prestataire
      AS_2065_3207_1?: string; // Pays du prestataire // Code ISO (FR)
      AS_2065_3251_1?: string; // Code postal du prestataire
      PJ_2065?: number; // Assiette de la contribution temporaire de solidarité au taux de 33%
    };

    export type FORM_2033A = {
      JD?: boolean; // Déposé néant?

      AA?: number; // Immobilisations incorporelles; fonds commercial; brut
      AB?: number; // Autres immobilisations incorporelles; brut
      AC?: number; // Immobilisations corporelles; brut
      AD?: number; // Immobilisations financières; brut
      AE?: number; // Total I de l'actif immobilisé; brut
      AF?: number; // Stocks de matières premières; d'approvisionnements et en cours de production; brut
      AG?: number; // Stocks de marchandises; brut
      AH?: number; // Avances et acomptes versés sur commandes; brut
      AJ?: number; // Créances clients et comptes rattachés; brut
      AK?: number; // Autres créances; brut
      AL?: number; // Valeurs mobilières de placement; brut
      AM?: number; // Disponibilités; brut
      AP?: number; // Charges constatées d'avance; brut
      AQ?: number; // Total II de l'actif circulant; brut
      AR?: number; // Total général de l'actif; brut
      BA?: number; // Immobilisations incorporelles; fonds commercial; amortissements et provisions
      BB?: number; // Autres immobilisations incorporelles; amortissements et provisions
      BC?: number; // Immobilisations corporelles; amortissements et provisions
      BD?: number; // Immobilisations financières; amortissements et provisions
      BE?: number; // Total I de l'actif immobilisé; amortissements et provisions
      BF?: number; // Stocks de matières premières; d'approvisionnements et en cours de production; amortissements et provisions
      BG?: number; // Stocks de marchandises; amortissements et provisions
      BH?: number; // Avances et acomptes versés sur commandes; amortissements et provisions
      BJ?: number; // Créances clients et comptes rattachés; amortissements et provisions
      BK?: number; // Autres créances; amortissements et provisions
      BL?: number; // Valeurs mobilières de placement; amortissements et provisions
      BM?: number; // Disponibilités; amortissements et provisions
      BP?: number; // Charges constatées d'avance; amortissements et provisions
      BQ?: number; // Total II de l'actif circulant; amortissements et provisions
      BR?: number; // Total général de l'actif; amortissements et provisions
      CA?: number; // Immobilisations incorporelles; fonds commercial; net N
      CB?: number; // Autres immobilisations incorporelles; net N
      CC?: number; // Immobilisations corporelles; net N
      CD?: number; // Immobilisations financières; net N
      CE?: number; // Total I de l'actif immobilisé; net N
      CF?: number; // Stocks de matières premières; d'approvisionnements et en cours de production; net N
      CG?: number; // Stocks de marchandises; net N
      CH?: number; // Avances et acomptes versés sur commandes; net N
      CJ?: number; // Créances clients et comptes rattachés; net N
      CK?: number; // Autres créances; net N
      CL?: number; // Valeurs mobilières de placement; net N
      CM?: number; // Disponibilités; net N
      CP?: number; // Charges constatées d'avance; net N
      CQ?: number; // Total II de l'actif circulant; net N
      CR?: number; // Total général de l'actif; net N
      EB?: number; // Réserve relative à l'achat d'œuvres d'art d'artistes vivants
      EE?: number; // Comptes courants d'associés
      AT?: number; // dont montant de la TVA
      FA?: number; // Capital social ou individuel
      FB?: number; // Écarts de réévaluation
      FC?: number; // Réserve légale
      FD?: number; // Réserves réglementées exercice N
      FE?: number; // Autres réserves
      FF?: number; // Report à nouveau
      FG?: number; // Résultat de l'exercice
      FH?: number; // Provisions réglementées
      FJ?: number; // Total I des capitaux propres
      FK?: number; // Provisions pour risques et charges
      FL?: number; // Emprunts et dettes assimilées
      FM?: number; // Avances et acomptes reçus sur commandes en cours
      FN?: number; // Fournisseurs et comptes rattachés
      FP?: number; // Autres dettes
      AU?: number; // Dettes fiscales et sociales
      FQ?: number; // Produits constatés d'avance
      FR?: number; // Total III des dettes
      FS?: number; // Total général du passif
      HA?: number; // Immobilisations financières à moins d'un an
      HB?: number; // Créances à plus d'un an
      HC?: number; // Comptes courants d'associés débiteurs
      JA?: number; // Dettes à plus d'un an
      JB?: number; // Coût de revient des immobilisations acquises ou créées lors de l'exercice
      JC?: number; // Prix de vente hors TVA des immobilisations cédées au cours de l'exercice
    };

    export type FORM_2033B = {
      JB?: boolean; // Déposé néant?

      AA?: number; // Ventes de marchandises; export et livraisons intra communautaires
      AB?: number; // Production vendue de biens; export et livraisons intra communautaires
      AC?: number; // Production vendue de services; export et livraisons intra communautaires
      AD?: number; // Part de loyers dispensés de réintégration
      AE?: number; // Fraction des loyers versés dans le cadre d'un crédit bail immobilier et de levée d'option
      AF?: number; // Charges afférentes à l'activité relevant du régime optionnel de taxation au tonnage des entreprises de transport maritime
      AG?: number; // Produits afférents à l'activité relevant du régime optionnel de taxation au tonnage des entreprises de transport maritime
      AH?: number; // Déduction exceptionnelle
      AJ?: number; // Résultat fiscal afférent à l'activité relevant du régime optionnel de taxation au tonnage des entreprises de transport maritime
      AK?: number; // Amortissement des souscriptions dans des PME innovantes (art 217 octies)
      AL?: number; // Amortissement exceptionnel de 25% des constructions nouvelles (art 39 quinquies D)
      AN?: number; // Crédit bail immobilier
      AP?: number; // Taxe professionnelle
      AQ?: number; // Bassins d'emploi à redynamiser (art. 44 duodecies)
      AU?: number; // Provisions fiscales pour implantations commerciales à l'étranger
      AV?: number; // Autres charges; cotisations versées aux organisations syndicales et professionnelles
      BA?: number; // Ventes de marchandises
      BB?: number; // Production vendue de biens
      BC?: number; // Production vendue de services
      BD?: number; // Production stockée
      BE?: number; // Production immobilisée
      BF?: number; // Subventions d'exploitation reçues
      BG?: number; // Autres produits
      BH?: number; // Total des produits d'exploitation hors TVA
      BJ?: number; // Achats de marchandises; y compris droits de douane
      BK?: number; // Variation de stock de marchandises
      BL?: number; // Achats de matières premières et autres approvisionnements y compris droits de douane
      BM?: number; // Variation de stock de matières premières et d'approvisionnements
      BN?: number; // Autres charges externes
      BP?: number; // Impôts; taxes et versements assimilés
      BQ?: number; // Rémunérations du personnel
      BR?: number; // Charges sociales
      BS?: number; // Dotations aux amortissements
      CK?: number; // Dont amortissements fonds de commerce
      BT?: number; // Dotations aux provisions
      BU?: number; // Autres charges
      BV?: number; // Total des charges d'exploitation
      BW?: number; // Résultat d'exploitation
      BX?: number; // Produits financiers
      BY?: number; // Produits exceptionnels
      BZ?: number; // Charges financières
      CA?: number; // Charges exceptionnelles
      CB?: number; // Impôt sur les bénéfices
      CC?: number; // Bénéfice ou perte N
      CD?: number; // Report du bénéfice
      CE?: number; // Rémunérations et avantages personnels non déductibles
      CF?: number; // Amortissements excédentaires et autres amortissements non déductibles
      CG?: number; // Provisions non déductibles
      CH?: number; // Impôts et taxes non déductibles
      CJ?: number; // Réintégrations diverses
      CM?: number; // Bénéfice fiscal avant imputation des déficits antérieurs
      CN?: number; // Déficit de l'exercice reporté en arrière pour entreprises IS
      CR?: number; // Bénéfice fiscal après imputation des déficits
      ED?: number; // Report du déficit
      EK?: number; // Exonérations ou abattements sur le bénéfice et exonération entreprises nouvelles ou zone franche Corse
      EL?: number; // Déductions diverses
      EM?: number; // Déficit fiscal avant imputation des déficits antérieurs
      EP?: number; // Déficits antérieurs reportables
      ER?: number; // Déficit fiscal après imputation des déficits
      EU?: number; // (FRR) France Ruralités Revitalisation (art.44 quindecies A)
      FA?: number; // Zone restruct. Défense (44 terdecies)
      FB?: number; // Zone revitalisation rurales (44 quindecies)
      FJ?: number; // Écarts de valeurs liquidatives sur OPC
      FK?: number; // Intérêts excédentaires sur comptes courants d'associés
      FL?: number; // Créance due au report en arrière du déficit
      GN?: number; // Crédit bail mobilier
      HL?: number; // Investissements et souscriptions outre-mer
      HW?: number; // Exonération ou abattement sur le bénéfice pour reprise d 'entreprises en difficulté
      JA?: number; // Déficits antérieurs reportables pour les entreprises à l'IS
      repetition2033BReintegrations?: { LA: string; LB: number }[]; // Détail des divers à réintégrer au résultat fiscal; libellé/montant
      repetition2033BDeductions?: { MA: string; MB: number }[]; // Détail des divers à déduire du résultat fiscal; libellé/montant
      MC?: number; // Déduction pour entreprise nouvelle; art 44 seixes
      MD?: number; // Déduction pour zone franche urbaine; art 44 octies A
      MF?: number; // Déduction pour jeune entreprise innovante; art 44 sexies A
      MH?: number; // Déduction pour zone franche d'activité NG; art 44 quaterdecies
      AR?: number; // Bassins urbains à dynamiser – BUD (art. 44 sexdecies)
      AY?: number; // Zone de développement prioritaire (44 septdecies)
      AS?: number; // Déduction exceptionnelle (art 39 decies A)
      AZ?: number; // Déduction exceptionnelle (art 39 decies B)
      CX?: number; // Déduction exceptionnelle (art 39 decies C)
      CY?: number; // Déduction exceptionnelle (art 39 decies D)
      CZ?: number; // Déduction exceptionnelle simulateur de conduite (art. 39 decies E)
      AT?: number; // Déductions exceptionnelles (art. 39 decies F)
      AX?: number; // Déduction exceptionnelle (art 39 decies G)
    };

    export type FORM_2033C = {
      RQ?: boolean; // Déposé néant?

      AA?: number; // Immobilisations incorporelles, fonds commercial, valeur brute au début de l'exercice
      AB?: number; // Autres immobilisations incorporelles, valeur brute au début de l'exercice
      AC?: number; // Immobilisations corporelles, terrains, valeur brute au début de l'exercice
      AD?: number; // Immobilisations corporelles, constructions, valeur brute au début de l'exercice
      AE?: number; // Immobilisations corporelles, installations techniques matériel et outillages industriels, valeur brute au début de l'exercice
      AF?: number; // Immobilisations corporelles, installations générales et aménagements divers, valeur brute au début de l'exercice
      AG?: number; // Immobilisations corporelles, matériel de transport, valeur brute au début de l'exercice
      AH?: number; // Autres immobilisations corporelles valeur brute au début de l'exercice
      AJ?: number; // Immobilisations financières, valeur brute au début de l'exercice
      AK?: number; // Total des immobilisations, valeur brute au début de l'exercice
      BA?: number; // Immobilisations incorporelles, fonds commercial, augmentations
      BB?: number; // Autres immobilisations incorporelles, augmentations
      BC?: number; // Immobilisations corporelles, terrains, augmentations
      BD?: number; // Immobilisations corporelles, constructions, augmentations
      BE?: number; // Immobilisations corporelles, installations techniques matériel et outillages industriels, augmentations
      BF?: number; // Immobilisations corporelles, installations générales et aménagements divers, augmentations
      BG?: number; // Immobilisations corporelles, matériel de transport, augmentations
      BH?: number; // Autres immobilisations corporelles augmentations
      BJ?: number; // Immobilisations financières, augmentations
      BK?: number; // Total des immobilisations, augmentations
      CA?: number; // Immobilisations incorporelles, fonds commercial, diminutions
      CB?: number; // Autres immobilisations incorporelles, diminutions
      CC?: number; // Immobilisations corporelles, terrains, diminutions
      CD?: number; // Immobilisations corporelles, constructions, diminutions
      CE?: number; // Immobilisations corporelles, installations techniques matériel et outillages industriels, diminutions
      CF?: number; // Immobilisations corporelles, installations générales et aménagements divers, diminutions
      CG?: number; // Immobilisations corporelles, matériel de transport, diminutions
      CH?: number; // Autres immobilisations corporelles, diminutions
      CJ?: number; // Immobilisations financières, diminutions
      CK?: number; // Total des immobilisations, diminutions
      DA?: number; // Immobilisations incorporelles, fonds commercial, valeur brute à la fin de l'exercice
      DB?: number; // Autres immobilisations incorporelles, valeur brute à la fin de l'exercice
      DC?: number; // Immobilisations corporelles, terrains, valeur brute à la fin de l'exercice
      DD?: number; // Immobilisations corporelles, constructions, valeur brute en fin d'exercice
      DE?: number; // Immobilisations corporelles, installations techniques matériel et outillages industriels, valeur brute à la fin de l'exercice
      DF?: number; // Immobilisations corporelles, installations générales et aménagements divers, valeur brute à la fin de l'exercice
      DG?: number; // Immobilisations corporelles, matériel de transport, valeur brute à la fin de l'exercice
      DH?: number; // Autres immobilisations corporelles, valeur brute à la fin de l'exercice
      DJ?: number; // Immobilisations financières, valeur brute à la fin de l'exercice
      DK?: number; // Total des immobilisations, valeur brute à la fin de l'exercice
      EA?: number; // Immobilisations incorporelles, fonds commercial, réévaluation légale, valeur d'origine en fin exercice
      EB?: number; // Autres immobilisations incorporelles, réévaluation légale, valeur d'origine en fin exercice
      EC?: number; // Immobilisations corporelles, terrains, réévaluation légale, valeur d'origine en fin exercice
      ED?: number; // Immobilisations corporelles, constructions, réévaluation légale, valeur d'origine en fin exercice
      EE?: number; // Immobilisations corporelles, installations techniques matériel et outillages industriels, réévaluation légale, valeur d'origine en fin exercice
      EF?: number; // Immobilisations corporelles, installations générales et aménagements divers, réévaluation légale, valeur d'origine en fin exercice
      EG?: number; // Immobilisations corporelles, matériel de transport, réévaluation légale, valeur d'origine en fin exercice
      EH?: number; // Autres immobilisations corporelles, réévaluation légale, valeur d'origine en fin exercice
      EJ?: number; // Immobilisations financières, réévaluation légale, valeur d'origine en fin exercice
      EK?: number; // Total des immobilisations, réévaluation légale, valeur d'origine en fin exercice
      FA?: number; // Autres immobilisations incorporelles, Montant des amortissements au début de l'exercice
      FB?: number; // Amortissements sur immobilisations corporelles, terrains, Montant des amortissements au début de l'exercice
      FC?: number; // Amortissements sur immobilisations corporelles, constructions, Montant des amortissements au début de l'exercice
      FD?: number; // Amortissements sur immobilisations corporelles, installations techniques, matériel et outillage industriels, Montant des amortissements au début de l'exercice
      FE?: number; // Amortissements sur immobilisations corporelles, installations générales, agencements et aménagements divers, valeur brute au début de l'exercice
      FF?: number; // Amortissements sur immobilisations corporelles, matériel de transport, Montant des amortissements au début de l'exercice
      FG?: number; // Amortissements sur autres immobilisations corporelles, Montant des amortissements au début de l'exercice
      FH?: number; // Total des amortissements sur immobilisations, Montant des amortissements au début de l'exercice
      GA?: number; // Autres immobilisations incorporelles, augmentations : dotations de l'exercice
      GB?: number; // Amortissements sur immobilisations corporelles, terrains, augmentations : dotations de l'exercice
      GC?: number; // Amortissements sur immobilisations corporelles, constructions, augmentations : dotations de l'exercice
      GD?: number; // Amortissements sur immobilisations corporelles, installations techniques, matériel et outillage industriels, augmentations : dotations de l'exercice
      GE?: number; // Amortissements sur immobilisations corporelles, installations générales, agencements et aménagements divers, augmentations : dotations de l'exercice
      GF?: number; // Amortissements sur immobilisations corporelles, matériel de transport, augmentations : dotations de l'exercice
      GG?: number; // Amortissements sur autres immobilisations corporelles, augmentations : dotations de l'exercice
      GH?: number; // Total des amortissements sur immobilisations, augmentations : dotations de l'exercice
      HA?: number; // Autres immobilisations incorporelles, diminutions amortissements afférents aux éléments sortis de l'actif et reprises
      HB?: number; // Amortissements sur immobilisations corporelles, terrains, diminutions pour éléments sortis de l'actif et reprises
      HC?: number; // Amortissements sur immobilisations corporelles, constructions, diminutions pour éléments sortis de l'actif et reprises
      HD?: number; // Amortissements sur immobilisations corporelles, installations techniques, matériel et outillage industriels, diminutions pour éléments sortis de l'actif et reprises
      HE?: number; // Amortissements sur immobilisations corporelles, installations générales, agencements et aménagements divers, diminutions pour éléments sortis de l'actif et reprises
      HF?: number; // Amortissements sur immobilisations corporelles, matériel de transport, diminutions pour éléments sortis de l'actif et reprises
      HG?: number; // Amortissements sur autres immobilisations corporelles, diminutions pour éléments sortis de l'actif et reprises
      HH?: number; // Total des amortissements sur immobilisations, diminutions pour éléments sortis de l'actif et reprises
      JA?: number; // Autres immobilisations incorporelles, montant des amortissements à la fin de l'exercice
      JB?: number; // Amortissements sur immobilisations corporelles, terrains, montant à la fin de l'exercice
      JC?: number; // Amortissements sur immobilisations corporelles, constructions, montant à la fin de l'exercice
      JD?: number; // Amortissements sur immobilisations corporelles, installations techniques, matériel et outillage industriels, montant à la fin de l'exercice
      JE?: number; // Amortissements sur immobilisations corporelles, installations générales, agencements et aménagements divers, montant à la fin de l'exercice
      JF?: number; // Amortissements sur immobilisations corporelles, matériel de transport, montant à la fin de l'exercice
      JG?: number; // Amortissements sur autres immobilisations corporelles, montant à la fin de l'exercice
      JH?: number; // Total des amortissements sur immobilisations, montant à la fin de l'exercice
      KA?: string[]; // ! Repetitions, Nature de l'immobilisation
      LA?: number[]; // ! Repetitions, Valeur d'actif
      LL?: number; // Total des valeurs d'actif
      MA?: number[]; // ! Repetitions, Amortissements
      ML?: number; // Total des amortissements
      NA?: number[]; // ! Repetitions, Valeur résiduelle
      NL?: number; // Total des valeurs résiduelles
      PA?: number[]; // ! Repetitions, Prix de cession
      PL?: number; // Total des prix de cession
      QA?: number[]; // ! Repetitions, Plus ou moins value à court terme
      QL?: number; // Total des plus ou moins values à court terme
      QM?: number; // Régularisations sur les plus ou moins values à court terme
      QN?: number; // Total des plus ou moins values nettes à court terme
      QP?: number; // Plus values taxables a 16,5%
      SA?: number[]; // ! Repetitions, Détail plus ou moins value à LT à 15 ou 12,8 %
      SL?: number; // Total des plus ou moins values à LT à 15 ou 12,8 %
      SM?: number; // Régularisation sur plus ou moins value à LT à 15 ou 12,8 %
      SN?: number; // Total des PMVLT à 15 ou 1,28 %
      TA?: number[]; // ! Repetitions, Détail plus ou moins value à LT à 0%
      TL?: number; // Total des plus ou moins values à LT à 0%
      TM?: number; // Régularisation sur plus ou moins value à LT à 0 %
      TN?: number; // Total des PMVLT à 0 %
      UA?: number; // Plus ou moins value a long terme a 16,5%
      UL?: number; // Total des plus ou moins values a long terme a 16,5%
      UM?: number; // Regularisations sur les plus ou moins values a long terme a 16,5%
      UN?: number; // Total des plus ou moins values nettes a long terme a 16,5%
      AL?: number; // Fonds commercial - Montant des amortissements au début de l'exercice
      AM?: number; // Fonds commercial - Augmentations : dotations de l'exercice
      AN?: number; // Fonds commercial - Diminutions : amortissements afférents aux éléments sortis de l'actif et reprises
      AP?: number; // Fonds commercial - Montant des amortissements à la fin de l'exercice
    };

    export type FORM_2033D = {
      PF?: boolean; // Déposé néant?

      AA?: number; // Provisions réglementées, amortissements dérogatoires, montant au début de l'exercice
      AB?: number; // Autres provisions réglementées, montant au début de l'exercice
      AC?: number; // Provisions pour risques et charges, montant au début de l'exercice
      AD?: number; // Provisions pour dépréciation sur immobilisations, montant au début de l'exercice
      AE?: number; // Provisions pour dépréciation sur stocks et en cours, montant au début de l'exercice
      AF?: number; // Provisions pour dépréciation sur clients et comptes rattachés, montant au début de l'exercice
      AG?: number; // Autres provisions pour dépréciation, montant au début de l'exercice
      AH?: number; // Total des provisions, montant au début de l'exercice
      AJ?: number; // Aides perçues ayant donné droit à la réduction d'impôt prévue au 4 de l'article 238 bis du CGI pour l'entreprise donatrice
      AK?: number; // Montant de l'investissement reçu qui a donné lieu à amortissement exceptionnel chez l'entreprise investisseur dans le cadre de l'article 217 octies du CGI
      AN?: number; // Provisions réglementées, amortissements dérogatoires, majoration exceptionnelle de 30 %, montant au début de l'exercice
      AP?: number; // Primes et cotisations complémentaires facultatives
      AQ?: number; // Cotisations personnelles obligatoire de l'exploitant
      AR?: string; // N° du centre de gestion agréé
      AS?: number; // Montant de la TVA collectée
      AT?: number; // Montant de la TVA déductible sur biens et services (sauf immobilisations)
      AU?: number; // Montant des prélèvements personnels de l'exploitant
      BA?: number; // Provisions réglementées, amortissements dérogatoires, augmentations, dotations de l'exercice
      BB?: number; // Autres provisions réglementées, augmentations, dotations de l'exercice
      BC?: number; // Provisions pour risques et charges, augmentations, dotations de l'exercice
      BD?: number; // Provisions pour dépréciation sur immobilisations, augmentations, dotations de l'exercice
      BE?: number; // Provisions pour dépréciation sur stocks et en cours, augmentations, dotations de l'exercice
      BF?: number; // Provisions pour dépréciation sur clients et comptes rattachés, augmentations, dotations de l'exercice
      BG?: number; // Autres provisions pour dépréciation, augmentations, dotations de l'exercice
      BH?: number; // Total des provisions, augmentations, dotations de l'exercice
      BN?: number; // Provisions réglementées, amortissements dérogatoires, majoration exceptionnelle de 30 %, augmentations
      CA?: number; // Provisions réglementées, amortissements dérogatoires, diminutions, reprises de l'exercice
      CB?: number; // Autres provisions réglementées, diminutions, reprises de l'exercice
      CC?: number; // Provisions pour risques et charges, diminutions, reprises de l'exercice
      CD?: number; // Provisions pour dépréciation sur immobilisations, diminutions, reprises de l'exercice
      CE?: number; // Provisions pour dépréciation sur stocks et en cours, diminutions, reprises de l'exercice
      CF?: number; // Provisions pour dépréciation sur clients et comptes rattachés, diminutions, reprises de l'exercice
      CG?: number; // Autres provisions pour dépréciation, diminutions, reprises de l'exercice
      CH?: number; // Total des provisions, diminutions, reprises de l'exercice
      CN?: number; // Provisions réglementées, amortissements dérogatoires, majoration exceptionnelle de 30 %, diminutions
      DA?: number; // Provisions réglementées, amortissements dérogatoires, montant à la fin de l'exercice
      DB?: number; // Autres provisions réglementées, diminutions, montant à la fin de l'exercice
      DC?: number; // Provisions pour risques et charges, montant à la fin de l'exercice
      DD?: number; // Provisions pour dépréciation sur immobilisations, montant à la fin de l'exercice
      DE?: number; // Provisions pour dépréciation sur stocks et en cours, montant à la fin de l'exercice
      DF?: number; // Provisions pour dépréciation sur clients et comptes rattachés, montant à la fin de l'exercice
      DG?: number; // Autres provisions pour dépréciation, montant à la fin de l'exercice
      DH?: number; // Total des provisions, montant à la fin de l'exercice
      DN?: number; // Provisions réglementées, amortissements dérogatoires, majoration exceptionnelle de 30 %, montant à la fin de l'exercice
      GJ?: string[]; // ! Repetitions, Détail de la ventilation des dotations aux provisions, libellé
      HJ?: number[]; // ! Repetitions, Détail de la ventilation des dotations aux provisions, montant
      HA?: number; // Dotations aux provisions pour indemnités pour congés à payer, charges sociales et fiscales correspondantes
      HH?: number; // Total des ventilations des dotations aux provisions et charges à payer non déductibles pour l'assiette de l'impôt
      MG?: number; // Déficits de l'exercice
      MH?: number; // Total des déficits restant à reporter
      PG?: number; // Déficit restant à reporter au titre de l'exercice précédent
      PH?: number; // Déficits imputés
      PJ?: number; // Déficits reportables
      AW?: number; // Dont montant déductible des cotisations facultatives versées en application du I de l'article 154 bis du CGI dont cotisations facultatives Madelin
      AX?: number; // Dont cotisations facultatives aux nouveaux plans d'épargne retraite
      AV?: number; // Dont montant déductible des cotisations sociales obligatoires hors CSG-CRDS
      AL?: number; // Déficits transférés de plein droit (article 209-II-2 du CGI)
      AM?: number; // Nombre d'opérations sur l'exercice
      AY?: number; // Provision pour amortissements dérogatoires - Fonds commercial – Dotations
      AZ?: number; // Provision pour amortissements dérogatoires – Fonds commercial – Reprises
    };

    export type FORM_2033E = {
      DB?: boolean; // Déposé néant?
    };

    export type FORM_2033F = {
      GS?: boolean; // Déposé néant?

      repetition2033FPhysiques?: {
        BA_3036_1?: string; // Nom patronymique, prénoms de l'associé personne physique
        BA_3036_2?: string; // Nom marital de l'associé personne physique
        BA_3036_3?: string; // Titre de l'associé personne physique
        BA_3042_1?: string; // Adresse de l'associé personne physique, numéro, type et nom voie
        BA_3042_2?: string; // Adresse de l'associé personne physique, complément de distribution
        BA_3042_3?: string; // Adresse de l'associé personne physique, lieu-dit, hameau
        BA_3164_1?: string; // Adresse de l'associé personne physique, ville
        BA_3207_1?: string; // Adresse de l'associé personne physique, pays // Code ISO (FR)
        BA_3251_1?: string; // Adresse de l'associé personne physique, code postal
        BE?: string; // string de naissance de l'associé personne physique
        BG_3164_1?: string; // Commune de naissance de l'associé personne physique
        BG_3207_1?: string; // Pays de naissance de l'associé personne physique // Code ISO (FR)
        BG_3251_1?: string; // Département de naissance de l'associé personne physique
        BD?: number; // Nombre de parts de l'associé personne physique
        BR?: number; // Pourcentage de détention de l'associé personne physique
      }[];
      repetition2033FMorales?: {
        GA_3036_1?: string; // Dénomination de l'associé personne morale
        GA_3036_2?: string; // Complément de désignation de l'associé personne morale
        GA_3036_3?: string; // Forme juridique de l'associé personne morale
        GA_3039_1?: string; // Numéro SIREN de l'associé personne morale
        GA_3042_1?: string; // Adresse de l'associé personne morale, numéro, type et nom voie
        GA_3042_2?: string; // Adresse de l'associé personne morale, complément de distribution
        GA_3042_3?: string; // Adresse de l'associé personne morale, lieu-dit, hameau
        GA_3164_1?: string; // Adresse de l'associé personne morale, ville
        GA_3207_1?: string; // Adresse de l'associé personne morale, pays // Code ISO (FR)
        GA_3251_1?: string; // Adresse de l'associé personne morale, code postal
        GD?: number; // Nombre de parts de l'associé personne morale
        GR?: number; // Pourcentage de détention de l'associé personne morale
      }[];
      GT?: number; // Nombre total de personnes morales associés ou actionnaires de l'entreprise
      GU?: number; // Nombre total de parts ou d'actions détenus par des associés ou actionnaires personnes morales de l'entreprise
      GV?: number; // Nombre total de personnes physiques associés ou actionnaires de l'entreprise
      GW?: number; // Nombre total de parts ou d'actions détenus par des associés ou actionnaires personnes physiques de l'entreprise
      GX?: number; // Total des lignes 901 + 903
      GY?: number; // Total des lignes 902 + 904
    };

    export type FORM_2033G = {
      GS?: boolean; // Déposé néant?
    };

    export type FORM_2069RCI = {
      AB?: boolean; // Déposé néant?
    };

    export type FORM_2031 = {
      AA_2031?: boolean; // Option pour la comptabilité super-simplifiée ?
      AB_2031_4440_1?: string; // Activité exercée, première ligne
      AB_2031_4440_2?: string; // Activité exercée, seconde ligne
      AD_2031?: boolean; // Soumission à la TVA?
      AE_2031?: number; // Quote-part de subvention d'équipement, d'indemnités d'assurance compensant la perte d'un élément de l'actif immobilisé et des plus-values à court terme définies à l'article 39 duodecies du CGI
      AF_2031?: number; // Charges ou dépenses ayant la nature de moins-values définies à l'article 39 duodecies
      BG_2031_3042_1?: string; // Adresse du domicile du déclarant, complément de distribution
      BG_2031_3042_2?: string; // Adresse du domicile du déclarant, numéro type nom voie
      BG_2031_3042_3?: string; // Adresse du domicile du déclarant, lieu-dit hameau
      BG_2031_3164_1?: string; // Adresse du domicile du déclarant, nom de la ville
      BG_2031_3207_1?: string; // Adresse du domicile de l'exploitant, pays (Code ISO)
      BG_2031_3251_1?: string; // Adresse du domicile du déclarant, code postal
      BM_2031_3042_1?: string; // Ancienne adresse du déclarant en cas de changement, numéro type nom voie
      BM_2031_3042_2?: string; // Ancienne adresse du déclarant en cas de changement, complément de distribution
      BM_2031_3042_3?: string; // Ancienne adresse du déclarant en cas de changement, lieu-dit, hameau
      BM_2031_3164_1?: string; // Ancienne adresse du déclarant en cas de changement, ville
      BM_2031_3207_1?: string; // Ancienne adresse du déclarant en cas de changement, pays
      BM_2031_3251_1?: string; // Ancienne adresse du déclarant en cas de changement, code postal
      BT_2031?: boolean; // Inscription à la chambre des métiers?
      CA_2031?: number; // Récapitulation des éléments d'imposition, résultat fiscal, bénéfice
      CB_2031?: number; // Récapitulation des éléments d'imposition, résultat fiscal, déficit
      CC_2031?: number; // Revenus de valeurs et capitaux mobiliers exonérés de l'impôt sur le revenu
      CD_2031?: number; // Quote part de frais et charges correspondants à déduire des revenus de valeurs et capitaux mobiliers
      CE_2031?: number; // Revenus de valeurs et capitaux mobiliers nets exonérés
      CF_2031?: number; // Revenus de valeurs et capitaux mobiliers soumis à l'impôt sur le revenu
      CH_2031?: number; // Total des revenus de valeurs et capitaux mobiliers à reporter
      CJ_2031?: number; // Total éléments d'imposition 3, bénéfice
      CK_2031?: number; // Total éléments d'imposition 3, déficit
      CL_2031?: number; // Bénéfice imposable
      CM_2031?: number; // Déficit déductible
      CN_2031?: number; // Plus values à long terme imposables à 12,8 %
      CP_2031?: number; // Plus values imposables selon les règles appliquées aux particuliers
      CQ_2031?: number; // Exonération ou abattement pratiqué sur les plus-values à long terme à 12,8 %
      CR_2031?: number; // Exonération ou abattement pratiqué sur le bénéfice professionnel
      CV_2031?: number; // Plus values à long terme imposables selon l'article 39 quindecies I.1
      CW_2031?: number; // Bic non professionnels, bénéfice
      CX_2031?: number; // Bic non professionnels, déficit
      DA_2031?: string; // Comptable salarié ou indépendant?
      DC_2031_3036_1?: string; // Nom et adresse du comptable ou du viseur conventionné: nom et prénom ou désignation
      DC_2031_3036_2?: string; // Nom et adresse du comptable ou du viseur conventionné: désignation personne, qualité et profession
      DC_2031_3036_3?: string; // Nom et adresse du comptable ou du viseur conventionné: forme juridique ou titre
      DC_2031_3042_1?: string; // Nom et adresse du comptable ou du viseur conventionné: numéro, type, nom voie
      DC_2031_3042_2?: string; // Nom et adresse du comptable ou du viseur conventionné: Complément de distribution
      DC_2031_3042_3?: string; // Nom et adresse du comptable ou du viseur conventionné: Lieu-dit hameau
      DC_2031_3148_1_TE?: string; // Nom et adresse du comptable ou du viseur conventionné: numéro de téléphone
      DC_2031_3164_1?: string; // Nom et adresse du comptable ou du viseur conventionné: ville
      DC_2031_3207_1?: string; // Nom et adresse du comptable ou du viseur conventionné: pays
      DC_2031_3251_1?: string; // Nom et adresse du comptable ou du viseur conventionné: code postal
      EA_2031_3036_1?: string; // Nom et adresse du CGA, de l'OMGA, du viseur ou du certificateur conventionné, désignation
      EA_2031_3036_2?: string; // Nom et adresse du CGA, de l'OMGA, du viseur ou du certificateur conventionné, complément de désignation
      EA_2031_3036_3?: string; // Nom et adresse du CGA, de l'OMGA, du viseur ou du certificateur conventionné, forme juridique
      EA_2031_3039_1?: string; // Nom et adresse du CGA, de l'OMGA, du viseur ou du certificateur conventionné, numéro d'agrément
      EA_2031_3042_1?: string; // Nom et adresse du CGA, de l'OMGA, du viseur ou du certificateur conventionné, numéro, type, nom voie
      EA_2031_3042_2?: string; // Nom et adresse du CGA, de l'OMGA, du viseur ou du certificateur conventionné, complément de distribution
      EA_2031_3042_3?: string; // Nom et adresse du CGA, de l'OMGA, du viseur ou du certificateur conventionné, lieu-dit, hameau
      EA_2031_3164_1?: string; // Nom et adresse du CGA, de l'OMGA, du viseur ou du certificateur conventionné, ville
      EA_2031_3207_1?: string; // Nom et adresse du CGA, de l'OMGA, du viseur ou du certificateur conventionné, pays
      EA_2031_3251_1?: string; // Nom et adresse du CGA, de l'OMGA, du viseur ou du certificateur conventionné, code postal
      FA_2031?: string; // Conseil salarié ou indépendant?
      FC_2031_3036_1?: string; // Nom et adresse du conseil, nom et prénom ou dénomination
      FC_2031_3036_2?: string; // Nom et adresse du conseil, qualité et profession ou complément de dénomination
      FC_2031_3036_3?: string; // Nom et adresse du conseil, forme juridique ou titre
      FC_2031_3042_1?: string; // Nom et adresse du conseil, numéro, type, nom voie
      FC_2031_3042_2?: string; // Nom et adresse du conseil, Complément de distribution
      FC_2031_3042_3?: string; // Nom et adresse du conseil, lieu-dit, hameau
      FC_2031_3148_1_TE?: string; // Nom et adresse du conseil, numéro de téléphone
      FC_2031_3164_1?: string; // Nom et adresse du conseil, ville
      FC_2031_3207_1?: string; // Nom et adresse du conseil, pays
      FC_2031_3251_1?: string; // Nom et adresse du conseil, code postal
      JA_2031?: boolean; // Entreprise nouvelle selon l'article 44 sexies
      JB_2031?: boolean; // Zone franche urbaine article 44 octies ou 44 octies A
      KC_2031?: boolean; // Zone franche d'activités nouvelle génération art.44 quaterdecies
      JK_2031?: number; // Résultat fiscal du mode de calcul de l'impôt sur les sociétés
      JL_2031?: boolean; // Enterprise nouvelle, autres dispositifs?
      KG_2031?: number; // Plus-values à court terme et à long terme exonérées
      KK_2031?: boolean; // Jeunes entreprises innovantes art 44 sexies OA?
      KM_2031?: number; // Plus values à long terme exonérées selon l'art 151 septies A
      KQ_2031?: boolean; // Zone franche d'activités art.44 quaterdecies
      KR_2031?: boolean; // Zone de restructuration de défense art.44 terdecies ?
      KV_2031?: boolean; // Visa : CGA/OMGA
      KW_2031?: boolean; // Visa : viseur ou certificateur conventionné
      KX_2031?: boolean; // ZRR art. 44 quindecies
      KY_2031?: boolean; // Si option pour le régime optionnel de taxation au tonnage (entreprises de transport maritime)
      KZ_2031?: boolean; // Option pour le crédit d'impôt outre-mer dans le secteur productif, art. 244 quater W
      AY_2031?: boolean; // France Ruralités Revitalisation art.44 quindecies
      AG_2031?: boolean; // Bassins urbains à dynamiser (BUD) art. 44 sexdecies
      AH_2031?: boolean; // L'entreprise dispose-t-elle d'une comptabilité informatisée ?
      AJ_2031?: string; // Si oui, indication du logiciel utilisé
      AQ_2031?: number; // 4bis Résultat net de cession, de concession ou de sous-concession des brevets et droits de propriété industrielle assimilés au taux de 10 %
      AP_2031?: number; // Dont plus-value à court terme exonérée (Art. 151 septies, 151 septies A et 238 quindecies)
      AL_2031?: number; // PV à court terme, subventions d'équipement et indemnités d'assurance pour perte d'un élément d'actif (Art. 39 duodecies)
      AM_2031?: number; // Moins-values à court terme (art 39 duodécies)
      AU_2031?: number; // Exonération ou abattement pratiqué sur le bénéfice non professionnel
      AR_2031?: boolean; // Zone de développement prioritaire art. 44 septdecies
      AV_2031?: number; // PV nettes à long terme imposable à 12,8 %
      AT_2031?: boolean; // ECF
      AW_2031_3036_1?: string; // Dénomination du prestataire
      AW_2031_3036_2?: string; // Complément de dénomination du prestataire
      AW_2031_3036_3?: string; // Complément 2 de dénomination du prestataire
      AW_2031_3039_1?: string; // Siren du prestataire
      AW_2031_3042_1?: string; // Numéro, type et nom voie du prestataire
      AW_2031_3042_2?: string; // Complément de distribution du prestataire
      AW_2031_3042_3?: string; // Lieu-dit, hameau du prestataire
      AW_2031_3164_1?: string; // Ville du prestataire
      AW_2031_3207_1?: string; // Pays du prestataire
      AW_2031_3251_1?: string; // Code postal du prestataire
      AC_2031?: number; // Assiette de la contribution temporaire de solidarité au taux de 33 %
      AX_2031?: boolean; // Reprise d'entreprise en difficulté art.44 septies
      SC_2031BIS?: number; // Résultat avant imputation des déficits antérieurs, bénéfice
      TC_2031BIS?: number; // Résultat avant imputation des déficits antérieurs, déficit
    };

    export type FORM_SUIV39C = {
      AH?: number; // Amortissements non déduits au titre des exercices antérieurs - Parts de la structure bailleresse détenues directement ou indirectement par des personnes physiques ou exploitants individuels
      AJ?: number; // Amortissements non déduits au titre des exercices antérieurs – Total
      AM?: number; // Amortissements non déduits au titre de l'exercice  - Parts de la structure bailleresse détenues directement ou indirectement par des personnes physiques ou exploitants individuels
      AN?: number; // Amortissements non déduits au titre de l'exercice – Total
      BH?: number; // Amortissements restant à déduire sur les exercices ultérieurs - Parts de la structure bailleresse détenues directement ou indirectement par des personnes physiques ou exploitants individuels
      BJ?: number; // Amortissements restant à déduire sur les exercices ultérieurs – Total
      BD?: number; // Imputation sur la quote-part de résultat de l'exercice des amortissements non déduits antérieurement - Parts de la structure bailleresse détenues directement ou indirectement par des personnes physiques ou exploitants individuels
      BE?: number; // Imputation sur la quote-part de résultat de l'exercice des amortissements non déduits antérieurement – Total
    };

    // Cerfa 2072C is for legal persons, unused for now
    export type FORM_2072C = {
      AA?: string // Activités exercées (information dans l'attribut "tip"),
      AD?: number // Revenu brut
      AE?: number // Paiement sur travaux
      AF?: number// Frais et charges autres qu'intérêts d'emprunts
      AJ?: number // Intérêts des emprunts
      AK?: number // Résultat net
      BE?: number // Nombre d'associés
      BF?: number // Nombre total de parts de la société au 1er janvier de l'année déclarée
      BG?: number // Montant global des produits financiers réalisés par la société immobilière
      BH?: number // Montant global des produits exceptionnels (plus-values de cession) réalisés par la société immobilière
      BN?: number // Montant nominal des parts au 1er janvier de l'année déclarée
      DJ?: number // Augmentation du nombre de parts au cours de l'année déclarée
      EA?: number // Diminution du nombre de parts au cours de l'année déclarée
      MW?: number // Montant global des charges exceptionnelles (moins-values de cession) réalisées par la société immobilière
      ZA_3036_1?: string // Nom, prénom ou dénomination du gérant
      ZA_3036_2?: string // Nom, prénom ou dénomination du gérant
      ZA_3036_3?: string // Forme juridique ou titre du gérant
      ZA_3042_1?: string // Adresse du gérant
      ZA_3042_2?: string // Complément d'adresse du gérant
      ZA_3042_3?: string // Lieu-dit, hameau du gérant
      ZA_3148_1_TE?: string // Téléphone du gérant
      ZA_3164_1?: string // Ville du gérant
      ZA_3207_1?: string // Pays du gérant
      ZA_3251_1?: string // Code postal du gérant
      ZB_3036_1?: string // Nom, prénom ou dénomination du comptable
      ZB_3036_2?: string // Qualité, complément de nom, prénom ou dénomination du comptable
      ZB_3036_3?: string // Forme juridique ou titre du comptable
      ZB_3042_1?: string // Adresse du comptable
      ZB_3042_2?: string // Complément d'adresse du comptable
      ZB_3042_3?: string // Lieu-dit, hameau du comptable
      ZB_3148_1_TE?: string // Téléphone du comptable
      ZB_3164_1?: string // Ville du comptable
      ZB_3207_1?: string // Pays du comptable
      ZB_3251_1?: string // Code postal du comptable
      ZC_3036_1?: string // Nom, prénom ou dénomination du conseil
      ZC_3036_2?: string // Qualité, complément de nom, prénom ou dénomination du conseil
      ZC_3036_3?: string // Forme juridique ou titre du conseil
      ZC_3042_1?: string // Adresse du conseil
      ZC_3042_2?: string // Complément d'adresse du conseil
      ZC_3042_3?: string // Lieu-dit, hameau du conseil
      ZC_3148_1_TE?: string // Téléphone du conseil
      ZC_3164_1?: string // Ville du conseil
      ZC_3207_1?: string // Pays du conseil
      ZC_3251_1?: string // Code postal du conseil
      ZD_3036_1?: string // Nom, prénom ou dénomination de l'administrateur de biens
      ZD_3036_2?: string // Qualité, complément de nom, prénom ou dénomination de l'administrateur de biens
      ZD_3036_3?: string // Forme juridique ou titre de l'administrateur de biens
      ZD_3042_1?: string // Adresse de l'administrateur de biens
      ZD_3042_2?: string // Complément d'adresse de l'administrateur de biens
      ZD_3042_3?: string // Lieu-dit, hameau de l'administrateur de biens
      ZD_3148_1_TE?: string // Téléphone de l'administrateur de biens
      ZD_3164_1?: string // Ville de l'administrateur de biens
      ZD_3207_1?: string // Pays de l'administrateur de biens
      ZD_3251_1?: string // Code postal de l'administrateur de biens
      // AA?: number // Total des produits d'exploitation BIC/IS
      AB?: number // Total des produits d'exploitation BA
      AC?: number // Résultat d'exploitation BIC/IS
      AG?: number // Résultat d'exploitation BA
      AH?: number // Résultat financier BIC/IS
      AT?: number // Résultat financier BA
      AU?: number // Résultat exceptionnel BIC/IS
      AL?: number // Résultat exceptionnel BA
      AM?: number // Résultat fiscal BIC/IS
      AN?: number // Résultat fiscal BA
      ZE_3042_1?: string // Numéro, type et nom voie du siège social au 1er janvier de l'année déclarée
      ZE_3042_2?: string // Complément de distribution du siège social au 1er janvier de l'année déclarée
      ZE_3042_3?: string // Lieu-dit, hameau du siège social au 1er janvier de l'année déclarée
      ZE_3164_1?: string // Ville du siège social au 1er janvier de l'année déclarée
      ZE_3207_1?: string // Pays du siège social au 1er janvier de l'année déclarée
      ZE_3251_1?: string // Code postal du siège social au 1er janvier de l'année déclarée
      ZF_3042_1?: string // Numéro, type et nom voie du siège social en cas de changement au cours de l'année déclarée
      ZF_3042_2?: string // Complément de distribution du siège social en cas de changement au cours de l'année déclarée
      ZF_3042_3?: string // Lieu-dit, hameau du siège social en cas de changement au cours de l'année déclarée
      ZF_3164_1?: string // Ville du siège social en cas de changement au cours de l'année déclarée
      ZF_3207_1?: string // Pays du siège social en cas de changement au cours de l'année déclarée
      ZF_3251_1?: string // Code postal du siège social en cas de changement au cours de l'année déclarée
      AQ?: number // Nombre total de parts dans le société au 31 décembre de l'année déclarée
      AR?: number // Montant nominal des parts au 31 décembre de l'année déclarée
      GA?: number // Revenus des immeubles BIC/IS
      GB?: number // Autres produits de gestion courante BIC/IS
      GC?: number // Total des produits d'exploitation BIC/IS
      GD?: number // Achat et autres charges externes BIC/IS
      GE?: number // Impôts, taxes et versements assimilés BIC/IS
      GF?: number // Charges de personnel BIC/IS
      GG?: number // Autres charges de gestion courante BIC/IS
      GH?: number // Dotations aux amortissements et aux provisions BIC/IS
      GJ?: number // Total des charges d'exploitation BIC/IS
      GK?: number // Résultat d'exploitation BIC/IS
      GL?: number // Produits des valeurs mobilières et participations BIC/IS
      GM?: number // Autres produits financiers BIC/IS
      GN?: number // Total des produits financiers BIC/IS
      GP?: number // Charges d'intérêts BIC/IS
      GQ?: number // Autres charges financières BIC/IS
      GR?: number // Total des charges financières BIC/IS
      GS?: number // Résultat financier BIC/IS
      GT?: number // Produits de cession d'éléments d'actifs BIC/IS
      GU?: number // Autres produits exceptionnels BIC/IS
      GV?: number // Total des produits exceptionnels BIC/IS
      GW?: number // Valeurs comptables des éléments d'actifs cédés BIC/IS
      GX?: number // Autres charges exceptionnelles BIC/IS
      GY?: number // Total des charges exceptionnelle BIC/IS
      GZ?: number // Résultat exceptionnel BIC/IS
      HA?: number // Résultat comptable BIC/IS
      HB?: number // Moins-values nettes à long terme de l'exercice BIC/IS
      HC?: number // Plus-values nettes à court terme réalisées au cours des exercices antérieurs à rapporter aux résultats de l'exercice BIC/IS
      HD?: number // Divers BIC/IS
      HE?: number // Total des réintégrations extra-comptables BIC/IS
      HF?: number // Produits financiers BIC/IS
      HG?: number // Plus-values à long terme de l'exercice BIC/IS
      HH?: number // Fraction des plus-values nettes à court terme dont l'imposition est différée BIC/IS
      HJ?: number // Divers BIC/IS
      HK?: number // Total des déductions extra-comptables BIC/IS
      HR?: number // Résultat fiscal BIC/IS
      JA?: number // Revenus des immeubles BA
      JB?: number // Autres produits de gestion courante BA
      JC?: number // Total des produits d'exploitation BA
      JD?: number // Achat et autres charges externes BA
      JE?: number // Impôts, taxes et versements assimilés BA
      JF?: number // Charges de personnel BA
      JG?: number // Autres charges de gestion courante BA
      JH?: number // Dotations aux amortissements et aux provisions BA
      JJ?: number // Total des charges d'exploitation BA
      JK?: number // Résultat d'exploitation BA
      JL?: number // Produits des valeurs mobilières et participations BA
      JM?: number // Autres produits financiers BA
      JN?: number // Total des produits financiers BA
      JP?: number // Charges d'intérêts BA
      JQ?: number // Autres charges financières BA
      JR?: number // Total des charges financières BA
      JS?: number // Résultat financier BA
      JT?: number // Produits de cession d'éléments d'actifs BA
      JU?: number // Autres produits exceptionnels BA
      JV?: number // Total des produits exceptionnels BA
      JW?: number // Valeurs comptables des éléments d'actifs cédés BIC/IS
      JX?: number // Autres charges exceptionnelles BA
      JY?: number // Total des charges exceptionnelles BA
      JZ?: number // Résultat exceptionnel BA
      KA?: number // Résultat comptable BA
      KB?: number // Moins-values nettes à long terme de l'exercice BA
      KC?: number // Plus-values nettes à court terme réalisées au cours des exercices antérieurs à rapporter aux résultats de l'exercice BA
      KD?: number // Divers BA
      KE?: number // Total des réintégrations extra-comptables BA
      KF?: number // Produits financiers BA
      KG?: number // Plus-values à long terme de l'exercice BA
      KH?: number // Fraction des plus-values nettes à court terme dont l'imposition est différée BA
      KJ?: number // Divers BA
      KK?: number // Total des déductions extra-comptables BA
      KR?: number // Résultat fiscal BA
      BJ_3036_1?: string[] // ! Repetitions, Nom, prénom ou dénomination du tiers non associé bénéficiant gratuitement de la jouissance de tout ou partie d'un immeuble de la société
      BJ_3036_2?: string[] // ! Repetitions, Qualité ou complément de nom/désignation du tiers non associé bénéficiant gratuitement de la jouissance de tout ou partie d'un immeuble de la société
      BJ_3036_3?: string[] // ! Repetitions, Forme juridique ou titre du tiers non associé bénéficiant gratuitement de la jouissance de tout ou partie d'un immeuble de la société
      BJ_3039_1?: string[] // ! Repetitions, SIREN du tiers non associé bénéficiant gratuitement de la jouissance de tout ou partie d'un immeuble de la société
      BK?: string[] // ! Repetitions, Numéro de l'immeuble attribué en jouissance au tiers non associé bénéficiant gratuitement de la jouissance de tout ou partie d'un immeuble de la société
      AS_3042_1?: string[] // ! Repetitions, Numéro, type et nom voie si l'adresse est différente de celle de l'immeuble dont il détient gratuitement la jouissance
      AS_3042_2?: string[] // ! Repetitions, Complément de distribution si l'adresse est différente de celle de l'immeuble dont il détient gratuitement la jouissance
      AS_3042_3?: string[] // ! Repetitions, Lieu-dit, hameau si l'adresse est différente de celle de l'immeuble dont il détient gratuitement la jouissance
      AS_3164_1?: string[] // ! Repetitions, Ville si l'adresse est différente de celle de l'immeuble dont il détient gratuitement la jouissance
      AS_3207_1?: string[] // ! Repetitions, Pays si l'adresse est différente de celle de l'immeuble dont il détient gratuitement la jouissance
      AS_3251_1?: string[] // ! Repetitions, Code postal si l'adresse est différente de celle de l'immeuble dont il détient gratuitement la jouissance
      DL_3042_1?: string[] // ! Repetitions, Immeuble nouveau numéro, type et nom voie
      DL_3042_2?: string[] // ! Repetitions, Immeuble nouveau complément de distribution
      DL_3042_3?: string[] // ! Repetitions, Immeuble nouveau lieu-dit, hameau
      DL_3164_1?: string[] // ! Repetitions, Immeuble nouveau, ville
      DL_3207_1?: string[] // ! Repetitions, Immeuble nouveau pays
      DL_3251_1?: string[] // ! Repetitions, Immeuble nouveau code postal
      EC?: boolean[] // ! Repetitions, En cas d'acquisition d'immeubles en cours de l'année déclarée, cocher la case
      MY?: number[] // ! Repetitions, Nombre de locaux
      AY?: string[] // ! Repetitions, Déduction spécifique
      PA?: string[] // ! Repetitions, Nature de l'immeuble - A
      PB?: string[] // ! Repetitions, Nature de l'immeuble - B
      PC?: string[] // ! Repetitions, N° d'immeuble
      AP?: string[] // ! Repetitions, Déduction au titre de l'amortissement
      PE?: string[] // ! Repetitions, N° associé ou usufruitier cédant
      PF?: boolean[] // ! Repetitions, Le cédant est une holding
      PG?: string[] // ! Repetitions, N° associé ou usufruitier cessionnaire
      PH?: boolean[] // ! Repetitions, Le cessionnaire est une holding
      PJ?: string[] // ! Repetitions, string des cessions de parts de la société immobilière
      PK?: number[] // ! Repetitions, Nombre de parts cédées au cours de l'année déclarée
      PL?: boolean[] // ! Repetitions, Cédant bénéficiant de la jouissance gratuite de tout ou partie d'un immeuble détenu par la société immobilière
      PM?: boolean[] // ! Repetitions, Cessionnaire bénéficiant de la jouissance gratuite de tout ou partie d'un immeuble détenu par la société immobilière
      HN?: number[] // ! Repetitions, Divers à réintégrer - Montant - BIC/IS
      KN?: number[] // ! Repetitions, Divers à réintégrer - Montant - BA
      HM?: string[] // ! Repetitions, Divers à réintégrer - Libellé - BIC/IS
      HQ?: number[] // ! Repetitions, Divers à déduire - Montant - BIC/IS
      KQ?: number[] // ! Repetitions, Divers à déduire - Montant - BA
      HP?: string[] // ! Repetitions, Divers à déduire - Libellé - BA
    };

    export type FORM_2072CA1 = {
      CJ?: number; // Revenus ou déficits relatifs aux parts détenues dans d'autres sociétés immobilières non passibles de l'impôt sur les sociétés
      CK?: number; // Revenus ou déficits à répartir entre les associés
      GD?: number; // Total de l'amortissement pratiqué - Méthode linéaire
      GE?: number; // Total de l'amortissement pratiqué - Méthode dégressive
      AA?: string[]; // ! Repetitions, Numéro d'ordre de l'immeuble
      BB?: number[]; // ! Repetitions, Montant brut des fermages ou des loyers encaissés
      BC?: number[]; // ! Repetitions, Dépenses par nature déductibles incombant normalement à la société immobilière et mises par convention à la charge des locataires
      BD?: number[]; // ! Repetitions, Recettes brutes diverses. Subventions ANAH, indemnités d'assurance
      BE?: number[]; // ! Repetitions, Recettes qu'aurait pu produire la location des propriétés qui ne sont pas affectées à l'habitation dont la société se réserve la jouissance ou qu'elle met gratuitement à la disposition des associés ou des tiers
      BF?: number[]; // ! Repetitions, Total des recettes
      BG?: number[]; // ! Repetitions, Frais d'administration et de gestion
      BH?: number[]; // ! Repetitions, Autres frais de gestion non déductibles pour leur montant réel (montant forfaitaire fixé à 20€ par local)
      BJ?: number[]; // ! Repetitions, Primes d'assurance
      BK?: number[]; // ! Repetitions, Dépenses de réparation, d'entretien et d'amélioration
      BL?: number[]; // ! Repetitions, Charges récupérables non récupérées au départ du locataire
      BM?: number[]; // ! Repetitions, Indemnités d'éviction, frais de relogement
      BN?: number[]; // ! Repetitions, Impositions (y compris la CRL payée au titre de l'année considérée)
      BP?: number[]; // ! Repetitions, Provisions pour charges de copropriété payées au titre de l'année considérée
      BQ?: number[]; // ! Repetitions, Régularisation des provisions pour charges de copropriété déduites au titre de l'année antérieure par les copropriétaires bailleurs
      BR?: number[]; // ! Repetitions, Total des déductions, frais et charges
      BS?: number[]; // ! Repetitions, Montant de la déduction spécifique (sauf mobilité professionnelle)
      BT?: number[]; // ! Repetitions, Intérêts des emprunts contractés pour l'acquisition, la reconstruction, l'agrandissement, la réparation, l'amélioration ou la conservation des immeubles
      BU?: number[]; // ! Repetitions, Revenu ou déficit de l'immeuble
      BV?: number[]; // ! Repetitions, Réintégration du supplément de déduction
      BW?: number[]; // ! Repetitions, Rémunérations et avantages en nature attribués aux associés
      CE?: number[]; // ! Repetitions, Revenu net ou déficit de l'immeuble
      DC?: number[]; // ! Repetitions, Dépenses spécifiques relatives aux immeubles spéciaux
      DD?: number[]; // ! Repetitions, Montant de la déduction au titre de l'amortissement au titre de l'année considérée
      EA?: number[]; // ! Repetitions, Prix de revient de l’immeuble en €
      EB?: string[]; // ! Repetitions, string de début de la période d’amortissement
      EC?: number[]; // ! Repetitions, Montant de la déduction pratiquée au titre de l’amortissement pour l’année considérée en €
      ED?: number[]; // ! Repetitions, Montant cumulé des déductions pratiquées au titre de l’amortissement de 1996 à l’année considérée en €
      EL?: number[]; // ! Repetitions, Montant de la déduction pratiquée au titre de l’amortissement pour l’année considérée en €
      EM?: number[]; // ! Repetitions, Montant cumulé des déductions pratiquées au titre de l’amortissement de 1996 à l’année considérée en €
      EU?: number[]; // ! Repetitions, Montant de la déduction pratiquée au titre de l’amortissement pour l’année considérée en €
      EV?: number[]; // ! Repetitions, Montant cumulé des déductions pratiquées au titre de l’amortissement de 1996 à l’année considérée en €
      EW?: number[]; // ! Repetitions, Montant total de la déduction pratiquée au titre de l’amortissement pour l’année considérée en €
      CF?: string[]; // ! Repetitions, string de la cession réalisée par la société immobilière
      CG?: number[]; // ! Repetitions, Montant de la cession réalisée
      CH?: string[]; // ! Repetitions, Méthode de calcul des parts cédées par la société immobilière (tableau TFR)
      GF?: string[]; // ! Repetitions, N° d'immeuble - Amortissement réalisé au regard des règles des revenus des professionnels ?: méthode par composant
      GA?: string[]; // ! Repetitions, Nom du composant amorti
      GB?: number[]; // ! Repetitions, Méthode linéaire
      GC?: number[]; // ! Repetitions, Méthode dégressive
    };

    export type FORM_2072CA2 = {
      AA?: string[]; // ! Repetitions, N° associé
      AB_3036_1?: string[]; // ! Repetitions, Associé personne morale, Désignation
      AB_3036_2?: string[]; // ! Repetitions, Associé personne morale, Complément de dénomination
      AB_3036_3?: string[]; // ! Repetitions, Associé personne morale, Complément 2 de dénomination
      AB_3039_1?: string[]; // ! Repetitions, Associé personne morale, N° siren
      AB_3042_1?: string[]; // ! Repetitions, Associé personne morale, Numéro, type et nom voie Adresse à l’ouverture de l’exercice clos déclaré
      AB_3042_2?: string[]; // ! Repetitions, Associé personne morale, Complément de distribution Adresse à l’ouverture de l’exercice clos déclaré
      AB_3042_3?: string[]; // ! Repetitions, Associé personne morale, Lieu-dit, hameau Adresse à l’ouverture de l’exercice clos déclaré
      AB_3164_1?: string[]; // ! Repetitions, Associé personne morale, Ville Adresse à l’ouverture de l’exercice clos déclaré
      AB_3207_1?: string[]; // ! Repetitions, Associé personne morale, Pays Adresse à l’ouverture de l’exercice clos déclaré
      AB_3251_1?: string[]; // ! Repetitions, Associé personne morale, Code postal Adresse à l’ouverture de l’exercice clos déclaré
      AC_3036_1?: string[]; // ! Repetitions, Associé personne physique, Nom
      AC_3036_2?: string[]; // ! Repetitions, Associé personne physique, Nom marital
      AC_3036_3?: string[]; // ! Repetitions, Associé personne physique, Titre (hidden)
      AC_3036_4?: string[]; // ! Repetitions, Associé personne physique, Prénom
      AC_3039_1?: string[]; // ! Repetitions, Associé personne physique, Numéro fiscal
      AC_3042_1?: string[]; // ! Repetitions, Associé personne physique, Numéro, type et nom voie, Adresse à l’ouverture de l’exercice clos déclaré
      AC_3042_2?: string[]; // ! Repetitions, Associé personne physique, Complément de distribution, Adresse à l’ouverture de l’exercice clos déclaré
      AC_3042_3?: string[]; // ! Repetitions, Associé personne physique, Lieu-dit, hameau, Adresse à l’ouverture de l’exercice clos déclaré
      AC_3164_1?: string[]; // ! Repetitions, Associé personne physique, Ville, Adresse à l’ouverture de l’exercice clos déclaré
      AC_3207_1?: string[]; // ! Repetitions, Associé personne physique, Pays, Adresse à l’ouverture de l’exercice clos déclaré
      AC_3251_1?: string[]; // ! Repetitions, Associé personne physique, Code postal, Adresse à l’ouverture de l’exercice clos déclaré
      AD?: string[]; // ! Repetitions, Associé personne physique, string de naissance
      AE_3164_1?: string[]; // ! Repetitions, Associé personne physique, Ville de naissance
      AE_3251_1?: string[]; // ! Repetitions, Associé personne physique, Numéro de département de naissance
      AE_3207_1?: string[]; // ! Repetitions, Associé personne physique, Pays de naissance (paysISOObligatoire)
      AF_3042_1?: string[]; // ! Repetitions, Numéro, type et nom voie Adresse à la clôture de l’exercice clos déclaré en cas de changement d'adresse au cours de l’année déclarée
      AF_3042_2?: string[]; // ! Repetitions, Complément de distribution Adresse à la clôture de l’exercice clos déclaré en cas de changement d'adresse au cours de l’année déclarée
      AF_3042_3?: string[]; // ! Repetitions, Lieu-dit, hameau Adresse à la clôture de l’exercice clos déclaré en cas de changement d'adresse au cours de l’année déclarée
      AF_3164_1?: string[]; // ! Repetitions, Ville Adresse à la clôture de l’exercice clos déclaré en cas de changement d'adresse au cours de l’année déclarée
      AF_3207_1?: string[]; // ! Repetitions, Pays Adresse à la clôture de l’exercice clos déclaré en cas de changement d'adresse au cours de l’année déclarée
      AF_3251_1?: string[]; // ! Repetitions, Code postal Adresse à la clôture de l’exercice clos déclaré en cas de changement d'adresse au cours de l’année déclarée
      AJ?: number[]; // ! Repetitions, Nombre de parts détenues dans la société immobilière au titre de l’exercice clos déclaré
      AL?: number[]; // ! Repetitions, Montant nominal des parts au titre de l’exercice clos déclaré
      AM?: boolean[]; // ! Repetitions, Acquisition de parts de la société immobilière au titre de l’exercice clos déclaré
      AN?: boolean[]; // ! Repetitions, Cession de parts de la société immobilière au cours de l’année déclarée
      AQ?: string[]; // ! Repetitions, Immeuble(s) dont l'associé à la jouissance gratuite
      BJ?: number[]; // ! Repetitions, Quote-part des revenus bruts
      BK?: number[]; // ! Repetitions, Quote-part des frais et charges déductibles
      BT?: number[]; // ! Repetitions, Quote-part des dépenses de travaux de rénovation énergétique
      BL?: number[]; // ! Repetitions, Quote-part des intérêts d’emprunt
      BP?: number[]; // ! Repetitions, Quote-part du revenu net ou déficit
      CC?: number[]; // ! Repetitions, Quote-part du montant de la moins-value réalisée par la société immobilière en €
      CD?: number[]; // ! Repetitions, Quote-part du montant de la plus-value de cession réalisée par la société immobilière en €
      CH?: number[]; // ! Repetitions, Montant des rémunérations et avantages en nature attribués
      CN?: number[]; // ! Repetitions, Intérêts des comptes courants d'associés
      EA?: string[]; // ! Repetitions, string d'entrée dans la société immobilière
      EB?: string[]; // ! Repetitions, string de sortie de la société immobilière
      EC?: string[]; // ! Repetitions, Détenteur de l'usufruit
      EG?: number[]; // ! Repetitions, Nombre de titres détenus en usufruit
      BR?: number[]; // ! Repetitions, Quote-part de l'amortissement
      AG?: string[]; // ! Repetitions, N° Associé relevant du régime des revenus professionnels
      AH_3036_1?: string[]; // ! Repetitions, Désignation de l'associé relevant du régime des revenus professionnels (Sequence 1)
      AH_3036_2?: string[]; // ! Repetitions, Complément de désignation de l'associé relevant du régime des revenus professionnels (Sequence 2)
      AH_3036_3?: string[]; // ! Repetitions, Forme juridique de l'associé relevant du régime des revenus professionnels (Sequence 3)
      AH_3039_1?: string[]; // ! Repetitions, Siren de l'associé relevant du régime des revenus professionnels (Sequence 1)
      AH_3042_1?: string[]; // ! Repetitions, Numéro, type et nom voie de l'associé relevant du régime des revenus professionnels (Sequence 1)
      AH_3042_2?: string[]; // ! Repetitions, Complément de distribution de l'associé relevant du régime des revenus professionnels (Sequence 2)
      AH_3042_3?: string[]; // ! Repetitions, Lieu-dit, hameau de l'associé relevant du régime des revenus professionnels (Sequence 3)
      AH_3164_1?: string[]; // ! Repetitions, Ville de l'associé relevant du régime des revenus professionnels (Sequence 1)
      AH_3207_1?: string[]; // ! Repetitions, Pays de l'associé relevant du régime des revenus professionnels (Sequence 1)
      AH_3251_1?: string[]; // ! Repetitions, Code postal de l'associé relevant du régime des revenus professionnels (Sequence 1)
      AK?: boolean[]; // ! Repetitions, Holding
      AP?: string[]; // ! Repetitions, Catégorie Fiscal
      BS_3036_1?: string[]; // ! Repetitions, Dénomination du comptable (Sequence 1)
      BS_3036_2?: string[]; // ! Repetitions, Complément de dénomination du comptable (Sequence 2)
      BS_3036_3?: string[]; // ! Repetitions, Complément 2 de dénomination du comptable (Sequence 3)
      BS_3042_1?: string[]; // ! Repetitions, Numéro, type et nom voie du comptable (Sequence 1)
      BS_3042_2?: string[]; // ! Repetitions, Complément de distribution du comptable (Sequence 2)
      BS_3042_3?: string[]; // ! Repetitions, Lieu-dit, hameau du comptable (Sequence 3)
      BS_3164_1?: string[]; // ! Repetitions, Ville du comptable (Sequence 1)
      BS_3207_1?: string[]; // ! Repetitions, Pays du comptable (Sequence 1)
      BS_3251_1?: string[]; // ! Repetitions, Code postal du comptable (Sequence 1)
      AR_3036_1?: string[]; // ! Repetitions, Dénomination du gérant (Sequence 1)
      AR_3036_2?: string[]; // ! Repetitions, Complément de dénomination du gérant (Sequence 2)
      AR_3036_3?: string[]; // ! Repetitions, Complément 2 de dénomination du gérant (Sequence 3)
      AR_3042_1?: string[]; // ! Repetitions, Numéro, type et nom voie du gérant (Sequence 1)
      AR_3042_2?: string[]; // ! Repetitions, Complément de distribution du gérant (Sequence 2)
      AR_3042_3?: string[]; // ! Repetitions, Lieu-dit, hameau du gérant (Sequence 3)
      AR_3164_1?: string[]; // ! Repetitions, Ville du gérant (Sequence 1)
      AR_3207_1?: string[]; // ! Repetitions, Pays du gérant (Sequence 1)
      AR_3251_1?: string[]; // ! Repetitions, Code postal du gérant (Sequence 1)
      AS_3036_1?: string[]; // ! Repetitions, Dénomination de l'administrateur de bien (Sequence 1)
      AS_3036_2?: string[]; // ! Repetitions, Complément de dénomination de l'administrateur de bien (Sequence 2)
      AS_3036_3?: string[]; // ! Repetitions, Complément 2 de dénomination de l'administrateur de bien (Sequence 3)
      AS_3042_1?: string[]; // ! Repetitions, Numéro, type et nom voie de l'administrateur de bien (Sequence 1)
      AS_3042_2?: string[]; // ! Repetitions, Complément de distribution de l'administrateur de bien (Sequence 2)
      AS_3042_3?: string[]; // ! Repetitions, Lieu-dit, hameau de l'administrateur de bien (Sequence 3)
      AS_3164_1?: string[]; // ! Repetitions, Ville de l'administrateur de bien (Sequence 1)
      AS_3207_1?: string[]; // ! Repetitions, Pays de l'administrateur de bien (Sequence 1)
      AS_3251_1?: string[]; // ! Repetitions, Code postal de l'administrateur de bien (Sequence 1)
      AT_3042_1?: string[]; // ! Repetitions, Numéro, type et nom voie de l'adresse à la clôture de l’exercice clos déclaré en cas de changement d'adresse au cours de l’année déclarée (Sequence 1)
      AT_3042_2?: string[]; // ! Repetitions, Complément de distribution de l'adresse à la clôture de l’exercice clos déclaré en cas de changement d'adresse au cours de l’année déclarée (Sequence 2)
      AT_3042_3?: string[]; // ! Repetitions, Lieu-dit, hameau de l'adresse à la clôture de l’exercice clos déclaré en cas de changement d'adresse au cours de l’année déclarée (Sequence 3)
      AT_3164_1?: string[]; // ! Repetitions, Ville de l'adresse à la clôture de l’exercice clos déclaré en cas de changement d'adresse au cours de l’année déclarée (Sequence 1)
      AT_3207_1?: string[]; // ! Repetitions, Pays de l'adresse à la clôture de l’exercice clos déclaré en cas de changement d'adresse au cours de l’année déclarée (Sequence 1)
      AT_3251_1?: string[]; // ! Repetitions, Code postal de l'adresse à la clôture de l’exercice clos déclaré en cas de changement d'adresse au cours de l’année déclarée (Sequence 1)
      AU?: string[]; // ! Repetitions, string d'entrée dans la société immobilière
      AV?: string[]; // ! Repetitions, string de sortie de la société immobilière
      AW?: number[]; // ! Repetitions, Nombre de parts détenues dans la société immobilière au titre de l’exercice clos déclaré
      AX?: number[]; // ! Repetitions, Montant nominal des parts au titre de l’exercice clos déclaré
      AY?: boolean[]; // ! Repetitions, Acquisition de parts de la société immobilière au titre de l’exercice clos déclaré (utilisation de la table TBX)
      AZ?: boolean[]; // ! Repetitions, Cession de parts de la société immobilière au titre de l’exercice clos déclaré (utilisation de la table TBX)
      BA?: string[]; // ! Repetitions, Immeuble(s) dont l'associé a la jouissance gratuite
      BB?: number[]; // ! Repetitions, Nombre de parts détenues en nue-propriété
      BC?: number[]; // ! Repetitions, Nombre de parts détenues en usufruit
      BD?: string[]; // ! Repetitions, Détenteur de l'usufruit
      BE?: number[]; // ! Repetitions, Quote-part du montant de la moins-value de cession réalisée par la société immobilière en €
      BF?: number[]; // ! Repetitions, Quote-part du montant de la plus-value de cession réalisée par la société immobilière en €
      BG?: number[]; // ! Repetitions, Quote-part du montant des produits financiers réalisés par la société immobilière
      BH?: number[]; // ! Repetitions, Montant des rémunérations et avantages en nature attribués
      CA?: number[]; // ! Repetitions, Intérêts des comptes courant d'associés
      CB?: number[]; // ! Repetitions, Quote-part du résultat fiscal de la société
    };

    export type FORM_2072CA5 = {
      LV_3036_1?: string; // Associé personne morale, Désignation
      LV_3036_2?: string; // Associé personne morale, Complément de dénomination
      LV_3036_3?: string; // Associé personne morale, Complément 2 de dénomination
      LV_3039_1?: string; // Associé personne morale, N° siren
      LV_3042_1?: string; // Associé personne morale, Numéro, type et nom voie Adresse à l’ouverture de l’exercice clos déclaré
      LV_3042_2?: string; // Associé personne morale, Complément de distribution Adresse à l’ouverture de l’exercice clos déclaré
      LV_3042_3?: string; // Associé personne morale, Lieu-dit, hameau Adresse à l’ouverture de l’exercice clos déclaré
      LV_3164_1?: string; // Associé personne morale, Ville Adresse à l’ouverture de l’exercice clos déclaré
      LV_3207_1?: string; // Associé personne morale, Pays Adresse à l’ouverture de l’exercice clos déclaré
      LV_3251_1?: string; // Associé personne morale, Code postal Adresse à l’ouverture de l’exercice clos déclaré
      LW_3036_1?: string; // Associé personne physique, Nom
      LW_3036_2?: string; // Associé personne physique, Nom marital
      LW_3036_3?: string; // Associé personne physique, Titre
      LW_3036_4?: string; // Associé personne physique, Prénom
      LW_3039_1?: string; // Associé personne physique, Numéro fiscal
      LW_3042_1?: string; // Associé personne physique, Numéro, type et nom voie, Adresse à l’ouverture de l’exercice clos déclaré
      LW_3042_2?: string; // Associé personne physique, Complément de distribution, Adresse à l’ouverture de l’exercice clos déclaré
      LW_3042_3?: string; // Associé personne physique, Lieu-dit, hameau, Adresse à l’ouverture de l’exercice clos déclaré
      LW_3164_1?: string; // Associé personne physique, Ville, Adresse à l’ouverture de l’exercice clos déclaré
      LW_3207_1?: string; // Associé personne physique, Pays, Adresse à l’ouverture de l’exercice clos déclaré
      LW_3251_1?: string; // Associé personne physique, Code postal, Adresse à l’ouverture de l’exercice clos déclaré
      LX?: string; // Associé personne physique, string de naissance
      LY_3042_1?: string; // Associé personne physique, Lieu de naissance numéro, type et nom voie
      LY_3042_2?: string; // Associé personne physique, Lieu de naissance complément de distribution
      LY_3042_3?: string; // Associé personne physique, Lieu de naissance lieu-dit, hameau
      LY_3164_1?: string; // Associé personne physique, Ville
      LY_3207_1?: string; // Associé personne physique, Pays
      LY_3251_1?: string; // Associé personne physique, Numéro de département de naissance
      LZ?: number; // Nombre de parts détenues dans la société immobilière au titre de l’exercice clos déclaré
      MB?: number; // Montant nominal des parts au titre de l’exercice clos déclaré
      MC?: boolean; // Acquisition de parts de la société immobilière au titre de l’exercice clos déclaré
      ME?: string; // string d'entrée dans la société immobilière
      MF?: boolean; // Cession de parts de la société immobilière au titre de l’exercice clos déclaré
      MH?: string; // string de sortie de la société immobilière
      MQ?: number; // Nombre de titres détenus en nue-propriété
      QM_3042_1?: string; // Adresse à la clôture de l’exercice clos déclaré en cas de changement d'adresse au cours de l’année déclarée numéro, type et nom voie
      QM_3042_2?: string; // Adresse à la clôture de l’exercice clos déclaré en cas de changement d'adresse au cours de l’année déclarée complément de distribution
      QM_3042_3?: string; // Adresse à la clôture de l’exercice clos déclaré en cas de changement d'adresse au cours de l’année déclarée lieu-dit, hameau
      QM_3164_1?: string; // Adresse à la clôture de l’exercice clos déclaré en cas de changement d'adresse au cours de l’année déclarée, ville
      QM_3207_1?: string; // Adresse à la clôture de l’exercice clos déclaré en cas de changement d'adresse au cours de l’année déclarée pays
      QM_3251_1?: string; // Adresse à la clôture de l’exercice clos déclaré en cas de changement d'adresse au cours de l’année déclarée code postal
      PE?: string; // Associé ou usufruitier cédant
      DH?: boolean; // Holding cédant
      PG?: string; // Associé ou usufruitier cessionnaire
      DJ?: boolean; // Holding cessionnaire
      DE?: string; // string de cession des parts de la société immobilière
      DF?: number; // Nombre de parts acquises
      DG?: boolean; // Jouissance gratuite de tout ou partie d'un immeuble détenu par la société
      LK?: boolean; // Céssionnaire bénéficiant de la jouissance gratuite de tout ou partie d'un immeuble détenu par la société immobilière
      AA?: string; // N° Associé
      AQ?: string; // Immeuble(s) dont l'associé à la jouissance gratuite
      EG?: number; // Nombre de titres détenus en usufruit
      CC?: number; // Quote-part du montant de la moins-value de cession réalisée par la société immobilière en €
      CD?: number; // Quote-part du montant de la plus-value de cession réalisée par la société immobilière en €
      EC?: string; // Détenteur de l'usufruit
      EE?: number; // Quote-part du montant des produits financiers réalisés par la société immobilière
      CH?: number; // Montant des rémunérations et avantages en nature attribués
      CN?: number; // Intérêts des comptes courants d'associés
      BJ?: number; // Quote-part des revenus bruts
      BK?: number; // Quote-part des frais et charges déductibles
      BL?: number; // Quote-part des intérêts d’emprunt
      BR?: number; // Quote-part de l'amortissement
      BP?: number; // Quote-part du revenu net ou déficit
      BN?: number; // Quote-part du montant des loyers abandonnés
    };

    export type FORM_2072E = {
      AA?: boolean; // Déposé néant
      AB?: number; // Effectifs au sens de la CVAE
      EA?: number; // Montant brut des fermages ou des loyers encaissés
      EB?: number; // Dépenses par nature déductibles de l'impôt sur le revenu incombant normalement à la société, la collectivité ou l'organisme sans but lucratif mises par convention à la charge des locataires
      EC?: number; // Recettes brutes diverses (subventions ANAH, indemnités d'assurances'.)
      ED?: number; // Total A
      EE?: number; // Frais d'administration et de gestion et autres frais de gestion
      EF?: number; // Primes d'assurances
      EG?: number; // Dépenses de réparation, d'entretien et d'amélioration
      EK?: number; // Dépenses spécifiques aux monuments historiques
      EL?: number; // Charges récupérables non récupérées au départ du locataire
      EM?: number; // Indeminités d'éviction, frais de relogement
      EN?: number; // Déductions spécifiques du revenu brut (diminuées des éventuelles réintégrations)
      EP?: number; // Provisions pour charges de copropriété payées par les copropriétaires bailleurs et régularisations éventuelles de provisions antérieures
      EQ?: number; // Total B
      ER?: number; // Calcul de la valeur ajoutée
      ES?: number; // Montant de la déduction au titre de l'amortissement
      EU?: number; // Valeur ajoutée rentrant dans le dispositif de la CVAE
      KA?: boolean; // Mono-etablissement au sens de la CVAE
      KB?: string; // Période de référence - du
      KC?: string; // Période de référence - au
      KD?: number; // Chiffre d'affaires de référence CVAE
      AQ?: number; // Chiffre d'affaires du groupe économique (entreprises répondant aux conditions de détention fixées à l'article 223 A du CGI)
      KG?: string; // string de cessation
    };

    export type FORM_2072S = {
      AD?: number; // Revenu brut
      AE?: number; // Paiement sur travaux
      AF?: number; // Frais et charges autres qu'intérêts d'emprunts
      ZZ?: string; // string de signature
      ZY_3036_1?: string; // Nom et prénom du signataire
      ZY_3036_2?: string; // Qualité du signataire
      ZY_3036_3?: string; // Titre du signataire
      ZY_3164_1?: string; // Lieu de signature
      AJ?: number; // Intérêts des emprunts
      AK?: number; // Résultat net
      BE?: number; // Nombre d'associés
      BF?: number; // Nombre fRevtotal de parts de la société au 1er janvier de l'année déclarée
      BG?: number; // Montant global des produits financiers réalisés par la société immobilière
      BH?: number; // Montant global des produits exceptionnels (plus-values de cession) réalisés par la société immobilière
      BN?: number; // Montant nominal des parts au 1er janvier de l'année déclarée
      DJ?: number; // Augmentation du nombre de parts au cours de l'année déclarée
      EA?: number; // Diminution du nombre de parts au cours de l'année déclarée
      MW?: number; // Montant global des charges exceptionnelles (moins-values de cession) réalisées par la société immobilière
      ZA_3036_1?: string; // Nom, prénom ou dénomination du gérant
      ZA_3036_2?: string; // Nom, prénom ou dénomination du gérant
      ZA_3036_3?: string; // Forme juridique ou titre du gérant
      ZA_3042_1?: string; // Adresse du gérant
      ZA_3042_2?: string; // Complément d'adresse du gérant
      ZA_3042_3?: string; // Lieu-dit, hameau du gérant
      ZA_3148_1_TE?: string; // Téléphone du gérant
      ZA_3164_1?: string; // Ville du gérant
      ZA_3207_1?: string; // Pays du gérant
      ZA_3251_1?: string; // Code postal du gérant
      ZB_3036_1?: string; // Nom, prénom ou dénomination du comptable
      ZB_3036_2?: string; // Qualité, complément de nom, prénom ou dénomination du comptable
      ZB_3036_3?: string; // Forme juridique ou titre du comptable
      ZB_3042_1?: string; // Adresse du comptable
      ZB_3042_2?: string; // Complément d'adresse du comptable
      ZB_3042_3?: string; // Lieu-dit, hameau du comptable
      ZB_3148_1_TE?: string; // Téléphone du comptable
      ZB_3164_1?: string; // Ville du comptable
      ZB_3207_1?: string; // Pays du comptable
      ZB_3251_1?: string; // Code postal du comptable
      ZC_3036_1?: string; // Nom, prénom ou dénomination du conseil
      ZC_3036_2?: string; // Qualité, complément de nom, prénom ou dénomination du conseil
      ZC_3036_3?: string; // Forme juridique ou titre du conseil
      ZC_3042_1?: string; // Adresse du conseil
      ZC_3042_2?: string; // Complément d'adresse du conseil
      ZC_3042_3?: string; // Lieu-dit, hameau du conseil
      ZC_3148_1_TE?: string; // Téléphone du conseil
      ZC_3164_1?: string; // Ville du conseil
      ZC_3207_1?: string; // Pays du conseil
      ZC_3251_1?: string; // Code postal du conseil
      ZD_3036_1?: string; // Nom, prénom ou dénomination de l'administrateur de biens
      ZD_3036_2?: string; // Qualité, complément de nom, prénom ou dénomination de l'administrateur de biens
      ZD_3036_3?: string; // Forme juridique ou titre de l'administrateur de biens
      ZD_3042_1?: string; // Adresse de l'administrateur de biens
      ZD_3042_2?: string; // Complément d'adresse de l'administrateur de biens
      ZD_3042_3?: string; // Lieu-dit, hameau de l'administrateur de biens
      ZD_3148_1_TE?: string; // Téléphone de l'administrateur de biens
      ZD_3164_1?: string; // Ville de l'administrateur de biens
      ZD_3207_1?: string; // Pays de l'administrateur de biens
      ZD_3251_1?: string; // Code postal de l'administrateur de biens
      ZE_3042_1?: string; // Numéro, type et nom voie du siège social au 1er janvier de l'année déclarée
      ZE_3042_2?: string; // Complément de distribution du siège social au 1er janvier de l'année déclarée
      ZE_3042_3?: string; // Lieu-dit, hameau du siège social au 1er janvier de l'année déclarée
      ZE_3164_1?: string; // Ville du siège social au 1er janvier de l'année déclarée
      ZE_3207_1?: string; // Pays du siège social au 1er janvier de l'année déclarée
      ZE_3251_1?: string; // Code postal du siège social au 1er janvier de l'année déclarée
      ZF_3042_1?: string; // Numéro, type et nom voie du siège social en cas de changement au cours de l'année déclarée
      ZF_3042_2?: string; // Complément de distribution du siège social en cas de changement au cours de l'année déclarée
      ZF_3042_3?: string; // Lieu-dit, hameau du siège social en cas de changement au cours de l'année déclarée
      ZF_3164_1?: string; // Ville du siège social en cas de changement au cours de l'année déclarée
      ZF_3207_1?: string; // Pays du siège social en cas de changement au cours de l'année déclarée
      ZF_3251_1?: string; // Code postal du siège social en cas de changement au cours de l'année déclarée
      AQ?: number; // Nombre total de parts dans le société à la string de clôture de l’exercice clos déclaré
      AR?: number; // Montant nominal des parts à la string de clôture de l’exercice clos déclaré
      BJ_3036_1?: string[]; // ! Repetitions, Nom, prénom ou dénomination du tiers non associé bénéficiant gratuitement de la jouissance de tout ou partie d'un immeuble de la société
      BJ_3036_2?: string[]; // ! Repetitions, Qualité ou complément de nom/désignation du tiers non associé bénéficiant gratuitement de la jouissance de tout ou partie d'un immeuble de la société
      BJ_3036_3?: string[]; // ! Repetitions, Forme juridique ou titre du tiers non associé bénéficiant gratuitement de la jouissance de tout ou partie d'un immeuble de la société
      BJ_3039_1?: string[]; // ! Repetitions, SIREN du tiers non associé bénéficiant gratuitement de la jouissance de tout ou partie d'un immeuble de la société
      BK?: string[]; // ! Repetitions, Numéro de l'immeuble attribué en jouissance au tiers non associé bénéficiant gratuitement de la jouissance de tout ou partie d'un immeuble de la société
      AS_3042_1?: string[]; // ! Repetitions, Numéro, type et nom voie si l'adresse est différente de celle de l'immeuble dont il détient gratuitement la jouissance
      AS_3042_2?: string[]; // ! Repetitions, Complément de distribution si l'adresse est différente de celle de l'immeuble dont il détient gratuitement la jouissance
      AS_3042_3?: string[]; // ! Repetitions, Lieu-dit, hameau si l'adresse est différente de celle de l'immeuble dont il détient gratuitement la jouissance
      AS_3164_1?: string[]; // ! Repetitions, Ville si l'adresse est différente de celle de l'immeuble dont il détient gratuitement la jouissance
      AS_3207_1?: string[]; // ! Repetitions, Pays si l'adresse est différente de celle de l'immeuble dont il détient gratuitement la jouissance
      AS_3251_1?: string[]; // ! Repetitions, Code postal si l'adresse est différente de celle de l'immeuble dont il détient gratuitement la jouissance
      DL_3042_1?: string[]; // ! Repetitions, Immeuble nouveau numéro, type et nom voie
      DL_3042_2?: string[]; // ! Repetitions, Immeuble nouveau complément de distribution
      DL_3042_3?: string[]; // ! Repetitions, Immeuble nouveau lieu-dit, hameau
      DL_3164_1?: string[]; // ! Repetitions, Immeuble nouveau, ville
      DL_3207_1?: string[]; // ! Repetitions, Immeuble nouveau pays
      DL_3251_1?: string[]; // ! Repetitions, Immeuble nouveau code postal
      EC?: boolean[]; // ! Repetitions, En cas d'acquisition d'immeubles en cours de l'année déclarée, cocher la case
      MY?: number[]; // ! Repetitions, Nombre de locaux
      AP?: string[]; // ! Repetitions, Déduction au titre de l'amortissement
      AY?: string[]; // ! Repetitions, Déduction spécifique
      PA?: string[]; // ! Repetitions, Nature de l'immeuble - A
      PB?: string[]; // ! Repetitions, Nature de l'immeuble - B
      PC?: string[]; // ! Repetitions, N° d'immeuble
      PE?: string[]; // ! Repetitions, N° associé ou usufruitier cédant
      PF?: boolean[]; // ! Repetitions, Le cédant est une holding
      PG?: string[]; // ! Repetitions, N° associé ou usufruitier cessionnaire
      PH?: boolean[]; // ! Repetitions, Le cessionnaire est une holding
      PJ?: string[]; // ! Repetitions, string des cessions de parts de la société immobilière
      PK?: number[]; // ! Repetitions, Nombre de parts cédées au cours de l'année déclarée
      PL?: boolean[]; // ! Repetitions, Cédant bénéficiant de la jouissance gratuite de tout ou partie d'un immeuble détenu par la société immobilière
      PM?: boolean[]; // ! Repetitions, Cessionnaire bénéficiant de la jouissance gratuite de tout ou partie d'un immeuble détenu par la société immobilière
      ZG_3036_1?: string[]; // ! Repetitions, Dénomination
      ZG_3036_2?: string[]; // ! Repetitions, Complément de dénomination
      ZG_3036_3?: string[]; // ! Repetitions, Complément 2 de dénomination
      ZG_3042_1?: string[]; // ! Repetitions, Numéro, type et nom voie
      ZG_3042_2?: string[]; // ! Repetitions, Complément de distribution
      ZG_3042_3?: string[]; // ! Repetitions, Lieu-dit, hameau
      ZG_3251_1?: string[]; // ! Repetitions, Code postal
      ZG_3164_1?: string[]; // ! Repetitions, Ville
      ZG_3148_1_TE?: string[]; // ! Repetitions, Téléphone Cogérant (selon la table de codes "TE")
      ZG_3207_1?: string[]; // ! Repetitions, Pays
    };

    export type FORM_2072SA1 = {
      AA?: string[]; // ! Repetitions, Numéro d'ordre de l'immeuble
      BB?: number[]; // ! Repetitions, Montant brut des fermages ou des loyers encaissés
      BC?: number[]; // ! Repetitions, Dépenses par nature déductibles incombant normalement à la société immobilière et mises par convention à la charge des locataires
      BD?: number[]; // ! Repetitions, Recettes brutes diverses. Subventions ANAH, indemnités d'assurance
      BE?: number[]; // ! Repetitions, Recettes qu'aurait pu produire la location des propriétés qui ne sont pas affectées à l'habitation dont la société se réserve la jouissance ou qu'elle met gratuitement à la disposition des associés ou des tiers
      BF?: number[]; // ! Repetitions, Total des recettes
      BG?: number[]; // ! Repetitions, Frais d'administration et de gestion
      BH?: number[]; // ! Repetitions, Autres frais de gestion non déductibles pour leur montant réel (montant forfaitaire fixé à 20€ par local)
      BJ?: number[]; // ! Repetitions, Primes d'assurance
      BK?: number[]; // ! Repetitions, Dépenses de réparation, d'entretien et d'amélioration
      DA?: number[]; // ! Repetitions, En cas d'option : dépenses de travaux de rénovation énergétique permettant à un bien de passer d’une classe énergétique E, F ou G à une classe A, B, C ou D
      BL?: number[]; // ! Repetitions, Charges récupérables non récupérées au départ du locataire
      BM?: number[]; // ! Repetitions, Indemnités d'éviction, frais de relogement
      BN?: number[]; // ! Repetitions, Impositions (y compris la CRL payée au titre de l'année considérée)
      BP?: number[]; // ! Repetitions, Provisions pour charges de copropriété payées au titre de l'année considérée
      BQ?: number[]; // ! Repetitions, Régularisation des provisions pour charges de copropriété déduites au titre de l'année antérieure par les copropriétaires bailleurs
      BR?: number[]; // ! Repetitions, Total des déductions, frais et charges
      BS?: number[]; // ! Repetitions, Montant de la déduction spécifique (sauf mobilité professionnelle)
      BT?: number[]; // ! Repetitions, Intérêts des emprunts contractés pour l'acquisition, la reconstruction, l'agrandissement, la réparation, l'amélioration ou la conservation des immeubles
      BU?: number[]; // ! Repetitions, Revenu ou déficit de l'immeuble
      BV?: number[]; // ! Repetitions, Réintégration du supplément de déduction
      BW?: number[]; // ! Repetitions, Rémunérations et avantages en nature attribués aux associés
      CE?: number[]; // ! Repetitions, Revenu net ou déficit de l'immeuble
      CF?: string[]; // ! Repetitions, string de la cession réalisée par la société immobilière
      CG?: number[]; // ! Repetitions, Montant de la cession réalisée
      CH?: string[]; // ! Repetitions, Méthode de calcul des parts cédées par la société immobilière
      CJ?: number; // Revenus ou déficits relatifs aux parts détenues dans d'autres sociétés immobilières non passibles de l'impôt sur les sociétés
      CK?: number; // Revenus ou déficits à répartir entre les associés
    };

    export type FORM_2072SA2 = {
      AA?: string[]; // ! Repetitions, N° associé
      AB_3036_1?: string[]; // ! Repetitions, Associé personne morale, Désignation
      AB_3036_2?: string[]; // ! Repetitions, Associé personne morale, Complément de dénomination
      AB_3036_3?: string[]; // ! Repetitions, Associé personne morale, Complément 2 de dénomination
      AB_3039_1?: string[]; // ! Repetitions, Associé personne morale, N° siren
      AB_3042_1?: string[]; // ! Repetitions, Associé personne morale, Numéro, type et nom voie Adresse à l’ouverture de l’exercice clos déclaré
      AB_3042_2?: string[]; // ! Repetitions, Associé personne morale, Complément de distribution Adresse à l’ouverture de l’exercice clos déclaré
      AB_3042_3?: string[]; // ! Repetitions, Associé personne morale, Lieu-dit, hameau Adresse à l’ouverture de l’exercice clos déclaré
      AB_3164_1?: string[]; // ! Repetitions, Associé personne morale, Ville Adresse à l’ouverture de l’exercice clos déclaré
      AB_3207_1?: string[]; // ! Repetitions, Associé personne morale, Pays Adresse à l’ouverture de l’exercice clos déclaré
      AB_3251_1?: string[]; // ! Repetitions, Associé personne morale, Code postal Adresse à l’ouverture de l’exercice clos déclaré
      AC_3036_1?: string[]; // ! Repetitions, Associé personne physique, Nom
      AC_3036_2?: string[]; // ! Repetitions, Associé personne physique, Nom marital
      AC_3036_3?: string[]; // ! Repetitions, Associé personne physique, Titre
      AC_3036_4?: string[]; // ! Repetitions, Associé personne physique, Prénom
      AC_3039_1?: string[]; // ! Repetitions, Associé personne physique, Numéro fiscal
      AC_3042_1?: string[]; // ! Repetitions, Associé personne physique, Numéro, type et nom voie, Adresse à l’ouverture de l’exercice clos déclaré
      AC_3042_2?: string[]; // ! Repetitions, Associé personne physique, Complément de distribution, Adresse à l’ouverture de l’exercice clos déclaré
      AC_3042_3?: string[]; // ! Repetitions, Associé personne physique, Lieu-dit, hameau, Adresse à l’ouverture de l’exercice clos déclaré
      AC_3164_1?: string[]; // ! Repetitions, Associé personne physique, Ville, Adresse à l’ouverture de l’exercice clos déclaré
      AC_3207_1?: string[]; // ! Repetitions, Associé personne physique, Pays, Adresse à l’ouverture de l’exercice clos déclaré
      AC_3251_1?: string[]; // ! Repetitions, Associé personne physique, Code postal, Adresse à l’ouverture de l’exercice clos déclaré
      AD?: string[]; // ! Repetitions, Associé personne physique, string de naissance
      AE_3164_1?: string[]; // ! Repetitions, Associé personne physique, Ville de naissance
      AE_3251_1?: string[]; // ! Repetitions, Associé personne physique, Numéro de département de naissance
      AE_3207_1?: string[]; // ! Repetitions, Associé personne physique, Pays de naissance
      AF_3042_1?: string[]; // ! Repetitions, Numéro, type et nom voie Adresse à la clôture de l’exercice clos déclaré en cas de changement d'adresse au cours de l’année
      AF_3042_2?: string[]; // ! Repetitions, Complément de distribution Adresse à la clôture de l’exercice clos déclaré en cas de changement d'adresse au cours de l’année
      AF_3042_3?: string[]; // ! Repetitions, Lieu-dit, hameau Adresse à la clôture de l’exercice clos déclaré en cas de changement d'adresse au cours de l’année
      AF_3164_1?: string[]; // ! Repetitions, Ville Adresse à la clôture de l’exercice clos déclaré en cas de changement d'adresse au cours de l’année
      AF_3207_1?: string[]; // ! Repetitions, Pays Adresse à la clôture de l’exercice clos déclaré en cas de changement d'adresse au cours de l’année
      AF_3251_1?: string[]; // ! Repetitions, Code postal Adresse à la clôture de l’exercice clos déclaré en cas de changement d'adresse au cours de l’année
      AJ?: number[]; // ! Repetitions, Nombre de parts détenues dans la société immobilière au titre de l’exercice clos déclaré
      AL?: number[]; // ! Repetitions, Montant nominal des parts au titre de l’exercice clos déclaré
      AM?: boolean[]; // ! Repetitions, Acquisition de parts de la société immobilière au titre de l’exercice clos déclaré
      AN?: boolean[]; // ! Repetitions, Cession de parts de la société immobilière au cours de l’année déclarée
      AQ?: string[]; // ! Repetitions, Immeuble(s) dont l'associé a la jouissance gratuite
      BJ?: number[]; // ! Repetitions, Quote-part des revenus bruts
      BK?: number[]; // ! Repetitions, Quote-part des frais et charges déductibles
      BT?: number[]; // ! Repetitions, Quote-part des dépenses de travaux de rénovation énergétique
      BL?: number[]; // ! Repetitions, Quote-part des intérêts d’emprunt
      BP?: number[]; // ! Repetitions, Quote-part du revenu net ou déficit
      CC?: number[]; // ! Repetitions, Quote-part du montant de la moins-value réalisée par la société immobilière en €
      CD?: number[]; // ! Repetitions, Quote-part du montant de la plus-value de cession réalisée par la société immobilière en €
      CH?: number[]; // ! Repetitions, Montant des rémunérations et avantages en nature attribués
      CN?: number[]; // ! Repetitions, Intérêts des comptes courants d'associés
      EA?: string[]; // ! Repetitions, string d'entrée dans la société immobilière
      EB?: string[]; // ! Repetitions, string de sortie de la société immobilière
      EC?: string[]; // ! Repetitions, Détenteur de l'usufruit
      EE?: number[]; // ! Repetitions, Quote-part d'usufruit
      EF?: number[]; // ! Repetitions, Quote-part de nue-propriété
      EG?: number[]; // ! Repetitions, Nombre de titres détenus en usufruit
    };
  }
}
