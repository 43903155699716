var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"realEstateAssetView"},[(_vm.isLoading)?_c('v-progress-linear',{staticClass:"my-2 ml-md-6",attrs:{"color":"primary","indeterminate":""}}):_vm._e(),_c('div',{staticClass:"realEstateAsset-header"},[_c('router-link',{staticClass:"products-back d-flex align-center",attrs:{"to":_vm.$route.name === _vm.ROUTE_NAMES.Indexation
          ? {
              name: _vm.ROUTE_NAMES.RealEstate,
              query: { tab: _vm.accountingPeriodsStore.isIR ? '1' : '2' },
            }
          : _vm.$route.name === _vm.ROUTE_NAMES.IndexationLMNP
          ? {
              name: _vm.ROUTE_NAMES.RealEstateLMNP,
              query: { tabIndex: '4' },
            }
          : { name: _vm.ROUTE_NAMES.RentalAgreements }}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"#000"}},[_vm._v("mdi-chevron-left")]),_vm._v(" Mes locations ")],1)],1),(!_vm.isLoading)?_c('div',[_c('v-tabs',{staticClass:"mt-2",attrs:{"color":"primary","background-color":"transparent","active-class":"active-tab","grow":"","id":"tab-items"},model:{value:(_vm.tabItemIndex),callback:function ($$v) {_vm.tabItemIndex=$$v},expression:"tabItemIndex"}},[_c('v-tab',{attrs:{"id":"tab-item-informations"}},[_vm._v("Résultat de la révision")]),_c('v-tab',{attrs:{"id":"tab-item-historique"}},[_vm._v("Historique des révisions")])],1),_c('div',{staticClass:"mt-4"},[_c('v-tabs-items',{model:{value:(_vm.tabItemIndex),callback:function ($$v) {_vm.tabItemIndex=$$v},expression:"tabItemIndex"}},[_c('v-tab-item',[_c('IndexationResult',{attrs:{"rentalAgreementId":_vm.rentalAgreementId,"productId":_vm.productId},on:{"goHistory":_vm.goHistory}})],1),_c('v-tab-item',[_c('IndexationHistory',{attrs:{"rentalAgreementId":_vm.rentalAgreementId}})],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }