


























































































































































import {
  computed,
  defineComponent,
  onBeforeMount,
  Ref,
  ref,
} from "@vue/composition-api";
import {
  TaxDeclaration2065,
  TaxDeclaration2065LineNumber,
  taxDeclaration2065LinesMetadata,
  Direction,
  TaxDeclaration2065LineNumberSummaryOfTaxElements,
  isTaxDeclaration2065LineNumberSummaryOfTaxElements,
  TaxDeclaration2065LineNumberAnnualContributionOnRentalIncome,
  isTaxDeclaration2065LineNumberAnnualContributionOnRentalIncomeElements,
  SubscriptionsModel,
} from "@edmp/api";
import { isArray } from "lodash";

import { accountingPeriodsStore, coreStore, subscriptionsStore } from "@/store";
import { ArticleEnum } from "@/composables/crisp.usable";
import {
  TaxDeclaration2065Compute,
  TaxDeclaration2065LineCompute,
} from "@/models";
import { taxDeclarationsService } from "@/services/TaxDeclarations.service";

import PageHeader from "@/components/atom/PageHeader.vue";
import { DevButton } from "@/components/atom/button";
import { HelpingMessage } from "@/components/atom/helping";
import TaxDeclaration2065Lines from "./TaxDeclaration2065Lines.vue";
import TaxDeclarationValidate from "../../events/yearEnd/TaxDeclarationValidate.vue";
import TeletransmitFollowUp from "../TeletransmitFollowUp.vue";
import DialogSubscribe from "../DialogSubscribe.vue";
import Decimal from "decimal.js-light";
import moment from "moment";
import confetti from "canvas-confetti";

export default defineComponent({
  name: "TaxDeclaration2065",
  components: {
    PageHeader,
    DevButton,
    HelpingMessage,
    TaxDeclaration2065Lines,
    TeletransmitFollowUp,
    TaxDeclarationValidate,
    DialogSubscribe,
  },
  setup() {
    const taxDeclaration2065: Ref<TaxDeclaration2065 | undefined> = ref();
    const subscription: Ref<SubscriptionsModel.Subscription | undefined> =
      ref();

    const isOpenDialogSubscribe = ref(false);
    const totalMost3000 = ref(false);

    const taxToPay = ref(false);
    const isOpenCongratsModal = ref(false);

    const exerciseYear = computed(() =>
      accountingPeriodsStore.currentAccountingPeriod
        ? new Date(
            accountingPeriodsStore.currentAccountingPeriod.endAt
          ).getFullYear()
        : ""
    );
    const taxDeclaration2065Compute = computed(() => {
      const mainSectionTitle = "Récapitulation des éléments d'imposition";
      const sectionTitles = {
        [mainSectionTitle]: {
          "Résultat fiscal": [
            TaxDeclaration2065LineNumberSummaryOfTaxElements.C_1_BENEFIT_NORMAL_RATE,
            TaxDeclaration2065LineNumberSummaryOfTaxElements.C_1_BENEFIT_15_RATE,
            TaxDeclaration2065LineNumberSummaryOfTaxElements.C_1_DEFICIT,
          ],
        },
        "Contribution annuelle sur les revenus locatifs": [
          TaxDeclaration2065LineNumberAnnualContributionOnRentalIncome.E_NET_REVENUE_SUBJECT_TO_CONTRIBUTION,
        ],
      };
      const formatLines = (linesNumber: TaxDeclaration2065LineNumber[]) =>
        linesNumber
          .map((lineNumber) => {
            if (
              isTaxDeclaration2065LineNumberSummaryOfTaxElements(lineNumber)
            ) {
              return {
                lineNumber,
                title: `Ligne ${lineNumber}`,
                ...taxDeclaration2065LinesMetadata[lineNumber],
                ...taxDeclaration2065.value?.lines[lineNumber],
                ...(subscription.value
                  ? { subscriptionPlanType: subscription.value.plan.type }
                  : {}),
                value: taxDeclaration2065.value?.lines[lineNumber]
                  ? subscription.value
                    ? subscription.value.plan.type ===
                      SubscriptionsModel.PlanType.Free
                      ? "€"
                      : `${
                          taxDeclaration2065.value?.lines[
                            lineNumber as TaxDeclaration2065LineNumberSummaryOfTaxElements
                          ].amountDirection === Direction.debit
                            ? "-"
                            : ""
                        }${taxDeclaration2065.value?.lines[
                          lineNumber as TaxDeclaration2065LineNumberSummaryOfTaxElements
                        ].amount.toLocaleString()}€`
                    : "0 €"
                  : "0 €",
              };
            }
            if (
              isTaxDeclaration2065LineNumberAnnualContributionOnRentalIncomeElements(
                lineNumber
              )
            ) {
              return {
                lineNumber,
                title: `Ligne ${lineNumber}`,
                ...taxDeclaration2065LinesMetadata[lineNumber],
                ...taxDeclaration2065.value?.lines[lineNumber],
                ...(subscription.value
                  ? { subscriptionPlanType: subscription.value.plan.type }
                  : {}),
                value: taxDeclaration2065.value?.lines[lineNumber]
                  ? subscription.value
                    ? subscription.value.plan.type ===
                      SubscriptionsModel.PlanType.Free
                      ? "€"
                      : `${
                          taxDeclaration2065.value?.lines[
                            lineNumber as TaxDeclaration2065LineNumberAnnualContributionOnRentalIncome
                          ].amountDirection === Direction.debit
                            ? "-"
                            : ""
                        }${taxDeclaration2065.value?.lines[
                          lineNumber as TaxDeclaration2065LineNumberAnnualContributionOnRentalIncome
                        ].amount.toLocaleString()}€`
                    : "0 €"
                  : "0 €",
              };
            }
          })
          .filter(
            (taxDeclaration2065Compute) =>
              taxDeclaration2065Compute !== undefined
          ) as TaxDeclaration2065LineCompute;

      const taxDeclaration2065Compute = Object.entries(sectionTitles).reduce(
        (taxDeclaration2065Compute, [title, subTitleOrLinesNumber]) => {
          if (isArray(subTitleOrLinesNumber)) {
            const linesNumber: TaxDeclaration2065LineNumber[] =
              subTitleOrLinesNumber;
            taxDeclaration2065Compute[title] = formatLines(linesNumber);
          } else {
            const taxDeclaration2065SubCompute = Object.entries(
              subTitleOrLinesNumber
            ).reduce(
              (taxDeclaration2065SubCompute, [subTitle, linesNumber]) => {
                taxDeclaration2065SubCompute[subTitle] =
                  formatLines(linesNumber);
                return taxDeclaration2065SubCompute;
              },
              {} as { [sectionSubTitle: string]: TaxDeclaration2065LineCompute }
            );

            taxDeclaration2065Compute[title] = taxDeclaration2065SubCompute;
          }
          return taxDeclaration2065Compute;
        },
        {} as TaxDeclaration2065Compute
      );

      const calculate = (amount: number, rate: number): Decimal =>
        new Decimal(amount).mul(new Decimal(rate));

      const benefit15RateAmount =
        taxDeclaration2065.value?.lines[
          TaxDeclaration2065LineNumberSummaryOfTaxElements.C_1_BENEFIT_15_RATE
        ]?.amount ?? 0;
      const benefitNormalRateAmount =
        taxDeclaration2065.value?.lines[
          TaxDeclaration2065LineNumberSummaryOfTaxElements
            .C_1_BENEFIT_NORMAL_RATE
        ]?.amount ?? 0;

      const value15 = calculate(benefit15RateAmount, 0.15);
      const valueNormal = calculate(benefitNormalRateAmount, 0.25);

      const subTitle = `Calcul du montant d'IS sur les bénéfices de la SCI pour l'exercice ${exerciseYear.value}`;

      taxDeclaration2065Compute[mainSectionTitle][subTitle] = [
        {
          lineNumber: "TAX_NORMAL_AMOUNT",
          title: "Ligne TAUX NORMAL",
          name: "Montant du bénéfice supérieur à 42 500€ * 25%",
          accounts: [],
          ...(subscription.value
            ? { subscriptionPlanType: subscription.value.plan.type }
            : {}),
          value: subscription.value
            ? subscription.value.plan.type === SubscriptionsModel.PlanType.Free
              ? "€"
              : `${Number(valueNormal.toFixed(0)).toLocaleString()}€`
            : "0 €",
        },
        {
          lineNumber: "TAX_15_AMOUNT",
          title: "Ligne TAUX 15%",
          name: "Montant du bénéfice jusqu'à 42 500€ * 15%",
          accounts: [],
          ...(subscription.value
            ? { subscriptionPlanType: subscription.value.plan.type }
            : {}),
          value: subscription.value
            ? subscription.value.plan.type === SubscriptionsModel.PlanType.Free
              ? "€"
              : `${Number(value15.toFixed(0)).toLocaleString()}€`
            : "0 €",
        },
        {
          lineNumber: "TAX_TOTAL",
          title: "Ligne TOTAL",
          name: "Total IS à payer à l'Etat",
          accounts: [],
          ...(subscription.value
            ? { subscriptionPlanType: subscription.value.plan.type }
            : {}),
          value: subscription.value
            ? subscription.value.plan.type === SubscriptionsModel.PlanType.Free
              ? "€"
              : `${Number(
                  value15.plus(valueNormal).toFixed(0)
                ).toLocaleString()}€`
            : "0 €",
        },
      ];

      return taxDeclaration2065Compute;
    });

    // Methods
    const showDev = computed(() => coreStore.isNotProduction);
    const isOpnTaxDeclarationValidate = ref(false);
    const openTaxDeclarationValidate = async () => {
      isOpnTaxDeclarationValidate.value = true;
    };

    // Init
    const init = async () => {
      taxDeclaration2065.value = await taxDeclarationsService.get2065({
        accountingPeriodId: accountingPeriodsStore.currentId,
      });
      subscription.value = subscriptionsStore.getSubscriptionByProduct(
        taxDeclaration2065.value.productId
      );
      if (subscription.value?.plan.type === SubscriptionsModel.PlanType.Free) {
        isOpenDialogSubscribe.value = true;
      }
      const benefit15RateAmount =
        taxDeclaration2065.value?.lines[
          TaxDeclaration2065LineNumberSummaryOfTaxElements.C_1_BENEFIT_15_RATE
        ]?.amount ?? 0;
      const benefitNormalRateAmount =
        taxDeclaration2065.value?.lines[
          TaxDeclaration2065LineNumberSummaryOfTaxElements
            .C_1_BENEFIT_NORMAL_RATE
        ]?.amount ?? 0;
      const calculate = (amount: number, rate: number): Decimal =>
        new Decimal(amount).mul(new Decimal(rate));

      const value15 = calculate(benefit15RateAmount, 0.15);
      const valueNormal = calculate(benefitNormalRateAmount, 0.25);

      const defaults = {
        origin: { y: 0.7 },
      };
      function fire(particleRatio, opts) {
        confetti({
          ...defaults,
          ...opts,
          particleCount: Math.floor(200 * particleRatio),
        });
      }
      if (Number(value15.plus(valueNormal)) <= 0) {
        isOpenCongratsModal.value = true;

        confetti({ zIndex: 1000 });

        fire(0.25, {
          spread: 26,
          startVelocity: 55,
        });
        fire(0.2, {
          spread: 60,
        });
        fire(0.35, {
          spread: 100,
          decay: 0.91,
          scalar: 0.8,
        });
        fire(0.1, {
          spread: 120,
          startVelocity: 25,
          decay: 0.92,
          scalar: 1.2,
        });
        fire(0.1, {
          spread: 120,
          startVelocity: 45,
        });
      } else if (Number(value15.plus(valueNormal)) < 3000) {
        taxToPay.value = true;
        totalMost3000.value = true;
      } else {
        taxToPay.value = true;
      }
    };

    onBeforeMount(async () => {
      await init();
    });

    return {
      init,
      showDev,
      openTaxDeclarationValidate,
      isOpnTaxDeclarationValidate,
      taxDeclaration2065Compute,
      isArray,
      ArticleEnum,
      isOpenDialogSubscribe,
      taxToPay,
      isOpenCongratsModal,
      moment,
      totalMost3000,
    };
  },
});
