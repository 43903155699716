var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"elevation-0"},[_c('v-card-title',{staticStyle:{"border-bottom":"1px #eeeeee solid"}},[_c('h4',[_vm._v("Editer le Procès Verbal de l'Assemblée Générale")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"#F4655F","depressed":"","fab":"","height":"30","width":"30"},nativeOn:{"click":function($event){return _vm.closeDialog($event)}}},[_c('v-icon',{attrs:{"color":"#fff","size":"20"}},[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-form',{ref:"form",staticClass:"agForm",on:{"submit":function($event){$event.preventDefault();return _vm.validate($event)}}},[_c('v-select',{staticClass:"mt-3",attrs:{"items":_vm.presentPartners,"deletable-chips":"","label":"Liste des associés présents","multiple":"","outlined":"","small-chips":""},model:{value:(_vm.partnersListPresent),callback:function ($$v) {_vm.partnersListPresent=$$v},expression:"partnersListPresent"}}),_c('v-select',{attrs:{"items":_vm.representPartners,"deletable-chips":"","label":"Liste des associés représentés","multiple":"","outlined":"","small-chips":""},model:{value:(_vm.partnersListRepresent),callback:function ($$v) {_vm.partnersListRepresent=$$v},expression:"partnersListRepresent"}}),(_vm.isLoadingAccountingResult)?_c('v-progress-linear',{attrs:{"color":"primary","indeterminate":"","height":"2"}}):_vm._e(),_c('v-text-field',{attrs:{"append-icon":"mdi-currency-eur","label":_vm.accountingPeriodsStore.isIS
            ? 'Résultat comptable'
            : 'Bénéfice comptable',"outlined":"","type":"number"},model:{value:(_vm.accountingResult),callback:function ($$v) {_vm.accountingResult=$$v},expression:"accountingResult"}}),(_vm.isLoadingTaxResult)?_c('v-progress-linear',{attrs:{"color":"primary","indeterminate":"","height":"2"}}):_vm._e(),_c('v-text-field',{attrs:{"append-icon":"mdi-currency-eur","label":_vm.accountingPeriodsStore.isIS
            ? 'Résultat fiscal à déclarer'
            : 'Bénéfice fiscal',"outlined":"","type":"number"},model:{value:(_vm.taxResult),callback:function ($$v) {_vm.taxResult=$$v},expression:"taxResult"}}),_c('v-text-field',{attrs:{"rules":[
          function () { return !!_vm.agHourEnd || 'Le champ ne peut pas être vide'; },
          function () { return _vm.validateFormat(_vm.agHourEnd) ||
            'Le champ n\'est pas au bon format: HH:MM'; } ],"append-icon":"mdi-clock","hint":"Format HH:MM","label":"Heure de fin","outlined":"","required":"","type":"text"},model:{value:(_vm.agHourEnd),callback:function ($$v) {_vm.agHourEnd=$$v},expression:"agHourEnd"}}),_c('v-expansion-panels',[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v("Ordres du jour")]),_c('v-expansion-panel-content',_vm._l((_vm.agendas),function(agenda,index){return _c('v-textarea',{key:'agenda-' + index,attrs:{"rules":[
                function () { return !!_vm.agendas[index] || 'Le champ ne peut pas être vide'; } ],"required":"","label":'Ordre ' + (index + 1),"outlined":"","auto-grow":"","rows":"2"},model:{value:(_vm.agendas[index]),callback:function ($$v) {_vm.$set(_vm.agendas, index, $$v)},expression:"agendas[index]"}})}),1)],1)],1),_c('v-spacer'),_c('v-expansion-panels',[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v("Résolutions")]),_c('v-expansion-panel-content',_vm._l((_vm.resolutions),function(resolution,index){return _c('v-textarea',{key:'resolution-' + index,attrs:{"rules":[
                function () { return !!_vm.resolutions[index] || 'Le champ ne peut pas être vide'; } ],"required":"","label":'Résolution ' + (index + 1),"outlined":"","auto-grow":"","rows":"2"},model:{value:(_vm.resolutions[index]),callback:function ($$v) {_vm.$set(_vm.resolutions, index, $$v)},expression:"resolutions[index]"}})}),1)],1)],1),_c('v-spacer'),_c('div',{staticClass:"fill-width text-center"},[_c('v-btn',{staticClass:"mt-1",attrs:{"color":"primary","depressed":"","type":"submit"}},[_c('v-icon',{staticClass:"mr-2 mt-1",attrs:{"small":""}},[_vm._v(" mdi-file-document-edit-outline ")]),_vm._v(" Editer le PV ")],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }