import { Task, TaskGroup, TasksService } from "@edmp/api";
import axios from "axios";

const api = "/api/v1/tasks";

class TasksService {
  async list(params: TasksService.ListIn) {
    return axios.get<TasksService.ListOut>(api, { params }).then((res) => {
      return res.data;
    });
  }

  async getGroup(params: TasksService.GetIn): Promise<TaskGroup> {
    return axios
      .get<TasksService.GetOut>(`${api}/${params.groupCode}`, { params })
      .then((res) => {
        return res.data;
      });
  }

  async validate(validate: TasksService.ValidateIn): Promise<Task> {
    return axios
      .post<TasksService.ValidateOut>(`${api}/${validate.id}`, validate)
      .then((res) => {
        return res.data;
      });
  }

  async validateGroup(
    params: TasksService.ValidateGroupIn
  ): Promise<TasksService.ValidateGroupOut> {
    return axios.post(`${api}/validateTaskGroup`, params);
  }

  async activate(activate: TasksService.ActivateIn): Promise<Task> {
    return axios
      .post<TasksService.ActivateOut>(`${api}/${activate.id}/activate`)
      .then((res) => {
        return res.data;
      });
  }
}

// Export a singleton instance in the global namespace
export const tasksService = new TasksService();
