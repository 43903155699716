

























































































































































































































































































































































































import { defineComponent, ref, PropType } from "@vue/composition-api";
import SectionTitle from "@/components/atom/SectionTitle.vue";
import { YesNoSwitch } from "@/components/atom/switch";
import DatePicker from "@/components/atom/DatePicker.vue";
import { format } from "date-fns";
import { RentalAgreement, Tenant, TenantTypeEnum } from "@edmp/api";
import { useRents } from "./rents.usable";
import { VForm } from "@/models";
import { accountingPeriodsStore, tenantsStore } from "@/store";
import { debounce } from "@/utils";

export default defineComponent({
  name: "RentalAgreementTenants",
  components: { DatePicker, YesNoSwitch, SectionTitle },
  props: {
    rentalAgreement: {
      type: Object as PropType<RentalAgreement>,
      required: true,
    },
    tenants: {
      type: Array as PropType<Tenant[]>,
      required: true,
    },
  },
  setup(props, context) {
    const {
      addTenant,
      tenantType,
      typeRentalAgreementItems,
      rentalAgreementRules,
    } = useRents();

    const goBack = () => {
      context.emit("goBack");
    };

    const goNext = () => {
      if ((context.refs.form as VForm).validate()) {
        context.emit("goNext");
      }
    };

    const today = ref(format(new Date(), "yyyy-MM-dd"));

    const updateRepresentativeAddress = (event, field, index) => {
      if (event.type === "keyup") {
        context.emit("updateTenant", {
          value: event.target.value,
          path: `representative.address[${field}]`,
          index,
        });
      } else {
        if (field === "street") {
          context.emit("updateTenant", {
            value: event.value.name,
            path: "representative.address.street",
            index,
          });
          context.emit("updateTenant", {
            value: event.value.city,
            path: "representative.address.city",
            index,
          });
          context.emit("updateTenant", {
            value: event.value.postcode,
            path: "representative.address.zip",
            index,
          });
        } else if (field === "city" || field === "zip") {
          context.emit("updateTenant", {
            value: event.value.city,
            path: "representative.address.city",
            index,
          });
          context.emit("updateTenant", {
            value: event.value.postcode,
            path: "representative.address.zip",
            index,
          });
        }
      }
    };

    const searchItemsStreetList = ref([]);
    const searchItemsCityList = ref([]);
    const searchItemsZipList = ref([]);

    const searchAddress = debounce((value: string, type: string) => {
      if (value.length > 2) {
        if (type === "street") {
          fetch(
            `https://api-adresse.data.gouv.fr/search/?q=${value}&limit=15&type=housenumber`
          )
            .then((response) => response.json())
            .then((data) => {
              const mappedData = data.features.map((feature) => {
                if (
                  feature.properties &&
                  feature.properties.name &&
                  feature.properties.postcode &&
                  feature.properties.city
                )
                  return {
                    text: `${feature.properties.name} ${feature.properties.postcode} - ${feature.properties.city}`,
                    value: feature.properties,
                  };
              });
              searchItemsStreetList.value = mappedData.filter(
                (mappedData) => !!mappedData
              );
            });
        } else if (type === "city") {
          fetch(
            `https://api-adresse.data.gouv.fr/search/?q=${value}&limit=15&type=municipality`
          )
            .then((response) => response.json())
            .then((data) => {
              const mappedData = data.features.map((feature) => {
                return {
                  text: `${feature.properties.city} - ${feature.properties.postcode}`,
                  value: feature.properties,
                };
              });
              searchItemsCityList.value = mappedData.filter(
                (mappedData) => !!mappedData
              );
            });
        } else if (type === "zip") {
          fetch(
            `https://api-adresse.data.gouv.fr/search/?q=${value}&limit=15&type=municipality`
          )
            .then((response) => response.json())
            .then((data) => {
              const mappedData = data.features.map((feature) => {
                return {
                  text: `${feature.properties.postcode} - ${feature.properties.city}`,
                  value: feature.properties,
                };
              });
              searchItemsZipList.value = mappedData.filter(
                (mappedData) => !!mappedData
              );
            });
        }
      }
    }, 500);

    const removeTenant = (tenant, index) => {
      if (tenant.id) {
        tenantsStore.updateTenant({ ...tenant, rentalAgreementId: "" });
      }
      context.emit("deleteTenant", index);
    };

    // const allTenants = computed(() => {
    //   const filteredTenants = tenantsStore.tenants.filter(
    //     (tenant) =>
    //       !tenant.rentalAgreementId &&
    //       !props.tenants.some((t) => t.id === tenant.id)
    //   );
    //   return filteredTenants.map((tenant) => {
    //     if (tenant.type === TenantTypeEnum.NATURAL_PERSON) {
    //       return {
    //         text: `${tenant.firstName} ${tenant.lastName}`,
    //         value: tenant.id,
    //       };
    //     } else {
    //       return { text: tenant.denomination, value: tenant.id };
    //     }
    //   });
    // });

    return {
      accountingPeriodsStore,
      today,
      goBack,
      goNext,
      typeRentalAgreementItems,
      tenantType,
      addTenant,
      TenantTypeEnum,
      updateRepresentativeAddress,
      searchItemsStreetList,
      searchItemsCityList,
      searchItemsZipList,
      searchAddress,
      removeTenant,
      rentalAgreementRules,
      // allTenants,
    };
  },
});
