







































































































































































































































































































































import DialogRight from "@/components/core/DialogRight.vue";
import ActivityUpdate from "@/components/core/rent/ActivityUpdate.vue";
import { dispatchRegularizationEvent, RegularizationEventCode } from "@/events";
import DatePicker from "@/components/atom/DatePicker.vue";
import {
  productsStore,
  documentsStore,
  realEstateAssetsStore,
  rentalAgreementsStore,
  tenantsStore,
  activitiesStore,
  regularizationsStore,
  usersStore,
  coreStore,
} from "@/store";
import {
  Address,
  ChargesTypeEnum,
  getMoment,
  NewRegularization,
  TenantTypeEnum,
} from "@edmp/api";
import { computed, defineComponent, PropType, ref } from "@vue/composition-api";
import TitleNew from "@/components/core/title/TitleNew.vue";
import { VForm } from "@/models";

export default defineComponent({
  name: "RegularizationMail",
  components: {
    DialogRight,
    ActivityUpdate,
    DatePicker,
    TitleNew,
  },
  props: {
    regularization: {
      type: Object as PropType<NewRegularization>,
      required: true,
    },
    provisions: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const activity = computed(() => {
      return activitiesStore.currentActivity;
    });
    const imageSignature = computed(() => {
      if (activity.value?.signature?.typeSignature === "base64") {
        return activity.value?.signature?.data;
      } else if (
        activity.value?.signature?.typeSignature === "image" &&
        activity.value.signature.signatureId
      ) {
        return documentsStore.getDocument(activity.value.signature.signatureId)
          ?.href;
      }
    });
    const imageLogo = computed(() => {
      if (activity.value?.logoId) {
        return documentsStore.getDocument(activity.value.logoId)?.href;
      }
    });
    const rentalAgreement = computed(() => {
      return rentalAgreementsStore.rentalAgreements.find(
        (rentalAgreement) =>
          rentalAgreement.id === props.regularization.rentalAgreementId
      );
    });
    const realEstateAsset = computed(() => {
      if (
        rentalAgreement.value &&
        rentalAgreement.value.product.realEstateAsset
      ) {
        return realEstateAssetsStore.realEstateAssets.find(
          (realEstateAsset) =>
            realEstateAsset.id ===
            rentalAgreement.value?.product.realEstateAsset?.id
        );
      }
    });
    const product = computed(() => {
      return productsStore.getProduct({
        id: productsStore.currentId,
      });
    });
    const tenants = computed(() => {
      if (props.regularization.rentalAgreementId)
        return tenantsStore.getTenantsByRentalAgreementId(
          props.regularization.rentalAgreementId
        );
      else return [];
    });

    const isFullYear = computed(() => {
      const periodStartYearMonthDay =
        props.regularization.periodStart.split("-");
      const periodEndYearMonthDay = props.regularization.periodEnd.split("-");
      return (
        // Month
        periodStartYearMonthDay["1"] === "01" &&
        // Day
        periodStartYearMonthDay["2"] === "01" &&
        // Month
        periodEndYearMonthDay["1"] === "12" &&
        // Day
        periodEndYearMonthDay["2"] === "31"
      );
    });

    const paymentDate = ref();
    const today = getMoment().format("YYYY-MM-DD");
    const maxDate = getMoment().add(3, "year").format("YYYY-MM-DD");

    const displayCommonCharges = ref(false);
    const displayIndividualCharges = ref(false);

    const totalCharges = computed(() => {
      let commonCharge;
      let individualCharge;
      const initialValue = 0;

      if (props.regularization.commonCharges) {
        commonCharge = props.regularization.commonCharges
          .reduce(
            (accumulator, charge) => accumulator + Number(charge.totalAmount),
            initialValue
          )
          .toFixed(2);
      }
      if (props.regularization.individualCharges) {
        individualCharge = props.regularization.individualCharges
          .reduce(
            (accumulator, charge) => accumulator + Number(charge.totalAmount),
            initialValue
          )
          .toFixed(2);
      }
      return { commonCharge, individualCharge };
    });

    const rentPlusChargesAndRegularization = computed(() => {
      if (rentalAgreement.value) {
        if (
          Number(rentalAgreement.value.financialConditions.amountOfCharges) *
            12 >
          Number(totalCharges.value.commonCharge) +
            Number(totalCharges.value.individualCharge)
        ) {
          if (
            rentalAgreement.value.financialConditions.amountOfCharges -
              Number(props.regularization.result) >
            0
          ) {
            return (
              rentalAgreement.value.financialConditions.amountOfCharges +
              rentalAgreement.value.financialConditions.rentCharge -
              Math.abs(Number(props.regularization.result))
            );
          } else if (
            rentalAgreement.value.financialConditions.amountOfCharges -
              Number(props.regularization.result) ===
            0
          ) {
            return rentalAgreement.value.financialConditions.rentCharge;
          } else {
            return (
              rentalAgreement.value.financialConditions.amountOfCharges +
              rentalAgreement.value.financialConditions.rentCharge -
              Math.abs(Number(props.regularization.result))
            );
          }
        } else {
          return (
            rentalAgreement.value.financialConditions.amountOfCharges +
            rentalAgreement.value.financialConditions.rentCharge +
            Math.abs(Number(props.regularization.result))
          );
        }
      }
    });

    let commonChargesValue;
    let individualChargesValue;
    if (props.regularization.commonCharges) {
      commonChargesValue = props.regularization.commonCharges.map((elem) => ({
        type:
          elem.type === ChargesTypeEnum.CO_PROPERTY_CHARGES
            ? "Charges de copropriété"
            : elem.type === ChargesTypeEnum.TCHW
            ? "TEOM"
            : elem.type === ChargesTypeEnum.SANITATION_CHARGES
            ? "Redevance assainissement"
            : "Entretien chaudière",
        totalAmount: elem.totalAmount,
      }));
    }
    if (props.regularization.individualCharges) {
      individualChargesValue = props.regularization.individualCharges.map(
        (elem) => ({
          type:
            elem.type === ChargesTypeEnum.COLD_WATER
              ? "Eau froide"
              : elem.type === ChargesTypeEnum.HOT_WATER
              ? "Eau chaude"
              : elem.type === ChargesTypeEnum.ELECTRICITY
              ? "Electricité"
              : "Gaz",
          totalAmount: elem.totalAmount,
        })
      );
    }
    const formatDate = (date) => {
      if (date === "") {
        return "";
      } else {
        const [year, month, day] = date.split("-");
        return `${day}/${month}/${year}`;
      }
    };

    const formatAddress = (
      address: Address = { street: "", city: "", zip: "" }
    ) => {
      if (address.street) {
        return address.street + ", " + address.zip + " " + address.city;
      } else {
        return null;
      }
    };

    async function sendRegularization() {
      if ((context.refs.form as VForm).validate()) {
        if (rentalAgreement.value?.id && paymentDate.value) {
          const isSend = await regularizationsStore.sendRegularizationMail({
            id: rentalAgreement.value?.id,
            paymentDate: paymentDate.value,
          });
          if (isSend) {
            dispatchRegularizationEvent({
              userId: usersStore.loggedInUser.id,
              productId: productsStore.currentId,
              date: getMoment().toISOString(),
              code: RegularizationEventCode.CLICK_REGULARIZATION_SEND_MAIL,
            });
            coreStore.displayFeedback({
              message: "Avis de régularisation des charges envoyé",
            });
          } else {
            coreStore.displayFeedback({
              message:
                "Un problème technique empêche l'envoi du courrier. Veuillez réessayer plus tard.",
            });
          }

          context.root.$router.push({ name: "RegularizationHistory" });
        }
      }
    }

    return {
      product,
      sendRegularization,
      rentalAgreement,
      realEstateAsset,
      imageSignature,
      imageLogo,
      tenants,
      formatAddress,
      TenantTypeEnum,
      formatDate,
      today,
      maxDate,
      rentPlusChargesAndRegularization,
      commonChargesValue,
      individualChargesValue,
      paymentDate,
      displayCommonCharges,
      displayIndividualCharges,
      totalCharges,
      isFullYear,
      // rules,
    };
  },
});
