import { ulid } from "ulid";
import { model, Schema, Document } from "mongoose";
import { getMoment, OneOf, TaxRateTva } from "..";
import Decimal from "decimal.js-light";

export enum RentalState {
  NOT_RENTAL = "not_rental",
  RENTAL = "rental",
  FURNISHED_RENTAL = "furnished_rental",
}

export enum RentalUsage {
  RESIDENTIAL = "residential",
  PROFESSIONAL = "professional",
  AGRICULTURAL = "agricultural",
  PARKING = "parking",
  GUEST_HOUSE = "guest_house",
  LONG_TERM_RENTAL = "long_term_rental",
  CLASSIFIED_HOLIDAY_FURNISHED_RENTAL = "classified_holiday_furnished_rental",
  UNCLASSIFIED_HOLIDAY_FURNISHED_RENTAL = "unclassified_holiday_furnished_rental",
  SELF_CATERING_ACCOMODATION = "self_catering_accomodation",
  MANAGED_RESIDENCE_ASSISTED_ELDERLY_HOME = "managed_residence_assisted_elderly_home",
  MANAGED_RESIDENCE_ELDERLY_RESIDENCE = "managed_residence_elderly_residence",
  MANAGED_RESIDENCE_STUDENT_RESIDENCE = "managed_residence_student_residence",
  MANAGED_RESIDENCE_BUSINESS_RESIDENCE = "managed_residence_business_residence",
  MANAGED_RESIDENCE_TOURISTIC_RESIDENCE = "managed_residence_touristic_residence",
}

/**
 * `NewRentalUnit` — Data to create a rental unit
 */
export type NewRentalUnit = Omit<RentalUnit, "id" | "deleted" | "createdAt" | "updatedAt">;

export interface TaxHistory {
  rate: number;
  dateChanged: string;
}

/**
 * `RentalUnit` — A rental unit inside a real estate asset
 */
export interface RentalUnit {
  id: string;
  productId: string;
  realEstateAssetId: string;
  name: string; // Name of the rental unit
  rentalState?: RentalState; // State  of the rental unit
  rentalUsage?: RentalUsage; // Usage  of the rental unit, use for determinate activate TVA
  taxTvaEnable?: boolean;
  taxRateTVA?: TaxRateTva;
  crlEnable?: boolean;
  deleted?: boolean;
  history?: TaxHistory[];
  createdAt: string;
  updatedAt: string;
}

export const TaxHistorySchema = new Schema({
  rate: Number,
  dateChanged: Date,
});

export const validateDateApplicationTva = (history: TaxHistory[] | undefined, dateApplication: string): boolean => {
  if (!history || history.length === 0) {
    return true;
  }
  const sortedHistory = history.sort((a, b) => getMoment(b.dateChanged).diff(getMoment(a.dateChanged)));
  const lastDate = getMoment(sortedHistory[0].dateChanged);

  return getMoment(dateApplication).isAfter(lastDate);
};

export const calculateTaxAmounts = (taxRateTVA: number, transactionAmountTTC: Decimal) => {
  const ht = transactionAmountTTC.div(1 + taxRateTVA / 100).toDecimalPlaces(2);
  const tva = transactionAmountTTC.minus(ht).toDecimalPlaces(2);

  return {
    TTC: transactionAmountTTC.toNumber(),
    TVA: tva.toNumber(),
    HT: ht.toNumber(),
  };
}

export type RentalUnitUpdate = Omit<RentalUnit, "productId" | "createdAt" | "updatedAt"> & {
  modificationTva?: { date: string; transactionIds: string[] };
};
export type RentalUnitUpdateInternal = Omit<RentalUnit, "createdAt" | "updatedAt"> & {
  modificationTva?: { date: string; transactionIds: string[] };
};

const rentalUnitSchema = new Schema<RentalUnitDocument>(
  {
    _id: { type: String, default: () => ulid() },
    name: { type: String, required: true, maxlength: 200 },
    productId: { type: String, index: true, required: true },
    realEstateAssetId: { type: String, index: true, required: true },
    rentalState: { type: RentalState },
    rentalUsage: { type: RentalUsage },
    taxTvaEnable: { type: Boolean },
    taxRateTVA: { type: TaxRateTva },
    crlEnable: { type: Boolean },
    history: { type: [{ type: TaxHistorySchema }] },
    deleted: { type: Boolean, default: false },
  },
  {
    timestamps: true,
    toJSON: {
      versionKey: false,
      virtuals: true,
      transform(doc, ret: RentalUnitDocument) {
        ret.id = ret._id;
        delete ret._id;
        return ret;
      },
    },
  }
);

export type RentalUnitDocument = RentalUnit & Document<string>;
// Name of the collection in third argument
export const RentalUnitModel = model<RentalUnitDocument>("RentalUnit", rentalUnitSchema, "RentalUnits");

// API
export namespace RentalUnitsService {
  export type CreateIn = NewRentalUnit;
  export type CreateOut = RentalUnit;

  export type ListIn = Partial<Pick<RentalUnit, "productId" | "realEstateAssetId">> & { withDeleted?: boolean };
  export type ListOut = RentalUnit[];

  export type GetIn = Pick<RentalUnit, "id">;
  export type GetOut = RentalUnit;

  export type UpdateIn = RentalUnitUpdate;
  export type UpdateOut = RentalUnit;

  export type DeleteIn = OneOf<Pick<RentalUnit, "id">, { realEstateAssetId: string }>;
  export type DeleteOut = boolean;
}
