import { Address, addressSchema, Amount, RequireField } from "./Common.model";
import { model, Schema, Document } from "mongoose";
import { ulid } from "ulid";
import { TaxDeclarationHeaderData } from "./TaxDeclaration.model";

// /!\ This model is use to archive document. It should match SummaryTable and tax-declaration-v2021.csv
export interface TaxDeclaration2072RentalBuilding {
  rentalBuildingId: string;
  name: string;
  address?: Address;
  rentalUnitTotal: string;
  taxDeduction: string;
  line1: string;
  line2: string;
  line3: string;
  line4: string;
  line5: string;
  line6: string;
  line7: string;
  line8: string;
  line9: string;
  line9b: string;
  line10: string;
  line11: string;
  line12: string;
  line13: string;
  line14: string;
  line15: string;
  line16: string;
  line17: string;
  line18: string;
  line19: string;
  line20: string;
}

export type TaxDeclaration2072Data = {
  productId: string;
  accountingPeriodId: string;
  r1: Amount;
  r2: Amount;
  r3: Amount;
  r4: Amount;
  r5: Amount;
  r6: Amount;
  rentalBuildings: TaxDeclaration2072RentalBuilding[];
}

export type TaxDeclaration2072DataArchive = TaxDeclarationHeaderData;
export type TaxDeclaration2072WithDataArchive<WithDataArchive extends boolean = true> = true extends WithDataArchive
  ? { dataArchived: TaxDeclaration2072DataArchive }
  : Record<never, never>;
export type TaxDeclaration2072<IsValidate extends boolean = boolean, WithDataArchive extends boolean = boolean> = {
  id?: string;
  productId: string;
  userId?: string;
  activityName?: string;
  activityAddress?: Address,
  activitySiret?: string;
  yearPeriod?: string;
  userLastName?: string;
  userFirstName?: string;
  taxRegime?: string;
  accountingPeriodId: string;
  r1: Amount;
  r2: Amount;
  r3: Amount;
  r4: Amount;
  r5: Amount;
  r6: Amount;
  isValidate?: IsValidate;
  rentalBuildings: TaxDeclaration2072RentalBuilding[];
} & (
  | { isValidate: false }
  | ({
      id: string;
      isValidate: true;
      isValidateByUserId: string;
      createdAt: string;
      updatedAt: string;
    } & TaxDeclaration2072WithDataArchive<WithDataArchive>)
);

export type TaxDeclaration2072Create = Omit<TaxDeclaration2072<true, true>, "id" | "createdAt" | "updatedAt">;

export type TaxDeclaration2072Update<> = Omit<
  RequireField<Partial<TaxDeclaration2072<true, false>>, "id">,
  "productId" | "accountingPeriodId" | "isValidateByUserId" | "createdAt" | "updatedAt"
>;

export type TaxDeclaration2072Generated = TaxDeclaration2072 & {
  dataArchive: TaxDeclaration2072DataArchive;
};

const rentalBuildingsSchema = new Schema<TaxDeclaration2072RentalBuilding>(
  {
    rentalBuildingId: { type: String, index: true },
    name: String,
    address: addressSchema,
    rentalUnitTotal: String,
    taxDeduction: String,
    line1: String,
    line2: String,
    line3: String,
    line4: String,
    line5: String,
    line6: String,
    line7: String,
    line8: String,
    line9: String,
    line9b: String,
    line10: String,
    line11: String,
    line12: String,
    line13: String,
    line14: String,
    line15: String,
    line16: String,
    line17: String,
    line18: String,
    line19: String,
    line20: String,
    line21: String,
    line22: String,
  },
  { _id: false }
);

// Mongo
export type TaxDeclaration2072Document = TaxDeclaration2072<true, true> & Document<string>;

const TaxDeclaration2072Schema = new Schema<TaxDeclaration2072<true, true>>(
  {
    _id: { type: String, default: (): string => ulid() },
    productId: { type: String, index: true },
    accountingPeriodId: { type: String, index: true },
    userId: { type: String, index: true },
    activityName: String,
    activityAddress: addressSchema,
    activitySiret: String,
    yearPeriod: String,
    userLastName: String,
    userFirstName: String,
    taxRegime: String,
    r1: String,
    r2: String,
    r3: String,
    r4: String,
    r5: String,
    r6: String,
    rentalBuildings: [rentalBuildingsSchema],
    dataArchived: {
      user: {
        id: { type: String },
        firstName: { type: String },
        lastName: { type: String },
        email: { type: String },
      },
      product: {
        id: { type: String },
        email: { type: String },
      },
      activity: {
        id: { type: String },
        type: { type: String },
        address: addressSchema,
        addressPrevious: addressSchema,
        siret: { type: String },
        name: { type: String },
        operatorType: { type: String },
        firstName: { type: String },
        lastName: { type: String },
        denomination: { type: String },
        legalStatus: { type: String },
      },
      accountingPeriod: {
        id: { type: String },
        startAt: { type: String },
        endAt: { type: String },
        taxRegime: { type: String },
        firstYear: { type: Boolean },
      },
      accountingPeriodPrevious: {
        startAt: { type: String },
        endAt: { type: String },
      },
      partnerOwned: {
        id: { type: String },
        type: { type: String },
        firstName: { type: String },
        lastName: { type: String },
        denomination: { type: String },
        role: { type: String },
        createdAt: { type: String },
      }
    }
  },
  {
    timestamps: true,
    toJSON: {
      versionKey: false,
      virtuals: true,
      transform(_, ret: TaxDeclaration2072Document): unknown {
        ret.id = ret._id;
        delete ret._id;
        return ret;
      },
    },
  }
);

// Name of the collection in third argument
export const TaxDeclaration2072Model = model<TaxDeclaration2072Document>(
  "TaxDeclaration2072",
  TaxDeclaration2072Schema,
  "TaxDeclarations2072"
);
