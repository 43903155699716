import { SubscriptionsModel } from "@edmp/api";
import { SubscriptionPlansItem } from "./Subscriptions.const";

export const getPrice = (
  plan: SubscriptionPlansItem,
  planSelected: SubscriptionsModel.PlanParams
) => {
  const price: {
    priceBeforeTaxReductionHT: string;
    priceBeforeTaxReductionTTC: string;
    priceHT: string;
    priceTTC: string;
    priceDiscountBeforeTaxReductionHT?: string;
    priceDiscountBeforeTaxReductionTTC: string;
    priceDiscountHT?: string;
    priceDiscountTTC?: string;
  } = {
    priceBeforeTaxReductionHT: "",
    priceBeforeTaxReductionTTC: "",
    priceDiscountBeforeTaxReductionTTC: "",
    priceHT: "",
    priceTTC: "",
  };
  if (plan.product) {
    const taxRatePercentage = plan.product.taxRate.percentage;

    for (const productPrice of plan.product.prices) {
      if (productPrice.durationType == planSelected.durationType) {
        price.priceBeforeTaxReductionHT = (
          (plan.priceBeforeTaxReduction || 0) / 100
        ).toFixed(2);
        const priceBeforeTaxReductionTTC =
          (plan.priceBeforeTaxReduction || 0) +
          ((plan.priceBeforeTaxReduction || 0) * taxRatePercentage) / 100;

        price.priceBeforeTaxReductionTTC = (
          priceBeforeTaxReductionTTC / 100
        ).toFixed(2);
        price.priceHT = (productPrice.amount / 100).toFixed(2);

        const TTC = (productPrice.amount * taxRatePercentage) / 100;
        price.priceTTC = ((TTC + productPrice.amount) / 100).toFixed(2);

        if (plan.product.coupon?.percent_off) {
          const discountBeforeTaxReductionPrice =
            (plan.priceBeforeTaxReduction || 0) -
            ((plan.priceBeforeTaxReduction || 0) *
              plan.product.coupon?.percent_off) /
              100;
          const discountPrice =
            productPrice.amount -
            (productPrice.amount * plan.product.coupon?.percent_off) / 100;

          price.priceDiscountBeforeTaxReductionHT = (
            discountBeforeTaxReductionPrice / 100
          ).toFixed(2);
          price.priceDiscountHT = (discountPrice / 100).toFixed(2);
          const priceDiscountBeforeTaxReductionTTC =
            discountBeforeTaxReductionPrice +
            (discountBeforeTaxReductionPrice * taxRatePercentage) / 100;

          price.priceDiscountBeforeTaxReductionTTC = (
            priceDiscountBeforeTaxReductionTTC / 100
          ).toFixed(2);
          const discountPriceTTC = (discountPrice * taxRatePercentage) / 100;
          price.priceDiscountTTC = (
            (discountPriceTTC + discountPrice) /
            100
          ).toFixed(2);
        }
      }
    }
  }
  return price;
};
