


























































































































































































import { computed, ComputedRef, defineComponent } from "@vue/composition-api";
import { ArticleEnum } from "@/composables/crisp.usable";
import PageHeader from "@/components/atom/PageHeader.vue";
import AccountingPeriodChoice from "@/components/core/accounting-period/AccountingPeriodChoice.vue";
import { ROUTE_NAMES } from "@/router/routes";
import {
  accountingBalanceSheetsStore,
  accountingPeriodsStore,
  subscriptionsStore,
  coreStore,
  tasksStore,
} from "@/store";
import {
  AccountingType,
  TaskStatus,
  TaskGroupCode,
  TaxRegime,
  getReferredIdByTypeWithReferences,
  TaskTypeReference,
  TaskCode,
  SubscriptionsModel,
} from "@edmp/api";
import { isArray, isFunction } from "lodash";
import { accountingsService } from "@/services";

type AccountingDocument = {
  documentName: string;
  name?: string;
  reporting?: () => Promise<void>;
  reportings?: { name: string; download: () => Promise<void> }[];
  disabled?: boolean;

  // Tags
  new?: boolean;
  comingSoon?: boolean;
  availableAfterClosure?: boolean;
  availableForPremiumOnly?: boolean;
};

export default defineComponent({
  name: "AccountingDocuments",
  components: {
    PageHeader,
    AccountingPeriodChoice,
  },
  setup() {
    const currentAccountingPeriod = computed(
      () => accountingPeriodsStore.currentAccountingPeriod
    );
    const currentSubscriptionType = computed(
      () => subscriptionsStore.getCurrentSubscription?.plan.type
    );
    const taskGroupTransactionYearEnd = computed(() =>
      tasksStore.closureTaskGroup.find(
        (taskGroup) => taskGroup.groupCode === TaskGroupCode.TransactionsYearEnd
      )
    );

    const balanceSheetTask = computed(() =>
      tasksStore.completedTasks?.find(
        (task) =>
          task.code === TaskCode.ValidateAndObtainBalanceSheet &&
          getReferredIdByTypeWithReferences(
            task.references,
            TaskTypeReference.accountingPeriod
          ) === accountingPeriodsStore.currentId
      )
    );
    const showExportBalanceSheet = computed(
      () => coreStore.isNotProduction || !!balanceSheetTask.value
    );

    const accountingResultTask = computed(() =>
      tasksStore.completedTasks?.find(
        (task) =>
          task.code === TaskCode.ViewAndControlResultsAccount &&
          getReferredIdByTypeWithReferences(
            task.references,
            TaskTypeReference.accountingPeriod
          ) === accountingPeriodsStore.currentId
      )
    );
    const showExportAccountingResult = computed(
      () => coreStore.isNotProduction || !!accountingResultTask.value
    );

    const isAvailableToRedirect = (accountingDocument: AccountingDocument) =>
      accountingDocument.name &&
      !accountingDocument.comingSoon &&
      !accountingDocument.availableAfterClosure &&
      !accountingDocument.availableForPremiumOnly;

    const accountingDocuments: ComputedRef<AccountingDocument[]> = computed(
      () => {
        const accountingDocuments: AccountingDocument[] = [];

        const accountingOperationsDefault: AccountingDocument = {
          documentName: "Mon fec",
          name: ROUTE_NAMES.Fec,
          reportings: [
            {
              name: "FEC",
              download: async () => {
                accountingsService.getReportingFec({
                  accountingPeriodId: accountingPeriodsStore.currentId,
                  type: AccountingType.COMPLETE,
                });
              },
            },
            {
              name: "FEC normé",
              download: async () => {
                accountingsService.getReportingFec({
                  accountingPeriodId: accountingPeriodsStore.currentId,
                  type: AccountingType.STANDARD,
                });
              },
            },
          ].filter((r) =>
            currentAccountingPeriod.value?.taxRegime === TaxRegime.IR_2072 &&
            r.name === "FEC normé"
              ? false
              : true
          ),
        };

        const accountingBalanceDefault: AccountingDocument = {
          documentName: "Ma balance",
          name: ROUTE_NAMES.AccountingBalance,
          reportings: [
            {
              name: "PDF",
              download: async () => {
                accountingsService.getReportingBalance({
                  accountingPeriodId: accountingPeriodsStore.currentId,
                });
              },
            },
            {
              name: "XLS",
              download: async () => {
                accountingsService.getReportingComplete({
                  accountingPeriodId: accountingPeriodsStore.currentId,
                });
              },
            },
          ],
        };

        const accountingAccountsBookDefault: AccountingDocument = {
          documentName: "Mon grand livre",
          comingSoon: true,
          name: "",
        };

        const accountingResultDefault: AccountingDocument = {
          documentName: "Mon compte de résultat",
          name: ROUTE_NAMES.AccountingResult,
          disabled: !showExportAccountingResult.value,
        };

        const AccountingCarryForwardDefault: AccountingDocument = {
          documentName: "Mes reports comptables & fiscaux",
          name: ROUTE_NAMES.AccountingCarryForward,
        };

        const defaultItemBalanceSheetDefault: AccountingDocument = {
          documentName: "Mon bilan",
          name: ROUTE_NAMES.AccountingBalanceSheet,
          disabled: !showExportBalanceSheet.value,
          reporting: async () => {
            if (
              !accountingBalanceSheetsStore.getCurrentAccountingBalanceSheet
            ) {
              await accountingBalanceSheetsStore.fetchAccountingBalanceSheets();
            }
            if (accountingBalanceSheetsStore.getCurrentAccountingBalanceSheet) {
              await accountingBalanceSheetsStore.reportAccountingBalanceSheets(
                accountingBalanceSheetsStore.getCurrentAccountingBalanceSheet.id
              );
            }
          },
        };

        if (currentAccountingPeriod.value?.taxRegime === TaxRegime.IR_2072) {
          delete accountingOperationsDefault.name;
          delete accountingBalanceDefault.name;

          accountingDocuments.push(
            accountingOperationsDefault,
            accountingBalanceDefault,
            accountingAccountsBookDefault,
            { ...accountingResultDefault, comingSoon: true }
          );
          if (
            currentSubscriptionType.value !==
            SubscriptionsModel.PlanType.Premium
          ) {
            accountingDocuments.push({
              documentName: "Mon bilan",
              availableForPremiumOnly: true,
            });
          } else if (
            taskGroupTransactionYearEnd.value?.status !== TaskStatus.COMPLETED
          ) {
            accountingDocuments.push({
              documentName: "Mon bilan",
              availableAfterClosure: true,
            });
          } else {
            accountingDocuments.push(defaultItemBalanceSheetDefault);
          }
        } else {
          accountingDocuments.push(
            accountingOperationsDefault,
            accountingBalanceDefault,
            accountingAccountsBookDefault,
            { ...accountingResultDefault },
            AccountingCarryForwardDefault,
            defaultItemBalanceSheetDefault
          );
        }

        return accountingDocuments;
      }
    );

    return {
      taskGroupTransactionYearEnd,
      currentSubscriptionType,
      TaxRegime,
      ArticleEnum,
      accountingDocuments,
      isAvailableToRedirect,
      isArray,
      isFunction,
      ROUTE_NAMES,
      accountingBalanceSheetsStore,
      subscriptionsStore,
      accountingPeriodsStore,
    };
  },
});
