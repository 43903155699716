


























































import { TaskGroupLocal } from "@/models";
import { accountingPeriodsStore, subscriptionsStore } from "@/store";
import { TaskStatus, TaskGroupCode } from "@edmp/api";
import { computed, defineComponent, PropType } from "@vue/composition-api";
import TaskGroupAction from "./TaskGroupAction.vue";

export default defineComponent({
  name: "TaskGroupHeader",
  components: { TaskGroupAction },
  props: {
    taskGroupLocal: {
      type: Object as PropType<TaskGroupLocal>,
      required: true,
    },
    isDisable: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const showComingSoonChip = computed(() => {
      const { isComingSoon, groupCode } = props.taskGroupLocal;
      const isBalanceSheetOrTaxReturn =
        groupCode === TaskGroupCode.BalanceSheet ||
        groupCode === TaskGroupCode.TeletransmitTaxReturn;
      const isIR = accountingPeriodsStore.isIR;
      const isPremium = subscriptionsStore.isPremium;

      if (!isComingSoon) return false;

      if (!isIR) return true;

      if (!isBalanceSheetOrTaxReturn) return true;

      return isPremium;
    });
    return {
      nbTasks: computed(
        () =>
          props.taskGroupLocal.tasksLocal.filter((task) => !task.isComingSoon)
            .length
      ),
      nbTasksDone: computed(
        () =>
          props.taskGroupLocal.tasksLocal.filter(
            (task) => !task.isComingSoon && task.status === TaskStatus.COMPLETED
          ).length
      ),
      dueDate: computed(() => {
        if (props.taskGroupLocal.dueDateLabel) {
          return props.taskGroupLocal.dueDateLabel;
        }
      }),
      showComingSoonChip,
    };
  },
});
