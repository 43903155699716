import store from "@/store/root";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { rentalsService } from "@/services";
import {
  RentIndexation,
  RentIndexationTypeParamEnum,
  NewRentIndexation,
} from "@edmp/api";
import Vue from "vue";
import { documentsStore } from "..";

export interface RentIndexationState {
  rentIndexations: Array<RentIndexation>;
  loading: boolean;
}

@Module({
  name: "rent-indexations-store",
  dynamic: true,
  namespaced: true,
  store,
})
export class RentIndexationsStore
  extends VuexModule
  implements RentIndexationState
{
  rentIndexations: Array<RentIndexation> = [];
  loading = false;

  @Mutation
  reset(): void {
    this.rentIndexations = [];
    this.loading = false;
  }

  @Mutation
  setLoading(isLoading: boolean): void {
    this.loading = isLoading;
  }

  @Action
  async fetchRentIndexations(
    rentalAgreementId: string
  ): Promise<RentIndexation[]> {
    this.setLoading(true);
    const rentIndexations = await rentalsService.agreements.listIndexations({
      rentalAgreementId: rentalAgreementId,
    });
    this.setRentIndexations(rentIndexations);
    this.setLoading(false);
    return rentIndexations;
  }

  @Mutation
  setRentIndexation(rentIndexation: RentIndexation): void {
    const index = this.rentIndexations.findIndex(
      ({ id }) => id == rentIndexation.id
    );
    if (index !== -1) {
      Vue.set(this.rentIndexations, index, rentIndexation);
    } else {
      this.rentIndexations.push(rentIndexation);
    }
  }

  @Mutation
  setRentIndexations(rentIndexations: RentIndexation[]): void {
    this.rentIndexations = rentIndexations;
  }

  @Mutation
  removeRentIndexation(rentIndexationtId: string): void {
    const index = this.rentIndexations.findIndex(
      ({ id }) => id == rentIndexationtId
    );
    if (index !== -1) {
      this.rentIndexations.splice(index, 1);
    }
  }
  @Action
  async createIndexation(
    rentIndexationCreate: NewRentIndexation
  ): Promise<RentIndexation> {
    this.setLoading(true);

    const rentIndexation = await rentalsService.agreements.createRentIndexation(
      rentIndexationCreate
    );
    this.setRentIndexation(rentIndexation);
    this.setLoading(false);

    return rentIndexation;
  }

  @Action
  async listIndexations(rentalAgreementId: string): Promise<RentIndexation[]> {
    return await rentalsService.agreements.listIndexations({
      rentalAgreementId,
    });
  }

  @Action
  async getListIndexations(irlType: RentIndexationTypeParamEnum) {
    return await rentalsService.agreements.getListIndexations(irlType);
  }

  @Action
  async sendIndexationMail(id: string): Promise<RentIndexation> {
    this.setLoading(true);
    const rentIndexationUpdated =
      await rentalsService.agreements.sendIndexation({ id });
    if (rentIndexationUpdated.noticeRentIndexationId) {
      documentsStore.addDocuments(rentIndexationUpdated.noticeRentIndexationId);
    }
    this.setRentIndexation(rentIndexationUpdated);

    this.setLoading(false);
    return rentIndexationUpdated;
  }
  @Action
  async deleteIndexation(id: string) {
    this.setLoading(true);
    await rentalsService.agreements.deleteIndexation(id);
    this.removeRentIndexation(id);
    this.setLoading(false);
  }

  @Action
  async deleteNoticeRentIndexation(params: {
    id: string;
    noticeRentIndexationId: string;
  }): Promise<RentIndexation> {
    this.setLoading(true);
    const updatedRentIndexation =
      await rentalsService.agreements.deleteNoticeRentIndexation(params.id);
    documentsStore.removeDocument(params.noticeRentIndexationId);
    this.setRentIndexation(updatedRentIndexation);
    this.setLoading(false);
    return updatedRentIndexation;
  }
}
