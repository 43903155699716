









































































import { defineComponent, ref } from "@vue/composition-api";
import TitleNew from "../title/TitleNew.vue";
import EventsGeneralAssemblyCard from "../events/generalAssembly/list/EventsGeneralAssemblyCard.vue";
import { assembliesStore, tasksStore } from "@/store";
import { ROUTE_NAMES } from "@/router/routes";
import { TaskLocal } from "@/models";

export default defineComponent({
  name: "TaskGeneralAssemblyDialog",
  components: {
    TitleNew,
    EventsGeneralAssemblyCard,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, context) {
    const selectedGeneralAssembly = ref("");

    const confirmPick = async () => {
      if (selectedGeneralAssembly.value) {
        await tasksStore.validateTask({
          taskLocal: tasksStore.taskCreate as TaskLocal,
          data: { assemblyId: selectedGeneralAssembly.value },
        });
        context.emit("close");
      }
    };

    return {
      assembliesStore,
      ROUTE_NAMES,
      selectedGeneralAssembly,
      confirmPick,
    };
  },
});
