import Decimal from "decimal.js-light";
import { cloneDeep } from "lodash";
import { Document, Schema, model } from "mongoose";
import { ulid } from "ulid";
import {
  AccountingCommon,
  AccountingPeriod,
  AccountingType,
  Direction,
  JournalEntryLine,
  JournalEntryReference,
  LedgerAccountEnum,
  LedgerAccountNumber,
  ReferenceCounter,
  Suggestion,
  SummaryTable,
  TaxRegime,
  decimal2JSON,
  getAmortisationAccountByAccount,
  getGlobalAccountFromAccountWithReferenceCounter,
  isAmortisationAccount,
  isExpenseAccounts,
  isProductAccounts,
  journalEntryReferenceSchema,
} from "..";

/**
 * * AccountingBalanceSheetLine
 */
export type AccountingBalanceSheetLine = {
  id?: string;
  account: LedgerAccountNumber;
  amount: number;
  direction: Direction;
  reference?: JournalEntryReference;
  transactionIds: string[];
  createdAt: string;
  updatedAt: string;
};

export type AccountingBalanceSheetLineCreate = {
  id?: string;
  account: LedgerAccountNumber;
  amount: number;
  direction: Direction;
  reference?: JournalEntryReference;
  transactionIds: string[];
};

export type AccountingBalanceSheetLineUpdate = {
  id?: string;
  account: LedgerAccountNumber;
  amount: number;
  direction: Direction;
  reference?: JournalEntryReference;
  transactionIds: string[];
};

// Mongo
export const accountingBalanceSheetLineSchema = new Schema<AccountingBalanceSheetLine>(
  {
    _id: { type: String, default: () => ulid() },
    account: { type: String, required: true, index: true },
    amount: { type: Schema.Types.Decimal128, required: true, index: true },
    direction: { type: String, required: true },
    reference: journalEntryReferenceSchema,
  },
  {
    timestamps: true,
    toJSON: {
      versionKey: false,
      virtuals: true,
      transform(doc, ret: AccountingBalanceSheetLineDocument) {
        ret.id = ret._id;
        decimal2JSON(ret);
        delete ret._id;
        return ret;
      },
    },
  }
);
export type AccountingBalanceSheetLineDocument = AccountingBalanceSheetLine & Document<string>;

/**
 * * AccountingBalanceSheet
 */
export type AccountingBalanceSheet = {
  id: string;
  productId: string;
  supportingDocumentId?: string;
  lines: (Omit<AccountingBalanceSheetLine, "reference"> & { reference?: ReferenceCounter })[];
  isValidated: boolean;
  createdAt: string;
  updatedAt: string;
} & (
  | {
      type: "recovery";
      isSkipped: boolean;
      startAt: AccountingPeriod["startAt"];
      endAt: AccountingPeriod["endAt"];
      // Seulement si on a converti un AccountingBalanceSheet qui dépend d'une période close en type "recovery"
      accountingPeriodId?: string;
    }
  | {
      type: "closure";
      accountingPeriodId: string;
    }
);
export type AccountingBalanceSheetCreate = {
  id?: null;
  productId: string;
  supportingDocumentId?: string;
  lines: AccountingBalanceSheetLineCreate[];
  isValidated: boolean;
} & (
  | {
      type: "recovery";
      isSkipped: boolean;
      startAt: AccountingPeriod["startAt"];
      endAt: AccountingPeriod["endAt"];
    }
  | {
      type: "closure";
      accountingPeriodId: string;
    }
);
export type AccountingBalanceSheetUpdate = {
  id: string;
  supportingDocumentId?: string;
  lines: AccountingBalanceSheetLineUpdate[];
  isValidated: boolean;
} & (
  | {
      type: "recovery";
      isSkipped: boolean;
      startAt: AccountingPeriod["startAt"];
      endAt: AccountingPeriod["endAt"];
    }
  | {
      type: "closure";
      accountingPeriodId: string;
    }
);
export type AccountingBalanceSheetUpdateInternal = {
  id: string;
  productId: string;
  supportingDocumentId?: string;
  lines: AccountingBalanceSheetLineUpdate[];
  isValidated: boolean;
} & (
  | {
      type: "recovery";
      isSkipped: boolean;
      startAt: AccountingPeriod["startAt"];
      endAt: AccountingPeriod["endAt"];
    }
  | {
      type: "closure";
      accountingPeriodId: string;
    }
);

/**
 * * AccountingBalanceSheetReporting
 * This interface is used to edit a FEC xlsx file for accounting and to archive it in BDD
 */
type AccountingBalanceSheetReportingCommon = {
  balancePreviousYear: number;
  balancePreviousYearDirection: Direction;
  balanceGross: number;
  balanceGrossDirection: Direction;
  balanceAmortisation: number;
  balanceAmortisationDirection: Direction;
  balanceNet: number;
  balanceNetDirection: Direction;
};
export type AccountingBalanceSheetReportingAccountBalanceType = AccountingBalanceSheetReportingCommon & {
  accountBalanceType: Suggestion["accountBalanceType"];
  lines: (AccountingBalanceSheetReportingCommon & {
    account: string;
    accountName: string;
    reference?: ReferenceCounter & { name?: string };
  })[];
};
export type AccountingBalanceSheetReportingAccountBalance<
  AccountBalance extends Suggestion["accountBalance"] = "Actif" | "Passif"
> = AccountingBalanceSheetReportingCommon & {
  accountBalance: AccountBalance;
} & (AccountBalance extends "Actif"
    ? {
        fixedAssets: AccountingBalanceSheetReportingAccountBalanceType;
        currentAssets: AccountingBalanceSheetReportingAccountBalanceType;
      }
    : AccountBalance extends "Passif"
    ? {
        shareholdersEquity: AccountingBalanceSheetReportingAccountBalanceType;
        debts: AccountingBalanceSheetReportingAccountBalanceType;
      }
    : never);
export interface AccountingBalanceSheetReporting extends AccountingCommon {
  type: AccountingType.BALANCE_SHEET;
  data: {
    accountingActif: AccountingBalanceSheetReportingAccountBalance<"Actif">;
    accountingPassif: AccountingBalanceSheetReportingAccountBalance<"Passif">;
  };
}

// Mongo
const accountingBalanceSheetSchema = new Schema<
  Omit<AccountingBalanceSheet, "lines"> & { lines: AccountingBalanceSheetLine[] }
>(
  {
    _id: { type: String, default: () => ulid() },
    productId: { type: String, required: true, index: true },
    supportingDocumentId: { type: String, index: true },
    lines: { type: [accountingBalanceSheetLineSchema], required: true },
    isValidated: { type: Boolean, required: true },
    isSkipped: { type: Boolean },
    type: { type: String, required: true },
    startAt: { type: String },
    endAt: { type: String },
    accountingPeriodId: { type: String, index: true },
  },
  {
    timestamps: true,
    toJSON: {
      versionKey: false,
      virtuals: true,
      transform(doc, ret: AccountingBalanceSheetDocument) {
        ret.id = ret._id;
        delete ret._id;
        return ret;
      },
    },
  }
);

export type AccountingBalanceSheetDocument = Omit<AccountingBalanceSheet, "lines"> & {
  lines: AccountingBalanceSheetLine[];
} & Document<string>;
export const AccountingBalanceSheetModel = model<AccountingBalanceSheetDocument>(
  "AccountingBalanceSheet",
  accountingBalanceSheetSchema,
  "AccountingBalanceSheets"
);

// Const
const accountingBalanceSheetDefaultAccountIR: LedgerAccountEnum[] = [
  LedgerAccountEnum.N101100,
  LedgerAccountEnum.N109000,
  LedgerAccountEnum.N110001,
  LedgerAccountEnum.N120001,
  LedgerAccountEnum.N165000,
  LedgerAccountEnum.N271000,
  LedgerAccountEnum.N445660,
  LedgerAccountEnum.N512000,
];

const accountingBalanceSheetDefaultAccountIS: LedgerAccountEnum[] = [
  LedgerAccountEnum.N101100,
  LedgerAccountEnum.N110001,
  LedgerAccountEnum.N120001,
  LedgerAccountEnum.N165000,
  LedgerAccountEnum.N445670,
  LedgerAccountEnum.N445800,
];

const accountingBalanceSheetDefaultAccountLMNP: LedgerAccountEnum[] = [];

export const accountingBalanceSheetIsSkippedNotReinit: LedgerAccountEnum[] = [
  LedgerAccountEnum.N455000,
  LedgerAccountEnum.N455010,
  LedgerAccountEnum.N512000,
];

interface AccountingBalanceSheetDefault {
  recovery: LedgerAccountEnum[];
  closure: LedgerAccountEnum[];
}

// ! To be rearranged after the closing period
export const accountingBalanceSheetDefaultAccount: { [key in TaxRegime]: AccountingBalanceSheetDefault } = {
  [TaxRegime.IR_2072]: {
    recovery: [
      LedgerAccountEnum.N101100,
      LedgerAccountEnum.N109000,
      LedgerAccountEnum.N110001,
      LedgerAccountEnum.N120001,
      LedgerAccountEnum.N165000,
      LedgerAccountEnum.N271000,
      LedgerAccountEnum.N445660,
      LedgerAccountEnum.N512000,
    ],
    closure: [],
  },
  [TaxRegime.IS_2065]: {
    recovery: [
      LedgerAccountEnum.N101100,
      LedgerAccountEnum.N110001,
      LedgerAccountEnum.N120001,
      LedgerAccountEnum.N165000,
      LedgerAccountEnum.N445670,
      LedgerAccountEnum.N445800,
    ],
    closure: [
      LedgerAccountEnum.N110001,
      LedgerAccountEnum.N101100,
      LedgerAccountEnum.N445670,
      LedgerAccountEnum.N401000,
      LedgerAccountEnum.N411000,
      LedgerAccountEnum.N467000,
      LedgerAccountEnum.N211100,
      LedgerAccountEnum.N213100,
      LedgerAccountEnum.N213500,
      LedgerAccountEnum.N218400,
      LedgerAccountEnum.N447000,
      LedgerAccountEnum.N444000,
      LedgerAccountEnum.N445800,
    ],
  },
  [TaxRegime.LMNP_2031]: {
    recovery: [],
    closure: [LedgerAccountEnum.N445670, LedgerAccountEnum.N445800],
  },
};

// Methods
export const getDefaultAccountingBalanceSheetLines = (
  taxRegime: TaxRegime,
  categories: Suggestion[]
): AccountingBalanceSheetCreate["lines"] => {
  if (taxRegime === TaxRegime.IR_2072) {
    return accountingBalanceSheetDefaultAccountIR
      .map((defaultAccountLineIR) => {
        const category = categories.find(
          (category) => category.number === defaultAccountLineIR && category.displayBilan
        );
        if (category) {
          const direction = getBalanceSheetAmountDirectionByAccountBalance(category.accountBalance);
          if (direction) {
            const accountingBalanceSheetLine: AccountingBalanceSheetCreate["lines"][number] = {
              account: defaultAccountLineIR,
              amount: 0,
              direction,
              transactionIds: [],
            };
            return accountingBalanceSheetLine;
          }
        }
      })
      .filter(
        (accountingBalanceSheetLine) => accountingBalanceSheetLine !== undefined
      ) as AccountingBalanceSheetCreate["lines"];
  } else if (taxRegime === TaxRegime.IS_2065) {
    return accountingBalanceSheetDefaultAccountIS
      .map((defaultAccountLineIS) => {
        const category = categories.find(
          (category) => category.number === defaultAccountLineIS && category.displayBilan
        );
        if (category) {
          const direction = getBalanceSheetAmountDirectionByAccountBalance(category.accountBalance);
          if (direction) {
            const accountingBalanceSheetLine: AccountingBalanceSheetCreate["lines"][number] = {
              account: defaultAccountLineIS,
              amount: 0,
              direction,
              transactionIds: [],
            };
            return accountingBalanceSheetLine;
          }
        }
      })
      .filter(
        (accountingBalanceSheetLine) => accountingBalanceSheetLine !== undefined
      ) as AccountingBalanceSheetCreate["lines"];
  } else if (taxRegime === TaxRegime.LMNP_2031) {
    return accountingBalanceSheetDefaultAccountLMNP
      .map((defaultAccountLineLMNP) => {
        const category = categories.find(
          (category) => category.number === defaultAccountLineLMNP && category.displayBilan
        );
        if (category) {
          const direction = getBalanceSheetAmountDirectionByAccountBalance(category.accountBalance);
          if (direction) {
            const accountingBalanceSheetLine: AccountingBalanceSheetCreate["lines"][number] = {
              account: defaultAccountLineLMNP,
              amount: 0,
              direction,
              transactionIds: [],
            };
            return accountingBalanceSheetLine;
          }
        }
      })
      .filter(
        (accountingBalanceSheetLine) => accountingBalanceSheetLine !== undefined
      ) as AccountingBalanceSheetCreate["lines"];
  }
  return [];
};

export const getBalanceSheetAmountDirectionByAccountNumber = (account: AccountingBalanceSheetLine["account"]) => {
  if (account === LedgerAccountEnum.N164100 || account === LedgerAccountEnum.N512100) {
    return Direction.debit;
  }
};

export const getBalanceSheetAmountDirectionByAccountBalance = (accountBalance: Suggestion["accountBalance"]) => {
  if (accountBalance === "Actif") {
    return Direction.debit;
  }
  if (accountBalance === "Passif") {
    return Direction.credit;
  }
};

export const isBidirectionalException = (account: string | LedgerAccountEnum): boolean => {
  return (
    account === LedgerAccountEnum.N110001 ||
    account === LedgerAccountEnum.N120001 ||
    account.substring(0, 6) === LedgerAccountEnum.N455000 ||
    account.substring(0, 6) === LedgerAccountEnum.N455010 ||
    account.substring(0, 6) === LedgerAccountEnum.N512000 ||
    account === LedgerAccountEnum.N108000
  );
};

// ! Temporary for awaiting balance sheet refactoring and managing double categorization for accounts 4
const updatableAccountCurrentBalanceSheet: { [key in TaxRegime]: LedgerAccountEnum[] } = {
  [TaxRegime.IR_2072]: [],
  [TaxRegime.IS_2065]: [
    LedgerAccountEnum.N445670,
    LedgerAccountEnum.N445800,
    LedgerAccountEnum.N447000,
    LedgerAccountEnum.N467000,
    LedgerAccountEnum.N444000,
  ],
  [TaxRegime.LMNP_2031]: [LedgerAccountEnum.N445670, LedgerAccountEnum.N445800],
};

export const isUpdatableAccountCurrentBalanceSheet = (
  account: string | LedgerAccountEnum,
  taxRegime: TaxRegime
): boolean => {
  return updatableAccountCurrentBalanceSheet[taxRegime].includes(account as LedgerAccountEnum);
};

export const getTotalPassiveAndActive = (
  accountingBalanceSheetLines: AccountingBalanceSheet["lines"],
  categories: Suggestion[]
) => {
  const calculateNetAmount = (accountingBalanceSheetLines: AccountingBalanceSheet["lines"]) =>
    accountingBalanceSheetLines
      .map((line) => {
        if (line.amount !== undefined && !isNaN(line.amount) && !Number.isInteger(Number(line.amount))) {
          line.amount = Number(new Decimal(line.amount).toFixed(0));
        }
        return line;
      })
      .map((line, index, lines) => {
        // Calculate Net amount
        const lineToUpdate = line;
        const account = line.reference
          ? getGlobalAccountFromAccountWithReferenceCounter(line.account, line.reference)
          : line.account;

        // We want account 164100 to be considered as depreciation of account 164200 without considering it as a standard depreciation. => https://gitlab.com/edmp/fonctionnel/-/issues/2052
        const amortisationAccount = getAmortisationAccountByAccount(
          (account === LedgerAccountEnum.N164200 || account === LedgerAccountEnum.N512000
            ? account
            : line.account) as LedgerAccountEnum
        );
        if (amortisationAccount) {
          const amortisationLineIndex = lines.findIndex((lineFind) => {
            const globalAccountFind = lineFind.reference
              ? getGlobalAccountFromAccountWithReferenceCounter(lineFind.account, lineFind.reference)
              : lineFind.account;
            const accountFind =
              globalAccountFind === LedgerAccountEnum.N164100 || globalAccountFind === LedgerAccountEnum.N512100
                ? globalAccountFind
                : lineFind.account;
            return (
              accountFind === amortisationAccount &&
              lineFind.reference?.type === line.reference?.type &&
              lineFind.reference?.referredId === line.reference?.referredId
            );
          });
          if (amortisationLineIndex !== -1) {
            lineToUpdate.amount = new Decimal(line.amount).sub(lines[amortisationLineIndex].amount).toNumber();
          }
        }
        return lineToUpdate;
      })
      .filter(
        (line) =>
          line !== undefined &&
          !isAmortisationAccount(line.account) &&
          (line.reference
            ? getGlobalAccountFromAccountWithReferenceCounter(line.account, line.reference)
            : line.account) !== LedgerAccountEnum.N164100 &&
          (line.reference
            ? getGlobalAccountFromAccountWithReferenceCounter(line.account, line.reference)
            : line.account) !== LedgerAccountEnum.N512100
      );

  const totalActive = calculateNetAmount(
    cloneDeep(accountingBalanceSheetLines).filter((line) => {
      const account = line.reference
        ? getGlobalAccountFromAccountWithReferenceCounter(line.account, line.reference)
        : line.account;
      if (account === LedgerAccountEnum.N512000 && line.direction === Direction.debit) {
        return false;
      }
      return (
        categories.find((category) => category.number === account)?.accountBalanceType === "fixed_assets" ||
        categories.find((category) => category.number === account)?.accountBalanceType === "current_assets"
      );
    })
  ).reduce(
    (previousValue, currentValue) =>
      isBidirectionalException(currentValue.account) && currentValue.direction === Direction.debit
        ? new Decimal(previousValue).sub(currentValue.amount).toNumber()
        : new Decimal(previousValue).plus(currentValue.amount).toNumber(),
    0
  );

  const totalPassive = calculateNetAmount(
    cloneDeep(accountingBalanceSheetLines).filter((line) => {
      const account = line.reference
        ? getGlobalAccountFromAccountWithReferenceCounter(line.account, line.reference)
        : line.account;
      if (account === LedgerAccountEnum.N512000 && line.direction === Direction.debit) {
        return true;
      }
      return (
        categories.find((category) => category.number === account)?.accountBalanceType === "shareholders_equity" ||
        categories.find((category) => category.number === account)?.accountBalanceType === "debts"
      );
    })
  ).reduce(
    (previousValue, currentValue) =>
      isBidirectionalException(currentValue.account) &&
      currentValue.account.substring(0, 6) !== LedgerAccountEnum.N512000 &&
      currentValue.direction === Direction.debit
        ? new Decimal(previousValue).sub(currentValue.amount).toNumber()
        : new Decimal(previousValue).plus(currentValue.amount).toNumber(),
    0
  );

  return { totalActive, totalPassive };
};

export const getResultNetAmount = (operations: JournalEntryLine[], taxDeclaration?: SummaryTable) => {
  const getDirectionFromAmount = (amount: Decimal) => {
    if (amount.isNegative()) {
      return Direction.debit;
    } else {
      return Direction.credit;
    }
  };

  const calculateBalance = (
    operation: JournalEntryLine,
    amount: { value: Decimal; direction: Direction }
  ): { value: number; direction: Direction } => {
    const operationAmount = new Decimal(operation.amount);
    let value = 0;
    let direction = amount.direction;
    if (operation.direction === amount.direction) {
      value = Number(amount.value.plus(operation.amount).abs().toFixed(2));
    }
    if (operation.direction === Direction.credit && amount.direction === Direction.debit) {
      const newBalance = operationAmount.sub(amount.value);
      direction = getDirectionFromAmount(newBalance) || amount.direction;
      value = Number(newBalance.abs().toFixed(2));
    }
    if (operation.direction === Direction.debit && amount.direction === Direction.credit) {
      const newBalance = amount.value.sub(operationAmount);
      direction = getDirectionFromAmount(newBalance) || amount.direction;
      value = Number(newBalance.abs().toFixed(2));
    }

    return {
      value,
      direction,
    };
  };

  let expenseAccountBalance = {
    value: 0,
    direction: Direction.credit,
  };
  const expenseAccountOperations = operations.filter((operation) => isExpenseAccounts(operation.account));
  for (const expenseAccountOperation of expenseAccountOperations) {
    expenseAccountBalance = calculateBalance(expenseAccountOperation, {
      value: new Decimal(expenseAccountBalance.value),
      direction: expenseAccountBalance.direction,
    });
  }

  let productAccountBalance = {
    value: 0,
    direction: Direction.credit,
  };
  const productAccountOperations = operations.filter((operation) => isProductAccounts(operation.account));
  for (const productAccountOperation of productAccountOperations) {
    productAccountBalance = calculateBalance(productAccountOperation, {
      value: new Decimal(productAccountBalance.value),
      direction: productAccountBalance.direction,
    });
  }

  const resultNetAmount = new Decimal(productAccountBalance.value).sub(expenseAccountBalance.value);
  if (taxDeclaration?.rows["Ligne 7"].value) {
    /**
     * Subtract Other non-deductible management fees for their real amount
     *  Lump sum fixed at 20€ per rental unit rental
     */
    resultNetAmount.sub(taxDeclaration.rows["Ligne 7"].value);
  }
  const resultNetValue = Number(resultNetAmount.abs().toFixed(2));
  const resultNetDirection = getDirectionFromAmount(resultNetAmount);

  return {
    value: resultNetValue,
    direction: resultNetDirection,
  };
};

// API
export namespace AccountingBalanceSheetsService {
  export type CreateIn = AccountingBalanceSheetCreate;
  export type CreateOut = AccountingBalanceSheet;

  export type ListIn = Partial<Pick<AccountingBalanceSheet, "productId" | "type">>;
  export type ListOut = AccountingBalanceSheet[];

  export type GetIn = Pick<AccountingBalanceSheet, "id">;
  export type GetOut = AccountingBalanceSheet;

  export type ReportingIn = Pick<AccountingBalanceSheet, "id">;
  export type ReportingOut = Buffer;

  export type UpdateIn = AccountingBalanceSheetUpdate;
  export type UpdateOut = AccountingBalanceSheet;

  export type RemoveIn = Pick<AccountingBalanceSheet, "id">;
  export type RemoveOut = boolean;
}
