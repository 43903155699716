import store from "@/store/root";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { rentalsService } from "@/services";
import { Regularization, NewRegularization } from "@edmp/api";
import Vue from "vue";
import { documentsStore } from "..";

export interface RegularizationsState {
  regularizations: Array<Regularization>;
  loading: boolean;
}

@Module({
  name: "regularizations-store",
  dynamic: true,
  namespaced: true,
  store,
})
export class RegularizationsStore
  extends VuexModule
  implements RegularizationsState
{
  regularizations: Array<Regularization> = [];
  loading = false;

  @Mutation
  reset(): void {
    this.regularizations = [];
    this.loading = false;
  }

  @Mutation
  setLoading(isLoading: boolean): void {
    this.loading = isLoading;
  }

  // Regularizations
  @Action
  async fetchRegularizations(
    rentalAgreementId: string
  ): Promise<Regularization[]> {
    this.setLoading(true);
    const regularizations = await rentalsService.agreements.listRegularizations(
      {
        rentalAgreementId,
      }
    );
    this.setRegularizations(regularizations);
    this.setLoading(false);
    return regularizations;
  }

  get getRegularizationsByRentalAgreementId() {
    return (rentalAgreementId: string) => {
      return this.regularizations.filter(
        (regularization) =>
          regularization.rentalAgreementId === rentalAgreementId
      );
    };
  }

  // Regularization
  @Mutation
  setRegularization(regularization: Regularization): void {
    const index = this.regularizations.findIndex(
      ({ id }) => id == regularization.id
    );
    if (index !== -1) {
      Vue.set(this.regularizations, index, regularization);
    } else {
      this.regularizations.push(regularization);
    }
  }

  @Mutation
  setRegularizations(regularizations: Regularization[]): void {
    this.regularizations = regularizations;
  }

  @Mutation
  removeRegularization(regularizationId: string): void {
    rentalsService.agreements.deleteRegularization(regularizationId);
    const index = this.regularizations.findIndex(
      ({ id }) => id == regularizationId
    );
    if (index !== -1) {
      this.regularizations.splice(index, 1);
    }
  }

  // Create
  @Action
  async createRegularization(
    regularization: NewRegularization
  ): Promise<Regularization> {
    this.setLoading(true);
    const newRegularization =
      await rentalsService.agreements.createRegularization(regularization);
    this.setRegularization(newRegularization);
    this.setLoading(false);
    return newRegularization;
  }

  @Action
  async deleteRegularization(regularizationId: string): Promise<void> {
    this.setLoading(true);
    await rentalsService.agreements.deleteRegularization(regularizationId);
    this.removeRegularization(regularizationId);
    this.setLoading(false);
  }

  @Action
  async sendRegularizationMail(params: {
    id: string;
    paymentDate: string;
  }): Promise<Regularization> {
    this.setLoading(true);
    const regularizationUpdated =
      await rentalsService.agreements.sendRegularizationMail(params);
    if (regularizationUpdated.noticeId) {
      documentsStore.addDocuments(regularizationUpdated.noticeId);
    }
    this.setRegularization(regularizationUpdated);
    this.setLoading(false);
    return regularizationUpdated;
  }

  @Action
  async deleteNoticeRegularization(params: {
    id: string;
    noticeRegularization: string;
  }): Promise<Regularization> {
    this.setLoading(true);
    const updatedRegularization =
      await rentalsService.agreements.deleleNoticeRegularization(params.id);
    documentsStore.removeDocument(params.noticeRegularization);
    this.setRegularization(updatedRegularization);
    this.setLoading(false);
    return updatedRegularization;
  }
}
