import { ref } from "@vue/composition-api";

export function useRegularization() {
  const itemsBaseCommonCharge = {
    expenses: 0,
    percentage: 100,
    totalAmount: "0.00",
  };

  const itemsBaseIndividualCharge = {
    indexStart: 0,
    indexEnd: 0,
    consumption: 0,
    unitCost: 0,
    percentage: 100,
    totalAmount: "0.00",
  };

  const headersFullYearCharge = [
    { text: "Nature de la charge", value: "chargeType" },
    { text: "Dépenses", value: "expenses" },
    {
      text: "Pourcentage à récupérer",
      info: "Ce champ indique la part des dépenses que le propriétaire peut récupérer auprès des locataires.<br />Par défaut, il est fixé à 100 %, mais le propriétaire peut choisir de ne pas récupérer la totalité de certaines charges,<br /> en ajustant ce pourcentage selon les règles de répartition définies dans le bail ou la copropriété.",
      value: "percentage",
    },
    { text: "Montant des dépenses récupérables", value: "totalAmount" },
  ];
  const itemsFullYearCharge = ref([
    {
      chargeType:
        "Charges immeuble selon décompte de charges (consommation d'eau/chauffage/électricité comprises)",
      ...itemsBaseCommonCharge,
    },
    {
      chargeType: "Taxe d'enlèvement des ordures ménagères",
      ...itemsBaseCommonCharge,
    },
    {
      chargeType: "Redevance d'assainissement",
      ...itemsBaseCommonCharge,
    },
    {
      chargeType: "Entretien chaudière",
      ...itemsBaseCommonCharge,
    },
  ]);
  const headersPartialYearCommonCharge = [
    { text: "Nature de la charge", value: "chargeType" },
    { text: "Dont locatif annuel", value: "expenses" },
    {
      text: "Pourcentage à récupérer",
      info: "Ce champ indique la part des dépenses que le propriétaire peut récupérer auprès des locataires.<br />Par défaut, il est fixé à 100 %, mais le propriétaire peut choisir de ne pas récupérer la totalité de certaines charges,<br /> en ajustant ce pourcentage selon les règles de répartition définies dans le bail ou la copropriété.",
      value: "percentage",
    },
    {
      text: "Montant proratisé",
      value: "totalAmount",
      info: "Montant calculé par rapport à la période renseignée en étape 1",
    },
  ];

  const itemsPartialYearCommonCharge = ref([
    {
      chargeType:
        "Charges immeuble selon décompte de charges au prorata hors consommation d'eau/chauffage/électricité",
      ...itemsBaseCommonCharge,
    },
    {
      chargeType: "Taxe d'enlèvement des ordures ménagères",
      ...itemsBaseCommonCharge,
    },
    {
      chargeType: "Redevance d'assainissement",
      ...itemsBaseCommonCharge,
    },
    {
      chargeType: "Entretien chaudière",
      ...itemsBaseCommonCharge,
    },
  ]);
  const headersPartialYearIndividualCharge = [
    { text: "Nature de la charge", value: "chargeType" },
    { text: "Indice de début", value: "indexStart" },
    { text: "Indice de fin", value: "indexEnd" },
    {
      text: "Consommation",
      value: "consumption",
      info: "Consommation = Indice de fin - Indice de début",
    },
    {
      text: "Prix à l'unité",
      value: "unitCost",
      info: "Prix à l'unité = Total des dépenses de la copropriété par poste de charge / Tantièmes de la copropriété par poste de charge",
    },
    {
      text: "Pourcentage à récupérer",
      info: "Ce champ indique la part des dépenses que le propriétaire peut récupérer auprès des locataires.<br />Par défaut, il est fixé à 100 %, mais le propriétaire peut choisir de ne pas récupérer la totalité de certaines charges,<br /> en ajustant ce pourcentage selon les règles de répartition définies dans le bail ou la copropriété.",
      value: "percentage",
    },
    {
      text: "Montant réel",
      value: "totalAmount",
      info: "Montant réel = Consommation x Prix à l'unité x (Pourcentage à récupérer / 100)",
    },
  ];

  const itemsIndividualCharge = ref([
    {
      chargeType: "Eau froide (en m³)",
      ...itemsBaseIndividualCharge,
    },
    {
      chargeType: "Eau chaude (en m³)",
      ...itemsBaseIndividualCharge,
    },
    {
      chargeType: "Électricité (en kWh)",
      ...itemsBaseIndividualCharge,
    },
    {
      chargeType: "Gaz (en m³)",
      ...itemsBaseIndividualCharge,
    },
  ]);

  return {
    itemsBaseCommonCharge,
    itemsBaseIndividualCharge,
    headersFullYearCharge,
    headersPartialYearCommonCharge,
    itemsFullYearCharge,
    itemsPartialYearCommonCharge,
    headersPartialYearIndividualCharge,
    itemsIndividualCharge,
  };
}
