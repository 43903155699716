import { SubscriptionsModel } from "@edmp/api";

export const subscriptionsFunctionalities = [
  {
    functionality: "Nombre d'accès associés",
    values: {
      SOLO: { isAvailable: true, infos: "1" },
      BASIC: { isAvailable: true, infos: "1" },
      PREMIUM: { isAvailable: true, infos: "illimité" },
      OPTIMUM: { isAvailable: true, infos: "illimité" },
    },
  },
  {
    functionality: "Nombre de Biens dans la SCI",
    values: {
      SOLO: { isAvailable: true, infos: "1" },
      BASIC: { isAvailable: true, infos: "Illimité" },
      PREMIUM: { isAvailable: true, infos: "illimité" },
      OPTIMUM: { isAvailable: true, infos: "illimité" },
    },
  },
  {
    functionality: "Récupération automatique des transactions bancaires",
    values: {
      SOLO: { isAvailable: false },
      BASIC: { isAvailable: true, infos: "1 compte bancaire toutes Banques" },
      PREMIUM: {
        isAvailable: true,
        infos: "jusqu'à 4 comptes bancaires agrégés",
      },
      OPTIMUM: {
        isAvailable: true,
        infos: "jusqu'à 4 comptes bancaires agrégés",
      },
    },
  },

  {
    functionality: "Historique des transactions",
    values: {
      SOLO: { isAvailable: true },
      BASIC: { isAvailable: true, infos: "illimité" },
      PREMIUM: { isAvailable: true, infos: "illimité" },
      OPTIMUM: { isAvailable: true, infos: "illimité" },
    },
  },

  {
    functionality: "Ajout manuel de transactions",
    values: {
      SOLO: { isAvailable: true },
      BASIC: { isAvailable: true },
      PREMIUM: { isAvailable: true },
      OPTIMUM: { isAvailable: true },
    },
  },
  {
    functionality: "Import de transactions sur fichier",
    values: {
      SOLO: { isAvailable: false },
      BASIC: { isAvailable: true, infos: "Fichier QIF et CSV" },
      PREMIUM: { isAvailable: true, infos: "Fichier QIF et CSV" },
      OPTIMUM: { isAvailable: true, infos: "Fichier QIF et CSV" },
    },
  },

  {
    functionality: "Classification automatique des transactions",
    values: {
      SOLO: { isAvailable: false },
      BASIC: { isAvailable: false },
      PREMIUM: { isAvailable: true },
      OPTIMUM: { isAvailable: true },
    },
  },
  {
    functionality: "Fichier des Ecritures Comptables",
    values: {
      SOLO: { isAvailable: false },
      BASIC: { isAvailable: true },
      PREMIUM: { isAvailable: true },
      OPTIMUM: { isAvailable: true },
    },
  },
  {
    functionality: "Edition et envoi des appels de loyer",
    values: {
      SOLO: { isAvailable: false },
      BASIC: { isAvailable: false },
      PREMIUM: { isAvailable: true },
      OPTIMUM: { isAvailable: true },
    },
  },
  {
    functionality: "Edition et envoi des quittances de loyer",
    values: {
      SOLO: { isAvailable: false },
      BASIC: { isAvailable: false },
      PREMIUM: { isAvailable: true },
      OPTIMUM: { isAvailable: true },
    },
  },
  {
    functionality: "Détection et envoi des relances pour impayés",
    values: {
      SOLO: { isAvailable: false },
      BASIC: { isAvailable: false },
      PREMIUM: { isAvailable: true },
      OPTIMUM: { isAvailable: true },
    },
  },
  {
    functionality: "Import des justificatifs",
    values: {
      SOLO: { isAvailable: true, infos: "20 par an" },
      BASIC: { isAvailable: true, infos: "50 par an" },
      PREMIUM: { isAvailable: true, infos: "illimité" },
      OPTIMUM: { isAvailable: true, infos: "illimité" },
    },
  },

  {
    functionality: "Traitement de la TVA",
    values: {
      SOLO: { isAvailable: false },
      BASIC: {
        isAvailable: true,
        infos: "Ecritures comptables<br/> Déclaration : non",
      },
      PREMIUM: {
        isAvailable: true,
        infos: "Ecritures comptables<br/> Déclaration : bientôt",
      },
      OPTIMUM: {
        isAvailable: true,
        infos: "Ecritures comptables<br/> Déclaration : bientôt",
      },
    },
  },

  {
    functionality: "Balance comptable mensuelle en temps réel",
    values: {
      SOLO: { isAvailable: true },
      BASIC: { isAvailable: true, infos: "Par Bien<br/> Par type de compte" },
      PREMIUM: { isAvailable: true, infos: "Par Bien<br/> Par type de compte" },
      OPTIMUM: { isAvailable: true, infos: "Par Bien<br/> Par type de compte" },
    },
  },

  {
    functionality:
      "Tableau de bord de pilotage : solde, recettes et dépenses, échéances, actions à réaliser",
    values: {
      SOLO: { isAvailable: true },
      BASIC: { isAvailable: true },
      PREMIUM: { isAvailable: true },
      OPTIMUM: { isAvailable: true },
    },
  },
  {
    functionality: "Clôture des comptes annuels guidée",
    values: {
      SOLO: { isAvailable: true },
      BASIC: { isAvailable: true },
      PREMIUM: { isAvailable: true },
      OPTIMUM: { isAvailable: true },
    },
  },
  {
    functionality:
      "Organisation de l'AG annuelle : convocation et PV conformes, rappels",
    values: {
      SOLO: { isAvailable: true },
      BASIC: { isAvailable: true },
      PREMIUM: { isAvailable: true },
      OPTIMUM: { isAvailable: true },
    },
  },
  {
    functionality: "Préparation des Déclarations 2072 et 2044 par Associé",
    values: {
      SOLO: { isAvailable: true },
      BASIC: { isAvailable: true },
      PREMIUM: { isAvailable: true },
      OPTIMUM: { isAvailable: true },
    },
  },
  {
    functionality: "Bail numérique",
    values: {
      SOLO: { isAvailable: false },
      BASIC: { isAvailable: false },
      PREMIUM: { isAvailable: true, infos: "Bientôt" },
      OPTIMUM: { isAvailable: true, infos: "Bientôt" },
    },
  },
  {
    functionality: "Espace de stockage sécurisé des documents",
    values: {
      SOLO: { isAvailable: true },
      BASIC: { isAvailable: true },
      PREMIUM: { isAvailable: true, infos: "Illimité" },
      OPTIMUM: { isAvailable: true, infos: "Illimité" },
    },
  },
  {
    functionality: "Notifications en temps réel",
    values: {
      SOLO: { isAvailable: true, infos: "email" },
      BASIC: { isAvailable: true, infos: "email" },
      PREMIUM: { isAvailable: true, infos: "email + sms" },
      OPTIMUM: { isAvailable: true, infos: "email + sms" },
    },
  },
  {
    functionality: "Calendrier des échéances",
    values: {
      SOLO: { isAvailable: true },
      BASIC: { isAvailable: true },
      PREMIUM: { isAvailable: true },
      OPTIMUM: { isAvailable: true },
    },
  },
  {
    functionality: "Assistance chat/email du Lundi au vendredi",
    values: {
      SOLO: { isAvailable: true },
      BASIC: { isAvailable: true },
      PREMIUM: { isAvailable: true },
      OPTIMUM: { isAvailable: true },
    },
  },
  {
    functionality: "Centre d'aide en ligne accessible 24/7",
    values: {
      SOLO: { isAvailable: true },
      BASIC: { isAvailable: true },
      PREMIUM: { isAvailable: true },
      OPTIMUM: { isAvailable: true },
    },
  },
];

export interface SubscriptionPlansItem {
  type: SubscriptionsModel.PlanTypeParams;
  title: string;
  description: string;
  priceBeforeTaxReduction?: number;
  pricingInfo?: string;
  btn: string;
  featureInfo?: string;
  features: string[];
  product?: SubscriptionsModel.ProductOut & {
    coupon?: SubscriptionsModel.MultiProductsDiscountOut;
  };
}

export const subscriptionPlans: SubscriptionPlansItem[] = [
  {
    type: SubscriptionsModel.PlanTypeParams.Solo,
    title: "Solo",
    description: "Pour une SCI familiale<br/>détenant 1 Bien non loué",
    btn: "Continuer avec Solo",
    features: [
      "1 Bien et 1 accès",
      "Injection manuelle des écritures",
      "Comptabilité simplifiée en ligne",
      "Suivi du solde/dépenses/recettes",
      "Calcul des déclarations 2044 et 2072",
      "Clôture de l'exercice guidée pas à pas",
      "Module Assemblée Générale avec Convocation et PV",
      "Archivage sécurisé des documents (20 par exercice)",
      "Rappel des échéances",
    ],
    product: undefined as
      | (SubscriptionsModel.ProductOut & {
          coupon?: SubscriptionsModel.MultiProductsDiscountOut;
        })
      | undefined,
  },
  {
    type: SubscriptionsModel.PlanTypeParams.Basic,
    title: "Basic",
    description: "Les services de base pour<br/>l'investisseur locatif en SCI",
    pricingInfo: "Quel que soit le nombre de biens",
    btn: "Continuer avec BASIC",
    featureInfo: 'Toutes les fonctionnalités de l\'abonnement "SOLO"',
    features: [
      "Nombre de Biens illimités",
      "Connexion d'1 compte bancaire pour récupérer automatiquement les transactions",
      "Import de transactions (nouveau)",
      "Balance comptable par Bien, Compte",
      "TVA : traitement des écritures",
      "Fichier des Ecritures Comptables",
    ],
    product: undefined as
      | (SubscriptionsModel.ProductOut & {
          coupon?: SubscriptionsModel.MultiProductsDiscountOut;
        })
      | undefined,
  },
  {
    type: SubscriptionsModel.PlanTypeParams.Premium,
    title: "Premium",
    description:
      "Tous les services OWNILY pour<br/>les investisseurs autonomes !",
    pricingInfo: "Quel que soit le nombre de biens",
    btn: "Continuer avec PREMIUM",
    featureInfo: 'Toutes les fonctionnalités de l\'abonnement "Basic"',
    features: [
      "Nombre d'accès illimité",
      "Jusqu'à 4 comptes bancaires pour votre SCI",
      "Classification automatique des recettes et dépenses",
      "Import de transactions",
      "Edition et envoi des appels de loyer",
      "Edition et envoi des quittances de loyer",
      "Détection et envoi des relances pour impayés",
      "Bail numérique (bientôt)",
      "Archivage sécurisé des documents : illimité",
      "Notifications par email et SMS",
    ],
    product: undefined as
      | (SubscriptionsModel.ProductOut & {
          coupon?: SubscriptionsModel.MultiProductsDiscountOut;
        })
      | undefined,
  },

  {
    type: SubscriptionsModel.PlanTypeParams.Optimum,
    title: "Optimum",
    description:
      "Tous les services OWNILY pour<br/>les investisseurs autonomes !",
    pricingInfo: "Quel que soit le nombre de biens",
    btn: "Continuer avec OPTIMUM",
    // featureInfo: 'Toutes les fonctionnalités de l'abonnement "Premium"',
    features: [
      "Connexion bancaires multiple",
      "Classification automatisée",
      "Comptabilité conforme adaptée SCI IS",
      "Gestion de vos amortissements des biens et travaux",
      "Suivi des Comptes Courants d'Associés",
      "Déclaration 2065 et 2033",
      "Edition et envoi des appels de loyer",
      "Détection et envoi des relances pour impayés",
      "Bail numérique (bientôt)",
      "Accès invités (illimité)",
      "Archivage sécurisé des documents (illimité)",
    ],
    product: undefined as
      | (SubscriptionsModel.ProductOut & {
          coupon?: SubscriptionsModel.MultiProductsDiscountOut;
        })
      | undefined,
  },

  {
    type: SubscriptionsModel.PlanTypeParams.LMNPEssential,
    title: "LMNP Essentiel",
    description:
      "Les services essentiels<br/>pour maîtriser votre déclaration fiscale LMNP !",
    priceBeforeTaxReduction: 8300,
    pricingInfo: "Quel que soit le nombre de biens",
    btn: "Continuer avec ESSENTIEL",
    features: [
      "Import des Revenus et Charges",
      "Connexion d'1 compte bancaire",
      "Classification facilitée",
      "Reprise Comptabilité et Amortissements",
      "Déclarations LMNP 2031 BIC-Réel",
      "Bilan et Fichier des Écritures Comptables",
      "Suivi par Bien toute l'année",
      "Assistance en ligne (chat, visio, téléphone)",
    ],
    product: undefined as
      | (SubscriptionsModel.ProductOut & {
          coupon?: SubscriptionsModel.MultiProductsDiscountOut;
        })
      | undefined,
  },

  {
    type: SubscriptionsModel.PlanTypeParams.LMNPOptimum,
    title: "LMNP Optimum",
    description:
      "Tous les services OWNILY<br/>pour une gestion optimisée de votre LMNP !",
    priceBeforeTaxReduction: 9900,
    pricingInfo: "Quel que soit le nombre de biens",
    btn: "Continuer avec OPTIMUM",
    featureInfo: 'Toutes les fonctionnalités de l\'abonnement "Essentiel"',
    features: [
      "Gestion des contrats de location",
      "Suivi des loyers : appels, quittances, relances, révision annuelle",
      "Bibliothèque de documents : bail d'habitation, bail mobilité...",
      "Calculateur Régularisation des charges",
      "Connexions bancaires multiples",
    ],
    product: undefined as
      | (SubscriptionsModel.ProductOut & {
          coupon?: SubscriptionsModel.MultiProductsDiscountOut;
        })
      | undefined,
  },
];

export const subscriptionPayments = [
  {
    type: SubscriptionsModel.PaymentMethodTypeParams.sepaDebit,
    title: "Prélèvement SEPA",
    text: "Le prélèvement SEPA est idéal pour les paiements récurrents et pour automatiser la comptabilité de votre SCI. Mettez le en place sur le compte bancaire de la SCI et ne vous souciez plus de rien. L'abonnement Ownily sera automatiquement visible dans l'espace \"Transactions\" de la SCI sur Ownily : gain de temps pour vous et votre compta !",
    icon: "mdi-currency-eur",
    iconColor: "--v-primary-base",
    recommend: true,
  },
  {
    type: SubscriptionsModel.PaymentMethodTypeParams.card,
    title: "Carte bancaire",
    text: "A savoir : si vous utilisez une carte bancaire personnelle pour ce paiement, l'opération n'apparaîtra pas sur le compte bancaire de la SCI. Vous devrez penser à l'injecter manuellement sur l'espace \"Transactions\" sur Ownily pour la comptabiliser dans les charges de la SCI.",
    icon: "mdi-credit-card",
    iconColor: "--v-primary-lighten1",
    recommend: false,
  },
];
