import { render, staticRenderFns } from "./Balance.vue?vue&type=template&id=0bc844cb&scoped=true&"
import script from "./Balance.vue?vue&type=script&lang=ts&"
export * from "./Balance.vue?vue&type=script&lang=ts&"
import style0 from "./Balance.vue?vue&type=style&index=0&id=0bc844cb&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bc844cb",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
installComponents(component, {VDivider})
