var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('EditableTable',{ref:"editableTable",attrs:{"headers":_vm.headers,"items":_vm.items,"viewType":_vm.viewType,"itemsPerPage":-1,"hideFooter":"","isEnableEditItem":false,"isEnableValidateItem":false,"hideAddButton":true},on:{"deleteItem":_vm.deleteItem},scopedSlots:_vm._u([_vm._l((_vm.headers),function(ref){
var headerValue = ref.value;
return {key:("item." + headerValue),fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('div',{key:headerValue,staticClass:"d-flex"},[(headerValue === 'kinds')?_c('div',_vm._l((value),function(kind,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(kind)+" ")])}),0):(headerValue === 'attributes')?_c('div',_vm._l((_vm.filteredAttributes(value)),function(attribute,index){return _c('v-chip',{key:index,staticClass:"chip",attrs:{"small":"","outlined":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"size":"20"}},'v-icon',attrs,false),on),[_vm._v(_vm._s(_vm.getAttributeDisplay(attribute).icon))]),_c('span',_vm._g(_vm._b({staticClass:"textOverflow"},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.getAttributeDisplay(attribute).name)+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.getAttributeDisplay(attribute).name)+" ")])],1)}),1):(headerValue === 'reconciliation')?_c('div',[(item.reportedLoss)?_c('span',{staticStyle:{"color":"red"}},[_vm._v("Déclaré en perte")]):(!!item.reconciliation)?_c('div',[(item.reconciliation.transaction)?_c('a',{on:{"click":function($event){return _vm.navigateToTransactions(item)}}},[_vm._v("Réconcilié")]):_vm._e()]):_c('span',[_vm._v(_vm._s("Non réconcilié"))])]):(headerValue === 'amount')?_c('div',[_vm._v(_vm._s(value)+"€")]):(headerValue === 'invoices')?_c('div',_vm._l((value),function(invoice,index){return _c('div',{key:index,staticClass:"mb-1 mt-1"},[(invoice !== undefined)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"supporting-document-type pa-3",attrs:{"depressed":"","small":"","color":"#757575","href":invoice.href,"target":"_blank"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.extension(invoice.type))+" ")])]}}],null,true)},[_c('span',[_vm._v("Voir le document")])]):_vm._e()],1)}),0):_c('div',[_vm._v(" "+_vm._s(value)+" ")])])]}}})],null,true)}),_c('Confirm',{ref:"confirmDialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }