























































































































































































import {
  defineComponent,
  computed,
  onMounted,
  watch,
  Ref,
  ref,
} from "@vue/composition-api";
import { ArticleEnum } from "@/composables/crisp.usable";
import { HelpingMessage } from "@/components/atom/helping";
import NewDocumentsTable from "./NewDocumentsTable.vue";
import DocumentsPanel from "./DocumentsPanel.vue";
import ModelsLibrary from "./ModelsLibrary.vue";
import AddDocumentsModal from "./AddDocumentsModal.vue";
import PageHeader from "../../atom/PageHeader.vue";
import { useDocuments } from "./useDocuments";
import {
  accountingPeriodsStore,
  coreStore,
  documentsStore,
  productsStore,
  subscriptionsStore,
  usersStore,
} from "@/store";
import { getMoment, SubscriptionsModel } from "@edmp/api";
import { ROUTE_NAMES } from "@/router/routes";
import { ForbiddenError, subject } from "@casl/ability";
import { ability } from "@/services";
import { FeedbackTypeEnum } from "@/store/modules/Core.store";
import router from "@/router";
import { dispatchModelsLibraryEvent, ModelsLibraryEventCode } from "@/events";
import UploadFlowDialog from "@/components/atom/UploadFlowDialog.vue";
import TitleNew from "../title/TitleNew.vue";

export default defineComponent({
  name: "Documents",
  components: {
    HelpingMessage,
    PageHeader,
    NewDocumentsTable,
    DocumentsPanel,
    AddDocumentsModal,
    ModelsLibrary,
    UploadFlowDialog,
    TitleNew,
  },
  setup(_, context) {
    const headers = [
      { text: "", value: "new" },
      { text: "Libellé", value: "name" },
      { text: "Bien", value: "realEstateAsset" },
      { text: "Date de génération", value: "createdAt" },
      { text: "Année d'exercice", value: "accountingPeriod" },
      { text: "Format", value: "type" },
      { text: "Supprimer", value: "delete", sortable: false },
    ];

    const headersRent = [
      { text: "", value: "new" },
      { text: "Libellé", value: "name" },
      { text: "Locataire", value: "tenant" },
      { text: "Bien", value: "realEstateAsset" },
      { text: "Période", value: "rentPeriod" },
      { text: "Format", value: "type" },
      { text: "Supprimer", value: "delete", sortable: false },
    ];

    const openUploadFlowDialog: Ref<boolean> = ref(false);
    const { documents, getDocuments } = useDocuments();

    watch(
      () => documentsStore.documents,
      () => {
        documents.value = documentsStore.documents;
      }
    );

    const accounting = computed(() =>
      documents.value.filter(
        (doc) => doc.tags && doc.tags.includes("accounting")
      )
    );
    const productAndPartners = computed(() =>
      documents.value.filter((doc) => doc.tags && doc.tags.includes("product"))
    );
    const rent = computed(() =>
      documents.value.filter(
        (doc) =>
          doc.tags &&
          doc.tags.includes("rental-agreement") &&
          doc.tags.includes("receipt")
      )
    );
    const fiscality = computed(() =>
      documents.value.filter((doc) => doc.tags && doc.tags.includes("tax"))
    );
    const events = computed(() =>
      documents.value.filter((doc) => doc.tags && doc.tags.includes("event"))
    );
    const realEstateAsset = computed(() =>
      documents.value.filter(
        (doc) => doc.tags && doc.tags.includes("real-estate-asset")
      )
    );

    const focus: Ref<string> = ref("");

    const subscription = computed(() => {
      return subscriptionsStore.getCurrentSubscription;
    });
    const checkPermissions = () => {
      try {
        ForbiddenError.from(ability).throwUnlessCan(
          "visit",
          subject("ModelsLibrary", {})
        );
        router.push({ name: ROUTE_NAMES.ModelsLibrary });
        dispatchModelsLibraryEvent({
          userId: usersStore.loggedInUser.id,
          productId: productsStore.currentId,
          subscriptionPlan:
            subscriptionsStore.getCurrentSubscription?.plan.type || "unknown",
          date: getMoment().toISOString(),
          code: ModelsLibraryEventCode.CLICK_CARD_MODELS_LIBRARY,
        });
      } catch (error) {
        if (error instanceof ForbiddenError) {
          coreStore.displayFeedback({
            type: FeedbackTypeEnum.WARNING,
            message: error.message,
          });
        }
        dispatchModelsLibraryEvent({
          userId: usersStore.loggedInUser.id,
          productId: productsStore.currentId,
          subscriptionPlan:
            subscriptionsStore.getCurrentSubscription?.plan.type || "unknown",
          date: getMoment().toISOString(),
          code: ModelsLibraryEventCode.CLICK_REFUSED_CARD_MODELS_LIBRARY,
        });
      }
    };

    async function init() {
      documents.value = [];

      getDocuments();

      if (context.root.$route.query["focus"]) {
        focus.value = context.root.$route.query["focus"].toString();
        setTimeout(() => {
          try {
            context.root.$vuetify.goTo(`#${focus.value}`, {
              offset: 12,
            });
          } catch (err) {
            // do nothing
          }
        }, 500);
      }
    }

    onMounted(() => {
      init();
    });
    watch(
      () => accountingPeriodsStore.currentId,
      () => init(),
      { deep: true }
    );

    return {
      openUploadFlowDialog,
      documents,
      getDocuments,
      headers,
      headersRent,
      focus,
      ArticleEnum,
      accountingPeriodsStore,
      productAndPartners,
      accounting,
      rent,
      fiscality,
      events,
      realEstateAsset,
      subscription,
      checkPermissions,
      ROUTE_NAMES,
      PlanType: SubscriptionsModel.PlanType,
    };
  },
});
