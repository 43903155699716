






























































































































































import DatePicker from "@/components/atom/DatePicker.vue";
import TransactionCategories from "@/components/core/transactions/transaction/TransactionCategories.vue";
import TransactionHeader from "@/components/core/transactions/transaction/TransactionHeader.vue";
import { VForm } from "@/models";
import { transactionsService } from "@/services/Transactions.service";
import {
  accountingPeriodsStore,
  productsStore,
  realEstateAssetsStore,
} from "@/store";
import {
  getMoment,
  LedgerAccountEnum,
  Transaction,
  TypeReference,
  validateDateApplicationTva,
} from "@edmp/api";
import {
  computed,
  defineComponent,
  ref,
  Ref,
  watch,
} from "@vue/composition-api";
import { initTransactionState } from "../transactions/transaction/transaction.usable";

export default defineComponent({
  name: "RealEstateTVAModify",
  components: { DatePicker, TransactionHeader, TransactionCategories },
  props: {
    realEstateAssetId: {
      type: String,
      required: true,
    },
    taxRateTva: {
      type: Number,
      required: true,
    },
  },
  setup(props, context) {
    const realEstateAsset = computed(() =>
      realEstateAssetsStore.getRealEstateAsset(props.realEstateAssetId)
    );
    const rentalUnit = computed(() =>
      realEstateAssetsStore.getRentalUnitByRealEstateAssetId(
        props.realEstateAssetId
      )
    );
    const transactionsWithTva: Ref<Transaction[]> = ref([]);

    const transactionStates = computed(() =>
      transactionsWithTva.value.map((transaction) =>
        initTransactionState(transaction, false)
      )
    );

    const computedAvatar = (transaction: Transaction) => {
      if (transaction.value.amount && transaction.value.amount >= 0) {
        return "#b8d7fb";
      } else {
        return "#7085d1";
      }
    };
    const maxDate = computed(() =>
      getMoment().subtract(1, "day").format("YYYY-MM-DD")
    );

    const upcomingTransactions = ref(true);
    const dateApplication = ref();
    const isLoading = ref(false);
    watch(
      () => dateApplication.value,
      async (newValue) => {
        if (newValue) {
          isLoading.value = true;
          let transactions: Transaction[] = [];
          for (const acc of accountingPeriodsStore.accountingPeriods) {
            if (!acc.closed) {
              transactions = [
                ...transactions,
                ...(await transactionsService.listPeriod({
                  productId: productsStore.currentId,
                  accountingPeriodId: acc.id,
                })),
              ];
            }
          }
          transactionsWithTva.value = transactions.filter(
            ({ operations, date }) =>
              operations?.journalEntry.lines?.some(
                ({ account }) => account === LedgerAccountEnum.N706000
              ) &&
              operations?.journalEntry.lines.some(
                ({ account, refs }) =>
                  account === LedgerAccountEnum.N445720 &&
                  refs?.some(
                    ({ type, referredId }) =>
                      type === TypeReference.realEstateAsset &&
                      referredId === realEstateAsset.value?.id
                  )
              ) &&
              getMoment(date.operation).isSameOrAfter(dateApplication.value)
          );
          isLoading.value = false;
        }
      }
    );

    const validateDateApplication = () =>
      validateDateApplicationTva(
        rentalUnit.value.history,
        dateApplication.value
      );

    const validate = () => {
      if ((context.refs.form as VForm).validate()) {
        const date = !upcomingTransactions.value
          ? dateApplication.value
          : new Date();
        const transactionIds: string[] = transactionStates.value.map(
          (transactionState) => transactionState.transaction.id
        );
        context.emit("validate", { date, transactionIds });
      }
    };

    return {
      upcomingTransactions,
      dateApplication,
      computedAvatar,
      maxDate,
      transactionStates,
      isLoading,
      validateDateApplication,
      validate,
    };
  },
});
