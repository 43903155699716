import { defaultUTM, Utm } from "@edmp/api";
import { format } from "date-fns";
import { Dictionary } from "vue-router/types/router";

export function onlyNumber(value: string | number): boolean {
  const string: string = typeof value === "number" ? value.toString() : value;
  const re = new RegExp(/^\d+(\.\d+)*$/);
  return re.test(string);
}

export const today = format(new Date(), "yyyy-MM-dd");

export const getUtm = function (
  query: Dictionary<string | (string | null)[]>
): Utm {
  const utm: Utm = defaultUTM;
  if (query) {
    utm.utmMedium = getUtmAsString(query.utm_medium);
    utm.utmSource = getUtmAsString(query.utm_source);
    utm.utmCampaign = getUtmAsString(query.utm_campaign);
    utm.utmContent = getUtmAsString(query.utm_content);
    utm.utmTerm = getUtmAsString(query.utm_term);
  }
  return utm;
};

const getUtmAsString = (value: Object | String | null): string => {
  if (!value) {
    return "";
  } else if (typeof value === "string" || value instanceof String) {
    return value.toString();
  } else {
    return JSON.stringify(value);
  }
};

export const countDecimals = function (value: number): number {
  let numberDecimals = 0;
  if (!value) {
    return 0;
  }
  const amount = value.toString().replace(",", ".");
  if (amount.includes(".")) {
    numberDecimals = amount.split(".")[1].length;
  }

  return numberDecimals;
};

export const formatDate = (date: string | undefined): string => {
  if (!date || date === "") {
    return "";
  } else {
    const [year, month, day] = date.split("-");
    return `${day}/${month}/${year}`;
  }
};

export const numberWithSpaces = (number: string | number) =>
  number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

export const debounce = (callback: Function, wait: number) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      callback(...args);
    }, wait);
  };
};

export const DecimalPrecision2 = (function () {
  return {
    // Decimal round (half away from zero)
    round: function (num, decimalPlaces) {
      const p = Math.pow(10, decimalPlaces || 0);
      const n = num * p * (1 + Number.EPSILON);
      return Math.round(n) / p;
    },
    // Decimal ceil
    ceil: function (num, decimalPlaces) {
      const p = Math.pow(10, decimalPlaces || 0);
      const n = num * p * (1 - Math.sign(num) * Number.EPSILON);
      return Math.ceil(n) / p;
    },
    // Decimal floor
    floor: function (num, decimalPlaces) {
      const p = Math.pow(10, decimalPlaces || 0);
      const n = num * p * (1 + Math.sign(num) * Number.EPSILON);
      return Math.floor(n) / p;
    },
    // Decimal trunc
    trunc: function (num, decimalPlaces) {
      return (num < 0 ? this.ceil : this.floor)(num, decimalPlaces);
    },
    // Format using fixed-point notation
    toFixed: function (num, decimalPlaces) {
      return this.round(num, decimalPlaces).toFixed(decimalPlaces);
    },
  };
})();
