








































import { defineComponent, ref } from "@vue/composition-api";
import TitleNew from "../title/TitleNew.vue";
import { accountingPeriodsStore, tasksStore, assembliesStore } from "@/store";
import { TaskGroupCode } from "@edmp/api";
import router from "@/router";
import { ROUTE_NAMES } from "@/router/routes";

export default defineComponent({
  name: "TaskGeneralAssemblyDialog",
  components: {
    TitleNew,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, context) {
    const generalAssemblyWithOwnily = ref(true);

    const validateEventGeneralAssemblyYearEndCreateStep = async () => {
      tasksStore.validateTaskGroup({
        groupCode: TaskGroupCode.EventGeneralAssemblyYearEnd,
        accountingPeriodId: accountingPeriodsStore.currentId,
      });
    };

    const handleGeneralAssembly = () => {
      if (
        generalAssemblyWithOwnily.value &&
        assembliesStore.listGeneralAssemblyEventsForCurrentAccountingPeriod
          .length >= 1
      ) {
        context.emit("openPickGeneralAssemblyDialog");
        context.emit("close");
      } else if (
        generalAssemblyWithOwnily.value &&
        assembliesStore.listGeneralAssemblyEventsForCurrentAccountingPeriod
          .length === 0
      ) {
        router.push({
          name: ROUTE_NAMES.EventsGeneralAssembly,
          query: { newEvent: "ordinary", pattern: "yearEnd" },
        });
      } else {
        validateEventGeneralAssemblyYearEndCreateStep();
        context.emit("close");
      }
    };

    return {
      generalAssemblyWithOwnily,
      handleGeneralAssembly,
    };
  },
});
