import { LedgerAccountEnum } from "../models/JournalComposedEntry"

export const accountsWithNoGenericCharges = [
    LedgerAccountEnum.N706000,
    LedgerAccountEnum.N706001,
    LedgerAccountEnum.N706101,
    LedgerAccountEnum.N708399,
    LedgerAccountEnum.N775000,
    LedgerAccountEnum.N164000,
    LedgerAccountEnum.N164100,
    LedgerAccountEnum.N164200,
    LedgerAccountEnum.N616600,
    LedgerAccountEnum.N661110,
    LedgerAccountEnum.N622710,
    LedgerAccountEnum.N627100,
    LedgerAccountEnum.N503000,
    LedgerAccountEnum.N455000,
    LedgerAccountEnum.N455010,
    LedgerAccountEnum.N671000,
    LedgerAccountEnum.N627800,
    LedgerAccountEnum.N627200,
    LedgerAccountEnum.N661100,
    LedgerAccountEnum.N661600,
    LedgerAccountEnum.N444000,
    LedgerAccountEnum.N695000,
    LedgerAccountEnum.N445670,
    LedgerAccountEnum.N445720,
    LedgerAccountEnum.N445800,
    LedgerAccountEnum.N201000,
    LedgerAccountEnum.N201100,
    LedgerAccountEnum.N271000,
    LedgerAccountEnum.N272000,
    LedgerAccountEnum.N261000,
    LedgerAccountEnum.N781100,
    LedgerAccountEnum.N213000,
    LedgerAccountEnum.N580000,
    LedgerAccountEnum.N508000,
    LedgerAccountEnum.N451000,
    LedgerAccountEnum.N512000,
    LedgerAccountEnum.N211100,
    LedgerAccountEnum.N213100,
    LedgerAccountEnum.N215700,
    LedgerAccountEnum.UNKNOWN
]