





























































































import { operationAccrualsStore } from "@/store";
import { OperationAccrualLib, OperationAccrualsModel } from "@edmp/api";
import {
  computed,
  defineComponent,
  PropType,
  ref,
  watch,
} from "@vue/composition-api";
import AccrualCard from "../AccrualCard.vue";
import { AccrualsInventoryState } from "./accrualsInventory.usable";

export default defineComponent({
  name: "AccrualsInventoryStep2",
  props: {
    accrualsInventoryState: {
      type: Object as PropType<AccrualsInventoryState>,
      required: true,
    },
    stepSelected: {
      type: String,
      required: true,
    },
  },
  components: {
    AccrualCard,
  },
  setup(props) {
    const accrualsInventoryState = ref<AccrualsInventoryState>(
      props.accrualsInventoryState
    );

    const currentBalance = computed(
      () => accrualsInventoryState.value.currentBalance
    );

    const operationAccruals = computed(() =>
      operationAccrualsStore.operationAccruals.filter(
        (operationAccrual) =>
          operationAccrual.journalEntry.lines?.find(
            (line) =>
              OperationAccrualLib.getDoubleEntryAccount(line.account) ===
              accrualsInventoryState.value.account
          ) &&
          !operationAccrual.reconciliation?.date &&
          !operationAccrual.reportedLoss
      )
    );
    const operationAccrualsLocal = ref<
      Array<{
        operationAccrual: OperationAccrualsModel.OperationAccrual;
        report: boolean;
      }>
    >([]);
    watch(
      operationAccruals,
      (newVal) => {
        if (newVal.length > 0) {
          operationAccrualsLocal.value = newVal.map((t) => ({
            operationAccrual: t,
            report: true,
          }));
        } else {
          operationAccrualsLocal.value = [];
        }
      },
      { immediate: true }
    );
    watch(
      () => operationAccrualsLocal.value.map((accrual) => accrual.report),
      () => {
        accrualsInventoryState.value.operationLossIds =
          operationAccrualsLocal.value
            .filter((accrual) => !accrual.report)
            .map((accrual) => accrual.operationAccrual.id);
      },
      { deep: true }
    );
    const reportAll = () => {
      operationAccrualsLocal.value.forEach((operation) => {
        operation.report = true;
      });
    };

    const declareAllAsLoss = () => {
      operationAccrualsLocal.value.forEach((operation) => {
        operation.report = false;
      });
    };

    const expandedPanels = ref<number[]>([]);
    const allExpanded = ref(false);
    watch(
      () => allExpanded.value,
      () => {
        if (allExpanded.value) {
          expandedPanels.value = operationAccruals.value.map(
            (_, index) => index
          );
        } else {
          expandedPanels.value = [];
        }
      }
    );

    return {
      operationAccrualsLocal,
      currentBalance,
      expandedPanels,
      allExpanded,
      reportAll,
      declareAllAsLoss,
    };
  },
});
