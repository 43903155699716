


























































































































import {
  accountingBalanceSheetsStore,
  accountingPeriodsStore,
  coreStore,
  operationAccrualsStore,
  productsStore,
} from "@/store";
import { FeedbackTypeEnum } from "@/store/modules/Core.store";
import {
  AccountingBalanceSheet,
  getMoment,
  LedgerAccountEnum,
  OperationAccrualLib,
  OperationAccrualsModel,
} from "@edmp/api";
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref,
} from "@vue/composition-api";
import Decimal from "decimal.js-light";
import AccrualCards from "../AccrualCards.vue";
import { useAccruals } from "../accruals.usable";
import AccrualMassCreate from "../create/AccrualMassCreate.vue";
import {
  AccrualsInventoryState,
  useAccrualsInventory,
} from "./accrualsInventory.usable";

export default defineComponent({
  name: "AccrualsInventoryStep1",
  props: {
    accrualsInventoryState: {
      type: Object as PropType<AccrualsInventoryState>,
      required: true,
    },
    stepSelected: {
      type: String,
      required: true,
    },
  },
  components: {
    AccrualCards,
    AccrualMassCreate,
  },
  setup(props, context) {
    const accrualsInventoryState = ref<AccrualsInventoryState>(
      props.accrualsInventoryState
    );
    const { hasCompletedTaxTeletransmitTask2023 } = useAccruals();
    const { initCurrentBalance } = useAccrualsInventory();
    const isOpenAddAccrual = ref(false);
    const isOpenDetailAccruals = ref(false);
    const isOpenRecoveryAddAccrual = ref(false);

    const operationAccruals = computed<
      OperationAccrualsModel.OperationAccrual[]
    >(() => {
      const filterByAccount = (
        operationAccrual: OperationAccrualsModel.OperationAccrual
      ) =>
        operationAccrual.journalEntry.lines?.some(
          (line) =>
            OperationAccrualLib.getDoubleEntryAccount(line.account) ===
            accrualsInventoryState.value.account
        );
      return operationAccrualsStore.operationAccruals.filter(
        (operationAccrual) =>
          filterByAccount(operationAccrual) &&
          getMoment(operationAccrual.journalEntry.accrualDate).isSameOrBefore(
            getMoment(accrualsInventoryState.value.accountingPeriod.endAt)
          ) &&
          !operationAccrual.reconciliation?.date &&
          !operationAccrual.reportedLoss
      );
    });

    const updateCurrentBalance = async () => {
      await accountingBalanceSheetsStore.fetchAccountingBalanceSheets();
      accrualsInventoryState.value.currentBalance = initCurrentBalance(
        accrualsInventoryState.value.account,
        accountingBalanceSheetsStore.getCurrentAccountingBalanceSheet as AccountingBalanceSheet
      );
    };

    const closeAddAccrual = async () => {
      await operationAccrualsStore.fetchOperationAccruals({
        productId: productsStore.currentId,
      });
      await updateCurrentBalance();
      isOpenAddAccrual.value = false;
    };

    const changeStep = (newStep: string) => {
      context.emit("update:stepSelected", newStep);
    };

    const recoveryOperationAccruals = computed(() =>
      operationAccrualsStore.recoveryOperationAccrualsByAccountingPeriodId(
        accrualsInventoryState.value.accountingPeriod.id
      )
    );
    const previousTotalAmount = computed(
      () =>
        new Decimal(
          recoveryOperationAccruals.value
            .filter((recoveryOperationAccrual) =>
              recoveryOperationAccrual.journalEntry.lines?.find(
                (line) =>
                  OperationAccrualLib.getDoubleEntryAccount(line.account) ===
                  accrualsInventoryState.value.account
              )
            )
            .reduce((acc, operationAccrual) => {
              const totalAmount =
                OperationAccrualLib.getDoubleEntryAmount(operationAccrual);
              return acc + totalAmount;
            }, 0)
        )
    );
    const remainingRecoveryAmount = computed(() => {
      return new Decimal(previousTotalAmount.value.toFixed(0))
        .abs()
        .minus(
          new Decimal(accrualsInventoryState.value.initialBalance ?? 0).abs()
        );
    });

    const validateRecoveryCreate = async () => {
      await updateCurrentBalance();
      if (!remainingRecoveryAmount.value.isZero()) {
        coreStore.displayFeedback({
          type: FeedbackTypeEnum.WARNING,
          message: `Il reste ${remainingRecoveryAmount.value.abs()}€ d'engagement à créer !`,
        });
        return;
      }
      isOpenRecoveryAddAccrual.value = false;
    };
    const cancelRecoveryCreate = () => {
      isOpenRecoveryAddAccrual.value = false;
      accrualsInventoryState.value.recoveryCreateCanceled = true;
    };

    onMounted(async () => {
      const isPreviousYearRecovery =
        accountingPeriodsStore.currentAccountingPeriod?.id ===
          accountingPeriodsStore.firstAccountingPeriod.id &&
        !accountingPeriodsStore.currentAccountingPeriod?.firstYear;

      const mustAddRecoveryAccrual =
        (hasCompletedTaxTeletransmitTask2023() || isPreviousYearRecovery) &&
        !remainingRecoveryAmount.value.isZero();

      if (mustAddRecoveryAccrual) {
        isOpenRecoveryAddAccrual.value = true;
      }
    });

    return {
      isOpenAddAccrual,
      isOpenDetailAccruals,
      isOpenRecoveryAddAccrual,
      closeAddAccrual,
      changeStep,
      validateRecoveryCreate,
      cancelRecoveryCreate,
      operationAccruals,
      LedgerAccountEnum,
    };
  },
});
