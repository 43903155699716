










































































































































































































































































































































































































































































































































































import {
  computed,
  defineComponent,
  reactive,
  ref,
  toRef,
  onBeforeMount,
  PropType,
  Ref,
  watch,
} from "@vue/composition-api";
import { format } from "date-fns";
import moment from "moment";
import Decimal from "decimal.js-light";
import { cloneDeep, omitBy } from "lodash";
import {
  AccountingBalanceSheet,
  AccountingBalanceSheetUpdate,
  BalanceCategories,
  Direction,
  getGlobalAccountFromAccountWithReferenceCounter,
  getMoment,
  LedgerAccountEnum,
  NewPartner,
  Partner,
  PartnerRole,
  PartnerTypeEnum,
  PartnerUpdate,
  TaxRegime,
  getBalancePartners,
  FunctionalError,
  EnumErrors,
  ACCOUNTING_BALANCE_SHEET_ERROR,
  countryISOcode,
} from "@edmp/api";

import {
  coreStore,
  productsStore,
  partnersStore,
  accountingPeriodsStore,
  accountingsStore,
  accountingBalanceSheetsStore,
} from "@/store";
import { debounce, onlyNumber } from "@/utils";
import { FeedbackTypeEnum } from "@/store/modules/Core.store";

import { useEmail } from "@/composables";
import { VForm } from "@/models";

import DatePicker from "@/components/atom/DatePicker.vue";
import Tag from "@/components/atom/Tag.vue";
import { AxiosError } from "axios";

const defaultPartner = {
  legalStatus: "",
  denomination: "",
  siret: "",
  lastName: "",
  firstName: "",
  birthDate: "",
  email: "",
  address: {
    street: "",
    city: "",
    zip: "",
  },
  birthAddress: {
    place: "",
    department: 0,
    country: "FR",
  },
  partsPP: 0,
  partsUF: 0,
  partsNP: 0,
  partsSocial: 0,
  pctDetention: 0,
  role: PartnerRole.GRANTEE,
  type: PartnerTypeEnum.NATURAL_PERSON,
};

export default defineComponent({
  name: "PartnerModal",
  components: {
    DatePicker,
    Tag,
  },
  props: {
    selectedPartner: {
      type: Object as PropType<(Partner | NewPartner) & { id?: string }>,
      default: function () {
        return defaultPartner;
      },
    },
  },

  setup(props, context) {
    const isTaxRegimeIs = computed(() => {
      return (
        accountingPeriodsStore.currentAccountingPeriod?.taxRegime ===
        TaxRegime.IS_2065
      );
    });
    const isTaxRegimeIr = computed(() => {
      return (
        accountingPeriodsStore.currentAccountingPeriod?.taxRegime ===
        TaxRegime.IR_2072
      );
    });
    const isTaxRegimeLmnp = computed(() => {
      return (
        accountingPeriodsStore.currentAccountingPeriod?.taxRegime ===
        TaxRegime.LMNP_2031
      );
    });

    const isCreating = ref(!props.selectedPartner.id);
    const isEditing = ref(false);
    const today = ref(format(new Date(), "yyyy-MM-dd"));
    const modalTitle = computed(
      () =>
        `${
          isCreating.value
            ? "Ajouter un associé"
            : isEditing.value
            ? PartnerTypeEnum.NATURAL_PERSON === partner.type
              ? `Modifier - ${partner.firstName} ${partner.lastName}`
              : `Modifier - ${partner.denomination}`
            : PartnerTypeEnum.NATURAL_PERSON === partner.type
            ? `Mon associé : ${partner.firstName} ${partner.lastName}`
            : `Mon associé : ${partner.denomination}`
        } `
    );

    /**
     * Partner
     */
    // In case of default partner/owner created, complete with defaultPartner
    const partner = reactive({
      ...defaultPartner,
      ...omitBy(props.selectedPartner, ["productId"]),
      partsPP: props.selectedPartner.partsPP,
      partsUF: props.selectedPartner.partsUF,
      partsNP: props.selectedPartner.partsNP,
      partsSocial: props.selectedPartner.partsSocial,
      pctDetention: props.selectedPartner.pctDetention,
    });

    const partsFiscales = computed(() => {
      return partner.partsPP + partner.partsUF;
    });

    const legalFormList: {
      text: string;
      value: string;
    }[] = [
      { text: "Personne Physique", value: PartnerTypeEnum.NATURAL_PERSON },
      { text: "Personne Morale", value: PartnerTypeEnum.LEGAL_PERSON },
    ];

    function closeDialog() {
      context.emit("close");
    }
    const todayFormated = moment().format("DD/MM/YYYY");

    const { emailLowerCase, validEmailRule } = useEmail();

    const validate = async () => {
      if ((context.refs.form as VForm).validate()) {
        try {
          if (props.selectedPartner.id) {
            await partnersStore.updatePartner({
              productId: productsStore.currentId,
              ...partner,
            } as Partner);
          } else {
            await partnersStore.createPartner({
              productId: productsStore.currentId,
              ...partner,
            });
          }
          try {
            await validatePartnerAccountBalance();
            context.emit("validate");
          } catch (err) {
            const error = err as AxiosError<FunctionalError>;
            if (
              error.response?.data.type === ACCOUNTING_BALANCE_SHEET_ERROR &&
              error.response?.data.error === EnumErrors.IsValidate
            ) {
              const message =
                "Si vous avez modifier les 'Nombre de parts', veuillez dévalider votre bilan et réessayer";
              coreStore.displayFeedback({
                message,
                type: FeedbackTypeEnum.ERROR,
              });
            } else {
              coreStore.displayFeedback({
                message: error.message,
                type: FeedbackTypeEnum.ERROR,
              });
            }
          }
          searchItemsStreetList.value = [];
          searchItemsCityList.value = [];
          searchItemsZipList.value = [];
          searchItemsPlaceList.value = [];
          searchItemsDepartmentList.value = [];
          partner.address.street = "";
          partner.address.city = "";
          partner.address.zip = "";
          partner.birthAddress.place = "";
          partner.birthAddress.department = 0;
        } catch (error) {
          console.error(error);
          const message =
            "Une erreur est survenue lors de la création de votre compte. L'adresse email utilisée est peut-être déjà associée à un compte.";
          coreStore.displayFeedback({
            message,
            type: FeedbackTypeEnum.ERROR,
          });
        }
      }
    };

    /**
     * Balance partner
     */
    const balancePartners: Ref<BalanceCategories["categories"]> = ref([]);
    function getBalance(partnerId) {
      const balancePartner = balancePartners.value.find(
        (category) => category.reference?.referredId === partnerId
      );
      if (balancePartner) {
        if (balancePartner.balanceDirection === Direction.credit) {
          return balancePartner.balance;
        } else {
          return -balancePartner.balance;
        }
      } else {
        return 0;
      }
    }

    // Balance partner recovery
    const isAllowEditingPartnerAccountBalanceRecovery = computed(
      () =>
        isEditing.value &&
        balanceSheetRecovery.value &&
        !balanceSheetRecovery.value.isValidated
    );

    const balanceSheetRecovery: Ref<
      (AccountingBalanceSheet & { type: "recovery" }) | undefined
    > = ref();
    const initBalanceSheetRecovery = () => {
      if (
        accountingBalanceSheetsStore.getPreviousYearAccountingBalanceSheet &&
        accountingBalanceSheetsStore.getPreviousYearAccountingBalanceSheet
          .type === "recovery" &&
        (partner as PartnerUpdate).id
      ) {
        const balanceSheetRecoveryTemp =
          accountingBalanceSheetsStore.getPreviousYearAccountingBalanceSheet;
        const balanceSheetRecoveryLinePartnerAccountBalance =
          balanceSheetRecoveryTemp.lines
            .filter((line) => {
              const account = line.reference
                ? getGlobalAccountFromAccountWithReferenceCounter(
                    line.account,
                    line.reference
                  )
                : line.account;
              return (
                account === LedgerAccountEnum.N455000 ||
                account === LedgerAccountEnum.N455010
              );
            })
            .find(
              (line) =>
                line.reference?.referredId === (partner as PartnerUpdate).id
            );
        if (balanceSheetRecoveryLinePartnerAccountBalance) {
          if (
            balanceSheetRecoveryLinePartnerAccountBalance.direction ===
            Direction.credit
          ) {
            balanceSheetRecoveryLinePartnerAccountBalanceAmount.value =
              balanceSheetRecoveryLinePartnerAccountBalance.amount;
          } else {
            balanceSheetRecoveryLinePartnerAccountBalanceAmount.value =
              -balanceSheetRecoveryLinePartnerAccountBalance.amount;
          }
        }
        balanceSheetRecoveryLinePartnerAccountBalanceEndDate.value = getMoment(
          balanceSheetRecoveryTemp.endAt
        ).format("L");
        balanceSheetRecovery.value = balanceSheetRecoveryTemp;
      }
    };
    watch(
      () => accountingBalanceSheetsStore.getPreviousYearAccountingBalanceSheet,
      () => {
        initBalanceSheetRecovery();
      },
      { deep: true }
    );
    const balanceSheetRecoveryLinePartnerAccountBalanceAmount = ref(0);
    const balanceSheetRecoveryLinePartnerAccountBalanceEndDate: Ref<
      string | undefined
    > = ref();
    const validatePartnerAccountBalance = async () => {
      if (balanceSheetRecovery.value) {
        const balanceSheetRecoveryLinePartnerAccountBalanceIndex =
          balanceSheetRecovery.value.lines.findIndex(
            (line) =>
              line.reference?.referredId === (partner as PartnerUpdate).id
          );
        if (balanceSheetRecoveryLinePartnerAccountBalanceIndex !== -1) {
          const balanceSheetRecoveryToSave: AccountingBalanceSheetUpdate =
            cloneDeep(balanceSheetRecovery.value);
          const balanceSheetRecoveryLinePartnerAccountBalanceAmountDecimal =
            new Decimal(
              balanceSheetRecoveryLinePartnerAccountBalanceAmount.value
            );
          balanceSheetRecoveryToSave.lines[
            balanceSheetRecoveryLinePartnerAccountBalanceIndex
          ].amount = balanceSheetRecoveryLinePartnerAccountBalanceAmountDecimal
            .abs()
            .toNumber();
          balanceSheetRecoveryToSave.lines[
            balanceSheetRecoveryLinePartnerAccountBalanceIndex
          ].direction = balanceSheetRecoveryLinePartnerAccountBalanceAmountDecimal.isPositive()
            ? Direction.credit
            : Direction.debit;

          await accountingBalanceSheetsStore.updateAccountingBalanceSheet(
            balanceSheetRecoveryToSave
          );
        }
      }
    };

    /* Adress */
    const searchItemsStreetList = ref([]);
    const searchItemsCityList = ref([]);
    const searchItemsZipList = ref([]);
    const searchItemsPlaceList = ref([]);
    const searchItemsDepartmentList = ref([]);

    const searchAddress = debounce((value: string, type: string) => {
      if (value.length > 2) {
        if (type === "street") {
          fetch(
            `https://api-adresse.data.gouv.fr/search/?q=${value}&limit=15&type=housenumber`
          )
            .then((response) => response.json())
            .then((data) => {
              const mappedData = data.features.map((feature) => {
                if (
                  feature.properties &&
                  feature.properties.name &&
                  feature.properties.postcode &&
                  feature.properties.city
                )
                  return {
                    text: `${feature.properties.name} ${feature.properties.postcode} - ${feature.properties.city}`,
                    value: feature.properties,
                  };
              });
              searchItemsStreetList.value = mappedData.filter(
                (mappedData) => !!mappedData
              );
            });
        } else if (type === "city" || type === "place") {
          fetch(
            `https://api-adresse.data.gouv.fr/search/?q=${value}&limit=15&type=municipality`
          )
            .then((response) => response.json())
            .then((data) => {
              const mappedData = data.features.map((feature) => {
                return {
                  text: `${feature.properties.city}`,
                  value: feature.properties,
                };
              });
              type === "city"
                ? (searchItemsCityList.value = mappedData.filter(
                    (mappedData) => !!mappedData
                  ))
                : (searchItemsPlaceList.value = mappedData.filter(
                    (mappedData) => !!mappedData
                  ));
            });
        } else if (type === "zip") {
          fetch(
            `https://api-adresse.data.gouv.fr/search/?q=${value}&limit=15&type=municipality`
          )
            .then((response) => response.json())
            .then((data) => {
              const mappedData = data.features.map((feature) => {
                return {
                  text: `${feature.properties.postcode}`,
                  value: feature.properties,
                };
              });
              searchItemsZipList.value = mappedData.filter(
                (mappedData) => !!mappedData
              );
            });
        } else if (type === "department") {
          fetch(`https://geo.api.gouv.fr/departements?nom=${value}&limit=5`)
            .then((response) => response.json())
            .then((data) => {
              const mappedData = data
                .map((feature) => {
                  return {
                    text: `${feature.code}`,
                    value: feature.code,
                  };
                })
                .map((test) => test.text);
              searchItemsDepartmentList.value = mappedData.filter(
                (mappedData) => !!mappedData
              );
            });
        }
      }
    }, 500);

    const updateAddress = (event, field, type) => {
      if (type === "updateAddress") {
        if (partner.address) {
          if (event.type === "keyup") {
            partner.address[field] = event.target.value;
          } else {
            if (field === "street") {
              partner.address.street = event.value.name;
              partner.address.city = event.value.city;
              partner.address.zip = event.value.postcode;
            } else if (field === "city") {
              partner.address.city = event.value.city;
              partner.address.zip = event.value.postcode;
            } else if (field === "zip") {
              partner.address.city = event.value.city;
              partner.address.zip = event.value.postcode;
            }
          }
        }
      } else if (type === "updateBirthAddress") {
        if (partner.birthAddress) {
          if (event.type === "keyup") {
            partner.birthAddress[field] = event.target.value;
          } else {
            if (field === "place") {
              partner.birthAddress.place = event.value.city;
              partner.birthAddress.department = event.value.context.slice(0, 2);
              partner.birthAddress.country = "FR";
            } else if (field === "department") {
              partner.birthAddress.department = event;
            } else if (field === "country") {
              partner.birthAddress.country = event.value;
            }
          }
        }
      }
    };

    const countryISOcodes = ref({
      countryISOcodes: Object.entries(countryISOcode).map(([key, value]) => ({
        text: value,
        value: key,
      })),
    });

    /**
     * Init
     */
    onBeforeMount(async () => {
      if (isCreating.value) {
        isEditing.value = true;
      }

      if (
        accountingPeriodsStore.currentId !==
        accountingPeriodsStore.lastAccountingPeriodNotClosed.id
      ) {
        const balanceCategories = await accountingsStore.getBalanceCategories({
          accountingPeriodId: accountingPeriodsStore.currentId,
        });
        balancePartners.value = getBalancePartners(balanceCategories);
      } else {
        const balanceCategories = await accountingsStore.getBalanceCategories({
          accountingPeriodId: accountingPeriodsStore.currentId,
        });
        balancePartners.value = getBalancePartners(balanceCategories);
      }

      await accountingBalanceSheetsStore.fetchAccountingBalanceSheets();
    });

    return {
      isCreating,
      isEditing,
      isTaxRegimeIs,
      isTaxRegimeIr,
      isTaxRegimeLmnp,
      closeDialog,
      validate,
      partsFiscales,
      validEmailRule,
      today,
      legalFormList,
      modalTitle,
      emailLowerCase: emailLowerCase(toRef(partner, "email")),
      partner,
      LEGAL_PERSON: PartnerTypeEnum.LEGAL_PERSON,
      NATURAL_PERSON: PartnerTypeEnum.NATURAL_PERSON,
      PartnerRole,
      todayFormated,
      getBalance,

      balanceSheetRecovery,
      balanceSheetRecoveryLinePartnerAccountBalanceAmount,
      balanceSheetRecoveryLinePartnerAccountBalanceEndDate,
      isAllowEditingPartnerAccountBalanceRecovery,

      onlyNumber,

      searchAddress,
      updateAddress,
      // updateBirthAddress,
      searchItemsStreetList,
      searchItemsCityList,
      searchItemsZipList,
      searchItemsDepartmentList,
      searchItemsPlaceList,
      countryISOcodes,
    };
  },
});
