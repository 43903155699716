







































import {
  accountingBalanceSheetsStore,
  accountingPeriodsStore,
  productsStore,
  tasksStore,
} from "@/store";
import {
  AccountingPeriod,
  getMoment,
  getReferredIdByTypeWithReferences,
  OperationAccrualLib,
  OperationAccrualsModel,
  TaskCode,
  TaskTypeReference,
} from "@edmp/api";
import {
  computed,
  defineComponent,
  onBeforeMount,
  PropType,
  ref,
  watch,
} from "@vue/composition-api";
import { cloneDeep } from "lodash";
import { TransactionState } from "../../../transactions/transaction/transaction.usable";
import { useAccruals } from "../accruals.usable";
import AccrualForm from "./AccrualForm.vue";

export default defineComponent({
  name: "AccrualCreate",
  props: {
    transactionState: {
      type: Object as PropType<TransactionState>,
      required: false,
    },
    periodChoice: {
      type: Boolean,
      required: false,
    },
  },
  components: {
    AccrualForm,
  },
  setup(props) {
    const { hasCompletedTaxTeletransmitTask2023 } = useAccruals();
    const accountingBalanceSheetRecovery = computed(() =>
      cloneDeep(
        accountingBalanceSheetsStore
          .getAccountingBalanceSheetByType("recovery")
          .find(
            (accountingBalanceSheet) =>
              accountingBalanceSheet.type === "recovery" &&
              getMoment(accountingBalanceSheet.endAt).isSame(
                getMoment(
                  accountingPeriodsStore.firstAccountingPeriodNotClosed.endAt
                ).subtract(1, "year")
              )
          )
      )
    );
    const getRecoveryText = () => {
      let date;
      if (hasCompletedTaxTeletransmitTask2023()) {
        const accountingPeriod2024 =
          accountingPeriodsStore.accountingPeriods.find(
            (accountingPeriod) =>
              getMoment(accountingPeriod.endAt).year() === 2024
          );
        date = getMoment(accountingPeriod2024?.startAt);
      } else {
        date = getMoment(accountingPeriodsStore.firstAccountingPeriod.startAt);
      }
      const formattedDate = date.format("DD/MM/YYYY");
      return `Avant le ${formattedDate}`;
    };

    const periodChoiceStep = ref(props.periodChoice);
    const accountingPeriodList = computed(() =>
      accountingPeriodsStore.accountingPeriods
        .filter((accountingPeriod) => {
          if (
            getMoment(accountingPeriod.endAt).year() === 2023 &&
            hasCompletedTaxTeletransmitTask2023()
          ) {
            return false;
          }
          const accrualsInventoryTask = tasksStore.notCompletedTasks.find(
            (task) =>
              getReferredIdByTypeWithReferences(
                task.references,
                TaskTypeReference.accountingPeriod
              ) === accountingPeriod.id &&
              task.code === TaskCode.AccrualsInventory
          );
          return accrualsInventoryTask && !accountingPeriod.closed;
        })
        .map((accountingPeriod) => {
          const startDate = getMoment(accountingPeriod.startAt);
          const endDate = getMoment(accountingPeriod.endAt);

          const startFormatted = startDate.format("DD/MM/YYYY");
          const endFormatted = endDate.format("DD/MM/YYYY");

          return {
            text: `${startFormatted} - ${endFormatted}`,
            value: accountingPeriod,
          };
        })
    );
    const recoveryPeriod = computed(() => {
      let addRecoveryPeriod = false;
      if (
        accountingBalanceSheetRecovery.value &&
        accountingPeriodsStore.accountingPeriods.some((accountingPeriod) => {
          const accrualsInventoryTask = tasksStore.notCompletedTasks.find(
            (task) =>
              getReferredIdByTypeWithReferences(
                task.references,
                TaskTypeReference.accountingPeriod
              ) === accountingPeriodsStore.firstAccountingPeriod.id &&
              task.code === TaskCode.AccrualsInventory
          );
          return accrualsInventoryTask && !accountingPeriod.closed;
        })
      ) {
        addRecoveryPeriod = true;
      } else if (hasCompletedTaxTeletransmitTask2023()) {
        addRecoveryPeriod = true;
      }
      if (addRecoveryPeriod) {
        return [
          {
            text: getRecoveryText(),
            value: "Reprise",
          },
        ];
      }
      return [];
    });
    const periodList = computed(() => [
      ...accountingPeriodList.value,
      ...recoveryPeriod.value,
    ]);
    watch(periodList, () => {
      if (periodList.value.length === 1) {
        periodSelected.value = periodList.value[0].value as
          | AccountingPeriod
          | "Reprise";
      }
    });
    const periodSelected = ref<AccountingPeriod | "Reprise">();
    const accrualType = computed<
      OperationAccrualsModel.Tags.RECOVERY | undefined
    >(() =>
      periodSelected.value === "Reprise"
        ? OperationAccrualsModel.Tags.RECOVERY
        : undefined
    );
    const transactionState = computed(() => props.transactionState);
    const account = computed(() => {
      if (
        accrualType.value === OperationAccrualsModel.Tags.RECOVERY &&
        transactionState.value?.isOpenReconciliation &&
        transactionState.value?.selectedCategory
      ) {
        return OperationAccrualLib.getDoublyEntryAccountFromReconciliationAccount(
          transactionState.value.selectedCategory.number
        );
      }
    });

    const validatePeriodChoice = () => {
      periodChoiceStep.value = false;
    };

    onBeforeMount(async () => {
      if (props.periodChoice) {
        await accountingPeriodsStore.fetchAccountingPeriods(
          productsStore.currentId
        );
      }
    });

    return {
      periodList,
      periodSelected,
      periodChoiceStep,
      accrualType,
      account,
      validatePeriodChoice,
    };
  },
});
