



































































































































































































import { operationAccrualsStore, productsStore } from "@/store";
import {
  computed,
  defineComponent,
  onBeforeMount,
  PropType,
  ref,
  watch,
} from "@vue/composition-api";
import {
  TransactionState,
  useTransaction,
} from "../../transactions/transaction/transaction.usable";
import { LedgerAccountEnum } from "@edmp/api";
import { useAccruals } from "./accruals.usable";
import AccrualCreate from "./create/AccrualCreate.vue";

export default defineComponent({
  name: "AccrualReconcile",
  props: {
    transactionState: {
      type: Object as PropType<TransactionState>,
      required: true,
    },
  },
  components: { AccrualCreate },
  setup(props, context) {
    const {
      accrualItems,
      initAccrualItems,
      getAttributeDisplay,
      MISSING_VALUE,
      openArticleAccountingAccrual,
    } = useAccruals();
    const { refreshTransaction } = useTransaction(
      props.transactionState,
      context
    );
    const isOpenAddAccrual = ref(false);
    watch(
      () => accrualItems.value,
      (val) => {
        if (!val.length) {
          isOpenAddAccrual.value = true;
        } else {
          isOpenAddAccrual.value = false;
        }
      }
    );
    watch(
      () => operationAccrualsStore.operationAccruals,
      (newVal, oldVal) => {
        if (newVal.length !== oldVal.length) {
          initAccrualItems();
        }
      }
    );
    const computedAvatar = computed(() => {
      if (
        props.transactionState.transaction.value.amount &&
        props.transactionState.transaction.value.amount >= 0
      ) {
        return "#b8d7fb";
      } else {
        return "#7085d1";
      }
    });
    const filteredAttributes = (attributes) => {
      return attributes.filter(
        (attribute) => getAttributeDisplay(attribute).name !== MISSING_VALUE
      );
    };
    const selectedAccrualItemId = ref();
    const validate = async () => {
      if (selectedAccrualItemId) {
        await operationAccrualsStore.reconcile({
          transactionId: props.transactionState.transaction.id,
          operationAccrualId: selectedAccrualItemId.value,
        });
        props.transactionState.isOpenReconciliation = false;
        await refreshTransaction();
      }
    };
    onBeforeMount(async () => {
      await operationAccrualsStore.fetchOperationAccruals({
        productId: productsStore.currentId,
        options: { includedReconciled: false },
        transactionId: props.transactionState.transaction.id,
      });
      await initAccrualItems();
    });

    return {
      accrualItems,
      selectedAccrualItemId,
      computedAvatar,
      isOpenAddAccrual,
      LedgerAccountEnum,
      filteredAttributes,
      getAttributeDisplay,
      openArticleAccountingAccrual,
      validate,
    };
  },
});
