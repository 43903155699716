



































































import AccountingPeriodChoice from "@/components/core/accounting-period/AccountingPeriodChoice.vue";
import Balance from "@/components/core/charts/Balance.vue";
import FinancesChart from "@/components/core/charts/FinancesChart.vue";
import TaskGroupResumeNavigateTo from "@/components/core/tasks/TaskGroupResumeNavigateTo.vue";
import { ROUTE_NAMES } from "@/router/routes";
import {
  accountingPeriodsStore,
  realEstateAssetsStore,
  tasksStore,
} from "@/store";
import { filteredTransactions } from "@/utils";
import {
  isReportExercise,
  TaskStatus,
  TaskGroupCode,
  TaxRegime,
} from "@edmp/api";
import { computed, defineComponent, onBeforeMount } from "@vue/composition-api";
import { cloneDeep } from "lodash";
import useAccounting from "../accounting/useAccounting";
import DocumentsOverview from "./documents/DocumentsOverview.vue";
import EventsOverview from "./events/EventsOverview.vue";
import TransactionsOverview from "./transactions/TransactionsOverview.vue";
import Title from "../title/Title.vue";

export default defineComponent({
  name: "Dashboard",
  components: {
    FinancesChart,
    Balance,
    EventsOverview,
    AccountingPeriodChoice,
    TaskGroupResumeNavigateTo,
    TransactionsOverview,
    DocumentsOverview,
    Title,
  },
  setup(_, context) {
    const transactionsFiltered = computed(() => filteredTransactions());

    // Tasks to do
    const taskGroupCodes = computed(() => {
      const defaultTask = [
        TaskGroupCode.StartWellLMNP,
        TaskGroupCode.StartWell,
        TaskGroupCode.StartWellInformation,
        TaskGroupCode.StartWellAccounting,
        TaskGroupCode.StartWellAccountingLMNP,
      ];
      if (accountingPeriodsStore.currentAccountingPeriod?.openAt) {
        defaultTask.push(TaskGroupCode.EventAccountingPeriodClosure);
      }
      return defaultTask;
    });

    const transactionsFilteredChart = computed(() => {
      return cloneDeep(filteredTransactions())?.map((transaction) => {
        if (transaction.operations?.journalEntry?.lines) {
          transaction.operations.journalEntry.lines =
            transaction.operations.journalEntry.lines.filter(
              (line) => !isReportExercise(line.account)
            );
        }
        return transaction;
      });
    });
    const balance = computed(() => {
      if (accountingPeriodsStore.currentAccountingPeriod) {
        return useAccounting.getBalance(
          accountingPeriodsStore.currentAccountingPeriod,
          transactionsFiltered.value
        );
      }
    });

    const balanceChart = computed(() => {
      if (accountingPeriodsStore.currentAccountingPeriod) {
        return useAccounting.getBalance(
          accountingPeriodsStore.currentAccountingPeriod,
          transactionsFilteredChart.value
        );
      }
    });
    const taskGroupCompleted = computed(() => {
      if (
        tasksStore.startWellTaskGroup.every(
          (group) => group.status === TaskStatus.COMPLETED
        )
      ) {
        return true;
      }
    });

    onBeforeMount(async () => {
      if (
        accountingPeriodsStore.currentAccountingPeriod?.taxRegime ===
        TaxRegime.LMNP_2031
      ) {
        if (realEstateAssetsStore.realEstateAssets.length === 0) {
          context.root.$router.push({
            name: ROUTE_NAMES.RealEstateAssets,
          });
        }
      }
    });

    return {
      taskGroupCodes,
      balance,
      balanceChart,
      ROUTE_NAMES,
      taskGroupCompleted,
    };
  },
});
