import { model, Schema, Document as DocumentMongoose } from "mongoose";
import { ulid } from "ulid";
import { RequireField, PartialField } from "..";
import { Metadata } from "aws-sdk/clients/s3";

import {
  SupportingDocumentTags,
  SupportingDocuments,
  SupportingDocumentsSchema,
  SupportingDocumentsJsonSchema,
} from "./Document.SupportingDocuments.model";

/*
 * `DocumentType` — The MIME type of the file
 */
export type DocumentType = "application/pdf" | "image/jpeg" | "image/png";

export enum DocumentFileType {
  PDF = "pdf",
  DOCX = "docx"
}
type MimeType = DocumentType | "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
export const mimeTypes: { [key in DocumentFileType]: MimeType } = {
  pdf: "application/pdf",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
}

export type DocumentTag =
  | "rental-agreement" // Lié à un bail
  | "tenant" // Lié à un locataire
  | "receipt" // Reçu d'un loyer
  | "transaction" // Lié à une transaction
  | "supporting" // Document justificatif IR
  | "event" // Document lié à un événement
  | "product" // Document lié à une société
  | "tax" // Document lié à une taxe
  | "accounting" // Document lié à la comptabilité
  | "accountingBalanceSheet" // Document lié à la balance comptable
  | "report" // Rapport
  | "year-end" // Clôture d'exercice
  | "invite" // Invitation
  | "real-estate-asset"
  | "real-estate-asset-image"
  | "signature-image"
  | "logo-image"
  | "notice-rent-indexation"
  | "notice-regularization"
  | SupportingDocumentTags;

export interface SearchDocumentsCriteria {
  name?: string;
  summary?: string;
  tags?: Array<DocumentTag>;
  product?: {
    id?: string;
    accountingPeriod?: {
      id?: string;
    };
    partner?: {
      id: string;
    };
    realEstateAsset?: {
      id?: string;
      rentalUnit?: {
        id: string;
      };
      rentalAgreement?: {
        id: string;
      };
    };
    tenant?: {
      id: string;
    }
  };
  user?: {
    id: string;
  };
  bankAccount?: {
    id?: string;
    transaction?: {
      id: string;
    };
  };
  rentPeriod?: string;
  generaleAssembly?: {
    id: string;
  };
  metadata?: Partial<SupportingDocuments>;
}

export interface DocumentStorage {
  type: string;
  Bucket: string;
  Key: string;
  contenttype: string;
}
export interface FileInfoMetaData extends Metadata {
  // Need to be in lowercase !!
  name: string;
  filename: string;
  path: string;
  size: string;
}

export type Document = {
  id: string;
  type: DocumentType;
  size: number;
  createdBy: string;
  href: string;
  storage?: DocumentStorage;
  createdAt: string;
  updatedAt: string;
} & RequireField<SearchDocumentsCriteria, "name">;

export type DocumentCreate = RequireField<
  PartialField<
    Omit<Document, "id" | "type" | "size" | "href" | "storage" | "createdAt" | "updatedAt">,
    "name" | "createdBy"
  > & {
    product: RequireField<RequireField<Document, "product">["product"], "id">;
  },
  "product"
>;
export type DocumentCreateInternal = Omit<Document, "id" | "href" | "createdAt" | "updatedAt">;

export type DocumentUpdate = RequireField<Partial<Omit<Document, "createdAt" | "updatedAt">>, "id">;

export interface FileInfo {
  fieldName: string;
  originalFilename: string;
  path: string;
  headers: {
    "content-disposition": string;
    "content-type": string;
  };
  size: number;
}

export const SearchDocumentsCriteriaJsonSchema = {
  type: "object",
  additionalProperties: false,
  description: "Search Document Criteria",
  properties: {
    name: { type: "string" },
    summary: { type: "string" },
    tags: {
      type: "array",
      items: { type: "string" },
    },
    product: {
      type: "object",
      properties: {
        id: { type: "string" },
        accountingPeriod: {
          type: "object",
          properties: {
            id: { type: "string" },
          },
        },
        partner: {
          type: "object",
          properties: {
            id: { type: "string" },
          },
        },
        realEstateAsset: {
          type: "object",
          properties: {
            id: { type: "string" },
            rentalUnit: {
              type: "object",
              properties: {
                id: { type: "string" },
              },
            },
            rentalAgreement: {
              type: "object",
              properties: {
                id: { type: "string" },
              },
            },
          },
        },
      },
      user: {
        type: "object",
        properties: {
          id: { type: "string" },
        },
      },
      bankAccount: {
        type: "object",
        properties: {
          id: { type: "string" },
          transaction: {
            type: "object",
            properties: {
              id: { type: "string" },
            },
          },
        },
      },
      rentPeriod: { type: "string" },
      generaleAssembly: {
        id: { type: "string" },
      },
    },
    metadata: {
      type: "object",
      properties: SupportingDocumentsJsonSchema,
    },
  },
};

export const DocumentJsonSchema = {
  type: "object",
  additionalProperties: false,
  description: "Document",
  properties: {
    _id: { type: "string" },
    type: { type: "string" },
    size: { type: "number" },
    createdBy: { type: "string" },
    createdAt: {
      type: "string",
      pattern: "^$|^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}",
      description: "ISO8601 date and time of creation for this task",
    },
    updatedAt: {
      type: "string",
      pattern: "^$|^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}",
      description: "ISO8601 date and time of creation for this task",
    },
    storage: {
      type: "object",
      properties: {
        type: { type: "string" },
        bucket: { type: "string" },
        key: { type: "string" },
        contentType: { type: "string" },
      },
    },
    href: { type: "string" },
    ...SearchDocumentsCriteriaJsonSchema.properties,
  },
};

// Défini ici afin que `type` soit une clé et non la définition d'un type d'élément
const DocumentStorageSchema = new Schema<DocumentStorage>(
  {
    type: String,
    Bucket: String,
    Key: String,
    contenttype: String,
  },
  { _id: false }
);

const DocumentSchema = new Schema<DocumentDocument>(
  {
    _id: { type: String, default: () => ulid() },
    type: String,
    size: Number,
    createdBy: String,
    createdAt: String,
    updatedAt: String,
    storage: DocumentStorageSchema,
    href: String,
    name: String,
    summary: String,
    tags: Array,
    product: {
      id: { type: String, index: true },
      accountingPeriod: {
        id: { type: String, index: true },
      },
      partner: {
        id: { type: String, index: true },
      },
      realEstateAsset: {
        id: { type: String, index: true },
        rentalUnit: {
          id: { type: String, index: true },
        },
        rentalAgreement: {
          id: { type: String, index: true },
        },
      },
    },
    user: {
      id: { type: String, index: true },
    },
    bankAccount: {
      id: { type: String, index: true },
      transaction: {
        id: { type: String, index: true },
      },
    },
    rentPeriod: { type: String, index: true },
    generaleAssembly: {
      id: { type: String, index: true },
    },
    metadata: SupportingDocumentsSchema,
  },
  {
    timestamps: true,
    toJSON: {
      versionKey: false,
      virtuals: true,
      transform(_, ret: Document & { _id?: string }): Document {
        ret.id = ret._id!; // We assume there is always an _id
        delete ret._id;
        return ret;
      },
    },
  }
);

export type DocumentDocument = Document & DocumentMongoose;

// Name of the collection in third argument
export const DocumentModel = model<DocumentDocument>("Document", DocumentSchema, "Documents");

// API
export namespace DocumentsService {
  export type CreateIn = { document: DocumentCreate; file: FileInfo };
  export type CreateOut = Document;

  export type ListIn = { productId?: string; accountingPeriodId?: string };
  export type ListOut = Document[];

  export type GetIn = Pick<Document, "id">;
  export type GetOut = Document;

  export type AddAttachmentTransactionIn = {
    document: RequireField<
      PartialField<DocumentCreate, "product"> & {
        bankAccount: Required<DocumentCreate["bankAccount"]>;
      },
      "bankAccount"
    >;
    file: FileInfo;
  };
  export type AddAttachmentTransactionOut = Document;

  export type DeleteIn = Pick<Document, "id">;
  export type DeleteOut = boolean;
}
