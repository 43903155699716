








































































































































































































































































































































































































import eventBus from "@/events/eventBus";
import router from "@/router";
import { ROUTE_NAMES } from "@/router/routes";
import {
  fixedAssetsStore,
  productsStore,
  realEstateAmortisationsStore,
  realEstateAssetsStore,
  realEstateLoansStore,
  rentalAgreementsStore,
  subscriptionsStore,
} from "@/store";
import {
  computed,
  defineComponent,
  onBeforeMount,
  PropType,
  ref,
  Ref,
  watch,
} from "@vue/composition-api";
import PageHeader from "../../atom/PageHeader.vue";
import RealEstateAmortisationLMNP from "./RealEstateAmortisationLMNP.vue";
import RealEstateForm from "./RealEstateForm.vue";
import RealEstateLoanLMNP from "./RealEstateLoanLMNP.vue";
import RealEstateFixedAssetLMNP from "./RealEstateFixedAssetLMNP.vue";
import RealEstateCongratsModal from "./RealEstateCongratsModal.vue";
import RealEstateRentLMNP from "./RealEstateRentLMNP.vue";
import { ProductsModel, RentalUsage, SubscriptionsModel } from "@edmp/api";

export default defineComponent({
  name: "RealEstateLMNP",
  components: {
    PageHeader,
    RealEstateForm,
    RealEstateAmortisationLMNP,
    RealEstateLoanLMNP,
    RealEstateFixedAssetLMNP,
    RealEstateCongratsModal,
    RealEstateRentLMNP,
  },
  props: {
    productId: {
      type: String,
      required: true,
    },
    realEstateAssetId: {
      type: String as PropType<string>,
      required: true,
    },
    rentalUnitId: { type: String, required: false },
    realEstateAmortisationId: { type: String, required: false },
    rentalAgreementId: { type: String, required: false },
    tabIndex: { type: String, required: false },
  },
  setup(props, context) {
    const tabItemIndex = ref(props.tabIndex ? parseInt(props.tabIndex) : 0);

    const isLoading = ref(true);
    const realEstateAssetId = ref(props.realEstateAssetId);
    const realEstateAsset = computed(() =>
      realEstateAssetsStore.getRealEstateAsset(realEstateAssetId.value)
    );
    const realEstateLoansList = computed(() => {
      if (realEstateAsset.value) {
        return realEstateLoansStore.getRealEstateLoansByRealEstateAssetId(
          realEstateAsset.value?.id
        );
      }
      return [];
    });
    const fixedAssetList = computed(() => {
      if (realEstateAsset.value) {
        return fixedAssetsStore.getFixedAssetsByRealEstateAssetId(
          realEstateAsset.value?.id
        );
      }
      return [];
    });
    const realEstateAmortisationList = computed(() =>
      realEstateAmortisationsStore.getRealEstateAmortisationsByRealEstateAssetId(
        realEstateAsset.value?.id || props.realEstateAssetId
      )
    );
    const isAmortisationExist = computed(
      () => realEstateAmortisationList.value.length !== 0
    );
    const rentalAgreementList = computed(() =>
      rentalAgreementsStore.getRentalAgreementsByRealEstateAssetId(
        realEstateAsset.value?.id || props.realEstateAssetId
      )
    );
    const rentalUnit = computed(() =>
      realEstateAssetsStore.getRentalUnitByRealEstateAssetId(
        realEstateAssetId.value
      )
    );
    const hasRealEstateAssetRentalUsageLongTerm = computed(
      () => rentalUnit.value.rentalUsage === RentalUsage.LONG_TERM_RENTAL
    );
    const subscriptionPlanType = computed(
      () =>
        subscriptionsStore.getSubscriptionByProduct(props.productId)?.plan.type
    );

    const isEditable: Ref<boolean> = ref(false);
    const isNew: Ref<boolean> = ref(realEstateAssetId.value === "new");
    const hasAccessMyRentalAgreementMenu = computed(() => {
      if (!isNew.value) {
        if (
          productsStore.currentProduct
            ? productsStore.currentProduct.tags.includes(
                ProductsModel.Tags.IMMOXYGEN
              )
            : false
        ) {
          return false;
        }
        return (
          (subscriptionPlanType.value &&
            ((subscriptionPlanType.value === SubscriptionsModel.PlanType.Free &&
              hasRealEstateAssetRentalUsageLongTerm) ||
              subscriptionPlanType.value ===
                SubscriptionsModel.PlanType.LMNPOptimum)) ||
          rentalAgreementList.value.length
        );
      }
      return false;
    });

    const openLoan: Ref<boolean> = ref(false);
    const openAmortisation: Ref<boolean> = ref(isAmortisationExist.value);
    const openFixedAsset: Ref<boolean> = ref(false);
    const openRental: Ref<boolean> = ref(false);
    const openCongratsDialog: Ref<boolean> = ref(false);

    const setEditing = (val: boolean) => (isEditable.value = val);

    const goToOwnily = () => {
      eventBus.$emit("menuDisabled", false);
      router.push({
        name: ROUTE_NAMES.Transactions,
      });
    };
    const goToAddAnotherAsset = async () => {
      eventBus.$emit("menuDisabled", false);
      openCongratsDialog.value = false;
      await init(true);
      tabItemIndex.value = 0;
      realEstateAssetId.value = "new";
      openAmortisation.value = false;
      openFixedAsset.value = false;
      openRental.value = false;
      openLoan.value = false;
      setEditing(true);
    };

    const closeFlow = () => {
      if (realEstateAssetsStore.realEstateAssets.length > 1) {
        router.push({ name: ROUTE_NAMES.RealEstateAssets });
      } else {
        openCongratsDialog.value = true;
      }
    };

    watch(
      () => productsStore.currentId,
      () => context.root.$router.push({ name: ROUTE_NAMES.RealEstateAssets }),
      { deep: true }
    );

    // Init
    const init = async (isRenew: boolean) => {
      isLoading.value = true;
      await productsStore.switchProduct({ id: props.productId });
      if (
        (!isRenew && realEstateAsset.value?.id) ||
        (props.realEstateAssetId && realEstateAsset.value?.id) ||
        props.realEstateAssetId !== "new"
      ) {
        try {
          await realEstateAssetsStore.fetchRealEstateAsset({
            realEstateAssetId:
              realEstateAsset.value?.id || props.realEstateAssetId,
            productId: productsStore.currentId,
          });
        } catch (err) {
          console.error(err);
          context.root.$router.push({ name: ROUTE_NAMES.RealEstateAssets });
        }
        isNew.value = false;
        isEditable.value = false;
      } else {
        isNew.value = true;
        isEditable.value = true;
      }
      isLoading.value = false;
    };

    watch(
      () => [props],
      async () => await init(false),
      {
        deep: true,
      }
    );

    onBeforeMount(async () => await init(false));

    const validateCreation = (newRealEstateAssetId) => {
      realEstateAssetId.value = newRealEstateAssetId;
      if (isNew.value) {
        tabItemIndex.value = 1;
      }
    };
    const cancelCreation = async () => {
      if (isNew.value) {
        if (realEstateAsset.value) {
          await realEstateAssetsStore.deleteRealEstateAsset(
            realEstateAsset.value?.id
          );
        }
        router.push({ name: ROUTE_NAMES.RealEstateAssets });
      }
    };

    const displayDeleteModal = ref(false);
    async function deleteRealEstateAsset(realEstateAssetId: string) {
      await realEstateAssetsStore.deleteRealEstateAsset(realEstateAssetId);
      displayDeleteModal.value = false;
      eventBus.$emit("closeRealEstateAssetModal");
      router.push({ name: ROUTE_NAMES.RealEstateAssets });
    }

    return {
      ROUTE_NAMES,
      realEstateAsset,
      realEstateLoansList,
      fixedAssetList,
      rentalAgreementList,
      isEditable,
      isLoading,
      isNew,
      closeFlow,
      setEditing,
      validateCreation,
      cancelCreation,
      openLoan,
      openAmortisation,
      openFixedAsset,
      openRental,
      openCongratsDialog,
      goToOwnily,
      goToAddAnotherAsset,
      tabItemIndex,
      displayDeleteModal,
      deleteRealEstateAsset,
      realEstateAssetsStore,
      subscriptionsStore,
      hasAccessMyRentalAgreementMenu,
      previousTab: () => tabItemIndex.value--,
      nextTab: () => tabItemIndex.value++,
    };
  },
});
