





























































































import { getMoment, NatureLabel, TaxRegime } from "@edmp/api";
import { computed, defineComponent } from "@vue/composition-api";

import { accountingPeriodsStore, realEstateAssetsStore } from "@/store";

import Tag from "@/components/atom/Tag.vue";
import { HelpingMessage } from "@/components/atom/helping";
import RealEstateAmortisationTable from "./RealEstateAmortisationTable.vue";
import { ArticleEnum, useCrisp } from "@/composables/crisp.usable";
import i18n from "@/plugins/i18n";

export default defineComponent({
  name: "RealEstateAmortisationModal",
  components: { Tag, HelpingMessage, RealEstateAmortisationTable },
  props: {
    realEstateAssetId: { type: String, required: true },
  },

  setup(props, context) {
    const accountingPeriod = computed(
      () => accountingPeriodsStore.currentAccountingPeriod
    );

    const realEstateAsset = computed(() =>
      realEstateAssetsStore.getRealEstateAsset(props.realEstateAssetId)
    );
    const realEstateAssetNature = computed(() =>
      realEstateAsset.value?.nature !== undefined
        ? NatureLabel[realEstateAsset.value.nature]
        : "inconnue"
    );
    const realEstateAssetBoughtPriceText = computed(() => {
      const price = realEstateAsset.value?.boughtPrice ?? 0;
      return price !== 0 ? i18n.n(price, "currency", "fr-FR") : "inconnue";
    });
    const realEstateAssetCommissioningAt = computed(() =>
      realEstateAsset.value?.commissioningAt !== undefined
        ? getMoment(realEstateAsset.value.commissioningAt).format("LL")
        : "inconnue"
    );

    const realEstateAssetEntryDateActivityLmnp = computed(() =>
      realEstateAsset.value?.entryDateActivityLmnp !== undefined
        ? getMoment(realEstateAsset.value.entryDateActivityLmnp).format("LL")
        : "inconnue"
    );

    function closeDialog() {
      context.emit("close");
    }

    return {
      accountingPeriod,
      TaxRegime,
      realEstateAssetNature,
      realEstateAssetBoughtPriceText,
      realEstateAssetCommissioningAt,
      realEstateAssetEntryDateActivityLmnp,
      closeDialog,

      openArticleAmortisationRecommendationAddLine: () =>
        useCrisp().openArticle(
          ArticleEnum.REAL_ESTATE_AMORTISATION_RECOMMENDATION_ADD_LINE
        ),
    };
  },
});
