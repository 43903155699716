































































import { defineComponent, ref } from "@vue/composition-api";
import PageHeader from "@/components/atom/PageHeader.vue";
import { ROUTE_NAMES } from "@/router/routes";
import IndexationResult from "@/components/core/rentalAgreements/IndexationResult.vue";
import { accountingPeriodsStore } from "@/store";
import IndexationHistory from "@/components/core/rentalAgreements/IndexationHistory.vue";

export default defineComponent({
  name: "Indexation",
  computed: {
    ROUTE_NAMES() {
      return ROUTE_NAMES;
    },
  },
  components: { IndexationHistory, IndexationResult, PageHeader },
  props: {
    productId: {
      type: String,
      required: true,
    },
    realEstateAssetId: {
      type: String,
      required: true,
    },
    rentalAgreementId: {
      type: String,
      required: true,
    },
    tabIndex: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const isLoading = ref(false);
    const tabItemIndex = ref(props.tabIndex ? parseInt(props.tabIndex) : 0);
    const goHistory = () => {
      tabItemIndex.value = 1;
    };

    return {
      tabItemIndex,
      isLoading,
      accountingPeriodsStore,
      goHistory,
    };
  },
});
