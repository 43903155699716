



































































































































































































































































































































































































































































































































































import {
  computed,
  defineComponent,
  onBeforeMount,
  PropType,
  Ref,
  ref,
  watch,
} from "@vue/composition-api";

import {
  accountingPeriodsStore,
  activitiesStore,
  coreStore,
  documentsStore,
  partnersStore,
  tasksStore,
} from "@/store";
import { taxDeclarationsService } from "@/services/TaxDeclarations.service";

import CustomLabelButton from "@/components/atom/button/CustomLabelButton.vue";
import DocumentCard from "@/components/core/documents/DocumentCard.vue";
import { YesNoSwitch } from "@/components/atom/switch";
import DatePicker from "@/components/atom/DatePicker.vue";
import { SubmitButton } from "@/components/atom/button";
import TitleNew from "@/components/core/title/TitleNew.vue";

import PDFViewer from "pdf-viewer-vue/dist/vue2-pdf-viewer";

import { debounce, downloadFileFromUrl } from "@/utils";
import {
  AccountingPeriod,
  getMoment,
  TasksService,
  TaskCode,
  TaxDeclarationsService,
  TaxDeclarationStatus,
  TaxRegime,
  BirthAddress,
} from "@edmp/api";
import { FeedbackTypeEnum } from "@/store/modules/Core.store";
import { TaskLocal, VForm } from "@/models";
import { countryISOcode } from "@edmp/api";

export default defineComponent({
  name: "TaxDeclarationValidate",
  components: {
    PDFViewer,
    CustomLabelButton,
    DocumentCard,
    YesNoSwitch,
    DatePicker,
    SubmitButton,
    TitleNew,
  },
  props: {
    taskLocal: {
      type: Object as PropType<TaskLocal>,
      required: false,
    },
    resolveTask: {
      type: Function as PropType<
        (
          task: TaskLocal,
          data: Pick<
            TasksService.ValidateIn,
            | TaskCode.FillCerfa2065
            | TaskCode.FillCerfa2031
            | TaskCode.FillCerfa2072
          >
        ) => Promise<void>
      >,
      required: false,
    },
  },
  setup(props, context) {
    const loading = ref(true);
    const loadingAdditionalInformations = ref(false);
    const loadingValidate = ref(false);
    const steps: Ref<1 | 2> = ref(1);
    const taxDeclaration = ref<TaxDeclarationsService.GetOut>();
    const pdf = ref<
      | {
          fileName: string;
          url: string;
        }
      | undefined
    >({ fileName: "", url: "" });
    const document = computed(() =>
      documentsStore.documents.find(
        (document) =>
          document.tags?.includes("tax") &&
          document.createdBy === "ownily" &&
          document.product?.accountingPeriod?.id ===
            accountingPeriodsStore.currentId &&
          document.id ===
            (
              taxDeclaration.value as unknown as
                | {
                    documentPdfCerfaId: string;
                  }
                | undefined
            )?.documentPdfCerfaId
      )
    );
    const currentAccountingPeriod = computed(
      () => accountingPeriodsStore.currentAccountingPeriod
    );

    const partners = computed(() => partnersStore.partners);
    const defaultAccountingPeriod = computed(() => ({
      [TaxRegime.IR_2072]: {
        startAt: `${
          currentAccountingPeriod.value
            ? getMoment(currentAccountingPeriod.value.startAt)
                .subtract(1, "year")
                .format("YYYY")
            : getMoment().subtract(1, "year").format("YYYY")
        }-01-01`,
        endAt: `${
          currentAccountingPeriod.value
            ? getMoment(currentAccountingPeriod.value.endAt)
                .subtract(1, "year")
                .format("YYYY")
            : getMoment().subtract(1, "year").format("YYYY")
        }-12-31`,
      },
      [TaxRegime.IS_2065]: {
        startAt: currentAccountingPeriod.value
          ? getMoment(currentAccountingPeriod.value.startAt)
              .subtract(1, "year")
              .format("YYYY-MM-DD")
          : getMoment().subtract(1, "year").format("YYYY-01-01"),
        endAt: currentAccountingPeriod.value
          ? getMoment(currentAccountingPeriod.value.endAt)
              .subtract(1, "year")
              .format("YYYY-MM-DD")
          : getMoment().subtract(1, "year").format("YYYY-12-31"),
      },
      [TaxRegime.LMNP_2031]: {
        startAt: currentAccountingPeriod.value
          ? getMoment(currentAccountingPeriod.value.startAt)
              .subtract(1, "year")
              .format("YYYY-MM-DD")
          : getMoment().subtract(1, "year").format("YYYY-01-01"),
        endAt: currentAccountingPeriod.value
          ? getMoment(currentAccountingPeriod.value.endAt)
              .subtract(1, "year")
              .format("YYYY-MM-DD")
          : getMoment().subtract(1, "year").format("YYYY-12-31"),
      },
    }));

    const initialPartners = ref(
      partners.value.map((p) => ({
        id: p.id,
        birthAddress: {
          place: p.birthAddress?.place ?? "",
          department: p.birthAddress?.department ?? 0,
          country: p.birthAddress?.country ?? "",
        },
        address: {
          street: p.address?.street ?? "",
          city: p.address?.city ?? "",
          zip: p.address?.zip ?? "",
        },
        pctDetention: p.pctDetention,
        partsSocial: p.partsSocial,
      }))
    );

    const additionalInformations = ref({
      hasPreviousAccountingPeriod: false,
      hasPreviousAddress: false,
      hasBirthAddress: false,
      countryISOcodes: Object.entries(countryISOcode).map(([key, value]) => ({
        text: value,
        value: key,
      })),
      rules: {
        exerciceStartDateMin: (): string | undefined => {
          if (currentAccountingPeriod.value) {
            const [year] = getMoment(
              additionalInformations.value.accountingPeriodPrevious.startAt
            )
              .subtract(1, "year")
              .format("YYYY-MM-DD")
              .split("-") ?? [undefined];
            const [month, day] = defaultAccountingPeriod.value[
              currentAccountingPeriod.value.taxRegime
            ].startAt
              .split("-")
              .slice(1);
            return `${year}-${month}-${day}`;
          }
        },
        exerciceStartDateMax: (): string | undefined => {
          if (currentAccountingPeriod.value) {
            const [year] =
              additionalInformations.value.accountingPeriodPrevious.endAt.split(
                "-"
              ) ?? [undefined];
            const [month, day] = defaultAccountingPeriod.value[
              currentAccountingPeriod.value.taxRegime
            ].endAt
              .split("-")
              .slice(1);
            return `${year}-${month}-${day}`;
          }
        },
        exerciceEndDateMin: (): string | undefined => {
          if (currentAccountingPeriod.value) {
            const [year] =
              additionalInformations.value.accountingPeriodPrevious.startAt.split(
                "-"
              ) ?? [undefined];
            const [month, day] = defaultAccountingPeriod.value[
              currentAccountingPeriod.value.taxRegime
            ].startAt
              .split("-")
              .slice(1);
            return `${year}-${month}-${day}`;
          }
        },
        exerciceEndDateMax: (): string | undefined => {
          if (currentAccountingPeriod.value) {
            const [year] =
              additionalInformations.value.accountingPeriodPrevious.endAt.split(
                "-"
              ) ?? [undefined];
            const [month, day] = defaultAccountingPeriod.value[
              currentAccountingPeriod.value.taxRegime
            ].endAt
              .split("-")
              .slice(1);
            return `${year}-${month}-${day}`;
          }
        },
        addressPreviousStreet: [
          () =>
            !!additionalInformations.value.addressPrevious.street ||
            "Le champ ne peut pas être vide",
        ],
        addressPreviousCity: [
          () =>
            !!additionalInformations.value.addressPrevious.city ||
            "Le champ ne peut pas être vide",
        ],
        addressPreviousZip: [
          () =>
            !!additionalInformations.value.addressPrevious.zip ||
            "Le champ ne peut pas être vide",
        ],
        partnerBirthAddressPlace: (partnerIndex: number) => [
          () =>
            !!additionalInformations.value.partners[partnerIndex].birthAddress
              .place || "Le champ ne peut pas être vide",
        ],
        partnerBirthAddressDepartment: (partnerIndex: number) => [
          () =>
            !!additionalInformations.value.partners[partnerIndex].birthAddress
              .department || "Le champ ne peut pas être vide",
          () =>
            additionalInformations.value.partners[partnerIndex].birthAddress
              .department !== 0 || "Veuillez renseigner un département valide",
        ],
        partnerBirthAddressCountry: (partnerIndex: number) => [
          () =>
            !!additionalInformations.value.partners[partnerIndex].birthAddress
              .country || "Le champ ne peut pas être vide",
        ],
        partnerInformationsStreet: (partnerIndex: number) => [
          () =>
            !!additionalInformations.value.partnerInformations[partnerIndex]
              .address.street || "Le champ ne peut pas être vide",
        ],
        partnerInformationsCity: (partnerIndex: number) => [
          () =>
            !!additionalInformations.value.partnerInformations[partnerIndex]
              .address.city || "Le champ ne peut pas être vide",
        ],
        partnerInformationsZip: (partnerIndex: number) => [
          () =>
            !!additionalInformations.value.partnerInformations[partnerIndex]
              .address.zip || "Le champ ne peut pas être vide",
        ],
      },
      accountingPeriodPrevious: {
        startAt: currentAccountingPeriod.value
          ? defaultAccountingPeriod.value[
              currentAccountingPeriod.value.taxRegime
            ].startAt
          : "",
        endAt: currentAccountingPeriod.value
          ? defaultAccountingPeriod.value[
              currentAccountingPeriod.value.taxRegime
            ].endAt
          : "",
      },
      addressPrevious: {
        street: "",
        city: "",
        zip: "",
      },
      partners: [] as (Pick<
        NonNullable<
          TaxDeclarationsService.ExportIn<TaxRegime.IS_2065>["partners"]
        >[number],
        "id"
      > & {
        birthAddress: Partial<
          NonNullable<
            TaxDeclarationsService.ExportIn<TaxRegime.IS_2065>["partners"]
          >[number]["birthAddress"]
        >;
      })[],
      partnerInformations: [
        {
          id: "",
          address: { street: "", city: "", zip: "" },
          pctDetention: 0,
          partsSocial: 0,
        },
      ],
    });

    // Methods
    const getPdf = async () => {
      try {
        const taxDeclarationsPdf = await taxDeclarationsService.downloadReport({
          accountingPeriodId: accountingPeriodsStore.currentId,
          accountingPeriodPrevious:
            additionalInformations.value.accountingPeriodPrevious,
          ...(additionalInformations.value.addressPrevious.street &&
          additionalInformations.value.addressPrevious.city &&
          additionalInformations.value.addressPrevious.zip
            ? { addressPrevious: additionalInformations.value.addressPrevious }
            : {}),
          ...(currentAccountingPeriod.value?.taxRegime === TaxRegime.IS_2065
            ? {
                partners: additionalInformations.value.partners,
              }
            : {}),
        });
        if (taxDeclarationsPdf) {
          pdf.value = {
            ...taxDeclarationsPdf,
          };
        }
        loadingAdditionalInformations.value = false;
      } finally {
        loadingAdditionalInformations.value = false;
      }
    };
    const getPartnerName = (p) => {
      const partner = partners.value.find(({ id }) => id === p.id);
      if (partner) {
        return `${partner.firstName} ${partner.lastName}`;
      }
    };
    const setAccountingPeriodPrevious = () => {
      if (!currentAccountingPeriod.value?.firstYear) {
        const accountingPeriodsSort = [
          ...accountingPeriodsStore.accountingPeriods,
        ].sort((a, b) => getMoment(a.endAt).diff(b.endAt));
        const currentAccountingPeriodIndex = accountingPeriodsSort.findIndex(
          ({ id }) => id === accountingPeriodsStore.currentId
        );
        const previousAccountingPeriod = accountingPeriodsSort[
          currentAccountingPeriodIndex - 1
        ] as AccountingPeriod | undefined;
        if (previousAccountingPeriod) {
          additionalInformations.value.accountingPeriodPrevious = {
            startAt: previousAccountingPeriod.startAt,
            endAt: previousAccountingPeriod.endAt,
          };
          additionalInformations.value.hasPreviousAccountingPeriod = true;
        }
      }
    };
    const setPartnersBirthAddress = () => {
      const partnersCopy = [...partners.value];
      additionalInformations.value.partners = partnersCopy.map((p) => ({
        id: p.id,
        birthAddress: {
          place: p.birthAddress?.place ?? "",
          department: p.birthAddress?.department ?? 0,
          country: p.birthAddress?.country ?? "FR",
        },
      }));

      additionalInformations.value.partnerInformations = partnersCopy.map(
        (p) => ({
          id: p.id,
          address: {
            street: p.address?.street ?? "",
            city: p.address?.city ?? "",
            zip: p.address?.zip ?? "",
          },
          pctDetention: p.pctDetention ?? 0,
          partsSocial: p.partsSocial ?? 0,
        })
      );

      additionalInformations.value.hasBirthAddress = partnersCopy.some((p) => {
        if (
          p.birthAddress.place === "" ||
          p.birthAddress.department === 0 ||
          p.birthAddress.country === ""
        ) {
          return false;
        } else {
          return true;
        }
      });
    };

    const handleDownload = () => {
      if (pdf.value) {
        downloadFileFromUrl(pdf.value.url, pdf.value.fileName);
      }
    };
    /* Adress */
    const searchItemsStreetList = ref([]);
    const searchItemsCityList = ref([]);
    const searchItemsZipList = ref([]);
    const searchItemsPlaceList = ref([]);
    const searchItemsDepartmentList = ref([]);

    const searchAddress = debounce((value: string, type: string) => {
      if (value.length > 2) {
        if (type === "street") {
          fetch(
            `https://api-adresse.data.gouv.fr/search/?q=${value}&limit=15&type=housenumber`
          )
            .then((response) => response.json())
            .then((data) => {
              const mappedData = data.features.map((feature) => {
                if (
                  feature.properties &&
                  feature.properties.name &&
                  feature.properties.postcode &&
                  feature.properties.city
                )
                  return {
                    text: `${feature.properties.name} ${feature.properties.postcode} - ${feature.properties.city}`,
                    value: feature.properties,
                  };
              });
              searchItemsStreetList.value = mappedData.filter(
                (mappedData) => !!mappedData
              );
            });
        } else if (type === "city" || type === "place") {
          fetch(
            `https://api-adresse.data.gouv.fr/search/?q=${value}&limit=15&type=municipality`
          )
            .then((response) => response.json())
            .then((data) => {
              const mappedData = data.features.map((feature) => {
                return {
                  text: `${feature.properties.city}`,
                  value: feature.properties,
                };
              });
              type === "city"
                ? (searchItemsCityList.value = mappedData.filter(
                    (mappedData) => !!mappedData
                  ))
                : (searchItemsPlaceList.value = mappedData.filter(
                    (mappedData) => !!mappedData
                  ));
            });
        } else if (type === "zip") {
          fetch(
            `https://api-adresse.data.gouv.fr/search/?q=${value}&limit=15&type=municipality`
          )
            .then((response) => response.json())
            .then((data) => {
              const mappedData = data.features.map((feature) => {
                return {
                  text: `${feature.properties.postcode}`,
                  value: feature.properties,
                };
              });
              searchItemsZipList.value = mappedData.filter(
                (mappedData) => !!mappedData
              );
            });
        } else if (type === "department") {
          fetch(`https://geo.api.gouv.fr/departements?nom=${value}&limit=5`)
            .then((response) => response.json())
            .then((data) => {
              const mappedData = data
                .map((feature) => {
                  return {
                    text: `${feature.code}`,
                    value: feature.code,
                  };
                })
                .map((test) => test.text);
              searchItemsDepartmentList.value = mappedData.filter(
                (mappedData) => !!mappedData
              );
            });
        }
      }
    }, 500);

    const updateAddress = (event, field, type, index?) => {
      if (type === "updatePreviousAddress") {
        if (additionalInformations.value.addressPrevious) {
          if (event.type === "keyup") {
            additionalInformations.value.addressPrevious[field] =
              event.target.value;
          } else {
            if (field === "street") {
              additionalInformations.value.addressPrevious.street =
                event.value.name;
              additionalInformations.value.addressPrevious.city =
                event.value.city;
              additionalInformations.value.addressPrevious.zip =
                event.value.postcode;
            } else if (field === "city") {
              additionalInformations.value.addressPrevious.city =
                event.value.city;
              additionalInformations.value.addressPrevious.zip =
                event.value.postcode;
            } else if (field === "zip") {
              additionalInformations.value.addressPrevious.city =
                event.value.city;
              additionalInformations.value.addressPrevious.zip =
                event.value.postcode;
            }
          }
        }
      } else if (type === "updatePartnerInformations") {
        if (event.type === "keyup") {
          additionalInformations.value.partnerInformations[index].address[
            field
          ] = event.target.value;
        } else {
          if (field === "street") {
            additionalInformations.value.partnerInformations[
              index
            ].address.street = event.value.name;
            additionalInformations.value.partnerInformations[
              index
            ].address.city = event.value.city;
            additionalInformations.value.partnerInformations[
              index
            ].address.zip = event.value.postcode;
          } else if (field === "city") {
            additionalInformations.value.partnerInformations[
              index
            ].address.city = event.value.city;
            additionalInformations.value.partnerInformations[
              index
            ].address.zip = event.value.postcode;
          } else if (field === "zip") {
            additionalInformations.value.partnerInformations[
              index
            ].address.city = event.value.city;
            additionalInformations.value.partnerInformations[
              index
            ].address.zip = event.value.postcode;
          }
        }
      } else if (type === "updatePartnerBirthAddress") {
        if (event.type === "keyup") {
          additionalInformations.value.partners[index].birthAddress[field] =
            event.target.value;
        } else {
          if (field === "place") {
            additionalInformations.value.partners[index].birthAddress.place =
              event.value.city;
            additionalInformations.value.partners[
              index
            ].birthAddress.department = event.value.context.slice(0, 2);
            additionalInformations.value.partners[index].birthAddress.country =
              "FR";
          } else if (field === "department") {
            additionalInformations.value.partners[
              index
            ].birthAddress.department = event;
          } else if (field === "country") {
            additionalInformations.value.partners[index].birthAddress.country =
              event;
          }
        }
      }
    };

    const validateAdditionalInformations = async (e: Event) => {
      e.preventDefault();
      if ((context.refs.form as VForm).validate()) {
        loadingAdditionalInformations.value = true;
        loading.value = true;
        try {
          if (additionalInformations.value.hasPreviousAddress) {
            const activityId = await activitiesStore.currentId;
            await activitiesStore.updateActivity({
              id: activityId,
              address: additionalInformations.value.addressPrevious,
            });
          }
          for (const partner of partners.value) {
            const partnerInformationss =
              additionalInformations.value.partnerInformations.find(
                (p) => p.id === partner.id
              );
            const birthpartnerInformationss =
              additionalInformations.value.partners.find(
                (p) => p.id === partner.id
              );
            if (partnerInformationss?.pctDetention === undefined) {
              coreStore.displayFeedback({
                type: FeedbackTypeEnum.ERROR,
                message: `Veuillez renseigner la part de détention dans la fiche des associés`,
              });
            }
            await partnersStore.updatePartner({
              ...partner,
              address: partnerInformationss?.address,
              birthAddress:
                birthpartnerInformationss?.birthAddress as BirthAddress,
              pctDetention: partnerInformationss?.pctDetention ?? 0,
              partsSocial: partnerInformationss?.partsSocial,
            });
          }

          await getPdf();
          steps.value = 2;
          loading.value = false;
        } catch (err) {
          const error = err as Error;
          coreStore.displayFeedback({
            type: FeedbackTypeEnum.ERROR,
            message: `Une erreur est survenue lors de la validation de votre déclaration: ${error.message}`,
            timeout: 8000,
          });
        }
      }
    };

    const validate = async () => {
      loadingValidate.value = true;
      try {
        if (props.taskLocal) {
          if (props.resolveTask) {
            if (
              currentAccountingPeriod.value?.taxRegime === TaxRegime.IS_2065
            ) {
              await props.resolveTask(props.taskLocal, {
                [TaskCode.FillCerfa2065]: {
                  accountingPeriodPrevious:
                    additionalInformations.value.accountingPeriodPrevious,
                  ...(additionalInformations.value.addressPrevious.street &&
                  additionalInformations.value.addressPrevious.city &&
                  additionalInformations.value.addressPrevious.zip
                    ? {
                        addressPrevious:
                          additionalInformations.value.addressPrevious,
                      }
                    : {}),
                  partners: additionalInformations.value
                    .partners as TaxDeclarationsService.ExportIn<TaxRegime.IS_2065>["partners"],
                },
              });
            } else if (
              currentAccountingPeriod.value?.taxRegime === TaxRegime.LMNP_2031
            ) {
              await props.resolveTask(props.taskLocal, {
                [TaskCode.FillCerfa2031]: {
                  accountingPeriodPrevious:
                    additionalInformations.value.accountingPeriodPrevious,
                  ...(additionalInformations.value.addressPrevious.street &&
                  additionalInformations.value.addressPrevious.city &&
                  additionalInformations.value.addressPrevious.zip
                    ? {
                        addressPrevious:
                          additionalInformations.value.addressPrevious,
                      }
                    : {}),
                },
              });
            } else if (
              currentAccountingPeriod.value?.taxRegime === TaxRegime.IR_2072
            ) {
              await props.resolveTask(props.taskLocal, {
                [TaskCode.FillCerfa2072]: {
                  accountingPeriodPrevious:
                    additionalInformations.value.accountingPeriodPrevious,
                  ...(additionalInformations.value.addressPrevious.street &&
                  additionalInformations.value.addressPrevious.city &&
                  additionalInformations.value.addressPrevious.zip
                    ? {
                        addressPrevious:
                          additionalInformations.value.addressPrevious,
                      }
                    : {}),
                },
              });
            }
          } else {
            if (
              currentAccountingPeriod.value?.taxRegime === TaxRegime.IS_2065
            ) {
              await tasksStore.validateTask({
                taskLocal: props.taskLocal,
                data: {
                  [TaskCode.FillCerfa2065]: {
                    accountingPeriodPrevious:
                      additionalInformations.value.accountingPeriodPrevious,
                    ...(additionalInformations.value.addressPrevious.street &&
                    additionalInformations.value.addressPrevious.city &&
                    additionalInformations.value.addressPrevious.zip
                      ? {
                          addressPrevious:
                            additionalInformations.value.addressPrevious,
                        }
                      : {}),
                    partners: additionalInformations.value
                      .partners as TaxDeclarationsService.ExportIn<TaxRegime.IS_2065>["partners"],
                  },
                },
              });
            } else if (
              currentAccountingPeriod.value?.taxRegime === TaxRegime.LMNP_2031
            ) {
              await tasksStore.validateTask({
                taskLocal: props.taskLocal,
                data: {
                  [TaskCode.FillCerfa2031]: {
                    accountingPeriodPrevious:
                      additionalInformations.value.accountingPeriodPrevious,
                    ...(additionalInformations.value.addressPrevious.street &&
                    additionalInformations.value.addressPrevious.city &&
                    additionalInformations.value.addressPrevious.zip
                      ? {
                          addressPrevious:
                            additionalInformations.value.addressPrevious,
                        }
                      : {}),
                  },
                },
              });
            } else if (
              currentAccountingPeriod.value?.taxRegime === TaxRegime.IR_2072
            ) {
              await tasksStore.validateTask({
                taskLocal: props.taskLocal,
                data: {
                  [TaskCode.FillCerfa2072]: {
                    accountingPeriodPrevious:
                      additionalInformations.value.accountingPeriodPrevious,
                    ...(additionalInformations.value.addressPrevious.street &&
                    additionalInformations.value.addressPrevious.city &&
                    additionalInformations.value.addressPrevious.zip
                      ? {
                          addressPrevious:
                            additionalInformations.value.addressPrevious,
                        }
                      : {}),
                  },
                },
              });
            }
          }
        }
        handleDownload();
        context.emit("close");
      } catch (err) {
        const error = err as Error;
        coreStore.displayFeedback({
          type: FeedbackTypeEnum.ERROR,
          message: `Une erreur est survenue lors de la validation de votre déclaration: ${error.message}`,
          timeout: 8000,
        });
      } finally {
        loadingValidate.value = false;
      }
      await init();
    };

    // Mutations
    watch(
      document,
      () => {
        if (document.value) {
          pdf.value = {
            fileName: document.value.name,
            url: document.value.href,
          };
        }
      },
      { deep: true }
    );

    // Init
    const init = async () => {
      loading.value = true;
      setAccountingPeriodPrevious();
      setPartnersBirthAddress();
      taxDeclaration.value = await taxDeclarationsService.get({
        accountingPeriodId: accountingPeriodsStore.currentId,
      });
      if (taxDeclaration.value?.status === TaxDeclarationStatus.NotSend) {
        if (steps.value === 2) {
          await getPdf();
        }
      } else {
        await documentsStore.fetchDocuments();
      }
      loading.value = false;
    };

    onBeforeMount(async () => {
      await init();
    });

    return {
      loading,
      loadingAdditionalInformations,
      loadingValidate,
      steps,
      currentAccountingPeriod,
      TaxRegime,
      additionalInformations,
      taxDeclaration,
      TaxDeclarationStatus,
      document,
      pdf,
      getPartnerName,
      getMoment,

      logErr: (err) => console.error(err),

      handleDownload,
      validateAdditionalInformations,
      validate,
      searchItemsStreetList,
      searchItemsCityList,
      searchItemsZipList,
      searchItemsPlaceList,
      searchItemsDepartmentList,
      searchAddress,
      updateAddress,
      initialPartners,
    };
  },
});
