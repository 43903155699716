




























































































































































































































































































































































































import { useBudgetInsight } from "@/composables";
import { accountingPeriodsStore, productsStore, usersStore } from "@/store";
import {
  computed,
  defineComponent,
  onBeforeMount,
  PropType,
  ref,
  watch,
} from "@vue/composition-api";
import router from "@/router";
import { ProductsModel, TaxRegime } from "@edmp/api";
import { dispatchOnBoardingEvent, OnBoardingEventSteps } from "@/events";
import { ROUTE_NAMES } from "@/router/routes";
import { CustomLabelSwitch } from "@/components/atom/switch";
import { banksService } from "@/services";
import { VForm } from "@/models";

export default defineComponent({
  name: "RegisterBankAccount",
  components: {
    CustomLabelSwitch,
  },
  props: {
    isOnbording: {
      type: Boolean,
      required: true,
    },
    step: {
      type: Number,
      required: false,
    },
    taxRegime: {
      type: String as PropType<TaxRegime>,
      required: true,
    },
  },
  setup(props, context) {
    const bankStep = ref(1);

    const products = computed(() => productsStore.products);
    const productIdSelected = ref<ProductsModel.Product["id"] | undefined>();

    const banks = computed(
      () =>
        usersStore.loggedInUser.banks?.map((bank) => {
          return {
            id: "", // No BankAccount id
            name: "",
            bankName: bank.name,
            bankId: bank.id,
            bankLogoUuid: bank.bi_connector_uuid,
            lastSeen: bank.lastSync,
            state: bank.bi_state,
          };
        }) ?? []
    );
    const urlLogo = ref<string | undefined>();
    const hasSelectedExistingConnection = ref<boolean>(
      banks.value.length ? true : false
    );
    const bankIdSelected = ref<string | undefined>();

    const isDedicatedBankAccount = ref(
      productsStore?.currentProduct?.type === ProductsModel.ProductTypes.LMNP
        ? productsStore?.currentProduct?.dedicatedBankAccount
        : false
    );

    const showCalendly = ref(false);
    const openCalendly = (value: boolean) => {
      showCalendly.value = value;
      if (value) {
        requestForAccompanied.value = true;
      }
    };

    const {
      requestForAccompanied,
      url,
      getUrl,
      getUrlManage,
      iframeLoadChange,
      displayIframe,
      biIframe,
      providerBI,
      providerMocks,
      fetchAvailableBank,
      fetchSynchronizeBankAccount,
      changeAccounts,
      stopSynchro,
    } = useBudgetInsight(productsStore.currentId as string, !props.step);

    //Init
    fetchAvailableBank();
    fetchSynchronizeBankAccount();
    // TODO MESSAGE AVANT DE SYNCHRONISER UN NOUVEAU COMPTE CORRIGER ....
    const user = computed(() => usersStore.loggedInUser);

    function cancelAddBankAccount() {
      stopSynchro();
      dispatchOnBoardingEvent({
        step: OnBoardingEventSteps.BANK_ACCOUNT,
        userId: usersStore.loggedInUser.id,
        userEmail: usersStore.loggedInUser.email,
        userPhone: usersStore.loggedInUser.phone || "",
        productId: productsStore.currentId,
        isCanceled: true,
        requestForAccompanied: requestForAccompanied.value,
      });
      if (props.step) {
        if (accountingPeriodsStore.isLMNP) {
          router.push({
            name: ROUTE_NAMES.RealEstateAssets,
          });
        } else {
          router.push({
            name: ROUTE_NAMES.Dashboard,
            query: { welcome: "new" },
          });
        }
      } else context.emit("cancel");
    }

    function updateDedicatedBankAccount(boolean: boolean | undefined) {
      isDedicatedBankAccount.value = !!boolean;
      const data = {
        ...productsStore.currentProduct,
        id: productsStore.currentId,
        dedicatedBankAccount: boolean,
      };
      productsStore.updateProduct(data);
      stopSynchro();
    }

    const validate = (e: Event) => {
      e.preventDefault();
      if ((context.refs.form as VForm).validate() && providerBI.value) {
        context.emit("next");
        if (hasSelectedExistingConnection && bankIdSelected.value) {
          getUrlManage(bankIdSelected.value, productIdSelected.value);
        } else {
          getUrl(providerBI.value.id, productIdSelected.value);
        }
      }
    };

    watch(changeAccounts, (newVal) => {
      if (newVal) context.emit("accountsChange");
    });

    // Init
    async function init() {
      urlLogo.value = (await banksService.getConfig()).logoUrl;
      if (products.value.length === 1) {
        productIdSelected.value = products.value[0].id;
      }
    }

    watch(
      () => productsStore.currentProduct,
      () => init(),
      { deep: true }
    );

    onBeforeMount(() => init());

    return {
      bankStep,

      productIdSelected,
      products,

      hasSelectedExistingConnection,
      banks,
      urlLogo,
      bankIdSelected,

      updateDedicatedBankAccount,
      isDedicatedBankAccount,
      openCalendly,
      showCalendly,
      productsStore,
      displayIframe,
      getUrl,
      getUrlManage,
      url,
      iframeLoadChange,
      biIframe,
      providerBI,
      providerMocks,
      cancelAddBankAccount,
      user,
      TaxRegime,

      validate,
    };
  },
  // mounted () {
  //   const recaptchaScript = document.createElement('script')
  //   recaptchaScript.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js')
  //   recaptchaScript.async = true
  //   document.head.appendChild(recaptchaScript)
  // }
});
