











import { defineComponent, computed } from "@vue/composition-api";

export default defineComponent({
  name: "TitleNew",
  props: {
    hLevel: {
      type: String,
      required: true,
    },
    close: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  setup(props) {
    const marginBottom = computed(() => `${props.hLevel}MarginBottom`);

    return {
      marginBottom,
    };
  },
});
