export const IndexationEventType = "IndexationEvent" as const;

export enum IndexationEventCode {
  ENTER_INDEXATION_INTERFACE = "enterIndexationInterface",
  ENTER_INDEXATION_INTERFACE_ERROR = "enterIndexationInterfaceError",
  SUBMIT_INDEXATION = "submitIndexation",
  OPEN_INDEXATION_NOTICE = "click-indexationNotice",
  DOWNLOAD_INDEXATION_TEMPLATE_WORD = "downloadIndexationTemplateWord",
  DOWNLOAD_INDEXATION_TEMPLATE_PDF = "downloadIndexationTemplatePdf",
  REQUIRES_PREMIUM_OPTIMUM = "requiresPremiumOptimum",
  SEND_MAIL_NOTICE_RENT_INDEXATION = "sendNoticeRentIndexation",
}

export type IndexationEventData<
  Code extends IndexationEventCode = IndexationEventCode
> = {
  code: Code;
  userId: string;
  productId: string;
  date: string;
} & (
  | { code: IndexationEventCode.ENTER_INDEXATION_INTERFACE }
  | { code: IndexationEventCode.ENTER_INDEXATION_INTERFACE_ERROR }
  | { code: IndexationEventCode.SUBMIT_INDEXATION }
  | { code: IndexationEventCode.OPEN_INDEXATION_NOTICE }
  | { code: IndexationEventCode.DOWNLOAD_INDEXATION_TEMPLATE_WORD }
  | { code: IndexationEventCode.DOWNLOAD_INDEXATION_TEMPLATE_PDF }
  | { code: IndexationEventCode.REQUIRES_PREMIUM_OPTIMUM }
  | { code: IndexationEventCode.SEND_MAIL_NOTICE_RENT_INDEXATION }
);

export type IndexationEvent = CustomEvent<IndexationEventData>;

export function dispatchIndexationEvent(data: IndexationEventData) {
  dispatchEvent(
    new CustomEvent<IndexationEventData>(IndexationEventType, {
      detail: data,
    })
  );
}
