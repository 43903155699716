


















































































































































































































































































































































































































import {
  computed,
  defineComponent,
  ref,
  watch,
  Ref,
  onBeforeMount,
} from "@vue/composition-api";
import confetti from "canvas-confetti";
import SectionTitle from "@/components/atom/SectionTitle.vue";
import DatePicker from "@/components/atom/DatePicker.vue";
import YesNoSwitch from "@/components/atom/switch/YesNoSwitch.vue";
import {
  DocumentModelType,
  FinancialConditions,
  getMoment,
  NewRentIndexation,
  RentalAgreement,
  RentIndexationTypeParamEnum,
  TenseArea,
  getNewRentAmount,
  Indexation,
} from "@edmp/api";
import { rentalsService } from "@/services";
import { VForm } from "@/models";
import dayjs from "dayjs";
import Dialog from "@/components/atom/Dialog.vue";
import HelpingMessage from "@/components/atom/helping/HelpingMessage.vue";
import { IndexationError } from "@/components/core/rentalAgreements/indexationError.usable";
import {
  productsStore,
  rentalAgreementsStore,
  rentIndexationsStore,
  usersStore,
} from "@/store";
import { dispatchIndexationEvent, IndexationEventCode } from "@/events";
import RentalAgreementsPreviewMail from "@/components/core/realEstate/RentalAgreementsPreviewMail.vue";
import IndexationMail from "./IndexationMail.vue";
import { BadgeFeatureSoon } from "@/components/atom/badge";

export default defineComponent({
  name: "IndexationResult",
  components: {
    Dialog,
    YesNoSwitch,
    DatePicker,
    SectionTitle,
    HelpingMessage,
    RentalAgreementsPreviewMail,
    IndexationMail,
    BadgeFeatureSoon,
  },
  props: {
    rentalAgreementId: {
      type: String,
      required: true,
    },
    productId: { type: String, required: true },
  },

  setup(props, context) {
    const isLoading = ref(false);
    const indexData: Ref<String[]> = ref([]);

    const rentalAgreement: Ref<RentalAgreement> = computed(
      () =>
        rentalAgreementsStore.getRentalAgreement(
          context.root.$route.params.rentalAgreementId
        ) as RentalAgreement
    );

    const indexation = rentalAgreement.value.financialConditions
      .indexation as Indexation;

    const index = computed(() => {
      switch (indexation.type) {
        case RentIndexationTypeParamEnum.ILC:
          return { type: "ILC", denomination: "Indice des Loyers Commerciaux" };
        case RentIndexationTypeParamEnum.ILAT:
          return {
            type: "ILAT",
            denomination: "Indice des Loyers des Activités Tertiaires",
          };
        case RentIndexationTypeParamEnum.ICC:
          return {
            type: "ICC",
            denomination: "Indice du Coût de la Construction",
          };
        default:
          return {
            type: "IRL",
            denomination: "Indice de Référence des Loyers",
          };
      }
    });
    const newIRLInsee = indexData.value.find((data) => {
      const inseeDataItems = data.replace(/\s/g, "").split(",");
      const currentIndexItems = indexation.index.replace(/\s/g, "").split(",");
      return (
        // Index 0 is the year of reference, index 1 is the quarterPeriod
        inseeDataItems[1] === currentIndexItems[1]
      );
    });

    const rentIndexation: Ref<NewRentIndexation> = ref({
      productId: context.root.$route.params.productId,
      rentalAgreementId: props.rentalAgreementId,
      currentRent: rentalAgreement.value?.financialConditions.rentCharge ?? 0,
      currentIrl:
        rentalAgreement.value?.financialConditions.indexation?.index ?? "",
      amountOfCharges:
        rentalAgreement?.value?.financialConditions.amountOfCharges ?? 0,
      newIrl: newIRLInsee?.toString() ?? "",
      newRent: rentalAgreement.value?.financialConditions.rentCharge,
      revisionDate: `${getMoment().format("YYYY-MM-DD")}`,
      hasConstruction: false,
    });

    const today = ref(getMoment().format("YYYY-MM-DD"));
    const indexDataAfterReferenceDate: Ref<String[] | undefined> = ref();
    const tempNewRent = ref(rentIndexation.value.newRent);
    const tempNewIrl = ref(rentIndexation.value.newIrl);
    const tempRevisionDate = ref(rentIndexation.value.revisionDate);
    const tempHasConstruction = ref(rentIndexation.value.hasConstruction);
    const rentWithCharges = computed(() => {
      const number = Number(
        Number(tempNewRent.value) + rentIndexation.value.amountOfCharges
      );
      return number.toFixed(2);
    });

    const rentWithSupplementRent = computed(() => {
      let number;
      if (
        rentalAgreement.value.financialConditions.tenseArea &&
        rentalAgreement.value.financialConditions.tenseArea.supplementRent
      ) {
        number = Number(
          Number(tempNewRent.value) +
            rentalAgreement.value.financialConditions.tenseArea.supplementRent
        );
      }
      return number.toFixed(2);
    });
    const rentWithSupplementRentAndCharges = computed(() => {
      const number = Number(
        Number(rentWithSupplementRent.value) +
          rentalAgreement.value.financialConditions.amountOfCharges
      );
      return number.toFixed(2);
    });

    //#region Modal
    const openModal = ref(true);
    const onCloseModal = () => {
      openModal.value = false;
    };
    const modalErrorValue = ref<IndexationError | undefined>();
    const openPreviewModalIndexation = ref(false);
    const openSendRentIndexationModal = ref(false);
    const isOpenCongratsModal = ref(false);

    function closePreviewModal() {
      openPreviewModalIndexation.value = false;
    }
    function closeSendRentIndexationModal() {
      openSendRentIndexationModal.value = false;
      init();

      context.emit("goHistory");
    }
    function closeSendRentIndexation() {
      openSendRentIndexationModal.value = false;
    }
    //#endregion

    const beNotified = ref(false);

    const now = new Date();
    const nextRevisionMonth = rentIndexation.value.newIrl.includes("T1")
      ? 1
      : rentIndexation.value.newIrl.includes("T2")
      ? 4
      : rentIndexation.value.newIrl.includes("T3")
      ? 7
      : 10;
    const nextRevisionDate = ref(
      dayjs(new Date(now.getFullYear() + 1, nextRevisionMonth - 1, 1)).format(
        "DD/MM/YYYY"
      )
    );

    const validateRentIndexation = () => {
      if ((context.refs.form as VForm).validate()) {
        isLoading.value = true;

        rentIndexation.value.revisionDate = tempRevisionDate.value;
        rentIndexation.value.hasConstruction = tempHasConstruction.value;
        rentIndexation.value.newIrl = tempNewIrl.value;
        rentIndexation.value.newRent = tempNewRent.value;

        rentIndexationsStore
          .createIndexation(rentIndexation.value)
          .finally(() => {
            isLoading.value = false;

            isOpenCongratsModal.value = true;

            confetti({ zIndex: 1000 });

            const defaults = {
              origin: { y: 0.7 },
            };

            function fire(particleRatio, opts) {
              confetti({
                ...defaults,
                ...opts,
                particleCount: Math.floor(200 * particleRatio),
              });
            }

            fire(0.25, {
              spread: 26,
              startVelocity: 55,
            });
            fire(0.2, {
              spread: 60,
            });
            fire(0.35, {
              spread: 100,
              decay: 0.91,
              scalar: 0.8,
            });
            fire(0.1, {
              spread: 120,
              startVelocity: 25,
              decay: 0.92,
              scalar: 1.2,
            });
            fire(0.1, {
              spread: 120,
              startVelocity: 45,
            });
          });

        dispatchIndexationEvent({
          userId: usersStore.loggedInUser.id,
          productId: productsStore.currentId,
          date: getMoment().toISOString(),
          code: IndexationEventCode.SUBMIT_INDEXATION,
        });
      }
    };

    const validateNewRent = () => {
      const rentalAgreement = rentalAgreementsStore.getRentalAgreement(
        context.root.$route.params.rentalAgreementId
      );
      if (
        tempHasConstruction.value &&
        !rentalAgreement?.financialConditions.tenseArea
      ) {
        return;
      } else if (
        tempHasConstruction.value &&
        rentalAgreement &&
        rentalAgreement.financialConditions &&
        rentalAgreement.financialConditions.tenseArea &&
        rentalAgreement.financialConditions.tenseArea
          .monthlyRentReferenceIncreased
      ) {
        return [
          () =>
            rentIndexation.value.newRent <=
              (
                (rentalAgreement.financialConditions as FinancialConditions)
                  .tenseArea as TenseArea
              ).monthlyRentReferenceIncreased ||
            "Selon votre paramétrage, votre loyer ne peut pas être révisé au-delà du loyer de référence majoré",
        ];
      } else {
        return [
          () =>
            tempNewRent.value <= rentIndexation.value.newRent ||
            "Selon votre paramétrage, votre loyer ne peut être révisé qu'à la baisse",
        ];
      }
    };

    const downloadMailModel = (fileType) => {
      switch (rentalAgreement.value.financialConditions.indexation?.type) {
        case "ilc":
          rentalsService.agreements.downloadDocumentModel({
            modelType: DocumentModelType.NOTICE_RENT_INDEXATION_ILC,
            fileType,
          });
          break;
        case "icc":
          rentalsService.agreements.downloadDocumentModel({
            modelType: DocumentModelType.NOTICE_RENT_INDEXATION_ICC,
            fileType,
          });
          break;
        case "ilat":
          rentalsService.agreements.downloadDocumentModel({
            modelType: DocumentModelType.NOTICE_RENT_INDEXATION_ILAT,
            fileType,
          });
          break;
        case "overseas":
          rentalsService.agreements.downloadDocumentModel({
            modelType: DocumentModelType.NOTICE_RENT_INDEXATION_IRL_OVERSEAS,
            fileType,
          });
          break;
        case "corsica":
          rentalsService.agreements.downloadDocumentModel({
            modelType: DocumentModelType.NOTICE_RENT_INDEXATION_IRL_CORSICA,
            fileType,
          });
          break;

        default:
          rentalsService.agreements.downloadDocumentModel({
            modelType: DocumentModelType.NOTICE_RENT_INDEXATION_IRL_METROPOLIS,
            fileType,
          });
          break;
      }
      dispatchIndexationEvent({
        userId: usersStore.loggedInUser.id,
        productId: productsStore.currentId,
        date: getMoment().toISOString(),
        code:
          fileType === "docx"
            ? IndexationEventCode.DOWNLOAD_INDEXATION_TEMPLATE_WORD
            : IndexationEventCode.DOWNLOAD_INDEXATION_TEMPLATE_PDF,
      });
    };

    watch(tempNewIrl, () => {
      rentIndexation.value.newIrl = tempNewIrl.value.toString();
      let splitIRLData: string[] = tempNewIrl.value.toString().split(", ");

      const newValue = splitIRLData[2];
      tempNewRent.value =
        getNewRentAmount(rentalAgreement.value, newValue) ?? 0;
      // tempNewRent.value = rentIndexation.value.newRent;
    });
    async function init() {
      const arrayData = await rentIndexationsStore.getListIndexations(
        rentalAgreement.value?.financialConditions.indexation?.type ??
          RentIndexationTypeParamEnum.IRL_metro
      );
      if (!arrayData || arrayData.length === 0) {
        modalErrorValue.value = {
          title: "Erreur de connexion à l'INSEE",
          hasAlert: false,
          message: `Impossible de récupérer automatiquement le nouvel ${index.value.type}. Veuillez saisir manuellement le nouvel ${index.value.type} en utilisant le lien d'accès au site de l'INSEE mis à votre disposition.`,
        };
      }
      const indexes: String[] = [];
      for (const data of arrayData) {
        indexes.push(data.indexData);
      }
      indexData.value = indexes;
      if (indexData.value) {
        const splitCurrentIrlData = rentIndexation.value.currentIrl.split(", ");
        const currentIrlYear = splitCurrentIrlData[0];
        const currentIrlTrimester = splitCurrentIrlData[1];

        indexDataAfterReferenceDate.value = indexData.value.filter((data) => {
          const splitReferenceData = data.split(", ");
          const referenceYear = splitReferenceData[0];
          const referenceTrimester = splitReferenceData[1];

          if (Number(referenceYear) === Number(currentIrlYear)) {
            return (
              Number(referenceTrimester[1]) > Number(currentIrlTrimester[1])
            );
          } else {
            return Number(referenceYear) >= Number(currentIrlYear);
          }
        });
        let newIRLInsee = indexData.value.find((data) => {
          const inseeDataItems = data.replace(/\s/g, "").split(",");
          const currentIndexItems = indexation.index
            .replace(/\s/g, "")
            .split(",");
          return (
            // Index 0 is the year of reference, index 1 is the quarterPeriod
            inseeDataItems[1] === currentIndexItems[1]
          );
        });
        tempNewIrl.value = newIRLInsee?.toString() ?? "";
        if (newIRLInsee && newIRLInsee !== indexation.index) {
          let splitIRLData: string[] = newIRLInsee.split(", ");

          const newValue = splitIRLData[2];

          rentIndexation.value.newRent =
            getNewRentAmount(rentalAgreement.value, newValue) ?? 0;
        } else {
          let splitIRLData: string[] = indexData.value[0].split(", ");

          const newValue = splitIRLData[2];

          rentIndexation.value.newRent =
            getNewRentAmount(rentalAgreement.value, newValue) ?? 0;
          modalErrorValue.value = {
            title:
              "Votre indice n'est pas disponible pour le moment. Il est conseillé d'attendre pour réviser votre loyer.",
            hasAlert: false,
            message: "",
          };
        }
      }
    }

    onBeforeMount(() => init());

    return {
      rentIndexation,
      isLoading,
      nextRevisionDate,
      validateRentIndexation,
      beNotified,
      modalErrorValue,
      validateNewRent,
      tempNewRent,
      tempHasConstruction,
      tempRevisionDate,
      downloadMailModel,
      rentalAgreement,
      indexData,
      indexDataAfterReferenceDate,
      today,
      openPreviewModalIndexation,
      openModal,
      onCloseModal,
      isOpenCongratsModal,
      closePreviewModal,
      openSendRentIndexationModal,
      closeSendRentIndexation,
      closeSendRentIndexationModal,
      index,
      tempNewIrl,
      rentWithCharges,
      rentWithSupplementRent,
      rentWithSupplementRentAndCharges,
      init,
    };
  },
});
