
































































































































































import {
  defineComponent,
  PropType,
  ref,
  Ref,
  watch,
} from "@vue/composition-api";
import { VForm } from "@/models";
import { AccountingPeriodCreateInternal, TaxRegime } from "@edmp/api";
import { accountingPeriodsStore, productsStore, usersStore } from "@/store";
import DatePicker from "@/components/atom/DatePicker.vue";
import { dispatchOnBoardingEvent, OnBoardingEventSteps } from "@/events";
import { onlyNumber } from "@/utils";
import { ProductsModel } from "@edmp/api";
import { ProductsService } from "@edmp/api";
import { ActivitiesModel } from "@edmp/api";
import { User } from "@edmp/api";

export default defineComponent({
  name: "registerActivityDetailsIR",
  components: {
    DatePicker,
  },
  props: {
    step: {
      type: String,
      required: true,
    },
    activityRegistered: {
      type: Boolean,
    },
    type: {
      type: String as PropType<
        ProductsModel.Tags.LRP | ProductsModel.Tags.IMMOXYGEN
      >,
      require: false,
    },
  },
  setup(props, context) {
    const validateInProgress: Ref<boolean> = ref(false);
    const previousYearDeclared: Ref<boolean | undefined> = ref(undefined);
    const acceptationGerantStatutaire: Ref<boolean> = ref(false);
    const exerciceStartChoices = ref([
      new Date().getFullYear() - 1,
      new Date().getFullYear(),
    ]);
    const exerciceStartSelect: Ref<number | undefined> = ref();
    const startExerciceDateValue = ref(
      `${exerciceStartSelect.value ?? new Date().getFullYear()}-01-01`
    );
    const endExerciceDateValue = ref(
      `${exerciceStartSelect.value ?? new Date().getFullYear()}-12-31`
    );

    const onChangeExercice = () => {
      if (previousYearDeclared.value === false) {
        exerciceStartSelect.value = new Date().getFullYear();
      }
      startExerciceDateValue.value = `${exerciceStartSelect.value}-01-01`;
      endExerciceDateValue.value = `${exerciceStartSelect.value}-12-31`;
    };
    watch([exerciceStartSelect, previousYearDeclared], () =>
      onChangeExercice()
    );

    const rules = ref({
      exerciceDateMin: (): string => {
        const [year] = startExerciceDateValue.value?.split("-") ?? [undefined];
        const [month, day] = `${new Date().getFullYear()}-01-01`
          .split("-")
          .slice(1);
        return `${exerciceStartSelect.value ?? year}-${month}-${day}`;
      },
      exerciceDateMax: (): string => {
        const [year] = endExerciceDateValue.value?.split("-") ?? [undefined];
        const [month, day] = `${new Date().getFullYear()}-12-31`
          .split("-")
          .slice(1);
        return `${exerciceStartSelect.value ?? year}-${month}-${day}`;
      },
    });

    const user: User = usersStore.loggedInUser;
    const productForm = ref<
      Omit<
        ProductsModel.ProductCreate<ProductsModel.ProductTypes.SCI>,
        "type" | "accountingPeriods" | "dedicatedBankAccount" | "activity"
      > & {
        activity: Omit<
          ActivitiesModel.ActivityCreate<ActivitiesModel.ActivityTypes.COMPANY>,
          "type" | "operatorType" | "hasCga"
        >;
      }
    >({
      name: "",
      activity: {
        name: "",
        siret: "",
        address: { street: "", city: "", zip: "" },
      },
    });

    const createAccountingPeriod = async (
      data: AccountingPeriodCreateInternal
    ) => {
      await accountingPeriodsStore.createAccountingPeriod(data);
    };

    // Send
    async function validate(e: Event): Promise<void> {
      e.preventDefault();
      if ((context.refs.form as VForm).validate()) {
        validateInProgress.value = true;
        try {
          try {
            const product: ProductsService.CreateIn = {
              type: ProductsModel.ProductTypes.SCI,
              name: productForm.value.name,
              activity: {
                type: ActivitiesModel.ActivityTypes.COMPANY,
                name: productForm.value.name,
                siret: productForm.value.activity.siret,
              },
              accountingPeriods: [],
              ...(props.type ? { tags: [props.type] } : {}),
            };

            await productsStore.createProduct(product).then(async () => {
              const data: AccountingPeriodCreateInternal = {
                productId: productsStore.currentId,
                taxRegime: TaxRegime.IR_2072,
                firstYear: previousYearDeclared.value === false,
                startAt: startExerciceDateValue.value,
                endAt: endExerciceDateValue.value,
              };
              if (exerciceStartSelect.value == new Date().getFullYear() - 1) {
                // Create year-1
                await createAccountingPeriod(data);

                // Create year
                data.startAt = `${new Date().getFullYear()}-01-01`;
                data.endAt = `${new Date().getFullYear()}-12-31`;
                data.firstYear = false;
                await createAccountingPeriod(data);
              } else {
                // Create year
                await createAccountingPeriod(data);
              }
            });
          } catch (err) {
            validateInProgress.value = false;
          }
          dispatchOnBoardingEvent({
            step: OnBoardingEventSteps.ACTIVITY_DETAILS,
            userId: user.id,
            userEmail: user.email,
            userPhone: user.phone || "",
            productId: productsStore.currentId,
            productType: ProductsModel.ProductTypes.SCI,
            taxRegime: "IR",
          });
          validateInProgress.value = false;
          context.emit("validate");
        } catch (err) {
          validateInProgress.value = false;
        }
      }
    }

    return {
      productForm,
      previousYearDeclared,
      exerciceStartChoices,
      exerciceStartSelect,
      acceptationGerantStatutaire,
      startExerciceDateValue,
      endExerciceDateValue,
      rules,
      validate,
      validateInProgress,
      onlyNumberRule: onlyNumber,
      user,
    };
  },
});
