













































import { VConfirmDialog } from "@/models";
import Confirm from "@/components/core/modals/Confirm.vue";
import { operationAccrualsStore } from "@/store";
import { OperationAccrualLib, OperationAccrualsModel } from "@edmp/api";
import { defineComponent, PropType, computed } from "@vue/composition-api";
import AccrualCard from "./AccrualCard.vue";

export default defineComponent({
  name: "AccrualCards",
  props: {
    operationAccruals: {
      type: Array as PropType<OperationAccrualsModel.OperationAccrual[]>,
      required: true,
    },
    removable: {
      type: [String, Boolean] as PropType<
        "all" | "recovery" | "closure" | false
      >,
      default: false,
    },
  },
  components: {
    AccrualCard,
    Confirm,
  },
  setup(props, context) {
    const removeAccrual = async (
      recoveryOperationAccrual: OperationAccrualsModel.OperationAccrual
    ) => {
      const openConfirm: boolean = await (
        context.refs.confirmDialog as VConfirmDialog
      ).open(`Êtes-vous sûr de vouloir supprimer cet engagement ?`);
      if (openConfirm) {
        await operationAccrualsStore.remove(recoveryOperationAccrual.id);
      }
    };
    const isRemovable = (
      operationAccrual: OperationAccrualsModel.OperationAccrual
    ) => {
      if (!props.removable) {
        return false;
      }
      switch (props.removable) {
        case "all":
          return true;
        case "recovery":
          return OperationAccrualLib.isOperationRecovery(operationAccrual);
        case "closure":
          return !OperationAccrualLib.isOperationRecovery(operationAccrual);
      }
    };

    const sortedOperationAccruals = computed(() => {
      return props.operationAccruals.slice().sort((a, b) => {
        const isARecovery = OperationAccrualLib.isOperationRecovery(a);
        const isBRecovery = OperationAccrualLib.isOperationRecovery(b);

        if (isARecovery && !isBRecovery) return -1;
        if (!isARecovery && isBRecovery) return 1;

        const isAClosure = OperationAccrualLib.isOperationClosure(a);
        const isBClosure = OperationAccrualLib.isOperationClosure(b);

        if (isAClosure && !isBClosure) return 1;
        if (!isAClosure && isBClosure) return -1;

        const dateA = new Date(a.journalEntry.accrualDate);
        const dateB = new Date(b.journalEntry.accrualDate);

        return dateB.getTime() - dateA.getTime();
      });
    });

    return {
      sortedOperationAccruals,
      removeAccrual,
      isRemovable,
    };
  },
});
