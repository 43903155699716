








































































































































































import {
  computed,
  defineComponent,
  onBeforeMount,
  ref,
  Ref,
  watch,
} from "@vue/composition-api";
import { productsStore } from "@/store";
import BankAccountsModal from "./RegisterBankAccountsModal.vue";
import BankAccounts from "./BankAccounts.vue";
import ManageBankAccountModal from "./ManageBankAccountModal.vue";
import { ProductsModel } from "@edmp/api";

export default defineComponent({
  name: "BankAccountsLmnp",
  components: {
    BankAccountsModal,
    ManageBankAccountModal,
    BankAccounts,
  },

  setup() {
    const product = computed(
      () =>
        productsStore.currentProduct as
          | ProductsModel.Product<ProductsModel.ProductTypes.LMNP>
          | undefined
    );

    const isDedicatedBankAccount: Ref<boolean | undefined> = ref();
    const panel = 0;

    const updateDedicatedBankAccount = (boolean: boolean | undefined) => {
      const data = {
        ...product.value,
        id: productsStore.currentId,
        dedicatedBankAccount: boolean,
      };
      productsStore.updateProduct(data);
    };

    const init = () => {
      isDedicatedBankAccount.value = product.value?.dedicatedBankAccount;
    };

    watch(
      () => [product],
      () => {
        init();
      },
      { deep: true }
    );

    onBeforeMount(async () => {
      await init();
    });

    return {
      panel,
      isDedicatedBankAccount,
      updateDedicatedBankAccount,
    };
  },
});
