
























































































































































































































































import {
  defineComponent,
  ref,
  Ref,
  computed,
  onBeforeMount,
  watch,
  PropType,
  reactive,
} from "@vue/composition-api";
import { VForm } from "@/models";
import {
  AccountingPeriod,
  ProductsModel,
  getMoment,
  TaxRegime,
  AccountingPeriodCreateInternal,
} from "@edmp/api";
import {
  accountingPeriodsStore,
  productsStore,
  realEstateAssetsStore,
} from "@/store";

import AccountingPeriodChoice from "@/components/core/accounting-period/AccountingPeriodChoice.vue";
import DatePicker from "@/components/atom/DatePicker.vue";
import { CustomLabelSwitch } from "@/components/atom/switch";
import GenericChargesDialog from "./GenericChargesDialog.vue";
import GenericChargesApplicationDialog from "./GenericChargesApplicationDialog.vue";
import { ROUTE_NAMES } from "@/router/routes";

const DEFAULT_ACCOUNTING_PERIOD = {
  taxRegime: TaxRegime.IS_2065,
  firstYear: false,
  startAt: `${new Date().getFullYear()}-01-01`,
  endAt: `${new Date().getFullYear()}-12-31`,
} as AccountingPeriod;

export default defineComponent({
  name: "AccountingIS",
  components: {
    AccountingPeriodChoice,
    DatePicker,
    CustomLabelSwitch,
    GenericChargesDialog,
    GenericChargesApplicationDialog,
  },
  props: {
    actions: { type: String as PropType<"create" | "update"> },
    isEditing: { type: Boolean, default: false },
  },
  setup(props, context) {
    const accountingPeriod: Ref<AccountingPeriod | undefined> = ref();
    const accountingPeriodExist = computed(
      () => !!accountingPeriodsStore.accountingPeriods.length
    );
    const validateInProgress: Ref<boolean> = ref(false);
    const taxRegime: Ref<TaxRegime> = ref(DEFAULT_ACCOUNTING_PERIOD.taxRegime);

    const isFirstExercice: Ref<boolean | undefined> = ref();
    const acceptationGerantStatutaire: Ref<boolean> = ref(false);
    const dateFirstExercice = accountingPeriodsStore.firstAccountingPeriod;
    const isFirstExerciceShow = computed(
      () =>
        accountingPeriodsStore.currentId ===
        accountingPeriodsStore.firstAccountingPeriod.id
    );

    // Fiscal exercice date
    const startExerciceDateValue: Ref<string | undefined> = ref();
    const endExerciceDateValue: Ref<string | undefined> = ref();
    const startExerciceDateText = computed(() =>
      getMoment(startExerciceDateValue.value).format("L")
    );
    const endExerciceDateText = computed(() =>
      getMoment(endExerciceDateValue.value).format("L")
    );

    const rules = reactive({
      isValidCurrentExerciceDate: computed(
        () =>
          getMoment().isBetween(
            getMoment(startExerciceDateValue.value),
            getMoment(endExerciceDateValue.value)
          ) || "La période sélectionnée ne comprend pas la date d'aujourd'hui"
      ),
      startExercice: {
        exerciceDateMin: () => {
          if (endExerciceDateValue.value) {
            const lastYearDate = `${new Date().getFullYear() - 1}-01-01`;
            const startExerciceDateMin = getMoment(
              endExerciceDateValue.value ?? `${new Date().getFullYear()}-12-31`
            );
            const result = startExerciceDateMin
              .add(-24, "months")
              .add(1, "days");

            return result.isBefore(getMoment(lastYearDate))
              ? getMoment(lastYearDate).format("YYYY-MM-DD")
              : result.format("YYYY-MM-DD");
          }
          return getMoment(`${new Date().getFullYear()}-01-01`)
            .subtract(1, "years")
            .format("YYYY-MM-DD");
        },
        exerciceDateMax: () => `${new Date().getFullYear()}-12-31`,
      },
      endExercice: {
        exerciceDateMin: () => {
          const endExerciceDateMin = getMoment(
            startExerciceDateValue.value ?? `${new Date().getFullYear()}-12-31`
          );

          return endExerciceDateMin.add(-1, "days").format("YYYY-MM-DD");
        },
        exerciceDateMax: () => {
          const endExerciceDateMax = getMoment(
            startExerciceDateValue.value ?? `${new Date().getFullYear()}-12-31`
          );

          return endExerciceDateMax
            .add(24, "months")
            .add(-1, "days")
            .format("YYYY-MM-DD");
        },
      },
    });

    // Open previous exercise
    const isOpenPreviousExercise: Ref<boolean> = ref(false);
    const previousStartExerciseDate = computed(() =>
      getMoment(startExerciceDateValue.value).subtract(1, "year")
    );
    const previousEndExerciseDate = computed(() =>
      getMoment(endExerciceDateValue.value).subtract(1, "year")
    );
    const isAutosizeOpenPreviousExercise = computed(() =>
      getMoment(previousEndExerciseDate.value)
        .add(6, "months")
        .isSameOrAfter(getMoment().startOf("day"))
    );

    const previousExerciseDateLabel = computed(
      () =>
        `Du ${previousStartExerciseDate.value.format(
          "L"
        )} au ${previousEndExerciseDate.value.format("L")}`
    );
    const currentExerciseDateLabel = computed(
      () => `Du ${startExerciceDateText.value} au ${endExerciceDateText.value}`
    );
    watch(
      [isFirstExercice, isAutosizeOpenPreviousExercise],
      () => {
        if (isFirstExercice || !isAutosizeOpenPreviousExercise) {
          isOpenPreviousExercise.value = false;
        }
      },
      {
        deep: true,
      }
    );

    // Send
    function validate(e: Event): void {
      e.preventDefault();
      if ((context.refs.form as VForm).validate()) {
        validateInProgress.value = true;

        const createAccountingPeriod = (
          data: AccountingPeriodCreateInternal
        ) => {
          try {
            accountingPeriodsStore.createAccountingPeriod(data).then(() => {
              context.emit("validate");
            });
          } catch (err) {
            validateInProgress.value = false;
          }
        };
        const updateAccountingPeriod = (data: AccountingPeriod) => {
          try {
            accountingPeriodsStore.updateAccountingPeriod(data).then(() => {
              context.emit("validate");
            });
          } catch (err) {
            validateInProgress.value = false;
          }
        };

        if (
          taxRegime.value &&
          startExerciceDateValue.value &&
          endExerciceDateValue.value
        ) {
          const data: AccountingPeriodCreateInternal = {
            productId: productsStore.currentId,
            taxRegime: taxRegime.value,
            firstYear: !!isFirstExercice.value,
            startAt: startExerciceDateValue.value,
            endAt: endExerciceDateValue.value,
          };
          if (props.actions === "create" && !accountingPeriodExist.value) {
            if (isOpenPreviousExercise.value) {
              // Create year-1
              const dataCreate: AccountingPeriodCreateInternal = {
                productId: productsStore.currentId,
                taxRegime: taxRegime.value,
                firstYear: !!isFirstExercice.value,
                startAt: getMoment(startExerciceDateValue.value)
                  .subtract(1, "year")
                  .format("YYYY-MM-DD"),
                endAt: getMoment(endExerciceDateValue.value)
                  .subtract(1, "year")
                  .format("YYYY-MM-DD"),
              };
              createAccountingPeriod(dataCreate);

              // Create year
              data.startAt = `${new Date().getFullYear()}-01-01`;
              data.endAt = `${new Date().getFullYear()}-12-31`;
              data.firstYear = false;
              createAccountingPeriod(data);
            } else {
              //  year
              createAccountingPeriod(data);
            }
            // Create year
            validateInProgress.value = false;
            context.emit("update:isEditing", false);
          } else {
            // Update existing accounting Period
            updateAccountingPeriod(
              Object.assign(data, {
                id: accountingPeriod.value?.id,
              } as AccountingPeriod)
            );
            validateInProgress.value = false;
            context.emit("update:isEditing", false);
            // Reload to change Menu IS <=> IR
            // location.reload();
          }
        }
      }
    }

    function cancelEditing() {
      accountingPeriod.value =
        accountingPeriodsStore.currentAccountingPeriod ??
        DEFAULT_ACCOUNTING_PERIOD;
      startExerciceDateValue.value = `${
        accountingPeriod.value?.startAt ?? DEFAULT_ACCOUNTING_PERIOD.startAt
      }`;
      endExerciceDateValue.value = `${
        accountingPeriod.value?.endAt ?? DEFAULT_ACCOUNTING_PERIOD.endAt
      }`;
      context.emit("update:isEditing", false);
    }

    /**
     * Init
     */
    async function init() {
      if (!props.actions || props.actions === "update") {
        accountingPeriod.value = accountingPeriodsStore.currentAccountingPeriod;
        startExerciceDateValue.value = `${accountingPeriod.value?.startAt}`;
        endExerciceDateValue.value = `${accountingPeriod.value?.endAt}`;
        isFirstExercice.value = accountingPeriod?.value?.firstYear;
      } else {
        const product = productsStore.products.find(
          ({ status }) => status === ProductsModel.ProductStatus.pending
        );
        accountingPeriod.value = accountingPeriodsStore.accountingPeriods.length
          ? accountingPeriodsStore.accountingPeriods
              .filter(({ productId }) => productId === product?.id)
              .reduce((prev, current) =>
                getMoment(prev.startAt).isBefore(current.startAt)
                  ? prev
                  : current
              )
          : DEFAULT_ACCOUNTING_PERIOD || DEFAULT_ACCOUNTING_PERIOD;
        startExerciceDateValue.value = `${
          accountingPeriod.value.startAt ?? DEFAULT_ACCOUNTING_PERIOD.startAt
        }`;
        endExerciceDateValue.value = `${
          accountingPeriod.value.endAt ?? DEFAULT_ACCOUNTING_PERIOD.endAt
        }`;
        if (!accountingPeriod.value.id) {
          context.emit("update:isEditing", true);
        } else {
          context.emit("update:isEditing", false);
        }
      }
    }

    const isOpenGenericChargesDialog = ref(false);
    const isOpenGenericChargesApplicationDialog = ref(false);

    watch(
      () => [
        accountingPeriodsStore.accountingPeriods,
        accountingPeriodsStore.currentAccountingPeriod,
        props.actions,
      ],
      () => init(),
      { deep: true }
    );

    onBeforeMount(() => init());

    return {
      cancelEditing,
      getMoment,
      accountingPeriod,
      accountingPeriodExist,
      isFirstExercice,
      taxRegime,
      startExerciceDateValue,
      startExerciceDateText,
      endExerciceDateValue,
      endExerciceDateText,
      isOpenPreviousExercise,
      isAutosizeOpenPreviousExercise,
      currentExerciseDateLabel,
      previousExerciseDateLabel,
      acceptationGerantStatutaire,
      rules,
      validate,
      validateInProgress,
      dateFirstExercice,
      isFirstExerciceShow,
      isOpenGenericChargesDialog,
      isOpenGenericChargesApplicationDialog,
      ROUTE_NAMES,
      realEstateAssetsStore,
    };
  },
});
