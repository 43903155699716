import { downloadFile } from "@/utils";
import { TaxDeclarationsService, DocumentFileType } from "@edmp/api";
import axios from "axios";

const api = "/api/v1/tax-declarations";

class TaxDeclarationsService {
  async list(params: TaxDeclarationsService.ListIn) {
    return axios
      .get<TaxDeclarationsService.ListOut>(`${api}`, { params })
      .then((res) => {
        return res.data;
      });
  }

  async get(params: TaxDeclarationsService.GetIn) {
    return axios
      .get<TaxDeclarationsService.GetOut>(
        `${api}/${params.accountingPeriodId}`,
        { params }
      )
      .then((res) => {
        return res.data;
      });
  }

  async downloadReport(
    params: TaxDeclarationsService.ExportIn & { directDownload?: boolean }
  ): Promise<void | { fileName: string; url: string }> {
    if (params.directDownload) {
      return axios
        .post<TaxDeclarationsService.ExportOut>(
          `${api}/${params.accountingPeriodId}/export`,
          params,
          {
            responseType: "blob", // Important : Format Blob !
            headers: {
              Accept: "application/pdf",
            },
          }
        )
        .then((res) => {
          const fileName = (res.headers["content-disposition"] || "").split(
            "filename="
          )[1];

          downloadFile(res.data, fileName, DocumentFileType.PDF);
        });
    } else {
      return axios
        .post<Blob>(`${api}/${params.accountingPeriodId}/export`, params, {
          headers: {
            Accept: "application/pdf",
          },
          responseType: "blob",
        })
        .then((res) => {
          const fileName = (res.headers["content-disposition"] || "").split(
            "filename="
          )[1];
          const url = window.URL.createObjectURL(res.data);
          return { fileName, url };
        });
    }
  }

  async get2072(params: TaxDeclarationsService.GetReporting2072In) {
    return axios
      .get<TaxDeclarationsService.GetReporting2072Out>(
        `${api}/${params.accountingPeriodId}/2072`,
        { params }
      )
      .then((res) => {
        return res.data;
      });
  }

  async get2072Template(params?: TaxDeclarationsService.GetTemplateIn) {
    return axios
      .get<TaxDeclarationsService.GetTemplateOut>(`${api}/2072/template`, {
        params,
      })
      .then((res) => {
        return res.data;
      });
  }

  async list2072Transactions(
    params: TaxDeclarationsService.SearchTransactionsIn
  ) {
    return axios
      .post<TaxDeclarationsService.SearchTransactionsOut>(
        `${api}/${params.accountingPeriodId}/2072/transactions`,
        {
          name: params.name,
          refs: params.refs,
        }
      )
      .then((res) => {
        return res.data;
      });
  }

  async download2044Report(params: TaxDeclarationsService.GetReporting2044In) {
    return axios
      .get<TaxDeclarationsService.GetReporting2044Out>(
        `${api}/${params.accountingPeriodId}/2044`,
        {
          params,
          responseType: "blob", // Important : Format Blob !
          headers: {
            Accept: "application/pdf",
          },
        }
      )
      .then((res) => {
        const fileName = (res.headers["content-disposition"] || "").split(
          "filename="
        )[1];

        downloadFile(res.data, fileName, DocumentFileType.PDF);
      });
  }

  async get2033(params: TaxDeclarationsService.Get2033In) {
    return axios
      .get<TaxDeclarationsService.Get2033Out>(
        `${api}/${params.accountingPeriodId}/2033`,
        { params }
      )
      .then((res) => {
        return res.data;
      });
  }

  async get2065(params: TaxDeclarationsService.Get2065In) {
    return axios
      .get<TaxDeclarationsService.Get2065Out>(
        `${api}/${params.accountingPeriodId}/2065`,
        { params }
      )
      .then((res) => {
        return res.data;
      });
  }

  async get2031(params: TaxDeclarationsService.Get2031In) {
    return axios
      .get<TaxDeclarationsService.Get2031Out>(
        `${api}/${params.accountingPeriodId}/2031`,
        { params }
      )
      .then((res) => {
        return res.data;
      });
  }
}

// Export a singleton instance in the global namespace
export const taxDeclarationsService = new TaxDeclarationsService();
