import { RequireField, addressSchema } from "./Common.model";
import { model, Schema, Document, ToObjectOptions } from "mongoose";
import { ulid } from "ulid";
import { Direction } from "./JournalComposedEntry";
import { TaxDeclarationHeaderData } from "..";
import { TaxDeclaration2033LineNumber } from "./TaxDeclaration2033.model";

// Types
export enum TaxDeclaration2065LineNumberSummaryOfTaxElements {
  "C_1_BENEFIT_NORMAL_RATE" = "C_1_BENEFIT_NORMAL_RATE", // C. Summary of tax elements - 1. Fiscal result - Benefit normal rate
  "C_1_BENEFIT_15_RATE" = "C_1_BENEFIT_15_RATE", // C. Summary of tax elements - 1. Fiscal result - Benefit 15% rate
  "C_1_DEFICIT" = "C_1_DEFICIT", // C. Summary of tax elements - 1. Fiscal result - Deficit
}
export enum TaxDeclaration2065LineNumberAnnualContributionOnRentalIncome {
  "E_NET_REVENUE_SUBJECT_TO_CONTRIBUTION" = "E_NET_REVENUE_SUBJECT_TO_CONTRIBUTION", // E. Annual contribution on rental INCOME - Net revenue subject to contribution
}

export const TaxDeclaration2065LineNumber = {
  ...TaxDeclaration2065LineNumberSummaryOfTaxElements,
  ...TaxDeclaration2065LineNumberAnnualContributionOnRentalIncome,
};
export type TaxDeclaration2065LineNumber =
  typeof TaxDeclaration2065LineNumber[keyof typeof TaxDeclaration2065LineNumber];

export type TaxDeclaration2065LineAmount<WithTransactions extends boolean = false> = {
  amount: number;
  amountDirection: Direction;
} & (WithTransactions extends true
  ? { transactionIds: string[] }
  : // eslint-disable-next-line @typescript-eslint/ban-types
    object);
export type TaxDeclaration2065LineText = {
  text: string;
};

export type TaxDeclaration2065LinesAmountWithTransactions = {
  [key in
    | TaxDeclaration2065LineNumberSummaryOfTaxElements
    | TaxDeclaration2065LineNumberAnnualContributionOnRentalIncome]: TaxDeclaration2065LineAmount<true>;
};
export type TaxDeclaration2065LinesAmountWithoutTransactions = {
  [key in never]: TaxDeclaration2065LineAmount<false>;
};
export type TaxDeclaration2065LinesText = {
  [key in never]: TaxDeclaration2065LineText;
};
export type TaxDeclaration2065Lines = TaxDeclaration2065LinesAmountWithTransactions &
  TaxDeclaration2065LinesAmountWithoutTransactions &
  TaxDeclaration2065LinesText;

export type TaxDeclaration2065DataArchive = TaxDeclarationHeaderData;
export type TaxDeclaration2065WithDataArchive<WithDataArchive extends boolean = true> = true extends WithDataArchive
  ? { dataArchived: TaxDeclaration2065DataArchive }
  : Record<never, never>;
export type TaxDeclaration2065<IsValidate extends boolean = boolean, WithDataArchive extends boolean = boolean> = {
  productId: string;
  accountingPeriodId: string;
  isValidate: IsValidate;
  lines: TaxDeclaration2065Lines;
} & (
  | { isValidate: false }
  | ({
      id: string;
      isValidate: true;
      isValidateByUserId: string;
      createdAt: string;
      updatedAt: string;
    } & TaxDeclaration2065WithDataArchive<WithDataArchive>)
);

export type TaxDeclaration2065Create = Omit<TaxDeclaration2065<true, true>, "id" | "createdAt" | "updatedAt">;

export type TaxDeclaration2065Update<> = Omit<
  RequireField<Partial<TaxDeclaration2065<true, false>>, "id">,
  "productId" | "accountingPeriodId" | "isValidateByUserId" | "createdAt" | "updatedAt"
>;

export type TaxDeclaration2065Generated = TaxDeclaration2065<false> & {
  dataArchive: TaxDeclaration2065DataArchive;
};

export type TaxDeclaration2065LinesMetadata = {
  name: string;
  description?: string;
  accounts: TaxDeclaration2033LineNumber[];
};

// Mongo
export type TaxDeclaration2065Document = TaxDeclaration2065<true, true> & Document<string>;

const TaxDeclaration2065Schema = new Schema<TaxDeclaration2065<true, true>>(
  {
    _id: { type: String, default: (): string => ulid() },
    productId: { type: String, index: true, required: true },
    accountingPeriodId: { type: String, index: true, required: true },
    isValidate: { type: Boolean },
    isValidateByUserId: { type: String },
    lines: {
      type: Map,
      of: {
        amount: { type: Number },
        amountDirection: { type: String, enum: Object.values(Direction) },
        transactionIds: [{ type: String }],
        text: { type: String },
      },
    },
    dataArchived: {
      user: {
        id: { type: String },
        firstName: { type: String },
        lastName: { type: String },
        email: { type: String },
      },
      product: {
        id: { type: String },
        email: { type: String },
      },
      activity: {
        id: { type: String },
        type: { type: String },
        address: addressSchema,
        addressPrevious: addressSchema,
        siret: { type: String },
        name: { type: String },
        operatorType: { type: String },
        firstName: { type: String },
        lastName: { type: String },
        denomination: { type: String },
        legalStatus: { type: String },
      },
      accountingPeriod: {
        id: { type: String },
        startAt: { type: String },
        endAt: { type: String },
        taxRegime: { type: String },
        firstYear: { type: Boolean },
      },
      accountingPeriodPrevious: {
        startAt: { type: String },
        endAt: { type: String },
      },
      partnerOwned: {
        id: { type: String },
        type: { type: String },
        firstName: { type: String },
        lastName: { type: String },
        denomination: { type: String },
        role: { type: String },
        birthAddress: {
          place: { type: String },
          department: { type: Number },
          country: { type: String },
        },
        createdAt: { type: String },
      },
    },
  },
  {
    timestamps: true,
    toJSON: {
      versionKey: false,
      virtuals: true,
      transform(
        doc: Omit<TaxDeclaration2065Document, "createdAt" | "updatedAt"> & {
          _id: string;
          createdAt: Date;
          updatedAt: Date;
        },
        ret: TaxDeclaration2065<true> & { _id?: string },
        options: ToObjectOptions
      ) {
        delete ret._id;
        ret.id = doc._id;

        ret.lines = (
          doc.lines as unknown as Map<TaxDeclaration2065LineNumber, typeof ret.lines[TaxDeclaration2065LineNumber]> &
            Document
        ).toJSON<typeof ret.lines>();
        for (const [lineName, line] of Object.entries(ret.lines)) {
          ret.lines[lineName as unknown as TaxDeclaration2065LineNumber] = (
            line as unknown as typeof doc.lines[TaxDeclaration2065LineNumber] & Document
          ).toJSON<typeof ret.lines[TaxDeclaration2065LineNumber]>();
        }

        return ret;
      },
    },
  }
);

export const TaxDeclaration2065Model = model<TaxDeclaration2065Document>(
  "TaxDeclaration2065",
  TaxDeclaration2065Schema,
  "TaxDeclarations2065"
);

// Function
export const isTaxDeclaration2065LineNumberSummaryOfTaxElements = (
  lineNumber: TaxDeclaration2065LineNumber
): lineNumber is TaxDeclaration2065LineNumberSummaryOfTaxElements => {
  return Object.values(TaxDeclaration2065LineNumberSummaryOfTaxElements).includes(
    lineNumber as TaxDeclaration2065LineNumberSummaryOfTaxElements
  );
};
export const isTaxDeclaration2065LineNumberAnnualContributionOnRentalIncomeElements = (
  lineNumber: TaxDeclaration2065LineNumber
): lineNumber is TaxDeclaration2065LineNumberAnnualContributionOnRentalIncome => {
  return Object.values(TaxDeclaration2065LineNumberAnnualContributionOnRentalIncome).includes(
    lineNumber as TaxDeclaration2065LineNumberAnnualContributionOnRentalIncome
  );
};

// Const
const taxDeclaration2065LinesMetadataLayer0: {
  [key in TaxDeclaration2065LineNumber]: TaxDeclaration2065LinesMetadata;
} = {
  C_1_BENEFIT_NORMAL_RATE: {
    name: "Bénéfice imposable au taux normal",
    accounts: [TaxDeclaration2033LineNumber.L370],
  },
  C_1_BENEFIT_15_RATE: {
    name: "Bénéfice imposable à 15 %",
    accounts: [TaxDeclaration2033LineNumber.L370],
  },
  C_1_DEFICIT: {
    name: "Déficit",
    accounts: [TaxDeclaration2033LineNumber.L372],
  },
  E_NET_REVENUE_SUBJECT_TO_CONTRIBUTION: {
    name: "Recettes nettes soumises à la contribution de 2,5 %",
    accounts: [],
  },
};

export const taxDeclaration2065LinesMetadata: {
  [key in TaxDeclaration2065LineNumber]: TaxDeclaration2065LinesMetadata;
} = {
  ...taxDeclaration2065LinesMetadataLayer0,
};
