import { GenericChargesModes } from "@edmp/api";

export const GenericChargesEventType = "GenericChargesEvent" as const;

export enum GenericChargesEventCode {
  SELECTED_MODE = "selectedMode",
  CONFIRMED_MODE = "confirmedMode",
}

export type GenericChargesEventData<
  Code extends GenericChargesEventCode = GenericChargesEventCode
> = {
  code: Code;
  userId: string;
  productId: string;
  genericChargesMode: GenericChargesModes;
  date: string;
} & (
  | { code: GenericChargesEventCode.SELECTED_MODE }
  | { code: GenericChargesEventCode.CONFIRMED_MODE }
);

export type GenericChargesEvent = CustomEvent<GenericChargesEventData>;

export function dispatchGenericChargesEvent(data: GenericChargesEventData) {
  dispatchEvent(
    new CustomEvent<GenericChargesEventData>(GenericChargesEventType, {
      detail: data,
    })
  );
}
