













































































































import { defineComponent, Ref, ref, watch } from "@vue/composition-api";
import EditableTable from "./table/EditableTable.vue";
import { accountingPeriodsStore, coreStore, documentsStore } from "@/store";
import AddDocumentsModalNew from "../core/documents/AddDocumentsModalNew.vue";
import { VForm } from "@/models";

export default defineComponent({
  name: "UploadFlowDialog",
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    EditableTable,
    AddDocumentsModalNew,
  },
  setup: (props, context) => {
    const inputFile: Ref<HTMLInputElement | null> = ref(null);
    const files: Ref<Array<File>> = ref([]);

    const step = ref(1);

    const deleteFile = (index) => {
      files.value.splice(index, 1);
    };

    const isAcceptedFile = (file) =>
      ["application/pdf", "image/png", "image/jpeg", "image/jpg"].includes(
        file.type
      ) && file.size / 1024 ** 2 <= 2;

    const storeFiles = async (event) => {
      event.preventDefault();
      if (event.dataTransfer) {
        if (event.dataTransfer.items) {
          [...event.dataTransfer.items].forEach((item) => {
            if (item.kind === "file") {
              const file = item.getAsFile();
              if (isAcceptedFile(file)) files.value.push(file);
            }
          });
        } else if (event.dataTransfer.files) {
          [...event.dataTransfer.files].forEach((file) => {
            if (isAcceptedFile(file)) files.value.push(file);
          });
        }
      } else if (event.target.files) {
        [...event.target.files].forEach((file) => {
          if (isAcceptedFile(file)) files.value.push(file);
        });
      }
    };
    const openFileSelect = () => {
      inputFile.value?.click();
    };

    const allowDrop = (event) => {
      event.preventDefault();
    };

    const mappedData = ref([{ document: null, file: null }]);

    const confirmUpload = async () => {
      if ((context.refs.form as VForm).validate()) {
        try {
          for (const data of mappedData.value) {
            if (data.document && data.file) {
              documentsStore.createDocument({
                document: data.document,
                file: data.file,
              });
            }
          }

          coreStore.displayFeedback({
            message: "Vos documents ont bien été enregistrés sur Ownily.",
          });

          context.emit("close");
          context.emit("documentAdded");
        } catch (err) {
          console.error(err);
        }
      }
    };

    watch(
      () => props.isOpen,
      () => {
        if (props.isOpen === false) {
          step.value = 1;
          files.value = [];
        }
      }
    );

    return {
      step,
      storeFiles,
      openFileSelect,
      allowDrop,
      files,
      deleteFile,
      inputFile,
      confirmUpload,
      accountingPeriodsStore,
      mappedData,
    };
  },
});
