import {
  AccountingBalanceSheet,
  AccountingPeriod,
  getMoment,
  LedgerAccountEnum,
  OperationAccrualsModel,
} from "@edmp/api";

export interface AccrualsInventoryState {
  accountingPeriod: AccountingPeriod;
  account: OperationAccrualsModel.DoubleEntryAccounts;
  firstYear: boolean;
  reportLossEnabled: boolean;
  initialBalance: number | undefined;
  currentBalance: number;
  dateInitialBalance: string;
  dateCurrentBalance: string;
  operationLossIds: string[];
  recoveryCreateCanceled: boolean;
}

export function useAccrualsInventory() {
  const initAccrualsInventoryState = (
    accountingPeriod: AccountingPeriod,
    account: OperationAccrualsModel.DoubleEntryAccounts,
    currentBalanceSheet: AccountingBalanceSheet,
    previousBalanceSheet?: AccountingBalanceSheet
  ): AccrualsInventoryState => {
    return {
      accountingPeriod,
      account,
      firstYear: accountingPeriod.firstYear,
      reportLossEnabled: account === LedgerAccountEnum.N411000,
      initialBalance: initInitialBalance(account, previousBalanceSheet),
      currentBalance: initCurrentBalance(account, currentBalanceSheet),
      dateInitialBalance: getMoment(accountingPeriod.startAt).format(
        "DD/MM/YYYY"
      ),
      dateCurrentBalance: getMoment(accountingPeriod.endAt).format(
        "DD/MM/YYYY"
      ),
      operationLossIds: [],
      recoveryCreateCanceled: false,
    };
  };

  const initInitialBalance = (
    account: LedgerAccountEnum,
    previousBalanceSheet?: AccountingBalanceSheet
  ) => {
    if (previousBalanceSheet) {
      return (
        previousBalanceSheet?.lines.find((line) => line.account === account)
          ?.amount ?? 0
      );
    }
  };

  const initCurrentBalance = (
    account: LedgerAccountEnum,
    currentBalanceSheet: AccountingBalanceSheet
  ) => {
    return Number(
      (
        currentBalanceSheet?.lines.find((line) => line.account === account)
          ?.amount ?? 0
      ).toFixed(0)
    );
  };

  return {
    initAccrualsInventoryState,
    initCurrentBalance,
  };
}
