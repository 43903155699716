





































































import { defineComponent, PropType, ref } from "@vue/composition-api";
import AccrualsInventoryStep1 from "./AccrualsInventoryStep1.vue";
import AccrualsInventoryStep2 from "./AccrualsInventoryStep2.vue";
import AccrualsInventorySimple from "./AccrualsInventorySimple.vue";
import { AccrualsInventoryState } from "./accrualsInventory.usable";

export default defineComponent({
  name: "AccrualsInventoryTab",
  props: {
    accrualsInventoryState: {
      type: Object as PropType<AccrualsInventoryState>,
      required: true,
    },
  },
  components: {
    AccrualsInventoryStep1,
    AccrualsInventoryStep2,
    AccrualsInventorySimple,
  },
  setup() {
    const stepSelected = ref("1");
    return {
      stepSelected,
    };
  },
});
