import Decimal from "decimal.js-light";
import moment from "moment-timezone";
import {
  AccountingBalanceSheet,
  AccountingPeriod,
  Direction,
  JournalComposedEntry,
  JournalComposedEntryTags,
  JournalEntryLine,
  LedgerAccountEnum,
  OperationAccrualsModel,
} from "..";

export namespace OperationAccrualLib {
  // Operation accrual type
  export const isOperationAccrual = (operation: JournalComposedEntry): boolean =>
    operation.tags?.includes(JournalComposedEntryTags.ACCRUAL);
  export const isOperationRecovery = (
    operation:
      | JournalComposedEntry
      | OperationAccrualsModel.OperationAccrual
      | OperationAccrualsModel.OperationAccrualCreate
  ): boolean => operation.tags?.includes(JournalComposedEntryTags.RECOVERY);
  export const isOperationClosure = (
    operation:
      | JournalComposedEntry
      | OperationAccrualsModel.OperationAccrual
      | OperationAccrualsModel.OperationAccrualCreate
  ): boolean => operation.tags?.includes(JournalComposedEntryTags.CLOSURE);
  export const isOperationLoss = (
    operation:
      | JournalComposedEntry
      | OperationAccrualsModel.OperationAccrual
      | OperationAccrualsModel.OperationAccrualCreate
  ): boolean => operation.tags?.includes(JournalComposedEntryTags.LOSS);

  // Date in accounting period
  const isLineWithinAccountingPeriod = (line: JournalEntryLine, accountingPeriod: AccountingPeriod): boolean => {
    if (!line.date) {
      return false;
    }
    return isDateWithinAccountingPeriod(line.date, accountingPeriod);
  };
  export const isDateWithinAccountingPeriod = (date: string, accountingPeriod: AccountingPeriod): boolean => {
    const operationDate = date ? moment(date) : null;
    if (!operationDate || !operationDate.isValid()) {
      return false;
    }
    return operationDate.isBetween(moment(accountingPeriod.startAt), moment(accountingPeriod.endAt), "day", "[]");
  };
  export const filteredLines = (
    operationAccrual: OperationAccrualsModel.OperationAccrual,
    accountingPeriod: AccountingPeriod
  ) => {
    const lines: JournalEntryLine[] = operationAccrual.journalEntry.lines || [];
    let result = lines.filter((line) => isLineWithinAccountingPeriod(line, accountingPeriod));
    if (isOperationRecovery(operationAccrual)) {
      // If the operation is reconciled, we inject the bank line and its counterpart if they are included in the accounting period. Otherwise, nothing is retrieved because the initial amount is already included in the balance sheet as part of a recovery operation
      const bankLine = result.find((line) => line.account === LedgerAccountEnum.N512000);
      const reportedLossLine = result.find((line) => line.account === LedgerAccountEnum.N654000);
      if (operationAccrual.reconciliation && bankLine) {
        result = result
          .filter((line) => {
            if (line.account === LedgerAccountEnum.N512000) {
              return true;
            }
            return isDoubleEntryAccount(line.account) && bankLine?.direction !== line.direction;
          })
          .map((line) => ({ ...line, amount: Number(line.amount.toFixed(0)) })); // We round the amounts when it's a recovery accrual because the specified amount is rounded.
      } else if (operationAccrual.reportedLoss && reportedLossLine) {
        // Same for reported loss
        result = result.filter((line) => {
          if (line.account === LedgerAccountEnum.N654000) {
            return true;
          }
          return isDoubleEntryAccount(line.account) && reportedLossLine?.direction !== line.direction;
        });
      } else {
        result = [];
      }
    }

    return result;
  };

  // Double entry
  const DoubleEntryAccountByAccount: { [key in OperationAccrualsModel.DoubleEntryAccounts]: LedgerAccountEnum[] } = {
    [LedgerAccountEnum.N411000]: [
      LedgerAccountEnum.N706000,
      LedgerAccountEnum.N708399,
      // notAvailableForAccrualCategorization
      LedgerAccountEnum.N706001,
      LedgerAccountEnum.N706101,
      LedgerAccountEnum.N758000,
      LedgerAccountEnum.N708300,
      LedgerAccountEnum.N775000,
      LedgerAccountEnum.N761000,
      LedgerAccountEnum.N791400,
      LedgerAccountEnum.N654000, // Reported loss
    ],
    [LedgerAccountEnum.N401000]: [
      LedgerAccountEnum.N615200,
      LedgerAccountEnum.N615210,
      LedgerAccountEnum.N606110,
      LedgerAccountEnum.N606300,
      LedgerAccountEnum.N606400,
      LedgerAccountEnum.N614020,
      LedgerAccountEnum.N622610,
      LedgerAccountEnum.N622700,
      LedgerAccountEnum.N623700,
      LedgerAccountEnum.N618000,
      LedgerAccountEnum.N625100,
      LedgerAccountEnum.N625700,
      LedgerAccountEnum.N627200,
      LedgerAccountEnum.N616100,
      // notAvailableForAccrualCategorization
      LedgerAccountEnum.N615310,
      LedgerAccountEnum.N635121,
      LedgerAccountEnum.N635125,
      LedgerAccountEnum.N695000,
      LedgerAccountEnum.N614010,
      LedgerAccountEnum.N671000,
      LedgerAccountEnum.N671400,
      LedgerAccountEnum.N675000,
      LedgerAccountEnum.N627800,
      LedgerAccountEnum.N661100,
      LedgerAccountEnum.N661600,
    ],
  };
  const notAvailableForAccrualCategorization: LedgerAccountEnum[] = [
    LedgerAccountEnum.N706001,
    LedgerAccountEnum.N706101,
    LedgerAccountEnum.N758000,
    LedgerAccountEnum.N708300,
    LedgerAccountEnum.N775000,
    LedgerAccountEnum.N761000,
    LedgerAccountEnum.N791400,
    LedgerAccountEnum.N654000,
    LedgerAccountEnum.N615310,
    LedgerAccountEnum.N635121,
    LedgerAccountEnum.N635125,
    LedgerAccountEnum.N654000,
    LedgerAccountEnum.N695000,
    LedgerAccountEnum.N614010,
    LedgerAccountEnum.N671000,
    LedgerAccountEnum.N671400,
    LedgerAccountEnum.N675000,
    LedgerAccountEnum.N627800,
    LedgerAccountEnum.N661100,
    LedgerAccountEnum.N661600,
  ];
  export const isDoubleEntryAccount = (
    value: LedgerAccountEnum | string
  ): value is OperationAccrualsModel.DoubleEntryAccounts => {
    return [
      LedgerAccountEnum.N411000,
      LedgerAccountEnum.N401000,
      LedgerAccountEnum.N419100,
      LedgerAccountEnum.N409100,
    ].includes(value as LedgerAccountEnum);
  };
  export const getDoubleEntryAccount = (
    value: LedgerAccountEnum | string,
    onlyCategorizable?: boolean
  ): LedgerAccountEnum => {
    for (const [key, values] of Object.entries(DoubleEntryAccountByAccount)) {
      if (values.includes(value as LedgerAccountEnum)) {
        if (onlyCategorizable && notAvailableForAccrualCategorization.includes(value as LedgerAccountEnum)) {
          return LedgerAccountEnum.UNKNOWN;
        }
        return key as LedgerAccountEnum;
      }
    }
    return LedgerAccountEnum.UNKNOWN;
  };
  export const getDoublyEntryAccountFromReconciliationAccount = (
    value: LedgerAccountEnum | string
  ): LedgerAccountEnum => {
    let result: LedgerAccountEnum;
    switch (value) {
      case LedgerAccountEnum.N706010:
        result = LedgerAccountEnum.N411000;
        break;

      case LedgerAccountEnum.N606010:
        result = LedgerAccountEnum.N401000;
        break;
      default:
        result = LedgerAccountEnum.UNKNOWN;
    }
    return result;
  };
  export const isClientAccount = (lines: JournalEntryLine[]): boolean =>
    lines.some((value) => getDoubleEntryAccount(value.account) === LedgerAccountEnum.N411000);

  export const isProviderAccount = (lines: JournalEntryLine[]): boolean =>
    lines.some((value) => getDoubleEntryAccount(value.account) === LedgerAccountEnum.N401000);
  export const getDirectionByDoubleEntryAccount = (account: OperationAccrualsModel.DoubleEntryAccounts) => {
    if (account === LedgerAccountEnum.N411000) {
      return Direction.credit;
    }
    if (account === LedgerAccountEnum.N401000) {
      return Direction.debit;
    }
    return;
  };

  // Amount
  export const getAmount = (
    operationAccrual: OperationAccrualsModel.OperationAccrual | OperationAccrualsModel.OperationAccrualCreate
  ) => {
    const lines: JournalEntryLine[] = operationAccrual.journalEntry.lines || [];
    const clientAccount: boolean = isClientAccount(lines);
    const providerAccount: boolean = isProviderAccount(lines);
    const filteredLines =
      lines.filter((line) => {
        if (line.account === LedgerAccountEnum.N512000) {
          return false;
        }
        if (operationAccrual.reportedLoss) {
          if (clientAccount && line.direction !== Direction.credit) {
            return false;
          }
          if (providerAccount && line.direction !== Direction.credit) {
            return false;
          }
        }
        return true;
      }) || [];

    return filteredLines
      .reduce((acc, line) => {
        const amount = new Decimal(line.amount || 0);
        return line.direction === Direction.debit ? acc.minus(amount) : acc.plus(amount);
      }, new Decimal(0))
      .toNumber();
  };
  export const getDoubleEntryAmount = (
    operationAccrual: OperationAccrualsModel.OperationAccrual | OperationAccrualsModel.OperationAccrualCreate
  ) => {
    const lines: JournalEntryLine[] = operationAccrual.journalEntry.lines || [];
    const filteredLines =
      lines.filter((line) => getDoubleEntryAccount(line.account) !== LedgerAccountEnum.UNKNOWN) || [];

    return filteredLines
      .reduce((acc, line) => {
        const amount = new Decimal(line.amount || 0);
        return line.direction === Direction.debit ? acc.minus(amount) : acc.plus(amount);
      }, new Decimal(0))
      .toNumber();
  };
  export const validateRecoveryAmount = (
    operationAccrualToAdd: { amount: number; doubleEntryAccount: LedgerAccountEnum | string },
    operationAccrualsRecovery: OperationAccrualsModel.OperationAccrual[],
    previousBalanceSheet: AccountingBalanceSheet
  ): boolean => {
    const recoveryTotalAmount = new Decimal(
      operationAccrualsRecovery
        .filter((operationAccrualRecovery) =>
          operationAccrualRecovery.journalEntry.lines?.find(
            (line) => getDoubleEntryAccount(line.account) === operationAccrualToAdd.doubleEntryAccount
          )
        )
        .reduce((acc, operationAccrual) => {
          const totalAmount = getDoubleEntryAmount(operationAccrual);
          return acc + totalAmount;
        }, 0)
    ).plus(operationAccrualToAdd.amount);
    const previousBalanceSheetAmount =
      previousBalanceSheet.lines.find((line) => line.account === operationAccrualToAdd.doubleEntryAccount)?.amount ?? 0;

    return new Decimal(previousBalanceSheetAmount).gte(Math.round(recoveryTotalAmount.toNumber()));
  };
}
