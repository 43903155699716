var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"accrualForm"}},[(_vm.firstStep)?_c('div',[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.validateFirstStep()}}},[_c('v-row',[_c('div',{staticClass:"mb-4",style:({ width: '100%' })},[_c('DatePicker',{attrs:{"refId":"addAccrualDate","max":_vm.accrualState.endAt,"min":_vm.accrualState.startAt,"label":"Date","required":"","rules":[
              function () { return !!_vm.accrualState.date || 'Le champ ne peut pas être vide'; } ]},model:{value:(_vm.accrualState.date),callback:function ($$v) {_vm.$set(_vm.accrualState, "date", $$v)},expression:"accrualState.date"}})],1)]),_c('v-row',[_c('v-text-field',{attrs:{"hide-details":"auto","id":"addAccrualAmount","disabled":!!_vm.transaction,"rules":[
            function () { return (_vm.accrualState.amount &&
                /^-?[0-9]\d*(\.\d+)?$/.test(
                  _vm.accrualState.amount.toString()
                )) ||
              'Le champ ne peut contenir que des nombres positifs'; },
            function () { return !(_vm.accrualState.amount == 0) ||
              "L'engagement ne peut pas être égal à 0"; },
            function () { return (_vm.accrualState.amount && _vm.accrualState.amount > 0) ||
              'Le nombre ne peut pas être négatif'; },

            function () { return (_vm.accrualState.amount &&
                _vm.countDecimals(_vm.accrualState.amount) <= 2) ||
              'Le montant doit contenir au maximum deux décimales'; } ],"label":"Montant","outlined":"","required":"","type":"number"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-select',{attrs:{"id":"selectionType","items":[
                { label: 'Crédit', val: 'crédit' },
                { label: 'Débit', val: 'débit' } ],"disabled":!!_vm.transaction || !!_vm.account,"label":"Choisir","outlined":"","item-text":"label","item-value":"val","rules":[function (v) { return !!v || 'Crédit ou Débit'; }],"required":""},model:{value:(_vm.currency),callback:function ($$v) {_vm.currency=$$v},expression:"currency"}})]},proxy:true}],null,false,3279431707),model:{value:(_vm.accrualState.amount),callback:function ($$v) {_vm.$set(_vm.accrualState, "amount", $$v)},expression:"accrualState.amount"}})],1),_c('v-row',[_c('v-text-field',{attrs:{"id":"addAccrualLabel","rules":[
            function () { return !!_vm.accrualState.summary || 'Le champ ne peut pas être vide'; } ],"label":"Libellé","outlined":"","required":"","type":"text"},model:{value:(_vm.accrualState.summary),callback:function ($$v) {_vm.$set(_vm.accrualState, "summary", $$v)},expression:"accrualState.summary"}})],1),_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"mt-1",attrs:{"color":"primary","depressed":"","type":"submit"}},[_vm._v(" Valider ")])],1)],1)],1):_vm._e(),(_vm.secondStep)?_c('div',[(!_vm.accrualState.isOpenCategorizationDetailStep)?_c('h4',{staticClass:"mb-5"},[_vm._v(" "+_vm._s(_vm.$d(new Date(_vm.accrualState.date), "day-month-year-long", "fr-FR"))+" ")]):_vm._e(),_c('div',{class:[
        'accrual-create mt-3',
        {
          'accrual-create--with-border':
            !_vm.accrualState.isOpenCategorizationDetailStep,
        } ]},[_c('div',{class:[
          'mx-2 mx-md-6 my-4',
          {
            'd-flex': !_vm.accrualState.isOpenCategorizationDetailStep,
          } ]},[(
            _vm.$vuetify.breakpoint.mdAndUp &&
            !_vm.accrualState.isOpenCategorizationDetailStep
          )?_c('div',{staticClass:"mr-6"},[_c('v-avatar',{staticClass:"accrual-icon",attrs:{"color":_vm.computedAvatar,"size":"22"}},[_c('v-icon',{attrs:{"color":"#fff","small":""}},[_vm._v(" "+_vm._s(_vm.accrualState.amount >= 0 ? "mdi-plus" : "mdi-minus")+" ")])],1)],1):_vm._e(),_c('div',[_c('div',{staticClass:"accrualDetails mr-10 mb-6"},[_c('h2',{staticClass:"accrualDetails-summary mr-10"},[_vm._v(" "+_vm._s(_vm.accrualState.summary)+" ")]),_c('h3',{staticClass:"accrualDetails-amount"},[_vm._v(" "+_vm._s(_vm.$n(_vm.accrualState.amount, "currency", "fr-FR"))+" ")])]),(_vm.accrualState.isOpenCategorizationDetailStep)?_c('div',[_c('div',{staticClass:"mx-2 mx-md-5 mb-3"},[(_vm.accrualState.isOpenCategorizationDetailStep === 1)?_c('CategorizationStep1',{attrs:{"accrualState":_vm.accrualState},on:{"update:accrualState":function($event){_vm.accrualState=$event},"update:accrual-state":function($event){_vm.accrualState=$event}}}):_vm._e(),(_vm.accrualState.isOpenCategorizationDetailStep === 2)?_c('CategorizationStep2',{attrs:{"accrualState":_vm.accrualState},on:{"update:accrualState":function($event){_vm.accrualState=$event},"update:accrual-state":function($event){_vm.accrualState=$event},"validate":function($event){return _vm.$emit('validate')}}}):_vm._e()],1)]):_c('div',[(
                _vm.accrualState.lines.length === 0 ||
                _vm.accrualState.isOpenCategorizationList === false
              )?_c('v-btn',{staticClass:"accrual-categorize-btn my-3",attrs:{"id":"track-accrual-categorize_main","color":"#F4655F","depressed":""},on:{"click":function($event){_vm.accrualState.isOpenCategorizationList = true}}},[_vm._v(" Catégoriser ")]):_vm._e()],1)])]),_c('div',[_c('DialogRight',{attrs:{"dialogModel":_vm.accrualState.isOpenCategorizationList}},[(_vm.accrualState.isOpenCategorizationList)?_c('Categories',{attrs:{"accrualState":_vm.accrualState,"categories":_vm.categoriesWithDoubleEntry},on:{"update:accrualState":function($event){_vm.accrualState=$event},"update:accrual-state":function($event){_vm.accrualState=$event}}}):_vm._e()],1)],1),_c('Next',{ref:"confirmDialog"})],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }