import { Document, model, Schema, ToObjectOptions } from "mongoose";
import { ulid } from "ulid";
import { CurrencyAndAmount, decimal2JSON, IsoDate, RequireField } from "./Common.model";
import { JournalComposedEntry, LedgerAccountName, LedgerAccountNumber, TypeReference } from "./JournalComposedEntry";
import { OperationModelSpec, TransactionModelSpec } from "./Definitions.model";
import { PartialField } from "..";

// Enum of type of transaction outside of Budget Insight
// It corresponds to transaction created or importer by User himself
export enum TransactionImportType {
  MANUAL = "MANUAL", // "Manual Transaction" created by user
  IMPORT = "IMPORT", // "Import transaction" imported by user
  EXPENSE_REPORT = "EXPENSE_REPORT", // "Expense Report" created by user for IS
  SYSTEM = "SYSTEM", // "Manual Transaction" created by system
  AMORTIZATION = "AMORTIZATION", // "Amortization" created by the system
  ACQUISITION = "ACQUISITION", // "Acquisition" created by the system
}
export enum TransactionImportTypeUser {
  MANUAL = "MANUAL", // "Manual Transaction" created by user
  IMPORT = "IMPORT", // "Import transaction" imported by user
  EXPENSE_REPORT = "EXPENSE_REPORT", // "Expense Report" created by user for IS
}

/**
 * `TransactionType` — The type of transaction
 * The type of transaction
 */
export type TransactionType =
  | "transfer" // Transfer
  | "order" // Order
  | "check" // Check
  | "deposit" // Deposit
  | "payback" // Payback
  | "withdrawal" // Withdrawal
  | "loan_payment" // Loan payment
  | "bank" // Bank fees
  | "card" // Card operation
  | "deferred_card" // Deferred card operation
  | "summary_card" // Monthly debit of a deferred card
  | "unknown" // Unknown transaction type
  | "MANUAL" // @see TransactionImportType
  | "EXPENSE_REPORT" // @see TransactionImportType
  | "IMPORT" //@see  TransactionImportType
  | "OWNILY" //@see  TransactionImportType
  | "SYSTEM" //@see  TransactionImportType
  | "AMORTIZATION" //@see  TransactionImportType
  | "ACCOUNTING_BALANCE_SHEET"
  | "ACQUISITION" // Acquisition
  | "ACCRUAL"

/**
 * `Transaction` — A bank account transaction
 */
export interface Transaction {
  id: string; // Transaction Index (_id is not exposed) @see Schema.toJSON
  bankAccountId: string;
  source: {
    provider: string;
    accountId?: string;
    transactionId?: string;
  };
  deleted?: boolean;
  type: TransactionType;
  date: {
    operation: IsoDate;
    realization?: IsoDate;
    value?: IsoDate;
    reconciled?: IsoDate;
    // book?: IsoDate;
  };
  summary: string; // Textual description of the operation
  value: CurrencyAndAmount;
  grossValue?: CurrencyAndAmount;
  commission: CurrencyAndAmount;
  originalValue?: CurrencyAndAmount;
  originalGrossValue?: CurrencyAndAmount;
  originalTransaction?: string;
  // Virtual data
  operations?: JournalComposedEntry; // Not saved in transaction collection
  createdAt: string;
  updatedAt: string;
}

export type NewBankAccountTransaction = Partial<
  Omit<Transaction, "id" | "deleted" | "operations" | "createdAt" | "updatedAt">
>;
export type NewBankAccountTransactionInternal = PartialField<
  Pick<Omit<Transaction, "date">, "bankAccountId" | "value" | "summary" | "type">,
  "type"
> & {
  date: Omit<Transaction["date"], "realization" | "value" | "book" | "reconciled">;
};

export type BankAccountTransactionUpdate = RequireField<
  Partial<Omit<Transaction, "operations" | "createdAt" | "updatedAt">>,
  "source"
>;

export type NewCompanyTransaction = Pick<Omit<Transaction, "date">, "value" | "summary" | "type"> & {
  date: Omit<Transaction["date"], "realization" | "value" | "book" | "reconciled">;
};
export type NewCompanyTransactionInternal = PartialField<
  Pick<
    Omit<Transaction, "date">,
    "bankAccountId" | "value" | "summary" | "type" | "source" | "commission" | "originalTransaction"
  >,
  "source" | "commission"
> & {
  date: PartialField<Omit<Transaction["date"], "realization" | "book" | "reconciled">, "value">;
};
export type NewCompanyTransactionRepository = PartialField<
  Pick<Omit<Transaction, "date">, "bankAccountId" | "value" | "summary" | "type" | "source" | "commission">,
  "type"
> & {
  date: Omit<Transaction["date"], "realization" | "value" | "book">;
};

export type CompanyTransactionUpdate = PartialField<
  Pick<Omit<Transaction, "date">, "id" | "value" | "summary" | "type">,
  "type"
> & {
  date: Omit<Transaction["date"], "realization" | "value" | "book" | "reconciled">;
};
export type CompanyTransactionUpdateInternal = PartialField<
  Pick<Omit<Transaction, "date">, "id" | "bankAccountId" | "value" | "summary" | "type">,
  "type"
> & {
  date: Omit<Transaction["date"], "realization" | "value" | "book">;
};

export type NewTransaction = NewBankAccountTransaction | NewCompanyTransaction | NewBankAccountTransactionInternal;
export type TransactionUpdate = BankAccountTransactionUpdate | CompanyTransactionUpdate;
export type TransactionUpdateInternal = CompanyTransactionUpdateInternal;

export const transactionSchema = new Schema<TransactionDocument>(
  {
    _id: { type: String, default: () => ulid() },
    bankAccountId: { type: String, required: true, index: true },
    source: {
      provider: { type: String, required: true },
      accountId: { type: String },
      transactionId: { type: String },
    },
    deleted: Boolean,
    type: String,
    date: {
      operation: { type: String, required: true, index: true }, // TODO MOVE IN DATE LATER with Operations service.transactions.companies.accounting.operations
      realization: String,
      value: String,
      book: String,
      reconciled: String,
    },
    value: { amount: Schema.Types.Decimal128, currency: String },
    summary: String,
    commission: { amount: Schema.Types.Decimal128, currency: String },
    originalValue: { amount: Schema.Types.Decimal128, currency: String },
    originalTransaction: String,
  },
  {
    timestamps: true,
    toJSON: {
      versionKey: false,
      virtuals: true,
      transform(
        doc: Omit<TransactionDocument, "createdAt" | "updatedAt"> & { _id: string; createdAt: Date; updatedAt: Date },
        ret: Transaction & { _id?: string },
        options: ToObjectOptions
      ) {
        delete ret._id;
        ret.id = doc._id;
        decimal2JSON(ret);
        return ret;
      },
    },
    toObject: { virtuals: true },
  }
);

transactionSchema.virtual("operations", {
  ref: OperationModelSpec.NAME,
  localField: "_id",
  foreignField: "transactionId",
  justOne: true,
});

// Index unique on sources fields
transactionSchema.index(
  {
    "source.provider": 1,
    "source.accountId": 1,
    "source.transactionId": 1,
  },
  {
    unique: true,
  }
);

export interface TransactionStatsStockTransactions extends Transaction {
  journalComposedEntry: JournalComposedEntry[];
}
export interface TransactionStats {
  stockTransactions: {
    total: number;
    valueAbsoluteDevise: number;
    categorizeTotal: number;
    categorizeValueAbsoluteDevise: number;
  };
  date: string;
}

/**
 * `Suggestion` — Suggestion for categorization
 */
export interface Suggestion {
  number: LedgerAccountNumber;
  name: LedgerAccountName;
  /**
   * Grouping entity.
   *
   * This might be used in presentation to simplify the layout
   * of the list of suggested accounts.
   */
  parent: string;

  accountBalance: "Actif" | "Passif" | "Produit" | "Charges" | "Technique";
  accountBalanceType: "fixed_assets" | "current_assets" | "shareholders_equity" | "debts";

  /**
   * A textual description of the intent of this ledger account.
   */
  description?: string;
  /**
   * List of fields the user might provide.
   *
   * The fields are the fields in CategorizationEntry, for example
   * `realEstateAsset`, `rentalUnit`, `partner`.
   *
   * The list in `fields` includes all the names also requested
   * in `required`.
   */
  fields?: TypeReference[];
  /**
   * List of fields the user must provide
   */
  required?: TypeReference[];

  sort: number;

  display: boolean;
  displayBilan: boolean;

  referenceCounter: TypeReference;
}

/**
 * `Suggestions` — Suggestions for categorization
 */
export interface Suggestions {
  suggest: Suggestion[];
}

export type TransactionDocument = Transaction & Document<string>;

// Name of the collection in third argument
export const TransactionModel = model<TransactionDocument>(
  TransactionModelSpec.NAME,
  transactionSchema,
  TransactionModelSpec.COLLECTION
);

// HELPER FUNCTION

// return true is transaction is created by User
export const isUserTransaction = (transaction: Transaction) =>
  Object.keys(TransactionImportTypeUser).includes(transaction.source.provider);

export const isDeletedTransaction = (transaction: Transaction) => transaction.deleted === true;

export const isReconciledTransaction = (transaction: Transaction) => !!transaction.date.reconciled;

export namespace BankAccountsTransactionsService {
  export type ListIn = Pick<Transaction, "bankAccountId">;
  export type ListOut = Transaction[];

  export type ImportIn = {
    document: Pick<Transaction, "bankAccountId"> & {
      accountingPeriodId: string;
      startAt: string;
      endAt: string;
      duplicationResolves?: { transactionToImportId: string; resolveOption: "import" | "noImport" }[];
    };
    file: File;
  };
  export type ImportOut<Status extends boolean = boolean> = (
    | { status: true; transactionIds: string[] }
    | {
        status: false;
        transactionsDuplicate: {
          transactionToImport: NewCompanyTransactionRepository & { id: string };
          transactionsConflict: Transaction[];
        }[];
        transactionsIgnored: (NewCompanyTransactionRepository & { id: string })[];
      }
  ) & {
    status: Status;
    transactionIds: string[];
  };
}

export namespace TransactionsService {
  export type CreateIn = NewCompanyTransaction & { productId: string };
  export type CreateOut = Transaction;

  export type ImportIn = BankAccountsTransactionsService.ImportIn;
  export type ImportOut<Status extends boolean = boolean> = BankAccountsTransactionsService.ImportOut<Status>;

  export type ListIn = { productId: string };
  export type ListOut = Transaction[];

  export type ListPeriodIn = { accountingPeriodId: string; productId: string };
  export type ListPeriodOut = Transaction[];

  export type GetIn = Pick<Transaction, "id">;
  export type GetOut = Transaction;

  export type UpdateIn = CompanyTransactionUpdate & { productId: string };
  export type UpdateOut = Transaction;

  export type DeleteIn = Pick<Transaction, "id">;
  export type DeleteOut = boolean;

  export type CountIn = { bankAccountId?: string };
  export type CountOut = {
    bankAccountId?: string;
    count: number;
  };

  export type TotalIn = never;
  export type TotalOut = { total: number };

  export type StockIn = { date?: string };
  export type StockOut = TransactionStats;
}
