var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('PageHeader',{attrs:{"displayAccountingPeriod":""}},[_vm._v("Mes documents comptables")]),_c('div',{staticClass:"section"},[_c('v-row',[_vm._l((_vm.accountingDocuments),function(accountingDocument,index){return _c('v-col',{key:index,attrs:{"cols":_vm.$vuetify.breakpoint.xsOnly
            ? '12'
            : _vm.$vuetify.breakpoint.mdAndDown
            ? '6'
            : '4'},on:{"click":function($event){_vm.isAvailableToRedirect(accountingDocument)
            ? _vm.$router.push({ name: ("" + (accountingDocument.name)) })
            : false}}},[_c('v-card',{attrs:{"outlined":""}},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":"primary","height":"10","indeterminate":""}})],1),_c('v-card-text',[_c('p',{attrs:{"id":"accountingDocument-card-name"}},[_c('b',[_vm._v(_vm._s(accountingDocument.documentName))])]),_c('div',{staticClass:"justify-end section-action d-flex"},[_c('div',{staticClass:"footer-card",on:{"click":function($event){$event.stopPropagation();}}},[(accountingDocument.comingSoon)?_c('v-badge',{attrs:{"content":"À venir","inline":"","color":"success"}}):_vm._e(),(accountingDocument.new)?_c('v-badge',{attrs:{"content":"new","inline":"","color":"success"}}):_vm._e(),(accountingDocument.availableAfterClosure)?_c('v-badge',{attrs:{"content":"Disponible après la cloture","inline":"","color":"warning"}}):_vm._e(),(accountingDocument.availableForPremiumOnly)?_c('v-badge',{attrs:{"content":"Disponible en Premium","inline":"","color":"warning"}}):_vm._e(),(
                    accountingDocument.reportings &&
                    !accountingDocument.disabled
                  )?[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"id":"productTour-exportReporting","color":"primary","depressed":"","small":"","outlined":""}},on),[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-upload")]),_vm._v(" Export "),_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}}],null,true)},[_c('v-list',{staticClass:"pa-0"},_vm._l((accountingDocument.reportings),function(reporting){return _c('v-list-item',{key:reporting.name,staticClass:"ma-2",attrs:{"id":"track-export-balance-report","link":""},on:{"click":function($event){return reporting.download()}}},[_c('v-list-item-title',[_vm._v(_vm._s(reporting.name))])],1)}),1)],1)]:_vm._e(),(_vm.isAvailableToRedirect(accountingDocument))?_c('v-btn',{staticClass:"btn-link",attrs:{"id":"accountingDocument-card-detail","text":"","small":"","depressed":""},on:{"click":function($event){return _vm.$router.push({ name: accountingDocument.name })}}},[_c('span',[_vm._v(" Voir le détail "),_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-eye-outline")])],1)]):_vm._e()],2)])])],2)],1)}),(
          ((_vm.accountingPeriodsStore.isIR && _vm.subscriptionsStore.isPremium) ||
            !_vm.accountingPeriodsStore.isIR) &&
          _vm.accountingBalanceSheetsStore.hasPreviousYearBalanceSheetRecovery &&
          !_vm.accountingBalanceSheetsStore.isSkippedAccountingBalanceSheetRecovery
        )?_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.xsOnly
            ? '12'
            : _vm.$vuetify.breakpoint.mdAndDown
            ? '6'
            : '4'}},[_c('v-card',{attrs:{"outlined":""}},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":"primary","height":"10","indeterminate":""}})],1),_c('v-card-text',[_c('p',{attrs:{"id":"accountingDocument-card-name"}},[_c('b',[_vm._v("Ma reprise de bilan")])]),_c('div',{staticClass:"justify-end section-action d-flex"},[_c('v-btn',{staticClass:"btn-link",attrs:{"text":"","small":"","depressed":""},on:{"click":function($event){return _vm.$router.push({
                    name: _vm.ROUTE_NAMES.AccountingBalanceSheetRecovery,
                  })}}},[_c('span',[_vm._v(" Voir le détail "),_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-eye-outline")])],1)])],1)])],2)],1):_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }