import { productsStore, transactionsStore } from "@/store";

import {
  AnomalyErrorResult,
  calculateTaxAmounts,
  CategorizationEntry,
  isPlanBankAccount,
  JournalEntryLine,
  JournalEntryReference,
  Suggestion,
  Transaction,
  TransactionImportType,
} from "@edmp/api";
import { Direction, TypeReference } from "@edmp/api";
import { computed, SetupContext } from "@vue/composition-api";
import Decimal from "decimal.js-light";
import { cloneDeep } from "lodash";
import { useCategorization } from "../categorization/categorization.usable";

export interface TransactionState {
  transaction: Transaction;

  savedCategories: CategorizationEntry[]; // savedCategories displayed on transactionCard and updated after saving
  lines: CategorizationEntry[]; // lines, categories used in edition in Categorization modals
  suggestedLines: CategorizationEntry[]; // suggestedLines, categories suggested on transactionCard
  suggestedTransactionIds: string[];
  selectedCategory?: Suggestion;
  anomaliesErrors: AnomalyErrorResult[];

  isOnAddManualTransaction: boolean;
  isOnDuplicateTransaction: boolean;
  isOpenCategorizationList: boolean;
  isOpenCategorizationDetailStep: number | false;
  isOpenCategorizationDuplicate: boolean;
  isOpenReconciliation: boolean;
  isUpdatingCategorization: boolean; // boolean used when saving categorization to display loading bar
  isCategorized: boolean;
  isManualTransaction: boolean;
  isImportTransaction: boolean;
  isSystemTransaction: boolean;
  isAmortizationTransaction: boolean;
  isAcquisitionTransaction: boolean;
  isExpenseTransaction: boolean;
}

// TODO in back to simplify here... Back should return CategoryEntry in controller. (front should work with category and no lines)
export const formatLinesTransaction = (
  categories: JournalEntryLine[]
): CategorizationEntry[] =>
  categories
    .filter(
      (line: JournalEntryLine) =>
        // Account 512000 and 512100
        !isPlanBankAccount(line.account) && line.account !== "512100"
    )
    .map((line: JournalEntryLine) => {
      const category: CategorizationEntry = {
        account: line.account,
        accountName: line.accountName,
        amount:
          line.direction === Direction.credit ? line.amount : -line.amount,
      };

      if (line.refs) {
        //Add each ref type (realEstateAsset, rentalUnit, partner, client) and id associated to object category
        line.refs.forEach((ref) => (category[ref.type] = ref.referredId));
      }
      return category;
    });

export const initTransactionState = (
  transaction: Transaction,
  isOnAddManualTransaction: boolean
): TransactionState => ({
  transaction: transaction,

  savedCategories:
    transaction.operations?.journalEntry?.lines &&
    transaction.operations?.journalEntry?.lines.length > 0
      ? formatLinesTransaction(transaction.operations?.journalEntry?.lines)
      : [],
  lines:
    transaction.operations?.journalEntry?.lines &&
    transaction.operations?.journalEntry?.lines.length > 0
      ? formatLinesTransaction(
          transaction.operations?.journalEntry?.lines
        ).reverse()
      : [],
  suggestedLines:
    transaction.operations?.journalEntry?.suggestedLines &&
    transaction.operations?.journalEntry?.suggestedLines.length > 0
      ? formatLinesTransaction(
          transaction.operations?.journalEntry?.suggestedLines
        )
      : [],
  suggestedTransactionIds: [],
  anomaliesErrors: [],

  isOnAddManualTransaction: isOnAddManualTransaction,
  isOnDuplicateTransaction: false,
  isUpdatingCategorization: false,
  isOpenCategorizationList: false,
  isOpenCategorizationDetailStep: false,
  isOpenCategorizationDuplicate: false,
  isOpenReconciliation: false,
  isCategorized:
    (transaction.operations?.journalEntry?.lines &&
      transaction.operations?.journalEntry?.lines.length > 0) ||
    false,
  isManualTransaction:
    transaction.source.provider === TransactionImportType.MANUAL || false,
  isImportTransaction:
    transaction.source.provider === TransactionImportType.IMPORT || false,
  isSystemTransaction:
    transaction.source.provider === TransactionImportType.SYSTEM || false,
  isAmortizationTransaction:
    transaction.source.provider === TransactionImportType.AMORTIZATION || false,
  isAcquisitionTransaction:
    transaction.source.provider === TransactionImportType.ACQUISITION || false,
  isExpenseTransaction:
    transaction.source.provider === TransactionImportType.EXPENSE_REPORT ||
    false,
});

export const useTransaction = (
  transactionState: TransactionState,
  context: SetupContext
) => {
  /**
   * Data
   */
  const transactionAmountTaxDecomposition = (rentalUnit) => {
    const transactionAmountTTC = new Decimal(
      transactionState.transaction.value.amount
    );
    const { getApplicableTaxRateTVA } = useCategorization(
      transactionState,
      context
    );
    if (rentalUnit) {
      const taxRateTVA =
        getApplicableTaxRateTVA(rentalUnit) || rentalUnit.taxRateTVA;
      return calculateTaxAmounts(taxRateTVA, transactionAmountTTC);
    }
  };

  const product = computed(() => productsStore.currentProduct);

  /**
   * * transactionState
   *
   * Use for get and update element in transactionState
   */
  const isManualTransaction = computed({
    get: () => transactionState.isManualTransaction,
    set: (isManual) =>
      context.emit(
        "update:transactionState",
        Object.assign(transactionState, { isManualTransaction: isManual })
      ),
  });

  /**
   * * Use
   */
  /**
   * It takes the transaction state and returns a transaction with the new categories
   * @returns A transaction object with the new categories.
   */
  const getTransactionWithNewCategories = () => {
    const transaction = Object.assign(cloneDeep(transactionState.transaction), {
      operations: Object.assign(
        cloneDeep(transactionState.transaction.operations) ?? {},
        {
          journalEntry: Object.assign(
            cloneDeep(
              transactionState.transaction.operations?.journalEntry
            ) ?? {
              date: cloneDeep(transactionState.transaction.date.operation),
            },
            {
              lines: cloneDeep(
                transactionState.lines.map((line) => {
                  const refs: JournalEntryReference[] = [];
                  if (line.realEstateAsset) {
                    refs.push({
                      type: TypeReference.realEstateAsset,
                      referredId: line.realEstateAsset,
                    });
                  }
                  if (line.rentalUnit) {
                    refs.push({
                      type: TypeReference.rentalUnit,
                      referredId: line.rentalUnit,
                    });
                  }
                  if (line.rentalAgreement) {
                    refs.push({
                      type: TypeReference.rentalAgreement,
                      referredId: line.rentalAgreement,
                    });
                  }
                  if (line.tenant) {
                    refs.push({
                      type: TypeReference.tenant,
                      referredId: line.tenant,
                    });
                  }
                  if (line.realEstateLoan) {
                    refs.push({
                      type: TypeReference.realEstateLoan,
                      referredId: line.realEstateLoan,
                    });
                  }
                  if (line.partner) {
                    refs.push({
                      type: TypeReference.partner,
                      referredId: line.partner,
                    });
                  }
                  if (line.supportingDocument) {
                    refs.push({
                      type: TypeReference.supportingDocument,
                      referredId: line.supportingDocument,
                    });
                  }
                  if (line.beneficiary) {
                    refs.push({
                      type: TypeReference.beneficiary,
                      referredId: line.beneficiary,
                    });
                  }

                  const amount = new Decimal(line.amount);

                  const journalEntryLine: JournalEntryLine = {
                    account: line.account,
                    accountName: line.accountName ?? "",
                    amount: line.amount,
                    direction:
                      amount.isPositive() || amount.isZero()
                        ? Direction.credit
                        : Direction.debit,
                    id: line.id,
                    refs,
                    anomalies: transactionState.anomaliesErrors,
                  };
                  return journalEntryLine;
                })
              ),
            }
          ),
        }
      ),
    });
    return transaction;
  };

  /**
   * It returns the summary of the transaction
   * @returns The summary of the transaction.
   */
  const getSummary = () => {
    let summary = "";
    const transaction = transactionState.transaction;
    if (transaction) {
      if (transaction.operations?.summary) {
        summary = transaction.operations.summary;
      } else {
        summary = transaction.summary;
      }
    }
    return summary;
  };

  const refreshTransaction = async (
    customTransactionId = transactionState.transaction.id
  ) => {
    await transactionsStore.fetchTransaction({
      id: customTransactionId,
    });
  };

  return {
    // Data
    transactionState,
    transactionAmountTaxDecomposition,
    product,
    // Store
    getTransactionWithNewCategories,
    isManualTransaction,
    // Use
    getSummary,
    refreshTransaction,
  };
};
