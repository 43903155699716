




























































import {
  accountingPeriodsStore,
  realEstateAssetsStore,
  subscriptionsStore,
} from "@/store";
import {
  getMoment,
  SubscriptionsModel,
  TaxDeclaration2065,
  TaxDeclaration2065LineNumberSummaryOfTaxElements,
} from "@edmp/api";
import moment from "moment";

import { computed, defineComponent, Ref, ref } from "@vue/composition-api";
type Event = {
  title: string;
  dueDate?: string;
};
export default defineComponent({
  name: "Events",
  setup() {
    const year = getMoment().format("YYYY");

    const arrayEventsIR: Ref<Event[]> = ref([
      {
        title: "Ouverture du service de télédéclaration sur impots.gouv.fr",
        dueDate: year + "-04-15",
      },
      {
        title: "Date limite de déclaration de résultat (papier)",
        dueDate: year + "-05-05",
      },
      {
        title: "Date limite de télédéclaration de résultat",
        dueDate: year + "-05-19",
      },
      {
        title: "Date limite de déclaration des revenus 2024 (Dpt : 01 à 19)",
        dueDate: year + "-05-25",
      },
      {
        title: "Date limite de déclaration des revenus 2024 (Dpt : 20 à 54)",
        dueDate: year + "-06-01",
      },
      {
        title: "Date limite de déclaration des revenus 2024 (Dpt : 55 à 976)",
        dueDate: year + "-06-08",
      },
      {
        title:
          "Déclaration de biens obligatoires par les propriétaires (ex : taxe logements vacants, taxe résidence secondaire)",
        dueDate: year + "-06-30",
      },
      {
        title:
          "Ouverture des modifications de la déclaration d’Impôt sur le Revenu",
        dueDate: year + "-08",
      },
      {
        title:
          "Date limite de paiement de la taxe foncière (si dématérialisée)",
        dueDate: year + "-10-15",
      },
      {
        title: "Date limite de paiement de la taxe foncière (en ligne)",
        dueDate: year + "-10-20",
      },
      {
        title: "Limite de modification de l’impôt sur le revenu",
        dueDate: year + "-12-01",
      },
    ]);

    const arrayEventsIS: Ref<Event[]> = ref([
      {
        title: "Ouverture du service de télédéclaration sur impots.gouv.fr",
        dueDate: year + "-04-15",
      },

      {
        title: "Date limite de déclaration de résultat (papier)",
        dueDate: year + "-05-05",
      },
      {
        title: "Date limite de paiement du solde N-1 de l’IS",
        dueDate: year + "-05-15",
      },
      {
        title: "Date limite de télédéclaration de résultat",
        dueDate: year + "-05-19",
      },

      {
        title:
          "Déclaration de biens obligatoires par les propriétaires (ex : taxe logements vacants, taxe résidence secondaire)",
        dueDate: year + "-06-30",
      },

      {
        title:
          "Date limite de paiement de la taxe foncière (si dématérialisée)",
        dueDate: year + "-10-15",
      },
      {
        title: "Date limite de paiement de la taxe foncière (en ligne)",
        dueDate: year + "-10-20",
      },
      {
        title: "Date limite d’adhésion au prélèvement pour la CFE",
        dueDate: year + "-11-30",
      },
      {
        title: "Date limite de paiement de la CFE",
        dueDate: year + "-12-15",
      },
    ]);

    const taxDeclaration2065: Ref<TaxDeclaration2065 | undefined> = ref();
    const benefit15RateAmount =
      taxDeclaration2065.value?.lines[
        TaxDeclaration2065LineNumberSummaryOfTaxElements.C_1_BENEFIT_15_RATE
      ]?.amount ?? 0;
    const benefitNormalRateAmount =
      taxDeclaration2065.value?.lines[
        TaxDeclaration2065LineNumberSummaryOfTaxElements.C_1_BENEFIT_NORMAL_RATE
      ]?.amount ?? 0;

    if (
      benefit15RateAmount > 0 ||
      benefitNormalRateAmount > 0 ||
      subscriptionsStore.getCurrentSubscription?.plan.type ===
        SubscriptionsModel.PlanType.Free
    ) {
      arrayEventsIS.value.push(
        {
          title: "Date limite dépôt de paiement du 1er acompte de l’IS",
          dueDate: year + "-03-15",
        },
        {
          title: "Date limite dépôt de paiement du 2ème acompte de l’IS",
          dueDate: year + "-06-16",
        },
        {
          title: "Date limite dépôt de paiement du 3ème acompte de l’IS",
          dueDate: year + "-09-16",
        },
        {
          title: "Date limite dépôt de paiement du 4ème acompte de l’IS",
          dueDate: year + "-12-16",
        }
      );
    }
    if (
      realEstateAssetsStore.rentalUnits.filter(
        (rentalUnit) => rentalUnit.taxTvaEnable === true
      ).length > 0
    ) {
      accountingPeriodsStore.isIS
        ? arrayEventsIS.value.push({
            title:
              "Date limite de dépôt de la déclaration annuelle de TVA si soumis à la TVA",
            dueDate: year + "-05-05",
          })
        : arrayEventsIR.value.push({
            title:
              "Date limite de dépôt de la déclaration annuelle de TVA si soumis à la TVA",
            dueDate: year + "-05-05",
          });
    }

    if (
      realEstateAssetsStore.rentalUnits.filter(
        (rentalUnit) => rentalUnit.crlEnable === true
      ).length > 0
    ) {
      arrayEventsIS.value.push({
        title: "Date limite de paiement de la CRL",
        dueDate: year + "-10-15",
      });
    }
    const page = ref(1);
    const itemsPerPage = 5;

    const eventsArray = computed(() =>
      Object.values(
        accountingPeriodsStore.isIR ? arrayEventsIR.value : arrayEventsIS.value
      )
    );
    const events = computed(() => {
      const start = (page.value - 1) * itemsPerPage;
      const end = start + itemsPerPage;
      eventsArray.value.sort(
        (a, b) => getMoment(a.dueDate).unix() - getMoment(b.dueDate).unix()
      );

      if (
        eventsArray.value.find(
          (event) =>
            event.title ===
            "Préparez votre clôture annuelle sereinement : Comptabilisez vos recettes et charges | Reprenez les soldes et les déficits"
        ) === undefined
      ) {
        eventsArray.value.unshift({
          title:
            "Préparez votre clôture annuelle sereinement : Comptabilisez vos recettes et charges | Reprenez les soldes et les déficits",
        });
      }

      return accountingPeriodsStore.currentAccountingPeriod?.endAt.slice(
        0,
        4
      ) === getMoment().subtract(1, "year").format("YYYY")
        ? eventsArray.value.slice(start, end)
        : [];
    });

    const totalPages = computed(() => {
      return Math.ceil(eventsArray.value.length / itemsPerPage);
    });

    function nextPage() {
      if (page.value < totalPages.value) {
        page.value++;
      }
    }

    function prevPage() {
      if (page.value > 1) {
        page.value--;
      }
    }
    return {
      events,
      moment,
      prevPage,
      nextPage,
      page,
      totalPages,
    };
  },
});
