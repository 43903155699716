



























































import { defineComponent, ref } from "@vue/composition-api";
import EditableTable from "@/components/atom/table/EditableTable.vue";
import TitleNew from "../title/TitleNew.vue";
import { operationsService } from "@/services";
import { productsStore } from "@/store";

export default defineComponent({
  name: "GenericChargesApplicationDialog",
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    EditableTable,
    TitleNew,
  },
  setup: (_, context) => {
    const applyTo = ref("noChange");

    const validateApplyCharges = () => {
      operationsService.adjustGenericCharges({
        productId: productsStore.currentId,
      });
      context.emit("close");
    };

    return {
      validateApplyCharges,
      applyTo,
    };
  },
});
