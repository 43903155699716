import { accountingsService } from "@/services";
import store from "@/store/root";
import { BalanceCategories, OperationLine, Suggestion } from "@edmp/api";
import { Dictionary } from "vue-router/types/router";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

/**
 * Store for accounting. Use it to persist data related to accounting.
 *
 * This store should be switch with accounting period
 */
export interface AccountingsState {
  operations: OperationLine[];
  loading: boolean;
}

@Module({
  name: "accountings-store",
  dynamic: true,
  namespaced: true,
  store,
})
export class AccountingsStore extends VuexModule implements AccountingsState {
  operations: OperationLine[] = [];
  currentId = "";
  loading = false;

  @Mutation
  reset(): void {
    this.loading = false;
  }

  @Mutation
  setLoading(isLoading: boolean): void {
    this.loading = isLoading;
  }

  @Mutation
  setOperations(operations: Array<OperationLine>): void {
    this.operations = [...operations].reverse(); // The fec operations is reversed in front (31/12 => 01/12)
  }
  @Action
  async getSuggestCategories(
    accountingPeriodId: string
  ): Promise<Dictionary<Suggestion[]>> {
    return await accountingsService.listCategories({
      accountingPeriodId,
    });
  }

  @Action
  async getOperations(accountingPeriodId: string): Promise<OperationLine[]> {
    return await accountingsService.listOperations({ accountingPeriodId });
  }

  @Action
  async fetchOperations(accountingPeriodId: string): Promise<void> {
    this.setLoading(true);
    this.setOperations([]);
    try {
      const operations = await accountingsService.listOperations({
        accountingPeriodId,
      });
      this.setOperations(operations);
    } catch (e) {
      console.error(e);
    }
    this.setLoading(false);
  }

  @Action
  async getBalanceCategories(params: {
    accountingPeriodId: string;
    includeBankAccountCategory?: boolean;
  }): Promise<BalanceCategories> {
    const balanceCategories = await accountingsService.getBalanceCategories(
      params
    );
    return balanceCategories;
  }
}
