





















































































import { VForm } from "@/models";
import { realEstateAssetsStore } from "@/store";
import { debounce } from "@/utils";
import { RealEstateAsset, RealEstateAssetUpdate } from "@edmp/api";
import { defineComponent, Ref, ref } from "@vue/composition-api";

export default defineComponent({
  name: "RealEstateAssetUpdate",
  props: {
    realEstateAsset: {
      type: Object as () => RealEstateAsset,
      required: true,
    },
  },
  setup(props, context) {
    const validateInProgress: Ref<boolean> = ref(false);
    const addressRef = ref(props.realEstateAsset.address);

    const address = ref({ ...addressRef.value });

    function resetForm() {
      if (address.value) {
        address.value.street = "";
        address.value.zip = "";
        address.value.city = "";
      }
      (context.refs.form as VForm).resetValidation();
    }

    async function validate() {
      validateInProgress.value = true;
      if ((context.refs.form as VForm).validate()) {
        try {
          // Udpate Adress in Tenant
          const realEstateAssetToUpdate: RealEstateAssetUpdate = {
            ...props.realEstateAsset,
            address: address.value,
          };
          // Update productStore
          await realEstateAssetsStore.updateRealEstateAsset(
            realEstateAssetToUpdate
          );

          context.emit("validate", address);

          resetForm();
        } catch (error) {
          console.error(Error);
        }
      }
      validateInProgress.value = false;
    }

    function closeDialog() {
      resetForm();
      context.emit("close");
    }
    const searchItemsStreetList = ref([]);
    const searchItemsCityList = ref([]);
    const searchItemsZipList = ref([]);

    const searchAddress = debounce((value: string, type: string) => {
      if (value.length > 2) {
        if (type === "street") {
          fetch(
            `https://api-adresse.data.gouv.fr/search/?q=${value}&limit=15&type=housenumber`
          )
            .then((response) => response.json())
            .then((data) => {
              const mappedData = data.features.map((feature) => {
                if (
                  feature.properties &&
                  feature.properties.name &&
                  feature.properties.postcode &&
                  feature.properties.city
                )
                  return {
                    text: `${feature.properties.name} ${feature.properties.postcode} - ${feature.properties.city}`,
                    value: feature.properties,
                  };
              });
              searchItemsStreetList.value = mappedData.filter(
                (mappedData) => !!mappedData
              );
            });
        } else if (type === "city") {
          fetch(
            `https://api-adresse.data.gouv.fr/search/?q=${value}&limit=15&type=municipality`
          )
            .then((response) => response.json())
            .then((data) => {
              const mappedData = data.features.map((feature) => {
                return {
                  text: `${feature.properties.city} - ${feature.properties.postcode}`,
                  value: feature.properties,
                };
              });
              searchItemsCityList.value = mappedData.filter(
                (mappedData) => !!mappedData
              );
            });
        } else if (type === "zip") {
          fetch(
            `https://api-adresse.data.gouv.fr/search/?q=${value}&limit=15&type=municipality`
          )
            .then((response) => response.json())
            .then((data) => {
              const mappedData = data.features.map((feature) => {
                return {
                  text: `${feature.properties.postcode} - ${feature.properties.city}`,
                  value: feature.properties,
                };
              });
              searchItemsZipList.value = mappedData.filter(
                (mappedData) => !!mappedData
              );
            });
        }
      }
    }, 500);

    const updateAddress = (event, field) => {
      if (address.value) {
        if (event.type === "keyup") {
          address.value[field] = event.target.value;
        } else {
          if (field === "street") {
            address.value.street = event.value.name;
            address.value.city = event.value.city;
            address.value.zip = event.value.postcode;
          } else if (field === "city") {
            address.value.city = event.value.city;
            address.value.zip = event.value.postcode;
          } else if (field === "zip") {
            address.value.city = event.value.city;
            address.value.zip = event.value.postcode;
          }
        }
      }
    };
    return {
      address,
      validateInProgress,
      validate,
      resetForm,
      closeDialog,
      searchAddress,
      updateAddress,
      searchItemsStreetList,
      searchItemsCityList,
      searchItemsZipList,
    };
  },
});
