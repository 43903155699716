



























































































































































































































































import AccountingPeriodChoice from "@/components/core/accounting-period/AccountingPeriodChoice.vue";
import Balance from "@/components/core/charts/Balance.vue";
import BalanceDetailsChart from "@/components/core/charts/BalanceDetailsChart.vue";
import TaskCard from "@/components/core/tasks/task/TaskCard.vue";
import { accountingsService } from "@/services";
import {
  accountingPeriodsStore,
  accountingResultsStore,
  accountingsStore,
  partnersStore,
  realEstateAssetsStore,
  tasksStore,
  transactionsStore,
  subscriptionsStore,
} from "@/store";
import { filteredTransactions, FilterKeys, FiltersParameters } from "@/utils";
import {
  AccountingType,
  BalanceCategories,
  Direction,
  getBalancePartners,
  getTotalBalancePartner,
  Partner,
  RealEstateAsset,
  TaxRegime,
  TaskStatus,
  AccountingResultLineType,
  getMoment,
} from "@edmp/api";
import {
  computed,
  ComputedRef,
  defineComponent,
  onBeforeMount,
  Ref,
  ref,
  watch,
} from "@vue/composition-api";
import { sum } from "lodash";
import TitleNew from "../title/TitleNew.vue";
import AccountingFinance from "./AccountingFinance.vue";
import AccountingMonthsTable from "./AccountingMonthsTable.vue";
import useAccounting from "./useAccounting";

export default defineComponent({
  name: "Accounting",
  components: {
    Balance,
    BalanceDetailsChart,
    AccountingPeriodChoice,
    AccountingMonthsTable,
    AccountingFinance,
    TitleNew,
    TaskCard,
  },
  setup() {
    const taxRegime = computed(
      () => accountingPeriodsStore.currentAccountingPeriod?.taxRegime
    );
    const accountingResult = computed(() => {
      const accountingResultLine =
        accountingResultsStore.getCurrentAccountingResult?.lines.find(
          (line) => line.type === AccountingResultLineType.ACCOUNTING_RESULT
        );
      return `${
        accountingResultLine?.direction === Direction.debit ? "-" : ""
      }${accountingResultLine?.amount}`;
    });
    const taskGroup = computed(() => {
      return tasksStore.accountingPeriodTaskGroup;
    });
    const partnersList: ComputedRef<Partner[]> = computed(
      () => partnersStore.partners
    );

    const selectedDetail: Ref<string> = ref("recettes");
    const transactionsFiltered = computed(() => filteredTransactions());
    const balance = computed(() => {
      if (accountingPeriodsStore.currentAccountingPeriod) {
        return useAccounting.getBalance(
          accountingPeriodsStore.currentAccountingPeriod,
          transactionsFiltered.value,
          {
            realEstateAssetIds:
              transactionsStore.filters.REAL_ESTATE_ASSETS?.ids,
          }
        );
      } else return null;
    });

    const realEstateAssetName: Ref<string | undefined> = ref("");
    const lastPeriodDate = computed(() => {
      if (
        accountingPeriodsStore.currentAccountingPeriod?.endAt.slice(0, 4) ===
        getMoment().format("YYYY")
      ) {
        return getMoment().format("DD/MM/YYYY");
      } else {
        return getMoment(
          accountingPeriodsStore.currentAccountingPeriod?.endAt
        ).format("31/12/YYYY");
      }
    });
    /*
     * Creating a dropdown menu that allows the user to select either the product or one or more real  estate assets.
     */
    // A boolean value that is set to true.
    const viewProductSelected: Ref<boolean> = ref(true);
    // A reference to an array of numbers.
    const viewRealEstateAssetsSelected: Ref<number | undefined> = ref();
    // A computed property that returns an array of objects.
    const viewData = computed(() => {
      if (realEstateAssetsStore.realEstateAssets) {
        return realEstateAssetsStore.realEstateAssets.map((realEstateAsset) => {
          return {
            id: realEstateAsset.id,
            name: realEstateAsset.name,
          };
        });
      }
    });
    const annualsCategories = computed(() => {
      return balance.value
        ? balance.value.categories
            .filter((c) =>
              selectedDetail.value === "recettes"
                ? c.direction === Direction.credit
                : c.direction === Direction.debit
            )
            .reduce((acc, val) => {
              if (val.name && val.monthly)
                acc[val.name] = sum(
                  Object.values(val.monthly).map((e) => Number(e))
                );
              return acc;
            }, {})
        : {};
    });
    const realEstateAssetSelectedByFilter = computed(() => {
      if (
        transactionsStore.filters.REAL_ESTATE_ASSETS?.ids[0] &&
        transactionsStore.filters.REAL_ESTATE_ASSETS?.ids.length === 1
      ) {
        const temp = realEstateAssetsStore.getRealEstateAsset(
          transactionsStore.filters.REAL_ESTATE_ASSETS?.ids[0]
        );
        if (temp) {
          const realEstateAssetIndex =
            realEstateAssetsStore.realEstateAssets.findIndex((s) => s === temp);
          if (realEstateAssetIndex !== undefined) {
            viewRealEstateAssetsSelected.value = realEstateAssetIndex;
          }

          realEstateAssetName.value = temp.name;
          return temp;
        }
      } else {
        // This line causes a complete freeze of the browser tab on the page's first loading
        // transactionsStore.removeFilter(FilterKeys.REAL_ESTATE_ASSETS);
        return {} as RealEstateAsset;
      }
    });
    // A computed property that returns a string.
    const viewSelectedText = computed(() => {
      if (realEstateAssetSelectedByFilter.value?.name) {
        return realEstateAssetSelectedByFilter.value.name;
      }
      if (viewProductSelected.value) {
        return "Total SCI";
      } else if (viewRealEstateAssetsSelected.value !== undefined) {
        if (viewData.value) {
          if (viewData.value[viewRealEstateAssetsSelected.value]?.name) {
            return viewData.value[viewRealEstateAssetsSelected.value].name;
          }
        }
      } else {
        return "";
      }
    });

    // const lastPeriodDate = ref("");
    const downloadBalanceReport = () =>
      accountingsService.getReportingBalance({
        accountingPeriodId: accountingPeriodsStore.currentId,
      });

    const downloadAccountingReport = () =>
      accountingsService.getReportingComplete({
        accountingPeriodId: accountingPeriodsStore.currentId,
      });

    const downloadFullAccountingReport = () =>
      accountingsService.getReportingFec({
        accountingPeriodId: accountingPeriodsStore.currentId,
        type: AccountingType.COMPLETE,
      });

    const downloadFullStandardAccountingReport = () =>
      accountingsService.getReportingFec({
        accountingPeriodId: accountingPeriodsStore.currentId,
        type: AccountingType.STANDARD,
      });

    const balancePartners: Ref<BalanceCategories["categories"]> = ref([]);
    const getBalancePartner = computed(
      () => (partnerId: string) =>
        getTotalBalancePartner(balancePartners.value, partnerId)
    );
    function getPartnerName(partnerId) {
      return partnersStore.getPartnerName(partnerId);
    }
    watch(
      () => accountingPeriodsStore.currentId,
      async () => {
        const balanceCategories = await accountingsStore.getBalanceCategories({
          accountingPeriodId: accountingPeriodsStore.currentId,
        });
        balancePartners.value = getBalancePartners(balanceCategories);
      }
    ),
      { deep: true };

    // Watching the viewProductSelected object and if it changes, it will reset the
    // viewRealEstateAssetsSelected object.
    watch(
      viewProductSelected,
      () => {
        if (viewProductSelected.value) {
          viewRealEstateAssetsSelected.value = undefined;
          transactionsStore.removeFilter(FilterKeys.REAL_ESTATE_ASSETS);
        } else if (
          !viewProductSelected.value &&
          viewRealEstateAssetsSelected.value === undefined
        ) {
          viewProductSelected.value = true;
        }
      },
      { deep: true }
    );
    // Watching the viewRealEstateAssetsSelected variable and when it changes, it will do something.
    watch(
      viewRealEstateAssetsSelected,
      () => {
        if (viewRealEstateAssetsSelected.value !== undefined) {
          viewProductSelected.value = false;
          if (viewData.value) {
            const selectedRealEstateAssets =
              viewData.value[viewRealEstateAssetsSelected.value];
            const filterParams: FiltersParameters[FilterKeys.REAL_ESTATE_ASSETS] =
              {
                ids: [selectedRealEstateAssets.id],
                label: `Biens : ${selectedRealEstateAssets.name}`,
              };

            transactionsStore.removeFilter(FilterKeys.REAL_ESTATE_ASSETS);
            transactionsStore.addFilter({
              code: FilterKeys.REAL_ESTATE_ASSETS,
              filterParams,
            });
            realEstateAssetName.value = selectedRealEstateAssets.name;
          }
        } else {
          viewProductSelected.value = true;
          transactionsStore.removeFilter(FilterKeys.REAL_ESTATE_ASSETS);
          realEstateAssetName.value = "";
        }
      },
      { deep: true }
    );

    onBeforeMount(async () => {
      accountingResultsStore.fetchAccountingResults();
      transactionsStore.resetFilter();

      const balanceCategories = await accountingsStore.getBalanceCategories({
        accountingPeriodId: accountingPeriodsStore.currentId,
      });
      balancePartners.value = getBalancePartners(balanceCategories);
    });

    return {
      downloadAccountingReport,
      downloadFullAccountingReport,
      downloadFullStandardAccountingReport,
      downloadBalanceReport,
      realEstateAssetName,
      viewProductSelected,
      viewRealEstateAssetsSelected,
      viewData,
      viewSelectedText,
      balance,
      transactionsFiltered,
      selectedDetail,
      annualsCategories,
      taxRegime,
      TaxRegime,
      taskGroup,
      partnersList,
      getBalancePartner,
      getPartnerName,
      TaskStatus,
      accountingResult,
      accountingPeriodsStore,
      subscriptionsStore,
      lastPeriodDate,
    };
  },
});
