var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"realEstateAmortisationTable"}},[_c('v-form',{ref:"form",model:{value:(_vm.isValidForm),callback:function ($$v) {_vm.isValidForm=$$v},expression:"isValidForm"}},[_c('div',{staticClass:"d-flex align-center my-1"},[(_vm.viewTypeTable === 'editable' && _vm.amortisationsType === 'default')?_c('v-text-field',{staticClass:"mr-2",style:({ 'max-width': ' 8rem' }),attrs:{"outlined":"","dense":"","suffix":"%","label":"Quote part terrain","hide-details":"auto","required":"","rules":[function () { return _vm.rules.groundShare.isValid || _vm.rules.groundShare.text; }]},model:{value:(_vm.groundShare),callback:function ($$v) {_vm.groundShare=$$v},expression:"groundShare"}}):_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([(_vm.amortisationsType === 'default')?{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('Tag',{staticClass:"mr-2",attrs:{"color":"primary"}},[_vm._v(" Quote part terrain : "+_vm._s(_vm.groundShare)+"% ")])],1)]}}:null],null,true)},[_c('span',[_vm._v("Le terrain n'est pas amortissable")])]),(_vm.amortisationsType === 'default')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('Tag',{staticClass:"mr-2",attrs:{"color":"primary"}},[_vm._v(" Valeur terrain : "+_vm._s(_vm.groundValueText)+" ")])],1)]}}],null,false,2736482512)},[_c('span',[_vm._v("Le terrain n'est pas amortissable")])]):_vm._e(),_c('Tag',{staticClass:"mr-2",attrs:{"color":"primary"}},[_vm._v(" Amortissements théoriques déjà constatés : "+_vm._s(_vm.$n(_vm.amortizationValueStats.valueAmortized, "currency", "fr-FR"))+" ")])],1),_c('EditableTable',{ref:"editableTable",attrs:{"headers":_vm.headers,"items":_vm.amortisationItems,"viewType":_vm.viewTypeTable,"isEditingOnStartup":_vm.isEditingOnStartup,"isDisableActionOnLastRow":_vm.amortisationsType === 'default',"isEnableValidateItem":false,"noDataTextHtml":"À compléter","addItemText":"Ajouter un composant","hideAddButton":_vm.amortisationsType === 'purchaseCosts'},on:{"addItem":_vm.addItem,"cancelEditItem":_vm.cancelEditItem,"deleteItem":_vm.deleteItem},scopedSlots:_vm._u([_vm._l((_vm.headers),function(ref){
var headerValue = ref.value;
return {key:("item." + headerValue),fn:function(ref){
var value = ref.value;
var rowIndex = ref.rowIndex;
var isEditing = ref.isEditing;
return [_c('div',{key:headerValue},[(
              !isEditing ||
              headerValue === 'rateText' ||
              (headerValue === 'valueText' &&
                _vm.amortisationsType !== 'purchaseCosts') ||
              headerValue === 'durationLeftInYearText' ||
              headerValue === 'netBookValueText' ||
              headerValue === 'dotationNValueText' ||
              (headerValue === 'shareText' &&
                (_vm.amortisationsType === 'purchaseCosts' ||
                  rowIndex === _vm.amortisationItems.length - 1))
            )?_c('div',[_c('span',{class:{
                'error--text':
                  _vm.viewTypeTable === 'editable' &&
                  headerValue === 'shareText' &&
                  !_vm.rules.share.isValid &&
                  rowIndex === _vm.amortisationItems.length - 1,
              }},[_vm._v(" "+_vm._s(value)+" ")])]):_vm._e(),(
              isEditing &&
              (_vm.amortisationsType === 'default'
                ? rowIndex !== _vm.amortisationItems.length - 1
                : true)
            )?_c('div',{staticClass:"py-1"},[(headerValue === 'typeText')?_c('v-select',{attrs:{"outlined":"","dense":"","items":_vm.amortisationTypeText,"item-text":"text","item-value":"value","label":"Composant","rules":[
                function () { return !!_vm.amortisations[rowIndex].type ||
                  'Le champ ne peut pas être null'; } ],"hide-details":"auto"},on:{"input":function($event){return _vm.updateCalculateAmortisation({
                  keyUpdated: _vm.headerValueTextToOriginalKey[headerValue],
                  rowIndex: rowIndex,
                })}},model:{value:(
                _vm.amortisations[rowIndex][
                  _vm.headerValueTextToOriginalKey[headerValue]
                ]
              ),callback:function ($$v) {_vm.$set(_vm.amortisations[rowIndex], 
                  _vm.headerValueTextToOriginalKey[headerValue]
                , $$v)},expression:"\n                amortisations[rowIndex][\n                  headerValueTextToOriginalKey[headerValue]\n                ]\n              "}}):_vm._e(),(
                (_vm.amortisationsType !== 'purchaseCosts' &&
                  headerValue === 'shareText') ||
                headerValue === 'durationInYearText' ||
                (_vm.amortisationsType === 'purchaseCosts' &&
                  headerValue === 'valueText')
              )?_c('v-text-field',{staticClass:"my-1",attrs:{"outlined":"","dense":"","suffix":(function () {
                  _vm.amortisationItems[rowIndex].durationLeftInYearText;
                })(),"hide-details":"auto","required":""},on:{"input":function($event){return _vm.updateCalculateAmortisation({
                  keyUpdated: _vm.headerValueTextToOriginalKey[headerValue],
                  rowIndex: rowIndex,
                })}},model:{value:(
                _vm.amortisations[rowIndex][
                  _vm.headerValueTextToOriginalKey[headerValue]
                ]
              ),callback:function ($$v) {_vm.$set(_vm.amortisations[rowIndex], 
                  _vm.headerValueTextToOriginalKey[headerValue]
                , $$v)},expression:"\n                amortisations[rowIndex][\n                  headerValueTextToOriginalKey[headerValue]\n                ]\n              "}}):_vm._e()],1):_vm._e()])]}}})],null,true)}),_c('div',{staticClass:"actions d-flex justify-end"},[(_vm.viewTypeTable === 'editable')?_c('div',{staticClass:"submit"},[_c('SubmitButton',{attrs:{"loading":_vm.isLoadingValidate,"disabled":!_vm.rules.share.isValid || !_vm.rules.form.isValid},on:{"click":_vm.validate}}),(!_vm.rules.share.isValid)?_c('span',{staticClass:"error--text"},[_vm._v(" "+_vm._s(_vm.rules.share.text)+" ")]):_vm._e()],1):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }