


















































































































































































































































































































import DatePicker from "@/components/atom/DatePicker.vue";
import DialogRight from "@/components/core/DialogRight.vue";
import Confirm from "@/components/core/modals/Confirm.vue";
import SupportingDocuments from "@/components/core/supportingDocuments/SupportingDocuments.vue";
import { VForm } from "@/models";
import { ability, operationsService } from "@/services";
import {
  accountingPeriodsStore,
  productsStore,
  coreStore,
  documentsStore,
  fixedAssetsStore,
  partnersStore,
  realEstateAssetsStore,
  transactionsStore,
} from "@/store";
import { FeedbackTypeEnum } from "@/store/modules/Core.store";
import { countDecimals } from "@/utils";
import { ForbiddenError, subject } from "@casl/ability";
import {
  AccountingPeriod,
  CategorizationEntry,
  ProductsModel,
  DocumentCreate,
  DocumentTag,
  getMoment,
  LedgerAccountEnum,
  PartnerTypeEnum,
  Suggestion,
  TransactionImportType,
  TypeReference,
} from "@edmp/api";
import {
  computed,
  ComputedRef,
  defineComponent,
  onBeforeMount,
  onMounted,
  reactive,
  Ref,
  ref,
  watch,
} from "@vue/composition-api";
import Decimal from "decimal.js-light";
import { get } from "lodash";
import Moment from "moment";
import FixedAssetCreate from "../../fixedAssets/FixedAssetForm.vue";
import RealEstateAssetCreate from "../../realEstate/RealEstateForm.vue";

export default defineComponent({
  name: "ExpenseReportCreate",
  components: {
    Confirm,
    SupportingDocuments,
    RealEstateAssetCreate,
    DialogRight,
    DatePicker,
    FixedAssetCreate,
  },
  setup(_, context) {
    const validateInProgress: Ref<boolean> = ref(false);

    const product = computed(
      () => productsStore.currentProduct as ProductsModel.Product
    );
    const accountingPeriod: ComputedRef<AccountingPeriod> = computed(
      () => accountingPeriodsStore.currentAccountingPeriod as AccountingPeriod
    );

    const transactionDate: Ref<string> = ref(
      Moment().isBetween(
        Moment(accountingPeriod.value.startAt),
        Moment(accountingPeriod.value.endAt)
      )
        ? Moment().format("YYYY-MM-DD")
        : Moment(accountingPeriod.value.startAt).format("YYYY-MM-DD")
    );

    const transactionSummary: Ref<string> = ref("");
    const transactionId: Ref<string> = ref("");
    const suggestion: Ref<{ name: string; number: string } | undefined> = ref({
      name: "",
      number: "",
    });

    // We could add a new Column to filter Categories for expense in CSV file in back
    const parentSuggestionsToDisplay = [
      "FRAIS DE FONCTIONNEMENT",
      "FRAIS BANQUES & ASSURANCES",
      "IMPOTS & TAXES",
      "TRAVAUX",
      "INVESTISSEMENTS & ACHATS",
    ];
    const suggestions = computed(() => {
      const suggestions = transactionsStore.getCategoriesListWithoutDisabled;
      const listSuggestions: {
        header?: string;
        text?: string;
        val?: Suggestion;
        divider?: boolean;
      }[] = [];

      for (const parent in suggestions) {
        if (parentSuggestionsToDisplay.includes(parent.toUpperCase())) {
          listSuggestions.push({ divider: true });
          listSuggestions.push({ header: parent.toUpperCase() });
          listSuggestions.push({ divider: true });
          for (const suggestion of suggestions[parent]) {
            listSuggestions.push({ text: suggestion.name, val: suggestion });
          }
        }
      }

      return listSuggestions;
    });

    const file: Ref<File | null> = ref(null);
    const amountTotal: Ref<number> = ref(0);
    const amount: Ref<number> = ref(0);
    const amountTax = computed(() =>
      amountTotal.value && amount.value
        ? Number(new Decimal(amountTotal.value - amount.value).toFixed(2))
        : 0
    );

    // Supporting document to allow reconciliate an existing supporting document
    const showFileInput: Ref<boolean> = ref(false);
    const getSupportingDocuments = computed(() =>
      documentsStore.documents
        .filter(({ tags = [] }) => tags.includes("supportingDocument"))

        .map((d) => ({
          name: `${d.metadata?.wording}`, // Wordings must always exist
          id: d.id,
          amount: d.metadata?.amountTotal,
        }))
    );

    const supportingDocument = reactive({
      list: computed(() => {
        return getSupportingDocuments.value;
      }),
      selected: "",

      create: false,
    });

    /**
     * References
     */
    const realEstateAssets = computed(() =>
      realEstateAssetsStore.realEstateAssets.map((realEstateAsset) => ({
        name: realEstateAsset.name,
        id: realEstateAsset.id,
      }))
    );
    const realEstateAsset = reactive<{
      list: ComputedRef<Array<{ name: string; id: string }>>;
      selected: { name: string; id: string } | undefined;
      create: boolean;
    }>({
      list: computed(() => realEstateAssets.value),
      selected: realEstateAssets.value[0],
      create: false,
    });
    watch(
      () => realEstateAsset.list,
      () => {
        realEstateAsset.selected = realEstateAssets.value[0];
      }
    );

    const partner = reactive<{
      list: ComputedRef<Array<{ name: string; id: string }>>;
      selected: { name: string; id: string } | undefined;
    }>({
      list: computed(() =>
        partnersStore.partners.map((e) => ({
          name:
            e.type === PartnerTypeEnum.LEGAL_PERSON
              ? e.denomination || e.siret || "Unknown"
              : e.type === PartnerTypeEnum.NATURAL_PERSON
              ? `${e.firstName} ${e.lastName}`
              : "Unknown",
          id: e.id,
        }))
      ),
      selected: partnersStore.partners.map((e) => ({
        name:
          e.type === PartnerTypeEnum.LEGAL_PERSON
            ? e.denomination || e.siret || "Unknown"
            : e.type === PartnerTypeEnum.NATURAL_PERSON
            ? `${e.firstName} ${e.lastName}`
            : "Unknown",
        id: e.id,
      }))[0],
    });
    if (partner.list.length > 0) partner.selected = partner.list[0];

    const fixedAssets = computed(() => {
      const fixedAssets = fixedAssetsStore.fixedAssets
        .filter((fixedAsset) => {
          if (
            getMoment(fixedAsset.commissioningAt).isBetween(
              getMoment(
                accountingPeriodsStore.currentAccountingPeriod?.startAt
              ),
              getMoment(accountingPeriodsStore.currentAccountingPeriod?.endAt),
              "days",
              "[]"
            )
          ) {
            return fixedAsset;
          }
        })
        .map((fixedAsset) => ({
          name: fixedAsset.name,
          id: fixedAsset.id,
        }));
      return fixedAssets;
    });
    const fixedAsset = reactive<{
      show: ComputedRef<boolean>;
      list: ComputedRef<Array<{ name: string; id: string }>>;
      selected: { name: string; id: string } | undefined;
      create: boolean;
    }>({
      show: computed(() => {
        return get(suggestion.value, "required", []).includes(
          TypeReference.fixedAsset
        );
      }),
      list: computed(() => fixedAssets.value),
      selected: fixedAssets.value[0],
      create: false,
    });
    watch(
      () => fixedAsset.list,
      () => {
        fixedAsset.selected = fixedAssets.value[0];
      }
    );

    async function validate() {
      if ((context.refs.form as VForm).validate()) {
        // Start loader
        validateInProgress.value = true;

        try {
          // -- FIRST we create A transaction  of Type EXPENSE_REPORT --
          const newTransaction = await transactionsStore.createTransaction({
            productId: product.value.id,
            date: transactionDate.value,
            amount: -amountTotal.value, // Transaction amount is total amount
            summary: transactionSummary.value,
            type: TransactionImportType.EXPENSE_REPORT,
          });
          transactionId.value = newTransaction.id;

          if (!file.value && !supportingDocument.selected) {
            throw new Error("Fichier manquant");
          }

          let document;

          // -- THEN we add supporting Document
          if (file.value) {
            const infos: DocumentCreate = {
              tags: ["supportingDocument" as DocumentTag],
              metadata: {
                wording: transactionSummary.value,
                issuanceDate: transactionDate.value,
                amountTotal: amountTotal.value,
                amount: amount.value,
                amountTax: amountTax.value,
              },
              product: {
                id: productsStore.currentId,
                accountingPeriod: { id: accountingPeriod.value.id },
              },
            };

            document = await documentsStore.createDocument({
              document: infos,
              file: file.value,
            });
          } else {
            document = supportingDocument.selected;
          }

          // Field bellow is required so error should not be possible
          if (fixedAsset.show) {
            if (!fixedAsset.selected) {
              throw new Error("Immobilisation manquant");
            }
          }
          if (!realEstateAsset.selected) {
            throw new Error("Bien manquant");
          }
          if (!partner.selected) {
            throw new Error("Associé manquant");
          }

          // Retrieve rental Unit, it could be need for CERFA ?
          const rentalUnit =
            realEstateAssetsStore.getRentalUnitByRealEstateAssetId(
              realEstateAsset.selected.id
            );

          // Now, we create operations in Debit
          const entries: CategorizationEntry[] = [
            {
              amount:
                amountTax.value !== 0 ? -amount.value : -amountTotal.value, // If amount is 0 we take total amount
              account: suggestion.value?.number as string,
              accountName: suggestion.value?.name as string,
              realEstateAsset: realEstateAsset.selected.id,
              rentalUnit: rentalUnit.id,
              partner: partner.selected.id,
              fixedAsset:
                fixedAsset.show && fixedAsset.selected
                  ? fixedAsset.selected.id
                  : undefined,
              supportingDocument: document?.id,
            },
          ];
          // If amountTax exist we subdivise for TVA
          if (amountTax.value !== 0) {
            entries.push({
              amount: -amountTax.value,
              account: LedgerAccountEnum.N445660,
              accountName: "TVA déductible",
              realEstateAsset: realEstateAsset.selected.id,
              rentalUnit: rentalUnit.id,
              partner: partner.selected.id,
              fixedAsset:
                fixedAsset.show && fixedAsset.selected
                  ? fixedAsset.selected.id
                  : undefined,
              supportingDocument: document?.id,
            } as CategorizationEntry);
          }

          // -- TO FINISH we categorize
          await operationsService.create({
            productId: product.value.id,
            accountingPeriodId: accountingPeriodsStore.currentId,
            transactionId: newTransaction.id,
            entries,
          });

          // Refresh Transaction
          await transactionsStore.fetchTransaction({
            id: newTransaction.id,
          });
        } catch (error) {
          console.error(error);
          // We remove transaction if error AND if transaction has been created
          if (transactionId.value) {
            await transactionsStore.deleteTransaction({
              id: transactionId.value,
            });
          }
        } finally {
          context.emit("finish");
        }

        // stop loader
        validateInProgress.value = false;
      }
    }

    async function cancel(): Promise<void> {
      context.root.$router.push({ query: {} });
      context.emit("finish");
    }

    const getSuggestions = async () => {
      if (productsStore.currentId && accountingPeriodsStore.currentId) {
        await transactionsStore.updateCategoriesList();
      }
    };
    const clickSuggestion = async (val: Suggestion) => {
      suggestion.value = val;
    };

    const createRealEstateAsset = () => {
      try {
        ForbiddenError.from(ability).throwUnlessCan(
          "addRealEstateAsset",
          subject("RentalManagement", {
            size: realEstateAssetsStore.realEstateAssets.length,
          })
        );
        realEstateAsset.create = true;
      } catch (error) {
        if (error instanceof ForbiddenError) {
          coreStore.displayFeedback({
            type: FeedbackTypeEnum.WARNING,
            message: error.message,
          });
        }
      }
    };
    const amountValidate = (amount: number) => {
      return amount.toString().length <= new Decimal(amount).toFixed(2).length;
    };

    onMounted(() => {
      fixedAssetsStore.fetchFixedAssets(productsStore.currentId);
    });

    onBeforeMount(getSuggestions);

    return {
      amountValidate,
      validate,
      cancel,
      product,
      transactionDate,
      transactionSummary,
      accountingPeriod,
      countDecimals,
      suggestion,
      suggestions,
      clickSuggestion,
      file,
      amount,
      amountTotal,
      amountTax,
      validateInProgress,
      realEstateAsset,
      createRealEstateAsset,
      partner,
      supportingDocument,
      showFileInput,
      fixedAsset,
      TypeReference,
    };
  },
});
