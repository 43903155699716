






































import {
  computed,
  ComputedRef,
  defineComponent,
  PropType,
  Ref,
  ref,
  // ref,
  // Ref,
} from "@vue/composition-api";
import { GeneralAssemblyEvent } from "@edmp/api";
import { accountingPeriodsStore, productsStore } from "@/store";

import EventsGeneralAssemblySelectModal from "./EventsGeneralAssemblySelectModal.vue";
import EventsGeneralAssemblyOrdinaryModal from "./EventsGeneralAssemblyOrdinaryModal.vue";

export default defineComponent({
  name: "EventsGeneralAssemblyModal",
  props: {
    event: {
      type: Object as PropType<GeneralAssemblyEvent>,
    },
    step: {
      type: String as PropType<"ordinary" | "extraordinary">,
    },
    pattern: {
      type: String as PropType<"yearEnd" | "other">,
    },
  },
  components: {
    EventsGeneralAssemblySelectModal,
    EventsGeneralAssemblyOrdinaryModal,
  },
  setup(props, context) {
    const product = computed(() => productsStore.currentProduct);

    // const step: Ref<string> = ref(props.step);
    const propPattern: Ref<"yearEnd" | "other" | undefined> = ref(
      props.pattern
    );

    // const title: ComputedRef<string> = computed(() => {
    //   if (step.value == "ordinary") {
    //     return "Ordinaire";
    //   } else if (step.value == "extraordinary") {
    //     return "Extraordinaire";
    //   } else return "";
    // });
    const title: ComputedRef<string> = computed(() => {
      if (props.step === "ordinary") {
        if (propPattern.value === "yearEnd") {
          return "d'approbation annuelle des comptes";
        }
        return "Ordinaire";
      } else if (props.step === "extraordinary") {
        return "Extraordinaire";
      } else return "";
    });

    function closeModal() {
      context.emit("closeModal");
    }

    function validate() {
      context.emit("validate");
    }

    // function validateStep(selectedStep: { type: string; pattern: string }) {
    //   step.value = selectedStep.type;
    //   pattern.value = selectedStep.pattern;
    // }

    return {
      product,
      accountingPeriodId: computed(() => accountingPeriodsStore.currentId),
      closeModal,
      validate,
      // step,
      propPattern,
      // validateStep,
      title,
    };
  },
});
