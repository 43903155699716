

























































































































import Confirm from "@/components/core/modals/Confirm.vue";
import { VConfirmDialog } from "@/models";
import { transactionsStore } from "@/store";
import { CheckNewAnomaliesType, Transaction } from "@edmp/api";
import {
  computed,
  defineComponent,
  onBeforeMount,
  PropType,
  Ref,
  ref,
  watch,
} from "@vue/composition-api";
import { useAnomalies } from "../../anomalies/anomalies.usable";
import DialogRight from "../../DialogRight.vue";
import AccrualReconcile from "../../operations/accruals/AccrualReconcile.vue";
import RentReceipt from "../../rent/RentReceipt.vue";
import Categories from "../categorization/Categories.vue";
import { useCategorization } from "../categorization/categorization.usable";
import Categorization from "../categorization/Categorization.vue";
import CategorizationStep1 from "../categorization/CategorizationStep1.vue";
import CategorizationStep2 from "../categorization/CategorizationStep2.vue";
import {
  formatLinesTransaction,
  initTransactionState,
  TransactionState,
  useTransaction,
} from "./transaction.usable";
import TransactionActions from "./TransactionActions.vue";
import TransactionAttachments from "./TransactionAttachments.vue";
import TransactionCategories from "./TransactionCategories.vue";
import TransactionHeader from "./TransactionHeader.vue";

export default defineComponent({
  name: "Transaction",
  components: {
    TransactionHeader,
    TransactionCategories,
    TransactionActions,
    TransactionAttachments,
    RentReceipt,
    AccrualReconcile,
    Categories,
    Categorization,
    CategorizationStep1,
    CategorizationStep2,
    DialogRight,
    Confirm,
  },
  props: {
    transactionId: {
      type: String,
      required: true,
    },
    isOnAddManualTransaction: {
      type: Boolean,
      default: false,
    },
    transactionSource: {
      type: Object as PropType<Transaction>,
    },
    displayTransactionSource: {
      type: Boolean,
      default: false,
    },
    isCategorized: {
      type: Boolean,
      default: false,
    },
    readonly: {
      default: false,
      type: Boolean,
    },
    isDisableRemove: {
      default: false,
      type: Boolean,
    },
    isDisableActions: {
      default: false,
      type: Boolean,
    },
    isDisableAttachments: {
      default: false,
      type: Boolean,
    },
    isDisableUpdateSummary: {
      default: false,
      type: Boolean,
    },
    tags: {
      type: Array as PropType<{ value: string; color: string }[]>,
    },
  },
  setup(props, context) {
    const { checkDisableAnomalies, checkOnNewAnomalies } = useAnomalies();
    const transaction = computed(() =>
      props.displayTransactionSource && props.transactionSource
        ? props.transactionSource
        : transactionsStore.transactions[props.transactionId]
    );
    const isOpenRentReceiptStep: Ref<number> = ref(0);

    const transactionState: Ref<TransactionState> = ref(
      initTransactionState(transaction.value, props.isOnAddManualTransaction)
    );

    const checkCategorizationAnomalies = async () => {
      const { anomaliesErrorsSortByPriority } = await checkOnNewAnomalies({
        checkNewAnomaliesType: CheckNewAnomaliesType.transaction,
        transactions: [transactionState.value.transaction],
      });
      transactionState.value.anomaliesErrors =
        anomaliesErrorsSortByPriority.filter((anomaly) =>
          checkDisableAnomalies(anomaly.anomalyError)
        );
    };

    watch(
      transaction,
      async () => {
        transactionState.value = initTransactionState(
          transaction.value,
          props.isOnAddManualTransaction
        );
        await checkCategorizationAnomalies();
      },
      { deep: true }
    );

    watch(
      () => transactionState.value.isOnDuplicateTransaction,
      () => {
        if (transactionState.value.isOnDuplicateTransaction) {
          context.emit("open:duplication", transaction.value.id);
        }
      },
      { deep: true }
    );

    const { isManualTransaction, getSummary } = computed(() =>
      useTransaction(transactionState.value, context)
    ).value;
    const {
      isOpenCategorizationList,
      isOpenCategorizationDetailStep,
      addManualTransactionFlow,
    } = computed(() =>
      useCategorization(transactionState.value, context)
    ).value;

    const summary = computed(() => {
      return getSummary();
    });

    const closeCategorizationDetails = () => {
      transactionState.value.lines = transactionState.value.savedCategories;
      isOpenCategorizationDetailStep.value = false;
      isOpenCategorizationList.value = false;
    };

    const computedAvatar = computed(() => {
      // if (
      //   transactionState.value.transaction.source.provider ===
      //   TransactionImportType.MANUAL
      // ) {
      //   if (transactionState.value.transaction.value.amount >= 0) {
      //     return "#fbb8d7";
      //   } else {
      //     return "#d17085";
      //   }
      // }
      // if (
      //   transactionState.value.transaction.source.provider ===
      //   TransactionImportType.IMPORT
      // ) {
      //   if (transactionState.value.transaction.value.amount >= 0) {
      //     return "#b8fbd7";
      //   } else {
      //     return "#70d185";
      //   }
      // }
      if (
        transaction.value.value?.amount &&
        transaction.value.value.amount >= 0
      ) {
        return "#b8d7fb";
      } else {
        return "#7085d1";
      }
    });

    watch(
      // For validate categorization on add manual transaction
      () => transactionState.value.isCategorized,
      (isCategorized) => context.emit("update:isCategorized", isCategorized),
      { deep: true }
    );

    watch(
      () => transactionState.value.isOpenReconciliation,
      async () => {
        if (!transactionState.value.isOpenReconciliation) {
          context.emit("reconcilationCompleted");
        }
      }
    );

    onBeforeMount(async () => {
      if (props.isOnAddManualTransaction) {
        isManualTransaction.value = true;
        addManualTransactionFlow(context.refs.confirmDialog as VConfirmDialog);
      }
      if (props.transactionSource && !props.displayTransactionSource) {
        isManualTransaction.value = true;
        transactionState.value.isOpenCategorizationDuplicate = true;
        transactionState.value.suggestedLines = [];
        transactionState.value.lines = formatLinesTransaction(
          props.transactionSource?.operations?.journalEntry?.lines?.map(
            (line) => {
              const filteredRefs = line.refs?.filter(
                (ref) => ref.type !== "supportingDocument"
              );
              return {
                ...line,
                refs: filteredRefs,
              };
            }
          ) ?? []
        ).reverse();
      }
      await checkCategorizationAnomalies();
    });

    return {
      summary,
      transactionState,
      transaction,
      computedAvatar,
      isOpenRentReceiptStep,
      closeCategorizationDetails,
    };
  },
});
