








































































import {
  defineComponent,
  ref,
  Ref,
  onBeforeMount,
  watch,
  PropType,
  computed,
} from "@vue/composition-api";
import moment from "moment";
import { AccountingPeriod, ProductsModel, TaxRegime } from "@edmp/api";
import { accountingPeriodsStore, productsStore } from "@/store";

import AccountingPeriodChoice from "@/components/core/accounting-period/AccountingPeriodChoice.vue";
import AccountingIR from "./AccountingIR.vue";
import AccountingIS from "./AccountingIS.vue";
import AccountingLMNP from "./AccountingLMNP.vue";
import { ROUTE_NAMES } from "@/router/routes";

export default defineComponent({
  name: "AccountingPeriod",
  components: {
    AccountingPeriodChoice,
    AccountingIR,
    AccountingIS,
    AccountingLMNP,
  },
  props: {
    actions: {
      type: String as PropType<"create" | "update">,
    },
    activityType: {
      type: String as PropType<"SCI" | "LMNP">,
      required: false,
    },
  },

  setup(props, context) {
    const isEditing: Ref<boolean> = ref(false);
    const accountingPeriodExist = computed(
      () => !!accountingPeriodsStore.accountingPeriods.length
    );
    const accountingPeriod: Ref<AccountingPeriod | undefined> = ref();
    const taxRegime: Ref<TaxRegime | undefined> = ref();

    const items = computed(() =>
      [
        {
          text: "Imposition sur les revenus des Associés (2072)",
          value: TaxRegime.IR_2072,
        },
        {
          text: "Imposition sur les Sociétés (2065)",
          value: TaxRegime.IS_2065,
        },
        {
          text: "Imposition sur les loueurs meublé non professionnels (2031)",
          value: TaxRegime.LMNP_2031,
        },
      ].filter((item) =>
        props.activityType
          ? ((props.activityType === "SCI" ||
              accountingPeriod.value?.taxRegime === TaxRegime.IR_2072 ||
              accountingPeriod.value?.taxRegime === TaxRegime.IS_2065) &&
              (item.value === TaxRegime.IR_2072 ||
                item.value === TaxRegime.IS_2065)) ||
            ((props.activityType === "LMNP" ||
              accountingPeriod.value?.taxRegime === TaxRegime.LMNP_2031) &&
              item.value === TaxRegime.LMNP_2031)
          : true
      )
    );

    /**
     * Init
     */
    async function init() {
      if (!props.actions || props.actions === "update") {
        taxRegime.value =
          accountingPeriodsStore.currentAccountingPeriod?.taxRegime ??
          TaxRegime.IR_2072;
      } else {
        const product = productsStore.products.find(
          ({ status }) => status === ProductsModel.ProductStatus.pending
        );
        const accountingPeriod = accountingPeriodsStore.accountingPeriods.length
          ? accountingPeriodsStore.accountingPeriods
              .filter(({ productId }) => productId === product?.id)
              .reduce((prev, current) =>
                moment(prev.startAt).isBefore(current.startAt) ? prev : current
              )
          : undefined;
        taxRegime.value = accountingPeriod?.taxRegime;
        if (!accountingPeriod) {
          if (props.activityType === "SCI") {
            taxRegime.value = TaxRegime.IR_2072;
          }
          if (props.activityType === "LMNP") {
            taxRegime.value = TaxRegime.LMNP_2031;
          }
        }
        // taxRegime.value = accountingPeriod?.taxRegime ?? TaxRegime.IR_2072;
        if (!accountingPeriod?.id) {
          isEditing.value = true;
        } else {
          isEditing.value = false;
        }
      }
    }

    watch(
      () => [
        accountingPeriodsStore.accountingPeriods,
        accountingPeriodsStore.currentAccountingPeriod,
        props.actions,
      ],
      () => init(),
      { deep: true }
    );

    onBeforeMount(() => init());

    const isTaxRegimeDisabled = computed(() => {
      if (
        (context.root.$route.name === ROUTE_NAMES.ActivityContinue ||
          context.root.$route.name === ROUTE_NAMES.ActivityCreate) &&
        isEditing.value
      ) {
        return false;
      } else return true;
    });

    return {
      accountingPeriodExist,
      isEditing,
      moment,
      accountingPeriod,
      taxRegime,
      TaxRegime,
      items,
      isTaxRegimeDisabled,
    };
  },
});
