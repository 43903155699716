import { VConfirmDialog } from "@/models";
import {
  coreStore,
  realEstateAssetsStore,
  realEstateLoansStore,
} from "@/store";
import { FeedbackTypeEnum } from "@/store/modules/Core.store";
import {
  AmortisationLine,
  CategorizationEntry,
  categorizationRules,
  getMonthlyPayment,
  isLoanTypeAutomatized,
  LedgerAccountEnum,
  RealEstateLoan,
  RentalUnit,
  round2decimals,
  Suggestion,
  TypeReference,
} from "@edmp/api";
import { SetupContext } from "@vue/composition-api";
import Decimal from "decimal.js-light";
import { merge } from "lodash";
import { AccrualState } from "../accruals/accruals.usable";
import { useCategorization } from "./categorization.usable";

export interface CategoryValidate {
  category: Suggestion;
  references: {
    realEstateAsset: string | undefined;
    rentalUnit: string | undefined;
    rentalAgreement: string | undefined;
    tenant: string | undefined;
    partner: string | undefined;
    realEstateLoan: string | undefined;
    supportingDocument: string | undefined;
    fixedAsset: string | undefined;
    beneficiary: string | undefined;
  };
  optionalsCategories: Map<LedgerAccountEnum, number>;
}

export default class CategorizationValidateCategories {
  protected accrualState: AccrualState;
  protected amountTaxDecomposition: ReturnType<
    typeof useCategorization
  >["amountTaxDecomposition"];
  protected categoriesList: ReturnType<
    typeof useCategorization
  >["categoriesList"];
  protected categoryValidate: CategoryValidate;
  protected newCategory: CategorizationEntry;
  protected getCategoryInfos: ReturnType<
    typeof useCategorization
  >["getCategoryInfos"];
  protected addCategory: ReturnType<typeof useCategorization>["addCategory"];
  protected operations: Map<LedgerAccountEnum, Function> = new Map<
    LedgerAccountEnum,
    Function
  >();
  protected confirmDialog: VConfirmDialog;
  protected rentalUnit;
  protected categories;

  constructor(
    accrualState: AccrualState,
    categoryValidate: CategoryValidate,
    confirmDialog: VConfirmDialog,
    context: SetupContext,
    rentalUnit?
  ) {
    const {
      amountTaxDecomposition,
      categoriesList,
      getCategoryInfos,
      addCategory,
      categories,
    } = useCategorization(accrualState, context);

    this.accrualState = accrualState;
    this.amountTaxDecomposition = amountTaxDecomposition;
    this.categoryValidate = categoryValidate;
    this.getCategoryInfos = getCategoryInfos;
    this.addCategory = addCategory;
    this.categories = categories;
    this.rentalUnit = rentalUnit;
    this.operations
      .set(LedgerAccountEnum.N708399, () => this.rentalChargesProvisions())
      .set(LedgerAccountEnum.N445720, () => this.tvaCollected())
      .set(LedgerAccountEnum.N706000, () => this.rentCollected(rentalUnit))
      .set(LedgerAccountEnum.N661100, () => this.loanDeductibleCosts())
      .set(LedgerAccountEnum.N164000, () => this.loanCapitalBorrowedV0())
      .set(LedgerAccountEnum.N661110, () => this.loanInterest())
      .set(LedgerAccountEnum.N616600, () => this.loanInsurance())
      .set(
        LedgerAccountEnum.N164100,
        async () => await this.loanCapitalBorrowedV1()
      )
      .set(LedgerAccountEnum.N445720, () => this.tvaCollected());

    this.confirmDialog = confirmDialog;

    const amountLeft = () => {
      let amount = 0;
      for (const line of accrualState.lines) {
        amount = amount + line.amount;
      }
      const amountFinal = accrualState.amount - amount;
      if (amountFinal > 0) return Number(accrualState.amount - amount);
      else {
        return 0;
      }
    };

    const newCategory: CategorizationEntry = {
      account: categoryValidate.category.number,
      accountName: categoryValidate.category.name,
      amount:
        accrualState.lines.length > 0
          ? amountLeft()
          : Number(accrualState.amount),
    };
    //Check each optional (realEstateAsset, rentalUnit, partner, client) is different than empty string
    Object.entries(categoryValidate.references).forEach(([key, value]) => {
      if (value) newCategory[key] = value;
    });

    this.newCategory = newCategory;

    if (Object.keys(categoriesList.value).length !== 0) {
      this.categoriesList = categoriesList;
    } else {
      coreStore.displayFeedback({
        type: FeedbackTypeEnum.ERROR,
        message: "Une erreur est survenue lors de la categorisation",
      });
      throw new Error("no categoriesList found");
    }
  }

  /**
   * * Launch validate categorization.
   *
   * It dynamically calls a function in this class.
   */
  async validateCategory() {
    // // Calculate bank commission
    // if (
    //   this.transactionState.transaction.commission.amount < 0 &&
    //   !this.transactionState.lines.some(
    //     (line) => line.account === LedgerAccountEnum.N627800
    //   )
    // ) {
    //   const categoryInfo = this.getCategoryInfos(LedgerAccountEnum.N627800);

    //   const newCategory = {
    //     ...this.newCategory,
    //     amount: this.transactionState.transaction.commission.amount,
    //     account: categoryInfo.number,
    //     accountName: categoryInfo.name,
    //   };

    //   this.addNewCategory(LedgerAccountEnum.N627800, newCategory);

    //   this.newCategory.amount = Number(
    //     new Decimal(this.newCategory.amount)
    //       .add(
    //         new Decimal(
    //           this.transactionState.transaction.commission.amount
    //         ).abs()
    //       )
    //       .toFixed(2)
    //   );
    // }

    const categoryFunction = this.operations.get(
      this.newCategory.account as LedgerAccountEnum
    );

    if (categoryFunction) {
      /* It's a way to dynamically call a function in a class. */
      await categoryFunction();
    } else {
      this.addNewCategory();
    }
  }

  /**
   * Helper
   */
  /* Rules categorize */
  rules = {
    taxTva: {
      /* Check if category contain tva */
      isEnable: (categoryValidate = this.categoryValidate): boolean => {
        if (categoryValidate.references.rentalUnit) {
          return categorizationRules.taxTvaRules.isEnableForRentalUnit(
            categoryValidate.references.rentalUnit,
            (rentalUnitId) =>
              realEstateAssetsStore.rentalUnits.find(
                (rentalUnit: RentalUnit) => rentalUnitId === rentalUnit.id
              )
          );
        }
        return false;
      },

      /* It's a rule that checks if the amount of the transaction is positive. */
      // ! Disable : https://gitlab.com/edmp/fonctionnel/-/issues/2359
      // tvaCollectedIsPositive: (newCategory = this.newCategory) => {
      //   if (new Decimal(newCategory.amount).toNumber() < 0) {
      //     this.newCategory.amount = Math.abs(newCategory.amount);
      //   }
      //   if (
      //     !categorizationRules.taxTvaRules.tvaCollectedIsPositive(
      //       newCategory.amount
      //     )
      //   ) {
      //     const message = `Le montant de l'opération comptable "${
      //       this.getCategoryInfos(newCategory.account).name
      //     }" doit être positif`;
      //     coreStore.displayFeedback({
      //       message,
      //       type: FeedbackTypeEnum.ERROR,
      //     });
      //     throw new Error(message);
      //   }
      // },
    },
    loanCapitalBorrowed: {
      isEqualLoanRepaymentDeadlineAmount: async (
        realEstateLoan: RealEstateLoan
      ): Promise<boolean> => {
        const totalAmount = getMonthlyPayment(realEstateLoan).neg().toNumber();
        const accrualAmount = this.accrualState.amount;

        const isAmountEqual =
          categorizationRules.loanCapitalBorrowedRules.isEqualLoanRepaymentDeadlineAmount(
            realEstateLoan,
            accrualAmount
          );

        if (!isAmountEqual) {
          const openConfirm = await this.confirmDialog.open(
            `Le montant de l'engagement (${accrualAmount} €/mois) ne correspond pas au montant d'échéance de remboursement de l'emprunt (${round2decimals(
              totalAmount
            )} €/mois).<br/><br/>
            Souhaitez-vous continuer avec cet emprunt ou annuler ?`,
            { width: 700 }
          );
          if (!openConfirm) {
            throw new Error("Loan doesn't match for amount");
          }
        }

        return isAmountEqual;
      },
      checkAndGetAmortisationLine: async (
        realEstateLoan: RealEstateLoan
      ): Promise<AmortisationLine> => {
        const accrualDate = this.accrualState.date;

        // Retrieve date of payment in Amortisation
        let selectedLine = realEstateLoan.amortisationLines
          ? categorizationRules.loanCapitalBorrowedRules.isEqualLoanRepaymentDeadlineDate(
              realEstateLoan.amortisationLines,
              accrualDate
            )
          : undefined;

        if (!selectedLine) {
          const loanName = realEstateLoan?.name ?? "";

          const openConfirm = await this.confirmDialog.open(
            `La date de l'engagement ne correspond pas aux échéances de remboursement de votre emprunt <b>${loanName}</b><br/><br/>
            Souhaitez-vous continuer ?`,
            { color: "white", width: 700 }
          );
          if (!openConfirm) {
            throw new Error("Loan doesn't match for date");
          }
          selectedLine = merge(realEstateLoan.currentAmortisation, {
            paymentAt: accrualDate,
          } as AmortisationLine);
        }

        return selectedLine;
      },
    },
  };

  /**
   * It takes a category object and validates it against the category's account and these references.
   * Remove not requested fields and cause error if require field is detected.
   * @param newCategory - The category object to validate
   * @returns The newCategory object
   * @example
   * * Example 1: Suppression of a reference not requested
   * * * Flow structure:
   * `Rent collected (706000)` -> `Rent charge - Provision (708399)`
   *                           -> `VAT collected (445720)`
   * * * Context :
   * This flow asks from field `fields` category `Rent collected (706000)`
   * the references `realEstateAsset`,`rentalUnit` and `rentalAgreement` and required from field `required`.
   * * * Application :
   * The sub-categorization `Rent charge - Provision (708399)` requests the same references,
   * This `function` will therefore have no impact on the references of this new categorization to record.
   * This field `fields` of the sub-categorization `VAT collected (445720)` requests only the references `realEstateAsset` and `rentalUnit`,
   * This `function` will therefore delete the reference too much `rentalAgreement`
   *
   * * Example 2: Detection of a required reference
   * * * Flow structure:
   * `VAT collected (445720)` -> `Rent collected (706000)`
   * * * Context :
   * This flow asks from field `fields` category `VAT collected (445720)`
   * the references `realEstateAsset`, `rentalUnit` and required from field `required`.
   * * * Application :
   * The sub-categorization `Rent conceded (706000)` request from `fields` the references `realEstateAsset`, `rentalUnit` and `rentalAgreement` and required from `required` field.
   * However, the benchmark `rentalAgreement` was not collected by the category `VAT collected (445720)` So an error will be caused.
   * * * Error resolution :
   * You will have harvested the supplementary reference `rentalAgreement`.
   */
  private validateReferences(
    newCategory = this.newCategory
  ): CategorizationEntry {
    const categoryInfo = this.getCategoryInfos(newCategory.account);

    // Remove references if not in category autosize field
    const referencesNotIn = Object.values(TypeReference).filter(
      (referenceField) =>
        !categoryInfo.fields?.includes(referenceField as TypeReference)
    );
    referencesNotIn.forEach((reference) => delete newCategory[reference]);

    // Error if require field not found
    const referencesRequire: string[] = [];
    if (categoryInfo.required) {
      for (const referenceRequire of categoryInfo.required) {
        const required = !newCategory[referenceRequire];
        if (required) {
          referencesRequire.push(referenceRequire);
        }
      }
    }
    if (referencesRequire.length) {
      let errorMessage = "Une erreur est survenue lors de la categorisation";
      if (referencesRequire.includes(TypeReference.realEstateLoan)) {
        errorMessage =
          "Vous devez sélectionner un prêt pour utiliser cette catégorie";
      }
      coreStore.displayFeedback({
        type: FeedbackTypeEnum.ERROR,
        message: errorMessage,
      });
      throw new Error(`Require reference ${referencesRequire}`);
    }

    return newCategory;
  }

  /**
   * It adds a new category to the transaction
   * @param {string} account - string - the account number of the category
   * @param newCategory - The new category to be added.
   */
  private addNewCategory(
    account = this.newCategory.account,
    newCategory = this.newCategory
  ) {
    const categoryInfo = this.getCategoryInfos(account);

    newCategory = {
      ...newCategory,
      amount: Number(newCategory.amount.toFixed(2)),
      account: categoryInfo.number,
      accountName: categoryInfo.name,
    };

    newCategory = this.validateReferences(newCategory);

    this.addCategory(newCategory);
  }

  /**
   * It adds a new category to the categorization entry.
   * @param newCategory - The new category you want to add.
   */
  private rentalChargesProvisions(newCategory = this.newCategory): void {
    this.addNewCategory(LedgerAccountEnum.N708399, newCategory);
  }

  /**
   * It adds a new category to the categorization.
   * @param newCategory - The new category to add.
   * @returns A new CategorizationEntry object with the category code and the newCategory object.
   */
  private tvaCollected(
    newCategory = this.newCategory,
    categories = this.categories
  ): void {
    // this.rules.taxTva.tvaCollectedIsPositive(); ! Disable : https://gitlab.com/edmp/fonctionnel/-/issues/2359
    const rentalUnit = realEstateAssetsStore.getRentalUnit(
      this.categoryValidate.references.rentalUnit as string
    );

    if (rentalUnit) {
      if (this.rules.taxTva.isEnable()) {
        this.addNewCategory(LedgerAccountEnum.N445720, {
          ...newCategory,
          amount: (
            this.amountTaxDecomposition(rentalUnit) as {
              TVA: number;
            }
          ).TVA,
        });
      }
    }
    const rentCategory = categories.value.find(
      (category) => category.account === LedgerAccountEnum.N706000
    );
    rentCategory.amount =
      rentCategory.amount -
      (
        this.amountTaxDecomposition(rentalUnit) as {
          TVA: number;
        }
      ).TVA;
  }

  /**
   * "If the transaction is a rental charge, then we create a new category for the rental charge, and we
   * create a new category for the VAT collected on the rental charge."
   * @param newCategory - CategorizationEntry
   */
  private rentCollected(newCategory = this.newCategory): void {
    const rentalUnit = realEstateAssetsStore.getRentalUnit(
      this.categoryValidate.references.rentalUnit as string
    );

    if (rentalUnit) {
      const amountRentCollected = this.categoryValidate.optionalsCategories.get(
        LedgerAccountEnum.N708399
      );
      if (amountRentCollected) {
        newCategory.amount = new Decimal(newCategory.amount)
          .minus(amountRentCollected)
          .toNumber();

        this.rentalChargesProvisions({
          ...newCategory,
          amount: amountRentCollected,
        });
      }

      if (this.rules.taxTva.isEnable()) {
        if (new Decimal(newCategory.amount).toNumber() !== 0) {
          newCategory.amount = new Decimal(newCategory.amount)
            .minus(
              (
                this.amountTaxDecomposition(rentalUnit) as {
                  TVA: number;
                }
              ).TVA
            )
            .toNumber();
        }

        this.addNewCategory(LedgerAccountEnum.N445720, {
          ...newCategory,
          amount: (
            this.amountTaxDecomposition(rentalUnit) as {
              TVA: number;
            }
          ).TVA,
        });
      }

      // Validate category
      this.addNewCategory(LedgerAccountEnum.N706000, newCategory);
    }
  }

  /**
   * This function adds a new category to the ledger
   * @param newCategory - This is the category that you want to add to the ledger.
   */
  private loanDeductibleCosts(newCategory = this.newCategory): void {
    this.addNewCategory(LedgerAccountEnum.N661100, newCategory);
  }

  private loanCapitalBorrowedV0(newCategory = this.newCategory): void {
    // [Flow] Validate category - Loan: interests and insurance (661100)
    const amountLoanDeductibleCosts =
      this.categoryValidate.optionalsCategories.get(LedgerAccountEnum.N661100);

    if (amountLoanDeductibleCosts) {
      newCategory.amount = new Decimal(newCategory.amount)
        .minus(amountLoanDeductibleCosts)
        .toNumber();

      this.loanDeductibleCosts({
        ...newCategory,
        amount: amountLoanDeductibleCosts,
      });
    }

    // Validate category
    this.addNewCategory(LedgerAccountEnum.N164000, newCategory);
  }

  /**
   * It adds a new category to the ledger.
   * @param newCategory - This is the category that you want to add to the ledger.
   */
  private loanInterest(newCategory = this.newCategory): void {
    this.addNewCategory(LedgerAccountEnum.N661110, newCategory);
  }

  /**
   * > This function adds a new category to the ledger
   * @param newCategory - This is the category that you want to add to the ledger.
   */
  private loanInsurance(newCategory = this.newCategory): void {
    this.addNewCategory(LedgerAccountEnum.N616600, newCategory);
  }

  /**
   * > This function adds a new category to the ledger
   * @param newCategory - This is the category that you want to add to the ledger.
   */

  /**
   * It validates the category, finds the realEstateLoan that matches the id of the realEstateLoan in the
   * categoryValidate, checks if the loan repayment deadline amount is equal to the transaction amount,
   * checks and gets the amortisation line for the loan, and then validates the category
   * @param newCategory - The category to be validated.
   */
  private async loanCapitalBorrowedV1(
    newCategory = this.newCategory
  ): Promise<void> {
    // [Flow] Validate category - Loan: interests and insurance  with LOAN

    /* Finding the realEstateLoan that matches the id of the realEstateLoan in the categoryValidate. */
    const realEstateLoan = realEstateLoansStore.realEstateLoans.find(
      (realEstateLoan) =>
        realEstateLoan.id === this.categoryValidate.references.realEstateLoan
    );

    if (!realEstateLoan || !isLoanTypeAutomatized(realEstateLoan.loanType)) {
      // MANUAL MODE : we not found a Loan OR Loan is not managed by system YET !
      this.loanInterest({
        ...newCategory,
        amount: 0,
      });
      this.loanInsurance({
        ...newCategory,
        amount: 0,
      });
      this.addNewCategory(LedgerAccountEnum.N164100, {
        ...newCategory,
        amount: 0,
      });
    } else {
      // Checking if the loan repayment deadline amount is equal to the transaction amount.
      const isEqualLoanRepaymentDeadlineAmount =
        await this.rules.loanCapitalBorrowed.isEqualLoanRepaymentDeadlineAmount(
          realEstateLoan
        );

      /* Checking and getting the amortisation line for the loan. */
      const selectedLine =
        await this.rules.loanCapitalBorrowed.checkAndGetAmortisationLine(
          realEstateLoan
        );

      if (selectedLine.interest) {
        this.loanInterest({
          ...newCategory,
          amount: -selectedLine.interest,
        });
      }

      if (realEstateLoan.insuranceIncludedInLoan && selectedLine?.insurance) {
        // Insurance is included in LOAN
        this.loanInsurance({
          ...newCategory,
          amount: -selectedLine?.insurance,
        });
      }

      // Validate category
      if (!selectedLine.principal && !isEqualLoanRepaymentDeadlineAmount) {
        // We recalculate amount to be sure to have SUM of amount of lines equal to transaction amount
        selectedLine.principal = new Decimal(newCategory.amount)
          .minus(selectedLine.interest ?? 0)
          .minus(selectedLine?.insurance ?? 0)
          .toNumber();
      }

      this.addNewCategory(LedgerAccountEnum.N164100, {
        ...newCategory,
        amount: -selectedLine.principal,
      });
    }
  }
}
