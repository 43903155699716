import { VConfirmDialog } from "@/models";
import { ability, operationsService } from "@/services";
import {
  accountingPeriodsStore,
  bankAccountsStore,
  coreStore,
  documentsStore,
  fixedAssetsStore,
  operationAccrualsStore,
  partnersStore,
  productsStore,
  realEstateAssetsStore,
  realEstateLoansStore,
  rentalAgreementsStore,
  tenantsStore,
  transactionsStore,
} from "@/store";
import { FeedbackTypeEnum } from "@/store/modules/Core.store";
import { ForbiddenError, subject } from "@casl/ability";
import {
  CategorizationEntry,
  CategorizationEntryParameters,
  LedgerAccountEnum,
  PartnerTypeEnum,
  RealEstateLoan,
  Suggestion,
  TenantTypeEnum,
  TypeReference,
  categorizationRules,
  distanceAmount,
  getLoanTypeText,
  getMonthlyPayment,
  isLoanTypeAutomatized,
  round2decimals,
  getMoment,
  RentalUnit,
} from "@edmp/api";
import { calculateTaxAmounts } from "@edmp/api/src";
import { SetupContext, computed } from "@vue/composition-api";
import Decimal from "decimal.js-light";
import { cloneDeep, get } from "lodash";
import { AccrualState } from "../accruals/accruals.usable";
import CategorizationValidateCategories, {
  CategoryValidate,
} from "./categorizationValidateCategories.usable";

export const useCategorization = (
  accrualState: AccrualState,
  context: SetupContext
) => {
  /**
   * Data
   */
  const categoriesList = computed(() => transactionsStore.categoriesList);

  /**
   * * Store
   * Use for provide store action to component
   */
  const updateCategoriesList = async () => {
    return await transactionsStore.updateCategoriesList();
  };

  /**
   * * transactionState
   *
   * Use for get and update element in transactionState
   */
  const categories = computed({
    get: () => accrualState.lines,
    set: (lines) =>
      context.emit(
        "update:accrualState",
        Object.assign(accrualState, { lines })
      ),
  });

  const selectedCategory = computed({
    get: () => accrualState.selectedCategory,
    set: (selectCategory) =>
      context.emit(
        "update:accrualState",
        Object.assign(accrualState, { selectedCategory: selectCategory })
      ),
  });

  const isOpenCategorizationList = computed({
    get: () => accrualState.isOpenCategorizationList,
    set: (isOpen) =>
      context.emit(
        "update:accrualState",
        Object.assign(accrualState, { isOpenCategorizationList: isOpen })
      ),
  });

  const isOpenCategorizationDetailStep = computed({
    get: () => accrualState.isOpenCategorizationDetailStep,
    set: (isOpen) =>
      context.emit(
        "update:accrualState",
        Object.assign(accrualState, {
          isOpenCategorizationDetailStep: isOpen,
        })
      ),
  });

  const isUpdatingCategorization = computed({
    get: () => accrualState.isUpdatingCategorization,
    set: (isUpdate) =>
      context.emit(
        "update:accrualState",
        Object.assign(accrualState, { isUpdatingCategorization: isUpdate })
      ),
  });

  /**
   * * Use
   */
  /**
   * AddCategory takes a CategorizationEntry and adds it to the beginning of the categories array.
   */
  const addCategory = (category: CategorizationEntry) => {
    categories.value.unshift(cloneDeep(category));
  };

  /**
   * It takes an index and a category, and if the index is not -1, it replaces the category at that index
   * with the new category
   */
  const updateCategory = (index: number, category: CategorizationEntry) => {
    if (index !== -1) {
      const lines = cloneDeep(categories.value);
      lines[index] = category;
      categories.value = lines;
    }
  };

  /**
   * It deletes a category from the list of categories
   */
  const deleteCategory = (index: number) => {
    categories.value.splice(index, 1);
    if (!categories.value.length) {
      isOpenCategorizationList.value = true;
      isOpenCategorizationDetailStep.value = false;
    }
    if (categories.value.length === 1) {
      const lines = cloneDeep(categories.value);
      lines[0].amount = accrualState.amount;
      categories.value = lines;
    }
  };

  /**
   * It resets the categories to the saved categories
   */
  const resetCategories = () => {
    isOpenCategorizationList.value = true;
    isOpenCategorizationDetailStep.value = false;
  };

  /**
   * It opens the categorization list and closes the categorization detail
   */
  const subDivide = () => {
    isOpenCategorizationList.value = true;
    isOpenCategorizationDetailStep.value = false;
  };

  /**
   * It takes an account number and returns the corresponding category information
   * @param {string} account - string - the account number
   * @returns The categoryInfos object
   */
  const getCategoryInfos = (account: string): Suggestion => {
    let categoryInfos: Suggestion | undefined;
    for (const parentCategories of Object.values(
      transactionsStore.categoriesList
    )) {
      categoryInfos = parentCategories.find(
        (category) => category.number === account
      );
      if (categoryInfos) break;
    }

    if (!categoryInfos) {
      coreStore.displayFeedback({
        type: FeedbackTypeEnum.ERROR,
        message: "Une erreur est survenue lors de la categorisation",
      });
      console.error(categoryInfos);
      throw new Error("no categoryInfos found");
    }

    return categoryInfos;
  };

  /**
   * It selects a category and opens the categorization detail step if authorized
   */
  const selectCategory = (category: Suggestion) => {
    try {
      ForbiddenError.from(ability).throwUnlessCan(
        "categorize",
        subject("Transaction", {
          categoryNumber: category.number,
        })
      );
      selectedCategory.value = category;
      isOpenCategorizationDetailStep.value = 1;
      isOpenCategorizationList.value = false;
    } catch (error) {
      if (error instanceof ForbiddenError) {
        coreStore.displayFeedback({
          type: FeedbackTypeEnum.WARNING,
          message: error.message,
        });
      }
    }
  };

  /**
   * It saves the categorization of an accrual.
   */
  const saveCategorization = async () => {
    isUpdatingCategorization.value = true;

    // Check rules
    const { isEqualAmount } = categorizationRules;
    if (!isEqualAmount(accrualState.amount, categories.value)) {
      const message = `La somme des opérations comptables doit être égale au montant de l'engagement. 
      Veuillez corriger avant de valider.`;
      coreStore.displayFeedback({
        message,
        type: FeedbackTypeEnum.ERROR,
        timeout: 15000,
      });
      isUpdatingCategorization.value = false;
      throw new Error(message);
    }

    // Remove accountName from state.lines
    // restLine = realEstateAsset, rentalUnit, client , partner
    const linesToUpdate = categories.value.map(
      ({ account, amount, ...restLine }) => {
        delete restLine.accountName;
        return {
          amount: amount,
          account: account.substring(0, 6),
          ...restLine,
        };
      }
    );

    try {
      await operationsService.accruals.create({
        productId: productsStore.currentId,
        accountingPeriodId: accrualState.accountingPeriod.id,
        amount: accrualState.amount,
        date: accrualState.date,
        summary: accrualState.summary,
        entries: linesToUpdate,
        type: accrualState.type,
      });

      // Fetching is different if we are in the reconciliation module
      if (accrualState.transaction) {
        await operationAccrualsStore.fetchOperationAccruals({
          productId: productsStore.currentId,
          options: { includedReconciled: false },
          transactionId: accrualState.transaction.id,
        });
      } else {
        await operationAccrualsStore.fetchOperationAccruals({
          productId: productsStore.currentId,
          accountingPeriodId: accountingPeriodsStore.currentId,
          options: { includedReconciled: true },
        });
        await operationAccrualsStore.fetchUnreconciledOperationAccruals({
          productId: productsStore.currentId,
        });
      }
      accrualState.isOpenCategorizationDetailStep = false;
    } catch (err) {
      resetCategories();
    }
  };

  /**
   * It validates the category line of a transaction
   */
  const validateCategory = async (
    categoryValidate: CategoryValidate,
    confirmDialog: VConfirmDialog
  ) => {
    await new CategorizationValidateCategories(
      accrualState,
      categoryValidate,
      confirmDialog,
      context
    ).validateCategory();
  };

  const MISSING_VALUE = "Ø";
  const getAttribute = (
    type: keyof CategorizationEntryParameters | TypeReference,
    id: string
  ): string => {
    if (type === "realEstateAsset") {
      const realEstateAsset = realEstateAssetsStore.realEstateAssets.find(
        (realEstateAsset) => realEstateAsset.id === id
      );
      return realEstateAsset ? realEstateAsset.name : MISSING_VALUE;
    }

    if (type === "rentalUnit") {
      const rentalUnit = realEstateAssetsStore.rentalUnits.find(
        (rentalUnit) => rentalUnit.id === id
      );
      return rentalUnit ? rentalUnit.name : MISSING_VALUE;
    }

    if (type === "rentalAgreement") {
      const rentalAgreement = rentalAgreementsStore.rentalAgreements.find(
        (rentalAgreement) => rentalAgreement.id === id
      );
      return rentalAgreement && rentalAgreement.name
        ? rentalAgreement.name
        : MISSING_VALUE;
    }

    if (type === "partner") {
      return partnersStore.getPartnerName(id);
    }

    if (type === "realEstateLoan") {
      const realEstateLoan = realEstateLoansStore.realEstateLoans.find(
        (realEstateLoan) => realEstateLoan.id === id
      );
      return realEstateLoan ? realEstateLoan.name : MISSING_VALUE;
    }

    if (type === "supportingDocument") {
      const document = documentsStore.documents.find(
        (document) => document.id === id
      );
      // Filter to ignore other documents than supporting documents even if already selected
      return document && document.tags?.includes("supportingDocument")
        ? document.metadata?.wording || MISSING_VALUE // There is always a wording for supporting documents
        : MISSING_VALUE;
    }

    if (type === "bankAccount") {
      const bankAccount = bankAccountsStore.bankAccounts.find(
        (bankAccount) => bankAccount.id === id
      );
      return bankAccount?.name || MISSING_VALUE;
    }

    if (type === "fixedAsset") {
      const fixedAsset = fixedAssetsStore.fixedAssets.find(
        (fixedAsset) => fixedAsset.id === id
      );
      return fixedAsset?.name || MISSING_VALUE;
    }

    if (type === "beneficiary") {
      const beneficiary = productsStore.products.find((p) => p.id === id);
      return beneficiary?.name || MISSING_VALUE;
    }
    return MISSING_VALUE;
  };

  const isTvaCollectedIncorrect = () => {
    const rent = accrualState.lines.find(
      (line) => line.account === LedgerAccountEnum.N706000
    );
    if (rent && rent.rentalUnit) {
      const rentalUnit = realEstateAssetsStore.getRentalUnit(rent.rentalUnit);

      if (rentalUnit && rentalUnit.taxRateTVA) {
        const tva = accrualState.lines.find(
          (line) => line.account === LedgerAccountEnum.N445720
        );
        const charges = accrualState.lines.find(
          (line) => line.account === LedgerAccountEnum.N708399
        );
        if (tva && rentalUnit) {
          const taxRateTVA = getApplicableTaxRateTVA() || rentalUnit.taxRateTVA;
          const tvaAmount = tva.amount;
          let total = new Decimal(rent.amount).add(tvaAmount);
          if (charges) {
            total = total.add(charges.amount);
          }
          total = total.toDecimalPlaces();
          const transactionAmountHT = new Decimal(tvaAmount)
            .mul(100)
            .div(taxRateTVA)
            .toDecimalPlaces(2);
          const calculatedTotal = transactionAmountHT
            .mul(1 + taxRateTVA / 100)
            .toDecimalPlaces(2);
          return !total.sub(calculatedTotal).abs().lte(0.1);
        } else return false;
      }
    }
  };

  const getApplicableTaxRateTVA = (
    rentalUnitFromCategorization?: RentalUnit
  ): number | undefined => {
    let rentalUnit = rentalUnitFromCategorization;

    if (!rentalUnit) {
      const rent = accrualState.lines.find(
        (line) => line.account === LedgerAccountEnum.N706000
      );
      if (!rent?.rentalUnit) return undefined;

      rentalUnit = realEstateAssetsStore.getRentalUnit(rent.rentalUnit);
    }

    if (!rentalUnit) return undefined;

    const history = [...(rentalUnit.history || [])];
    const targetDate = getMoment(accrualState.date);

    // Sort history by dateChanged
    history.sort((a, b) =>
      getMoment(b.dateChanged).diff(getMoment(a.dateChanged))
    );

    // Check if targetDate is after the first history entry
    if (history.length > 0) {
      const firstDate = getMoment(history[0].dateChanged);
      if (targetDate.isAfter(firstDate)) {
        return Number(rentalUnit.taxRateTVA);
      }
    }

    for (let i = 0; i < history.length; i++) {
      const current = history[i];
      const next = history[i + 1];
      const currentDate = getMoment(current.dateChanged);

      // Check if targetDate is between currentDate and nextDate
      if (next) {
        const nextDate = getMoment(next.dateChanged);
        if (
          targetDate.isSameOrBefore(currentDate) &&
          targetDate.isAfter(nextDate)
        ) {
          return current.rate;
        }
      } else if (targetDate.isSameOrBefore(currentDate)) {
        return current.rate;
      }
    }

    // Return the default tax rate if no match is found
    return Number(rentalUnit.taxRateTVA);
  };

  /**
   * It returns the amount of money that is missing from the transaction
   */
  const getMissingAmount = () =>
    categorizationRules.getMissingAmount(accrualState.amount, categories.value);

  const isRequired = (name: TypeReference, category?: Suggestion): boolean => {
    return get(
      category ? category : accrualState.selectedCategory,
      "required",
      new Array<string>()
    ).includes(name);
  };

  const isOptional = (name: TypeReference, category?: Suggestion): boolean => {
    return (
      get(
        category ? category : accrualState.selectedCategory,
        "fields",
        new Array<string>()
      ).includes(name) && !isRequired(name, category)
    );
  };

  const getRentalUnitFromRealEstateAssetId = (realEstateAssetId: string) =>
    realEstateAssetsStore.getRentalUnitByRealEstateAssetId(realEstateAssetId);

  const isSamePeriodAsAcquisition = (date): boolean =>
    getMoment(date).isBetween(
      accountingPeriodsStore.currentAccountingPeriod?.startAt,
      accountingPeriodsStore.currentAccountingPeriod?.endAt,
      undefined,
      "[]"
    );

  /**
   * References
   */
  const realEstateAssets = (category?: Suggestion) => {
    const realEstateAssets = realEstateAssetsStore.realEstateAssets
      .filter((realEstateAsset) => {
        if (
          (category
            ? category.number
            : accrualState.selectedCategory?.number) ===
          LedgerAccountEnum.N213000
        ) {
          const boughtDate = accountingPeriodsStore.isLMNP
            ? realEstateAsset.entryDateActivityLmnp
            : realEstateAsset.boughtAt;
          if (!isSamePeriodAsAcquisition(boughtDate)) {
            return false;
          }
        }
        return true;
      })
      .map((realEstateAsset) => ({
        name: realEstateAsset.name,
        id: realEstateAsset.id,
      }));
    if (isOptional(TypeReference.realEstateAsset, category)) {
      return [{ name: "Aucun", id: "" }, ...realEstateAssets];
    }
    return realEstateAssets;
  };
  const fixedAssets = (category?: Suggestion) => {
    const fixedAssets = fixedAssetsStore.fixedAssets
      .filter((fixedAsset) => {
        if (
          getMoment(fixedAsset.commissioningAt).isBetween(
            getMoment(accountingPeriodsStore.currentAccountingPeriod?.startAt),
            getMoment(accountingPeriodsStore.currentAccountingPeriod?.endAt),
            "days",
            "[]"
          )
        ) {
          return fixedAsset;
        }
      })
      .map((fixedAsset) => ({
        name: fixedAsset.name,
        id: fixedAsset.id,
      }));
    if (isOptional(TypeReference.fixedAsset, category)) {
      return [{ name: "Aucun", id: "" }, ...fixedAssets];
    }
    return fixedAssets;
  };

  const tenants = (realEstateAssetId?: string, category?: Suggestion) => {
    const tenants = realEstateAssetId
      ? tenantsStore
          .getTenantsByRealEstateAssetId(realEstateAssetId)

          .map((tenant) => {
            if (tenant.type === TenantTypeEnum.NATURAL_PERSON) {
              return {
                name: `${tenant.firstName} ${tenant.lastName}`,
                id: tenant.id as string,
              };
            }
            return {
              name: `${tenant.denomination}`,
              id: tenant.id as string,
            };
          })
      : [];
    if (isOptional(TypeReference.tenant, category)) {
      return [{ name: "Aucun", id: "" }, ...tenants];
    }
    return tenants;
  };

  const partners = () =>
    partnersStore.partners.map((e) => ({
      name:
        e.type === PartnerTypeEnum.LEGAL_PERSON
          ? e.denomination || e.siret || "Unknown"
          : e.type === PartnerTypeEnum.NATURAL_PERSON
          ? `${e.firstName} ${e.lastName}`
          : "Unknown",
      id: e.id,
    }));

  const loanTitle = (loan: RealEstateLoan): string => {
    if (isLoanTypeAutomatized(loan.loanType)) {
      return `${loan.name} (${round2decimals(
        getMonthlyPayment(loan)
      )} €, le ${new Date(loan.loanStartAt).getDate()} du mois)`;
    } else {
      return `${loan.name} (${getLoanTypeText(loan.loanType)})`;
    }
  };

  const loans = (realEstateAssetId?: string, category?: Suggestion) => {
    const realEstateLoans = realEstateAssetId
      ? realEstateLoansStore
          .getRealEstateLoansByRealEstateAssetId(realEstateAssetId)
          .map((loan) => ({
            name: loanTitle(loan),
            id: loan.id as string,
            distanceAmount: distanceAmount(
              getMonthlyPayment(loan).neg().toNumber(),
              accrualState.amount
            ),
          }))
          .sort((a, b) => (a.distanceAmount < b.distanceAmount ? -1 : 1))
      : [];
    if (isOptional(TypeReference.realEstateLoan, category)) {
      return [{ name: "Aucun", id: "" }, ...realEstateLoans];
    }
    return realEstateLoans;
  };

  const getSupportingDocuments = computed(() =>
    documentsStore.documents
      .filter((document) => {
        return (
          getMoment(document.metadata?.issuanceDate).isBetween(
            getMoment(accrualState.startAt),
            getMoment(accrualState.endAt),
            "day",
            "[]"
          ) && document.tags?.includes("supportingDocument")
        );
      })

      .map((d) => ({
        name: `${d.metadata?.wording} - ${d.metadata?.amountTotal}€`, // Wordings must always exist
        id: d.id,
        amount: d.metadata?.amountTotal,
        date: d.metadata?.issuanceDate,
        distanceAmount: distanceAmount(
          d?.metadata?.amountTotal ?? 0,
          Math.abs(accrualState.amount)
        ),
        distanceDate: d.metadata?.issuanceDate
          ? Math.abs(
              new Date(d.metadata?.issuanceDate).getTime() -
                new Date(accrualState.date).getTime()
            )
          : Number.MAX_SAFE_INTEGER,
      }))
      .sort((a, b) => {
        if (a.distanceAmount === b.distanceAmount) {
          return a.distanceDate - b.distanceDate;
        }
        return a.distanceAmount - b.distanceAmount;
      })
  );
  const supportingDocuments = (category?: Suggestion) => {
    let documents = getSupportingDocuments.value;
    if (
      (category ? category : accrualState.selectedCategory?.number) ===
      LedgerAccountEnum.N213000
    ) {
      documents = documents.filter((document) =>
        isSamePeriodAsAcquisition(document.date)
      );
    }
    documents = documents.filter((document) =>
      getMoment(document.date).isSame(accrualState.date)
    );
    return documents;
  };
  const amountTaxDecomposition = (rentalUnit) => {
    const transactionAmountTTC = new Decimal(accrualState.amount);
    const { getApplicableTaxRateTVA } = useCategorization(
      accrualState,
      context
    );
    if (rentalUnit) {
      const taxRateTVA =
        getApplicableTaxRateTVA(rentalUnit) || rentalUnit.taxRateTVA;
      return calculateTaxAmounts(taxRateTVA, transactionAmountTTC);
    }
  };

  return {
    accrualState,
    // Data
    categoriesList,
    amountTaxDecomposition,
    // Store
    updateCategoriesList,
    categories,
    isOpenCategorizationList,
    isOpenCategorizationDetailStep,
    // Use
    addCategory,
    updateCategory,
    resetCategories,
    deleteCategory,
    subDivide,
    getCategoryInfos,
    selectCategory,
    saveCategorization,
    validateCategory,
    getAttribute,
    isTvaCollectedIncorrect,
    getMissingAmount,
    getRentalUnitFromRealEstateAssetId,
    isRequired,
    isOptional,
    // references
    fixedAssets,
    realEstateAssets,
    tenants,
    partners,
    loans,
    getSupportingDocuments,
    supportingDocuments,
    getApplicableTaxRateTVA,
  };
};
