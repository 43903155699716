











































































































































































































































































































































































































































































































































import DatePicker from "@/components/atom/DatePicker.vue";
import { ArticleEnum, useCrisp } from "@/composables/crisp.usable";
import { ROUTE_NAMES } from "@/router/routes";
import {
  accountingPeriodsStore,
  coreStore,
  productsStore,
  realEstateAmortisationsStore,
  realEstateAssetsStore,
  subscriptionsStore,
  tasksStore,
} from "@/store";
import {
  getMoment,
  getReferredIdByTypeWithReferences,
  isCommissionedBeforeAmortizationRecovery,
  NatureLabel,
  RealEstateAmortisation,
  RealEstateAsset,
  RealEstateAssetUpdate,
  TaskTypeReference,
  TaskCode,
  RealEstateAmortisationCreate,
  defaultAmortisationsByRealEstateAssetNature,
  defaultAmortisationsPurchaseCostsByRealEstateAssetNature,
  getDefaultGroundShareByRealEstateAssetNature,
  defaultAmortisations,
  calculateAmortisation,
  defaultAmortisationsPurchaseCosts,
} from "@edmp/api";
import {
  computed,
  defineComponent,
  nextTick,
  PropType,
  ref,
  Ref,
} from "@vue/composition-api";
import { format } from "date-fns";
import PageHeader from "../../atom/PageHeader.vue";
import Anomalies from "../anomalies/Anomalies.vue";
import TitleNew from "../title/TitleNew.vue";
import { HelpingMessage } from "@/components/atom/helping";
import Tag from "@/components/atom/Tag.vue";
import RealEstateAmortisationTable from "./RealEstateAmortisationTable.vue";
import RealEstateAmortisationForm from "./RealEstateAmortisationForm.vue";
import RealEstateAmortisations from "./RealEstateAmortisations.vue";
import { VForm } from "@/models";
import { cloneDeep, merge } from "lodash";
import i18n from "@/plugins/i18n";
import { useRealEstateAmortisation } from "../realEstateAmortisation/realEstateAmortisation.usable";
import { FeedbackTypeEnum } from "@/store/modules/Core.store";
import Vue from "vue";

type RealEstateAmortisationCreateLocal = Omit<
  RealEstateAmortisationCreate,
  "type" | "accountingPeriodIdAllowedToEdit" | "realEstateAssetId"
> & { id: null; type: RealEstateAmortisationCreate["type"] | undefined };

export default defineComponent({
  name: "RealEstateAmortisationLMNP",
  components: {
    TitleNew,
    Tag,
    HelpingMessage,
    Anomalies,
    RealEstateAmortisations,
    RealEstateAmortisationTable,
    RealEstateAmortisationForm,
    PageHeader,
    DatePicker,
  },
  props: {
    productId: {
      type: String,
      required: true,
    },
    realEstateAssetId: {
      type: String as PropType<string | null>,
      required: true,
    },
    realEstateAmortisationId: { type: String, required: false },
    isFlow: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const isLoading = ref(true);
    const validateInProgress = ref(false);
    const isOpenAmortisationTable = ref(false);
    const isOpenPurchaseCostsAmortisationTable = ref(false);
    const realEstateAsset = ref<RealEstateAsset | undefined>(
      cloneDeep(
        realEstateAssetsStore.getRealEstateAsset(props.realEstateAssetId)
      )
    );
    const realEstateAssetNature = computed(() =>
      realEstateAsset.value?.nature !== undefined
        ? NatureLabel[realEstateAsset.value.nature]
        : "inconnue"
    );
    const groundShare = ref(
      (() => {
        if (realEstateAsset.value?.groundShare) {
          return realEstateAsset.value.groundShare;
        } else if (realEstateAsset.value?.nature) {
          return getDefaultGroundShareByRealEstateAssetNature(
            realEstateAsset.value.nature
          );
        } else {
          return 20;
        }
      })()
    );

    const realEstateAssetMarketPriceText = computed(() =>
      realEstateAsset.value?.marketPrice !== undefined &&
      realEstateAsset.value?.marketPrice !== 0
        ? i18n.n(realEstateAsset.value.marketPrice, "currency", "fr-FR")
        : "inconnu"
    );

    const realEstateAssetEntryDateActivityLmnp = computed(() =>
      realEstateAsset.value?.entryDateActivityLmnp !== undefined
        ? getMoment(realEstateAsset.value.entryDateActivityLmnp).format("LL")
        : "inconnue"
    );

    // MarketPrice
    const formattedMarketPrice = computed(() =>
      realEstateAsset.value?.marketPrice
        ? i18n.n(realEstateAsset.value.marketPrice)
        : ""
    );
    const updateMarketPrice = (value) => {
      if (realEstateAsset.value) {
        realEstateAsset.value.marketPrice = parseFloat(value) || 0;
      }
    };

    const today = ref(format(new Date(), "yyyy-MM-dd"));

    const scrollToElement = (elementId) =>
      nextTick(() => {
        const element = document.getElementById(elementId);
        if (element) {
          nextTick(() => {
            element.scrollIntoView({ behavior: "smooth" });
          });
        }
      });

    const amortizationRecoveryTask = computed(() =>
      tasksStore.completedTasks?.find(
        (task) =>
          task.code ===
            TaskCode.AmortizationsResumptionPostAmortizationsUpdate &&
          getReferredIdByTypeWithReferences(
            task.references,
            TaskTypeReference.product
          ) === productsStore.currentId
      )
    );
    const isReadonlyMessage = computed(() => {
      const amortizationRecoveryTaskAccountingPeriod =
        accountingPeriodsStore.accountingPeriods.find(
          (acc) =>
            acc.id ===
            getReferredIdByTypeWithReferences(
              amortizationRecoveryTask.value?.references,
              TaskTypeReference.accountingPeriod
            )
        );
      if (amortizationRecoveryTaskAccountingPeriod) {
        const year = new Date(
          amortizationRecoveryTaskAccountingPeriod.endAt
        ).getFullYear();
        return (
          "Votre clôture de l'année " +
          year +
          " a été faite, les amortissements ne sont plus modifiables pour assurer le principe de continuité de la comptabilité"
        );
      }
    });
    const isReadonly = computed(() => {
      return (
        realEstateAsset.value &&
        amortizationRecoveryTask.value &&
        isCommissionedBeforeAmortizationRecovery(
          realEstateAsset.value,
          amortizationRecoveryTask.value
        )
      );
    });

    const openArticleRealEstateAmortisationAddingLine = () => {
      return useCrisp().openArticle(
        ArticleEnum.REAL_ESTATE_AMORTISATION_RECOMMENDATION_ADD_LINE
      );
    };

    // Amortisation
    const realEstateAmortisationList = computed(() =>
      realEstateAmortisationsStore.getRealEstateAmortisationsByRealEstateAssetId(
        props.realEstateAssetId
      )
    );
    const isAmortisationExist = computed(
      () => realEstateAmortisationList.value.length !== 0
    );
    const isEditableAmortisation: Ref<boolean> = ref(false);
    const setEditingAmortisation = (val: boolean) =>
      (isEditableAmortisation.value = val);
    const isEditablePurchaseCostsAmortisation: Ref<boolean> = ref(false);
    const setEditingPurchaseCostsAmortisation = (val: boolean) =>
      (isEditablePurchaseCostsAmortisation.value = val);
    const isEditable: Ref<boolean> = ref(!isAmortisationExist.value);
    const setEditing = (val: boolean) => (isEditable.value = val);

    const realEstateAmortisations = computed(() => {
      const realEstateAmortisations = cloneDeep(
        realEstateAmortisationsStore.getRealEstateAmortisationsByRealEstateAssetId(
          props.realEstateAssetId as string
        )
      );
      return realEstateAmortisations.filter(
        (amortisation) => amortisation.type !== "purchase-costs"
      );
    });

    const realEstateAmortisationsPurchaseCosts = computed(() => {
      const realEstateAmortisationsPurchaseCosts = cloneDeep(
        realEstateAmortisationsStore.getRealEstateAmortisationsByRealEstateAssetId(
          props.realEstateAssetId as string
        )
      );
      return realEstateAmortisationsPurchaseCosts.filter(
        (amortisation) => amortisation.type === "purchase-costs"
      );
    });

    const isNew = computed(() => !realEstateAmortisations.value.length);

    const realEstateAssetBoughtPrice = computed(
      () => realEstateAsset.value?.marketPrice ?? 0
    );

    const newAmortisations: RealEstateAmortisationCreateLocal = {
      id: null,
      type: undefined,
      share: 0,
      value: 0,
      startAt: getMoment(
        realEstateAsset.value?.commissioningAt ||
          `${accountingPeriodsStore.lastAccountingPeriodNotClosed.startAt}T00:00:00.000Z`
      ).toISOString(),
      durationInYear: 0,
      rate: 0,
    };

    const newAmortisationsPurchaseCosts: RealEstateAmortisationCreateLocal = {
      id: null,
      type: undefined,
      share: 0,
      value: realEstateAsset.value?.boughtFee || 0,
      startAt: getMoment(
        realEstateAsset.value?.commissioningAt ||
          `${accountingPeriodsStore.lastAccountingPeriodNotClosed.startAt}T00:00:00.000Z`
      ).toISOString(),
      durationInYear: 0,
      rate: 0,
    };

    const amortisations: Ref<
      ((RealEstateAmortisationCreateLocal | RealEstateAmortisation) & {
        netBookValue?: number;
        durationLeftInYear?: number;
        dotationNValue?: number;
      })[]
    > = ref(
      (() => {
        if (isNew.value) {
          if (realEstateAsset.value?.nature) {
            return cloneDeep(
              defaultAmortisationsByRealEstateAssetNature[
                realEstateAsset.value.nature
              ].map((defaultAmortisation) => {
                const { value, ...defaultAmortisationRest } =
                  defaultAmortisation;
                return merge(cloneDeep(newAmortisations), {
                  value,
                  ...defaultAmortisationRest,
                });
              })
            );
          } else {
            return [];
          }
        } else {
          return cloneDeep(realEstateAmortisations.value);
        }
      })()
    );

    const updateAmortisations = (tableAmortisations) => {
      amortisations.value = tableAmortisations;
    };

    const updateAmortisationsPurchaseCosts = (tableAmortisations) => {
      amortisationsPurchaseCosts.value = tableAmortisations;
    };

    const amortisationsPurchaseCosts: Ref<
      ((RealEstateAmortisationCreateLocal | RealEstateAmortisation) & {
        netBookValue?: number;
        durationLeftInYear?: number;
        dotationNValue?: number;
      })[]
    > = ref(
      (() => {
        if (isNew.value) {
          if (realEstateAsset.value?.nature) {
            return cloneDeep(
              defaultAmortisationsPurchaseCostsByRealEstateAssetNature[
                realEstateAsset.value.nature
              ].map((defaultAmortisation) => {
                const { value, ...defaultAmortisationRest } =
                  defaultAmortisation;
                return merge(cloneDeep(newAmortisationsPurchaseCosts), {
                  value,
                  ...defaultAmortisationRest,
                });
              })
            );
          } else {
            return [];
          }
        } else {
          return cloneDeep(realEstateAmortisationsPurchaseCosts.value);
        }
      })()
    );

    const {
      totalAmortisationByRealEstateAssets,
      amortizationValueStatsForCurrentAccountingPeriod,
    } = useRealEstateAmortisation();

    const totalAmortisation = computed(
      () =>
        Number(
          totalAmortisationByRealEstateAssets.value[
            props.realEstateAssetId as string
          ]
        ) || 0
    );

    const amortizationValueStats = computed(() =>
      amortisations.value.reduce(
        (prev, current) => {
          const { valueToAmortizedForCurrentAccountingPeriod, valueAmortized } =
            amortizationValueStatsForCurrentAccountingPeriod(
              props.realEstateAssetId as string,
              current.durationInYear,
              current.value
            );

          return {
            valueToAmortizedForCurrentAccountingPeriod:
              prev.valueToAmortizedForCurrentAccountingPeriod +
              valueToAmortizedForCurrentAccountingPeriod,
            valueAmortized: prev.valueAmortized + valueAmortized,
          };
        },
        {
          valueToAmortizedForCurrentAccountingPeriod: 0,
          valueAmortized: 0,
        }
      )
    );

    const amortizationValueStatsPurchaseCosts = computed(() =>
      amortisationsPurchaseCosts.value.reduce(
        (prev, current) => {
          const { valueToAmortizedForCurrentAccountingPeriod, valueAmortized } =
            amortizationValueStatsForCurrentAccountingPeriod(
              props.realEstateAssetId as string,
              current.durationInYear,
              current.value
            );

          return {
            valueToAmortizedForCurrentAccountingPeriod:
              prev.valueToAmortizedForCurrentAccountingPeriod +
              valueToAmortizedForCurrentAccountingPeriod,
            valueAmortized: prev.valueAmortized + valueAmortized,
          };
        },
        {
          valueToAmortizedForCurrentAccountingPeriod: 0,
          valueAmortized: 0,
        }
      )
    );

    const updateCalculateAmortisation = async ({
      keyUpdated,
      rowIndex,
    }: {
      keyUpdated: keyof Pick<
        RealEstateAmortisation,
        "type" | "share" | "value" | "durationInYear"
      >;
      rowIndex: number;
    }) => {
      const value = amortisations.value[rowIndex][keyUpdated];

      if (keyUpdated === "type" && realEstateAsset.value?.nature) {
        const typeDefaultAmortisationsByRealEstateAssetNature = cloneDeep(
          defaultAmortisationsByRealEstateAssetNature[
            realEstateAsset.value.nature
          ].find(({ type }) => type === value)
        );
        if (typeDefaultAmortisationsByRealEstateAssetNature) {
          Vue.set(
            amortisations.value,
            rowIndex,
            merge(
              cloneDeep(amortisations.value[rowIndex]),
              typeDefaultAmortisationsByRealEstateAssetNature
            )
          );
        } else {
          const typeDefaultAmortisations = cloneDeep(
            Object.values(defaultAmortisations).find(
              ({ type }) => type === value
            )
          );
          if (typeDefaultAmortisations) {
            Vue.set(
              amortisations.value,
              rowIndex,
              merge(
                cloneDeep(amortisations.value[rowIndex]),
                typeDefaultAmortisations
              )
            );
          }
        }
        await updateCalculateAmortisation({
          keyUpdated: "share",
          rowIndex,
        });
      }

      if (
        keyUpdated === "share" ||
        keyUpdated === "value" ||
        keyUpdated === "durationInYear"
      ) {
        amortisations.value[rowIndex][keyUpdated] = Number(value);

        Vue.set(
          amortisations.value,
          rowIndex,
          merge(
            { ...amortisations.value[rowIndex] },
            calculateAmortisation({
              amortisationType: "default",
              amortisationValues: {
                share: amortisations.value[rowIndex].share,
                value: amortisations.value[rowIndex].value,
                durationInYear: amortisations.value[rowIndex].durationInYear,
                rate: amortisations.value[rowIndex].rate,
              },
              boughtPrice: realEstateAssetBoughtPrice.value,
              keyUpdated,
            })
          )
        );
      }
    };

    const updateCalculateAmortisationPurchaseCosts = async ({
      keyUpdated,
      rowIndex,
    }: {
      keyUpdated: keyof Pick<
        RealEstateAmortisation,
        "type" | "share" | "value" | "durationInYear"
      >;
      rowIndex: number;
    }) => {
      const value = amortisationsPurchaseCosts.value[rowIndex][keyUpdated];

      if (keyUpdated === "type" && realEstateAsset.value?.nature) {
        const typeDefaultAmortisationsByRealEstateAssetNature = cloneDeep(
          defaultAmortisationsPurchaseCostsByRealEstateAssetNature[
            realEstateAsset.value.nature
          ].find(({ type }) => type === value)
        );
        if (typeDefaultAmortisationsByRealEstateAssetNature) {
          Vue.set(
            amortisationsPurchaseCosts.value,
            rowIndex,
            merge(
              cloneDeep(amortisationsPurchaseCosts.value[rowIndex]),
              typeDefaultAmortisationsByRealEstateAssetNature
            )
          );
        } else {
          const typeDefaultAmortisations = cloneDeep(
            Object.values(defaultAmortisationsPurchaseCosts).find(
              ({ type }) => type === value
            )
          );
          if (typeDefaultAmortisations) {
            Vue.set(
              amortisationsPurchaseCosts.value,
              rowIndex,
              merge(
                cloneDeep(amortisationsPurchaseCosts.value[rowIndex]),
                typeDefaultAmortisations
              )
            );
          }
        }
        await updateCalculateAmortisationPurchaseCosts({
          keyUpdated: "share",
          rowIndex,
        });
      }

      if (keyUpdated === "value" || keyUpdated === "durationInYear") {
        amortisationsPurchaseCosts.value[rowIndex][keyUpdated] = Number(value);

        Vue.set(
          amortisationsPurchaseCosts.value,
          rowIndex,
          merge(
            amortisationsPurchaseCosts.value[rowIndex],
            calculateAmortisation({
              amortisationType: "purchaseCosts",
              amortisationValues: {
                share: amortisationsPurchaseCosts.value[rowIndex].share,
                value: amortisationsPurchaseCosts.value[rowIndex].value,
                durationInYear:
                  amortisationsPurchaseCosts.value[rowIndex].durationInYear,
                rate: amortisationsPurchaseCosts.value[rowIndex].rate,
              },
              keyUpdated,
            })
          )
        );
      }
    };

    const accountingPeriodFromRealEstatAssetCommissioningDate = computed(() => {
      const currentCommissioningAt = getMoment(
        realEstateAsset.value?.commissioningAt
      );
      let selectedAccountingPeriod =
        accountingPeriodsStore.accountingPeriods.find((accountingPeriod) => {
          const accountingPeriodStartAt = getMoment(accountingPeriod.startAt);
          const accountingPeriodEndAt = getMoment(accountingPeriod.endAt);
          return currentCommissioningAt.isBetween(
            accountingPeriodStartAt,
            accountingPeriodEndAt,
            undefined,
            "[]"
          );
        });

      // Return the accounting period when the real estate asset was commissioned
      if (selectedAccountingPeriod) return selectedAccountingPeriod;
      // Return the last accounting period
      return accountingPeriodsStore.firstAccountingPeriodNotClosed;
    });

    async function validate() {
      // Part 1 => create amortisation simulation
      validateInProgress.value = true;
      if (realEstateAsset.value && (context.refs.form as VForm).validate()) {
        await realEstateAssetsStore.updateRealEstateAsset(
          realEstateAsset.value as RealEstateAssetUpdate
        );

        if (realEstateAmortisationsPurchaseCosts.value[0]) {
          await realEstateAmortisationsStore.updateRealEstateAmortisation({
            ...realEstateAmortisationsPurchaseCosts.value[0],
            value: realEstateAsset.value.boughtFee,
          });
        }

        setEditing(false);
        isEditableAmortisation.value = true;
        isEditablePurchaseCostsAmortisation.value = true;
      }

      // Part 2 => validate amortisation table
      try {
        if (realEstateAsset.value) {
          await realEstateAssetsStore.updateRealEstateAsset(
            merge(cloneDeep(realEstateAsset.value), {
              groundShare: Number(groundShare.value),
            })
          );
        }
      } catch (error) {
        console.log(error);
        const message =
          "Une erreur est survenue lors de la création de votre amortissement";
        coreStore.displayFeedback({
          message,
          type: FeedbackTypeEnum.ERROR,
        });
      }

      for (let index = 0; index < amortisations.value.length; index++) {
        const data = updateCalculateAmortisation({
          keyUpdated: "share",
          rowIndex: index,
        });
        const amortisation = {
          ...cloneDeep(amortisations.value[index]),
          ...data,
        };

        try {
          let createdOrUpdatedAmortisation: RealEstateAmortisation;
          if (amortisation.id) {
            createdOrUpdatedAmortisation =
              await realEstateAmortisationsStore.updateRealEstateAmortisation(
                amortisation
              );
          } else {
            createdOrUpdatedAmortisation =
              await realEstateAmortisationsStore.createRealEstateAmortisation({
                accountingPeriodIdAllowedToEdit:
                  accountingPeriodFromRealEstatAssetCommissioningDate.value.id,
                realEstateAssetId: props.realEstateAssetId as string,
                ...(amortisation as Omit<
                  RealEstateAmortisationCreateLocal,
                  "type"
                > & { type: RealEstateAmortisationCreate["type"] }),
              });
          }
          amortisations.value[index] = createdOrUpdatedAmortisation;
          updateCalculateAmortisation({
            keyUpdated: "share",
            rowIndex: index,
          });
        } catch (err) {
          console.log(err);
          const message =
            "Une erreur est survenue lors de la création de votre amortissement";
          coreStore.displayFeedback({
            message,
            type: FeedbackTypeEnum.ERROR,
          });
        }
      }

      if (realEstateAsset.value?.boughtFee) {
        for (
          let index = 0;
          index < amortisationsPurchaseCosts.value.length;
          index++
        ) {
          const data = updateCalculateAmortisationPurchaseCosts({
            keyUpdated: "durationInYear",
            rowIndex: index,
          });

          const amortisationPurchaseCosts = {
            ...cloneDeep(amortisationsPurchaseCosts.value[index]),
            ...data,
            value: realEstateAsset.value?.boughtFee,
          };
          try {
            let createdOrUpdatedAmortisation: RealEstateAmortisation;
            if (amortisationPurchaseCosts.id) {
              createdOrUpdatedAmortisation =
                await realEstateAmortisationsStore.updateRealEstateAmortisation(
                  amortisationPurchaseCosts
                );
            } else {
              createdOrUpdatedAmortisation =
                await realEstateAmortisationsStore.createRealEstateAmortisation(
                  {
                    accountingPeriodIdAllowedToEdit:
                      accountingPeriodFromRealEstatAssetCommissioningDate.value
                        .id,
                    realEstateAssetId: props.realEstateAssetId as string,
                    ...(amortisationPurchaseCosts as Omit<
                      RealEstateAmortisationCreateLocal,
                      "type"
                    > & { type: RealEstateAmortisationCreate["type"] }),
                  }
                );
            }
            amortisationsPurchaseCosts.value[index] =
              createdOrUpdatedAmortisation;
            updateCalculateAmortisationPurchaseCosts({
              keyUpdated: "durationInYear",
              rowIndex: index,
            });
          } catch (err) {
            console.log(err);
            const message =
              "Une erreur est survenue lors de la création de votre amortissement";
            coreStore.displayFeedback({
              message,
              type: FeedbackTypeEnum.ERROR,
            });
          }
        }
      }
      validateInProgress.value = false;
    }

    return {
      isOpenAmortisationTable,
      isOpenPurchaseCostsAmortisationTable,
      ROUTE_NAMES,
      realEstateAsset,
      realEstateAssetNature,
      realEstateAssetMarketPriceText,
      realEstateAssetEntryDateActivityLmnp,
      formattedMarketPrice,
      updateMarketPrice,
      isEditable,
      today,
      setEditing,
      isLoading,
      validateInProgress,
      isAmortisationExist,
      openArticleRealEstateAmortisationAddingLine,
      isEditableAmortisation,
      setEditingAmortisation,
      isEditablePurchaseCostsAmortisation,
      setEditingPurchaseCostsAmortisation,
      isReadonly,
      isReadonlyMessage,
      subscriptionsStore,
      accountingPeriodsStore,
      scrollToElement,
      validate,
      realEstateAmortisationsPurchaseCosts,
      totalAmortisation,
      amortizationValueStats,
      amortizationValueStatsPurchaseCosts,
      openArticleAmortisationRecommendationAddLine: () =>
        useCrisp().openArticle(
          ArticleEnum.REAL_ESTATE_AMORTISATION_RECOMMENDATION_ADD_LINE
        ),
      updateAmortisations,
      updateAmortisationsPurchaseCosts,
      getDefaultGroundShareByRealEstateAssetNature,
    };
  },
});
