import { OperationAccrualsService, OperationsService } from "@edmp/api";
import axios from "axios";

const api = "/api/v1/operations";

class OperationService {
  async create(operationCreate: OperationsService.CreateIn) {
    return axios
      .post<OperationsService.CreateOut>(`${api}`, operationCreate)
      .then((res) => res.data);
  }

  async listCategories(params: OperationsService.ListCategoriesIn) {
    return axios
      .get<OperationsService.ListCategoriesOut>(`${api}`, { params })
      .then((res) => res.data);
  }

  async update(operationUpdate: OperationsService.PatchIn) {
    return axios
      .patch<OperationsService.PatchOut>(
        `${api}/${operationUpdate.id}`,
        operationUpdate
      )
      .then((res) => res.data);
  }

  async adjustGenericCharges(params: OperationsService.AdjustGenericChargesIn) {
    return axios
      .put<OperationsService.AdjustGenericChargesOut>(
        `${api}/adjust-generic-charges/${params.productId}`
      )
      .then((res) => res.data);
  }

  accruals = {
    async create(params: OperationAccrualsService.CreateIn) {
      return axios
        .post<OperationAccrualsService.CreateOut>(`${api}/accruals`, params)
        .then((res) => res.data);
    },

    async list(params: OperationAccrualsService.ListIn) {
      return axios
        .get<OperationAccrualsService.ListOut>(`${api}/accruals`, { params })
        .then((res) => res.data);
    },

    async reconcile(params: OperationAccrualsService.ReconcileIn) {
      return axios
        .post<OperationAccrualsService.ReconcileOut>(
          `${api}/accruals/reconcile`,
          params
        )
        .then((res) => res.data);
    },

    async remove(params: OperationAccrualsService.RemoveIn) {
      const res = await axios.delete<OperationAccrualsService.RemoveOut>(
        `${api}/accruals/${params.id}`,
        { params }
      );
      return res.data;
    },

    async reportLoss(params: OperationAccrualsService.ReportLossIn) {
      const res = await axios.post<OperationAccrualsService.ReportLossOut>(
        `${api}/accruals/reportLoss`,
        params
      );
      return res.data;
    },
  };
}

// Export a singleton instance in the global namespace
export const operationsService = new OperationService();
