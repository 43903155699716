import { render, staticRenderFns } from "./AccountingIS.vue?vue&type=template&id=1338bd64&scoped=true&"
import script from "./AccountingIS.vue?vue&type=script&lang=ts&"
export * from "./AccountingIS.vue?vue&type=script&lang=ts&"
import style0 from "./AccountingIS.vue?vue&type=style&index=0&id=1338bd64&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1338bd64",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VScrollXTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VAlert,VBtn,VCheckbox,VDivider,VForm,VRadio,VRadioGroup,VScrollXTransition})
