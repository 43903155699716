import store from "@/store/root";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { AssembliesService, GeneralAssemblyEvent } from "@edmp/api";
import { assembliesService } from "@/services/Assemblies.service";
import { accountingPeriodsStore } from "..";

export interface AssembliesState {
  generalAssemblyEvents: Array<GeneralAssemblyEvent>;
  loading: boolean;
}

@Module({
  name: "assemblies-store",
  dynamic: true,
  namespaced: true,
  store,
})
export class AssembliesStore extends VuexModule implements AssembliesState {
  generalAssemblyEvents: Array<GeneralAssemblyEvent> = [];
  loading = false;

  @Mutation
  reset(): void {
    this.generalAssemblyEvents = [];
    this.loading = false;
  }

  get getGeneralAssemblyEvent() {
    return (generalAssemblyEventId: string) =>
      this.generalAssemblyEvents.find(
        (generalAssemblyEvent) =>
          generalAssemblyEvent.id === generalAssemblyEventId
      );
  }

  get listGeneralAssemblyEventsForCurrentAccountingPeriod() {
    return this.generalAssemblyEvents.filter(
      (generalAssemblyEvent) =>
        generalAssemblyEvent.accountingPeriodId ===
        accountingPeriodsStore.currentAccountingPeriod?.id
    );
  }

  @Mutation
  setGeneralAssemblyEvents(
    generalAssemblyEvents: GeneralAssemblyEvent[]
  ): void {
    this.generalAssemblyEvents = generalAssemblyEvents;
  }

  @Mutation
  addGeneralAssemblyEvent(generalAssemblyEvent: GeneralAssemblyEvent): void {
    this.generalAssemblyEvents = [
      ...this.generalAssemblyEvents,
      generalAssemblyEvent,
    ];
  }

  @Mutation
  setLoading(isLoading: boolean): void {
    this.loading = isLoading;
  }

  @Action
  async createGeneralAssemblyEvent(assemblyCreate: AssembliesService.CreateIn) {
    const newGeneralAssemblyEvent = await assembliesService.create(
      assemblyCreate
    );
    this.addGeneralAssemblyEvent(newGeneralAssemblyEvent);
    return newGeneralAssemblyEvent;
  }

  @Action
  async fetchGeneralAssemblyEvents(productId: string) {
    this.setLoading(true);
    this.setGeneralAssemblyEvents(await assembliesService.list({ productId }));
    this.setLoading(false);
  }

  @Action
  async update(assemblyUpdate: AssembliesService.UpdateIn) {
    const eventUpdated = await assembliesService.update(assemblyUpdate);
    await this.fetchGeneralAssemblyEvents(eventUpdated.productId);
  }

  @Action
  async invite(params: AssembliesService.InviteIn) {
    return await assembliesService.invite(params);
  }

  @Action
  async report(params: AssembliesService.ReportIn) {
    return await assembliesService.report(params);
  }
}
