






































import { HelpingMessage } from "@/components/atom/helping";
import { accountingPeriodsStore } from "@/store";
import { TaxRegime } from "@edmp/api";
import { computed, defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "SubscriptionDeductibility",
  components: {
    HelpingMessage,
  },
  props: {},
  setup() {
    const isIR = computed(
      () =>
        accountingPeriodsStore.currentAccountingPeriod?.taxRegime ===
        TaxRegime.IR_2072
    );
    const isIS = computed(
      () =>
        accountingPeriodsStore.currentAccountingPeriod?.taxRegime ===
        TaxRegime.IS_2065
    );
    const isLMNP = computed(
      () =>
        accountingPeriodsStore.currentAccountingPeriod?.taxRegime ===
        TaxRegime.LMNP_2031
    );

    return {
      isIR,
      isIS,
      isLMNP,
    };
  },
});
