




























































































































































































































































































import DialogRight from "@/components/core/DialogRight.vue";
import ActivityUpdate from "@/components/core/rent/ActivityUpdate.vue";
import { dispatchIndexationEvent, IndexationEventCode } from "@/events";
import {
  productsStore,
  documentsStore,
  realEstateAssetsStore,
  rentalAgreementsStore,
  tenantsStore,
  activitiesStore,
  rentIndexationsStore,
  usersStore,
  coreStore,
} from "@/store";
import {
  Address,
  getMoment,
  NewRentIndexation,
  TenantTypeEnum,
} from "@edmp/api";
import { computed, defineComponent, PropType } from "@vue/composition-api";

export default defineComponent({
  name: "IndexationMail",
  components: {
    DialogRight,
    ActivityUpdate,
  },
  props: {
    rentIndexationOut: {
      type: Object as PropType<NewRentIndexation>,
      required: true,
    },
    index: {
      type: Object,
      required: false,
    },
  },
  setup(props, context) {
    const activity = computed(() => {
      return activitiesStore.currentActivity;
    });
    const imageSignature = computed(() => {
      if (activity.value?.signature?.typeSignature === "base64") {
        return activity.value?.signature?.data;
      } else if (
        activity.value?.signature?.typeSignature === "image" &&
        activity.value.signature.signatureId
      ) {
        return documentsStore.getDocument(activity.value.signature.signatureId)
          ?.href;
      }
    });
    const imageLogo = computed(() => {
      if (activity.value?.logoId) {
        return documentsStore.getDocument(activity.value.logoId)?.href;
      }
    });
    const rentalAgreement = computed(() => {
      return rentalAgreementsStore.rentalAgreements.find(
        (rentalAgreement) =>
          rentalAgreement.id === props.rentIndexationOut.rentalAgreementId
      );
    });
    const tenant = computed(() => {
      if (rentalAgreement.value?.id)
        return tenantsStore.getTenantsByRentalAgreementId(
          rentalAgreement.value.id
        );
    });
    const today = computed(() => getMoment().format("DD-MM-YYYY"));

    const realEstateAsset = computed(() => {
      if (
        rentalAgreement.value &&
        rentalAgreement.value.product.realEstateAsset
      ) {
        return realEstateAssetsStore.realEstateAssets.find(
          (realEstateAsset) =>
            realEstateAsset.id ===
            rentalAgreement.value?.product.realEstateAsset?.id
        );
      }
    });
    const rentPlusCharges = computed(() => {
      if (
        rentalAgreement.value &&
        rentalAgreement.value.financialConditions.amountOfCharges
      ) {
        return (
          Number(props.rentIndexationOut.newRent) +
          rentalAgreement.value.financialConditions.amountOfCharges
        );
      }
    });
    const formatDate = (date) => {
      if (date === "") {
        return "";
      } else {
        const [year, month, day] = date.split("-");
        return `${day}-${month}-${year}`;
      }
    };

    const formatAddress = (
      address: Address = { street: "", city: "", zip: "" }
    ) => {
      if (address.street) {
        return address.street + ", " + address.zip + " " + address.city;
      } else {
        return null;
      }
    };

    const product = computed(() => {
      return productsStore.getProduct({
        id: productsStore.currentId,
      });
    });

    const tenants = computed(() => {
      if (tenant.value)
        return tenantsStore.getTenantsByRentalAgreementId(
          props.rentIndexationOut.rentalAgreementId
        );
      else return [];
    });

    async function sendIndexation() {
      if (rentalAgreement.value?.id) {
        const isSend = await rentIndexationsStore.sendIndexationMail(
          rentalAgreement.value?.id
        );
        if (isSend) {
          documentsStore.fetchDocuments();

          dispatchIndexationEvent({
            userId: usersStore.loggedInUser.id,
            productId: productsStore.currentId,
            date: getMoment().toISOString(),
            code: IndexationEventCode.SEND_MAIL_NOTICE_RENT_INDEXATION,
          });
          coreStore.displayFeedback({
            message: "Préavis de révision envoyé",
          });
        } else {
          coreStore.displayFeedback({
            message:
              "Un problème technique empêche l'envoi du courrier. Veuillez réessayer plus tard.",
          });
        }

        context.emit("close");
      }
    }

    return {
      product,
      sendIndexation,
      rentalAgreement,
      realEstateAsset,
      imageSignature,
      imageLogo,
      tenants,
      formatAddress,
      TenantTypeEnum,
      formatDate,
      today,
      rentPlusCharges,
    };
  },
});
