import { accountingsService } from "@/services";
import store from "@/store/root";
import {
  AccountingCarryForward,
  AccountingCarryForwardCreate,
  AccountingCarryForwardType,
  AccountingCarryForwardUpdate,
  getMoment,
} from "@edmp/api";
import Vue from "vue";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { accountingPeriodsStore, productsStore } from "..";
import { cloneDeep } from "lodash";

export interface AccountingCarryForwardsState {
  accountingCarryForwards: AccountingCarryForward[];
  loading: boolean;
}

@Module({
  name: "accounting-carry-forward-store",
  dynamic: true,
  namespaced: true,
  store,
})
export class AccountingCarryForwardsStore
  extends VuexModule
  implements AccountingCarryForwardsState
{
  accountingCarryForwards: AccountingCarryForwardsState["accountingCarryForwards"] =
    [];
  loading = false;

  @Mutation
  reset(): void {
    this.loading = false;
  }

  @Mutation
  setLoading(isLoading: boolean): void {
    this.loading = isLoading;
  }

  // Getters
  get getAccountingCarryForward() {
    return (accountingCarryForwardId) =>
      this.accountingCarryForwards.find(
        ({ id }) => id === accountingCarryForwardId
      );
  }

  get getAccountingCarryForwardByAccountingPeriod() {
    return (accountingPeriodId) =>
      this.accountingCarryForwards.filter(
        (accountingCarryForward) =>
          accountingCarryForward.type === AccountingCarryForwardType.CLOSURE &&
          accountingCarryForward.accountingPeriodId === accountingPeriodId
      );
  }

  get getAccountingCarryForwardByType() {
    return (type: AccountingCarryForward["type"]) =>
      this.accountingCarryForwards.filter(
        (accountingCarryForward) => accountingCarryForward.type === type
      );
  }

  get getAccountingCarryForwardRecovery() {
    return this.getAccountingCarryForwardByType(
      AccountingCarryForwardType.RECOVERY
    )[0] as AccountingCarryForward<AccountingCarryForwardType.RECOVERY>;
  }

  get getCurrentAccountingCarryForward() {
    return this.accountingCarryForwards.find(
      (accountingCarryForward) =>
        accountingCarryForward.type === AccountingCarryForwardType.CLOSURE &&
        accountingCarryForward.accountingPeriodId ===
          accountingPeriodsStore.currentId
    );
  }

  get getPreviousAccountingCarryForward() {
    const accountingPeriods = cloneDeep(
      accountingPeriodsStore.accountingPeriods
    ).sort((acc1, acc2) => getMoment(acc1.endAt).diff(acc2.endAt));
    const accountingPeriodIndex = accountingPeriods.findIndex(
      (accountingPeriod) =>
        accountingPeriod.id === accountingPeriodsStore.currentId
    );
    if (accountingPeriodIndex !== -1) {
      if (accountingPeriodIndex === 0) {
        return this.getAccountingCarryForwardByType(
          AccountingCarryForwardType.RECOVERY
        )[0] as AccountingCarryForward | undefined;
      } else if (accountingPeriods[accountingPeriodIndex - 1]) {
        return this.getAccountingCarryForwardByType(
          AccountingCarryForwardType.CLOSURE
        ).find(
          (accountingCarryForward) =>
            accountingCarryForward.type ===
              AccountingCarryForwardType.CLOSURE &&
            accountingCarryForward.accountingPeriodId ===
              accountingPeriods[accountingPeriodIndex - 1].id
        );
      }
    }
  }

  // Mutations
  @Mutation
  setAccountingCarryForwards(
    accountingCarryForwards: AccountingCarryForwardsState["accountingCarryForwards"]
  ): void {
    this.accountingCarryForwards = accountingCarryForwards;
  }

  @Mutation
  setAccountingCarryForward(
    accountingCarryForward: AccountingCarryForward
  ): void {
    const accountingCarryForwardIndex = this.accountingCarryForwards.findIndex(
      ({ id }) => id === accountingCarryForward.id
    );
    if (accountingCarryForwardIndex !== -1) {
      Vue.set(
        this.accountingCarryForwards,
        accountingCarryForwardIndex,
        accountingCarryForward
      );
    } else {
      this.accountingCarryForwards.push(accountingCarryForward);
    }
  }

  @Mutation
  delAccountingCarryForward(
    accountingCarryForwardId: AccountingCarryForward["id"]
  ): void {
    const accountingCarryForwardIndex = this.accountingCarryForwards.findIndex(
      ({ id }) => id === accountingCarryForwardId
    );
    if (accountingCarryForwardIndex !== -1) {
      this.accountingCarryForwards.splice(accountingCarryForwardIndex, 1);
    }
  }

  // Actions
  @Action
  async createAccountingCarryForward(
    accountingCarryForwardCreate: AccountingCarryForwardCreate
  ): Promise<AccountingCarryForward> {
    const accountingCarryForwardCreated =
      await accountingsService.carryForwards.create(
        accountingCarryForwardCreate
      );
    this.setAccountingCarryForward(accountingCarryForwardCreated);
    return accountingCarryForwardCreated;
  }

  @Action
  async fetchAccountingCarryForwards(): Promise<AccountingCarryForward[]> {
    const accountingCarryForwards = await accountingsService.carryForwards.list(
      {
        productId: productsStore.currentId,
      }
    );
    this.setAccountingCarryForwards(accountingCarryForwards);
    return accountingCarryForwards;
  }

  @Action
  async updateAccountingCarryForward(
    accountingCarryForwardUpdate: AccountingCarryForwardUpdate
  ): Promise<AccountingCarryForward> {
    const accountingCarryForwardUpdated =
      await accountingsService.carryForwards.update(
        accountingCarryForwardUpdate
      );
    this.setAccountingCarryForward(accountingCarryForwardUpdated);
    return accountingCarryForwardUpdated;
  }

  @Action
  async deleteAccountingCarryForward(
    accountingCarryForwardId: AccountingCarryForward["id"]
  ): Promise<boolean> {
    const isDeleted = await accountingsService.carryForwards.delete({
      id: accountingCarryForwardId,
    });
    if (isDeleted) {
      this.delAccountingCarryForward(accountingCarryForwardId);
    }
    return isDeleted;
  }
}
