import { ROUTE_NAMES } from "@/router/routes";
import { usersStore, productsStore, accountingPeriodsStore } from "@/store";
import { TaxRegime, UserTags } from "@edmp/api";
import { Ref, SetupContext } from "@vue/composition-api";

export function useInit() {
  async function initApp(context?: SetupContext) {
    await usersStore.fetchLoggedInUser();
    if (productsStore.products.length > 0)
      if (context && context.root.$route.params.productId) {
        await productsStore.switchProduct({
          id: context.root.$route.params.productId,
        });
      } else {
        await productsStore.selectFirst();
      }
  }

  async function initOnBoardingStep(
    context: SetupContext,
    currentStep: number,
    data: { taxRegime: Ref<TaxRegime | undefined> }
  ): Promise<void> {
    if (!usersStore.isLogged && currentStep > 2) {
      context.root.$router.push({
        name: ROUTE_NAMES.SignIn,
      });
    } else if (usersStore.isLogged) {
      await initApp(context);
      // Si les données requises pour le compte sont présents on redirige
      // vers `context.root.$router.currentRoute.fullPath` ou `Dashboard`.
      // Sinon (else if) on effectue une reprise de l'onboarding.
      if (
        productsStore.products.length > 0 &&
        accountingPeriodsStore.accountingPeriods.length > 0 &&
        currentStep == 0
      ) {
        const { fullPath } = context.root.$router.currentRoute;
        if (fullPath != undefined || fullPath != null) {
          // Nécessaire pour test 2e2
          context.root.$router.push({
            path: context.root.$router.currentRoute.fullPath,
          });
        } else {
          context.root.$router.push({
            name: ROUTE_NAMES.Dashboard,
          });
        }
      } else if (
        !productsStore.products.length &&
        !data.taxRegime.value &&
        currentStep != 1
      ) {
        if (
          data.taxRegime &&
          !usersStore.loggedInUser.productsArchived.length
        ) {
          if (usersStore.loggedInUser.tags.includes(UserTags.SCI_IR)) {
            data.taxRegime.value = TaxRegime.IR_2072;
          }
          if (usersStore.loggedInUser.tags.includes(UserTags.SCI_IS)) {
            data.taxRegime.value = TaxRegime.IS_2065;
          }
          if (usersStore.loggedInUser.tags.includes(UserTags.LMNP)) {
            data.taxRegime.value = TaxRegime.LMNP_2031;
          }

          if (
            data.taxRegime.value &&
            !!usersStore.loggedInUser.firstName &&
            !!usersStore.loggedInUser.lastName &&
            productsStore.products.length === 0 &&
            currentStep !== 3
          ) {
            context.root.$router.push({
              name: ROUTE_NAMES.RegisterActivityDetails,
              query: { registrationIncomplete: "true" },
            });
            return;
          }
        }
        context.root.$router.push({
          name: ROUTE_NAMES.RegisterActivity,
          query: { registrationIncomplete: "true" },
        });
      } else if (
        !(
          !!usersStore.loggedInUser.firstName ||
          !!usersStore.loggedInUser.lastName
        ) &&
        currentStep !== 2
      ) {
        context.root.$router.push({
          name: ROUTE_NAMES.RegisterUser,
          query: { registrationIncomplete: "true" },
        });
      } else if (
        !!usersStore.loggedInUser.firstName &&
        !!usersStore.loggedInUser.lastName &&
        productsStore.products.length === 0 &&
        currentStep !== 3
      ) {
        context.root.$router.push({
          name: ROUTE_NAMES.RegisterActivityDetails,
          query: { registrationIncomplete: "true" },
        });
      } else if (
        productsStore.products.length > 0 &&
        accountingPeriodsStore.accountingPeriods.length > 0 &&
        currentStep == 3
      ) {
        if (accountingPeriodsStore.isLMNP) {
          context.root.$router.push({
            name: ROUTE_NAMES.RealEstateAssets,
          });
        } else {
          context.root.$router.push({
            name: ROUTE_NAMES.Dashboard,
            query: { welcome: "new" },
          });
        }
      }
    }
  }
  return {
    initApp,
    initOnBoardingStep,
  };
}
