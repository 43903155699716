var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"newDocuments-card pa-4 pa-sm-8"},[_c('div',{staticClass:"newDocuments-header"},[_c('h4',{staticClass:"newDocuments-title mb-0"},[_vm._v("Mes documents récents")]),_c('router-link',{staticClass:"newDocuments-linkBtn",attrs:{"id":"track-see_all_document","to":{ name: _vm.ROUTE_NAMES.NewDocuments }}},[_vm._v(" Tout voir "),_c('v-icon',{attrs:{"color":"#2c2c2c"}},[_vm._v("mdi-arrow-right")])],1)],1),_c('v-data-table',{staticClass:"documents-table",attrs:{"hide-default-header":_vm.$vuetify.breakpoint.smAndUp,"hide-default-footer":"","headers":_vm.lastDocumentsHeader,"items":_vm.lastDocuments,"items-per-page":3,"item-class":"align","no-data-text":"Aucun document"},on:{"click:row":_vm.showDocument},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"document-name"},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.name))])])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.$d(new Date(item.createdAt), "short", "fr-FR"))+" ")])]}},{key:"item.accountingPeriod",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.getAccountingPeriodYear(item.product.accountingPeriod))+" ")])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"document-type pa-3",attrs:{"depressed":"","color":"#757575","target":item.id}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.extension(item.type))+" ")])]}}],null,true)},[_c('span',[_vm._v("Voir le document")])])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }