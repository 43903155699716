

























































































import {
  computed,
  defineComponent,
  Ref,
  ref,
  watch,
} from "@vue/composition-api";
import PageHeader from "@/components/atom/PageHeader.vue";
import { ROUTE_NAMES } from "@/router/routes";
import RegularizationInitial from "@/components/core/rentalAgreements/regularizationFlow/RegularizationInitial.vue";
import RegularizationChargeClearance from "@/components/core/rentalAgreements/regularizationFlow/RegularizationChargeClearance.vue";
import RegularizationResult from "@/components/core/rentalAgreements/regularizationFlow/RegularizationResult.vue";

import { accountingPeriodsStore, realEstateAssetsStore } from "@/store";
import {
  getMoment,
  PropertyTypeEnum,
  NewRegularization,
  ChargesTypeEnum,
} from "@edmp/api";
import Vue from "vue";
import { useRegularization } from "./regularizationFlow/regularization.usable";

export default defineComponent({
  name: "RegularizationFlow",
  components: {
    RegularizationInitial,
    RegularizationChargeClearance,
    RegularizationResult,
    PageHeader,
  },
  setup(props, context) {
    const { itemsBaseCommonCharge, itemsBaseIndividualCharge } =
      useRegularization();

    const isFullYear = computed(() => {
      const periodStartYearMonthDay =
        regularization.value.periodStart.split("-");
      const periodEndYearMonthDay = regularization.value.periodEnd.split("-");
      return (
        // Month
        periodStartYearMonthDay["1"] === "01" &&
        // Day
        periodStartYearMonthDay["2"] === "01" &&
        // Month
        periodEndYearMonthDay["1"] === "12" &&
        // Day
        periodEndYearMonthDay["2"] === "31"
      );
    });
    const baseData = {
      commonCharges: [
        { type: ChargesTypeEnum.CO_PROPERTY_CHARGES, ...itemsBaseCommonCharge }, // Charges d'immeuble
        { type: ChargesTypeEnum.TCHW, ...itemsBaseCommonCharge }, // TEOM
        { type: ChargesTypeEnum.SANITATION_CHARGES, ...itemsBaseCommonCharge }, // Redevance d'assainissement
        { type: ChargesTypeEnum.BOILER_MAINTENANCE, ...itemsBaseCommonCharge }, // Entretien chaudière
      ],
      individualCharges: [
        { type: ChargesTypeEnum.COLD_WATER, ...itemsBaseIndividualCharge },
        { type: ChargesTypeEnum.HOT_WATER, ...itemsBaseIndividualCharge },
        { type: ChargesTypeEnum.ELECTRICITY, ...itemsBaseIndividualCharge },
        { type: ChargesTypeEnum.GAS, ...itemsBaseIndividualCharge },
      ],
    };

    const regularization: Ref<NewRegularization> = ref({
      rentalAgreementId: context.root.$route.params.rentalAgreementId,
      productId: context.root.$route.params.productId,
      periodStart: getMoment()
        .startOf("year")
        .subtract(1, "year")
        .format("YYYY-MM-DD"),
      periodEnd: getMoment()
        .endOf("year")
        .subtract(1, "year")
        .format("YYYY-MM-DD"),
      propertyType: PropertyTypeEnum.CO_OWNERSHIP,
      date: getMoment().format("YYYY-MM-DD"),
      commonCharges: { ...JSON.parse(JSON.stringify(baseData.commonCharges)) },
      individualCharges: {
        ...JSON.parse(JSON.stringify(baseData.individualCharges)),
      },
    });
    const step = ref(1);

    watch(isFullYear, () => {
      resetRegularization();
    });

    const resetRegularization = () => {
      Vue.set(regularization.value, "commonCharges", {
        ...JSON.parse(JSON.stringify(baseData.commonCharges)),
      });
      Vue.set(regularization.value, "individualCharges", {
        ...JSON.parse(JSON.stringify(baseData.individualCharges)),
      });
    };
    const redirectToPreviousPage = () => {
      if (context.root.$route.name === ROUTE_NAMES.RegularizationLMNP) {
        context.root.$router.push({
          name: ROUTE_NAMES.RealEstateLMNP,
          query: { tabIndex: "4" },
        });
      } else {
        context.root.$router.push({
          name: ROUTE_NAMES.RealEstate,
          query: { tab: accountingPeriodsStore.isIR ? "1" : "2" },
        });
      }
    };

    const validate = async () => {
      realEstateAssetsStore.selectRealEstateAssetId("");
      redirectToPreviousPage();
    };

    const updateRegularizationField = (value: string, path: string) => {
      const pList = path.split(".");
      const key = pList.pop();
      const pointer = pList.reduce((accumulator, currentValue) => {
        if (accumulator[currentValue] === undefined) {
          Vue.set(accumulator, currentValue, {});
        }
        return accumulator[currentValue];
      }, regularization.value);
      Vue.set(pointer, key as string, value);
    };

    watch(
      regularization,
      () => {
        if (isFullYear.value) {
          if (regularization.value.commonCharges) {
            Object.values(regularization.value.commonCharges).forEach(
              (charge) => {
                const result = (
                  (charge.expenses as number) *
                  ((charge.percentage as number) / 100)
                ).toFixed(2);
                Vue.set(charge, "totalAmount", result);
              }
            );
          }
        } else {
          if (
            regularization.value.commonCharges &&
            regularization.value.individualCharges
          ) {
            const periodDays = getMoment(regularization.value.periodEnd)
              .add(1, "day")
              .diff(getMoment(regularization.value.periodStart), "days");

            Object.values(regularization.value.commonCharges).forEach(
              (charge) => {
                const proration = periodDays / 365;
                const result = (
                  ((charge.expenses as number) *
                    proration *
                    (charge.percentage as number)) /
                  100
                ).toFixed(2);
                Vue.set(charge, "totalAmount", result);
              }
            );

            Object.values(regularization.value.individualCharges).forEach(
              (charge) => {
                charge.consumption =
                  (charge.indexEnd as number) - (charge.indexStart as number);
                const result = (
                  (charge.consumption as number) *
                  (charge.unitCost as number) *
                  ((charge.percentage as number) / 100)
                ).toFixed(2);
                Vue.set(charge, "totalAmount", result);
              }
            );
          }
        }
      },
      { deep: true }
    );

    return {
      step,
      validate,
      ROUTE_NAMES,
      regularization,
      isFullYear,
      updateRegularizationField,
      redirectToPreviousPage,
      accountingPeriodsStore,
      resetRegularization,
    };
  },
});
