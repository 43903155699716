






























































































import {
  accountingBalanceSheetsStore,
  operationAccrualsStore,
  productsStore,
} from "@/store";
import {
  AccountingBalanceSheet,
  LedgerAccountEnum,
  OperationAccrualLib,
  OperationAccrualsModel,
} from "@edmp/api";
import { computed, defineComponent, PropType, ref } from "@vue/composition-api";
import AccrualCards from "../AccrualCards.vue";
import AccrualMassCreate from "../create/AccrualMassCreate.vue";
import {
  AccrualsInventoryState,
  useAccrualsInventory,
} from "./accrualsInventory.usable";

export default defineComponent({
  name: "AccrualsInventorySimple",
  props: {
    accrualsInventoryState: {
      type: Object as PropType<AccrualsInventoryState>,
      required: true,
    },
  },
  components: {
    AccrualCards,
    AccrualMassCreate,
  },
  setup(props) {
    const accrualsInventoryState = ref<AccrualsInventoryState>(
      props.accrualsInventoryState
    );
    const { initCurrentBalance } = useAccrualsInventory();
    const isRentDueThisYear = ref(false);
    const isOpenAddAccrual = ref(false);
    const isOpenDetailAccruals = ref(false);

    const operationAccruals = computed<
      OperationAccrualsModel.OperationAccrual[]
    >(() => {
      const filterByAccount = (
        operationAccrual: OperationAccrualsModel.OperationAccrual
      ) =>
        operationAccrual.journalEntry.lines?.some(
          (line) =>
            OperationAccrualLib.getDoubleEntryAccount(line.account) ===
            accrualsInventoryState.value.account
        );
      const result = operationAccrualsStore.operationAccruals.filter(
        (operationAccrual) =>
          filterByAccount(operationAccrual) &&
          !operationAccrual.reconciliation?.date
      );
      isRentDueThisYear.value = !!result.length;
      return result;
    });

    const getDisplayText = computed(() => {
      let result = "";
      switch (accrualsInventoryState.value.account) {
        case LedgerAccountEnum.N411000:
          if (operationAccruals.value.length) {
            result = "Vous avez des loyers restants dus sur l'année en cours :";
          } else {
            result = "Avez-vous des loyers restants dûs sur l'année en cours ?";
          }
          break;
        case LedgerAccountEnum.N401000:
          if (operationAccruals.value.length) {
            result =
              "Vous avez des charges restantes à payer sur l'année en cours :";
          } else {
            result =
              "Avez-vous des charges restantes à payer sur l'année en cours ?";
          }
          break;
        default:
          result = "";
      }

      return result;
    });

    const updateCurrentBalance = async () => {
      await accountingBalanceSheetsStore.fetchAccountingBalanceSheets();
      accrualsInventoryState.value.currentBalance = initCurrentBalance(
        accrualsInventoryState.value.account,
        accountingBalanceSheetsStore.getCurrentAccountingBalanceSheet as AccountingBalanceSheet
      );
    };

    const closeAddAccrual = async () => {
      await operationAccrualsStore.fetchOperationAccruals({
        productId: productsStore.currentId,
        options: { includedReconciled: true },
      });
      await updateCurrentBalance();
      isOpenAddAccrual.value = false;
    };

    return {
      isRentDueThisYear,
      isOpenAddAccrual,
      isOpenDetailAccruals,
      operationAccruals,
      getDisplayText,
      closeAddAccrual,
    };
  },
});
