var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-5",attrs:{"id":"taxDeclaration2065Confirm","outlined":""}},[_c('v-card-title',[_c('TitleNew',{attrs:{"hLevel":"h2","close":""},on:{"close":function($event){return _vm.$emit('close')}}},[_vm._v(" "+_vm._s(_vm.taxDeclaration ? "Confirmation de votre déclaration fiscale" : "Votre déclaration fiscale")+" ")])],1),(_vm.steps === 1)?[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.validateAdditionalInformations($event)}}},[_c('v-card-text',[(_vm.additionalInformations.hasPreviousAccountingPeriod)?[_c('div',{staticClass:"section-header"},[_c('b',{staticClass:"section-header-title"},[_vm._v(" Veuillez renseigner votre période fiscale de l'année "+_vm._s(_vm.currentAccountingPeriod ? _vm.getMoment(_vm.currentAccountingPeriod.endAt) .subtract(1, "year") .format("YYYY") : "précédente")+" ")])]),_c('div',{staticClass:"section-body"},[_c('div',{staticClass:"position position-left"},[_c('DatePicker',{attrs:{"label":"Date début d'exercice","header-date-format":function (date) { return _vm.getMoment(date).locale('fr').format('MMMM'); },"min":_vm.additionalInformations.rules.exerciceStartDateMin(),"max":_vm.additionalInformations.rules.exerciceStartDateMax(),"dense":"","disabled":_vm.additionalInformations.accountingPeriodPrevious.startAt !==
                  '',"no-title":"","scrollable":"","required":""},model:{value:(
                  _vm.additionalInformations.accountingPeriodPrevious.startAt
                ),callback:function ($$v) {_vm.$set(_vm.additionalInformations.accountingPeriodPrevious, "startAt", $$v)},expression:"\n                  additionalInformations.accountingPeriodPrevious.startAt\n                "}})],1),_c('div',{staticClass:"position position-right"},[_c('DatePicker',{attrs:{"label":"Date fin d'exercice","header-date-format":function (date) { return _vm.getMoment(date).locale('fr').format('MMMM'); },"min":_vm.additionalInformations.rules.exerciceEndDateMin(),"max":_vm.additionalInformations.rules.exerciceEndDateMax(),"dense":"","disabled":_vm.additionalInformations.accountingPeriodPrevious.endAt !== '',"no-title":"","scrollable":"","required":""},model:{value:(
                  _vm.additionalInformations.accountingPeriodPrevious.endAt
                ),callback:function ($$v) {_vm.$set(_vm.additionalInformations.accountingPeriodPrevious, "endAt", $$v)},expression:"\n                  additionalInformations.accountingPeriodPrevious.endAt\n                "}})],1)])]:_vm._e(),_c('v-row',{staticClass:"mb-3",staticStyle:{"align-items":"center"},attrs:{"no-gutters":""}},[_c('b',[_vm._v(" Votre activité a-t-elle changé d'adresse postale par rapport à votre précédente déclaration fiscale ? ")]),_c('YesNoSwitch',{staticClass:"ml-3",model:{value:(_vm.additionalInformations.hasPreviousAddress),callback:function ($$v) {_vm.$set(_vm.additionalInformations, "hasPreviousAddress", $$v)},expression:"additionalInformations.hasPreviousAddress"}})],1),(_vm.additionalInformations.hasPreviousAddress)?_c('div',[_c('v-row',{staticClass:"gap-10",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-combobox',{staticClass:"position-left",attrs:{"id":"street","value":_vm.additionalInformations.addressPrevious.street,"items":_vm.searchItemsStreetList,"label":"Rue","outlined":"","type":"text","rules":_vm.additionalInformations.rules.addressPreviousStreet},on:{"keyup":function (event) {
                    _vm.updateAddress(event, 'street', 'updatePreviousAddress');
                    _vm.searchAddress(event.target.value, 'street');
                  },"input":function (event) { return _vm.updateAddress(event, 'street', 'updatePreviousAddress'); }}})],1),_c('v-col',{attrs:{"cols":"8","sm":"7","md":"4"}},[_c('v-combobox',{staticClass:"position-left",attrs:{"id":"city","value":_vm.additionalInformations.addressPrevious.city,"items":_vm.searchItemsCityList,"label":"Ville","outlined":"","type":"text","rules":_vm.additionalInformations.rules.addressPreviousCity},on:{"keyup":function (event) {
                    _vm.updateAddress(event, 'city', 'updatePreviousAddress');
                    _vm.searchAddress(event.target.value, 'city');
                  },"input":function (event) { return _vm.updateAddress(event, 'city', 'updatePreviousAddress'); }}})],1),_c('v-col',{attrs:{"cols":"4","sm":"5","md":"2"}},[_c('v-combobox',{staticClass:"position-right",attrs:{"id":"zip","value":_vm.additionalInformations.addressPrevious.zip,"items":_vm.searchItemsZipList,"label":"Code Postal","outlined":"","type":"text","rules":_vm.additionalInformations.rules.addressPreviousStreet},on:{"keyup":function (event) {
                    _vm.updateAddress(event, 'zip', 'updatePreviousAddress');
                    _vm.searchAddress(event.target.value, 'zip');
                  },"input":function (event) { return _vm.updateAddress(event, 'zip', 'updatePreviousAddress'); }}})],1)],1)],1):_vm._e(),(
            _vm.currentAccountingPeriod &&
            (_vm.currentAccountingPeriod.taxRegime === _vm.TaxRegime.IS_2065 ||
              _vm.currentAccountingPeriod.taxRegime === _vm.TaxRegime.IR_2072)
          )?[_c('div',{staticClass:"mb-2"},[_c('b',[_vm._v(" Veuillez renseigner "+_vm._s(!_vm.additionalInformations.hasBirthAddress ? "le lieu de naissance et" : "")+" l'adresse postale de vos associés ")])]),_vm._l((_vm.additionalInformations.partners),function(partner,index){return _c('div',{key:partner.id},[_c('div',{staticClass:"section-header-subTitle mb-1"},[_vm._v(" "+_vm._s(_vm.getPartnerName(partner))+" ")]),(
                _vm.initialPartners[index].birthAddress.place === '' ||
                _vm.initialPartners[index].birthAddress.department === 0 ||
                _vm.initialPartners[index].birthAddress.country === ''
              )?_c('p',[_vm._v(" Lieu de naissance ")]):_vm._e(),(
                _vm.initialPartners[index].birthAddress.place === '' ||
                _vm.initialPartners[index].birthAddress.department === 0 ||
                _vm.initialPartners[index].birthAddress.country === ''
              )?_c('div',{staticClass:"section-body"},[_c('v-row',{staticClass:"gap-10",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-combobox',{attrs:{"id":"street","value":_vm.additionalInformations.partners[index].birthAddress
                        .place,"items":_vm.searchItemsPlaceList,"label":"Commune de naissance","outlined":"","type":"text","rules":_vm.additionalInformations.rules.partnerBirthAddressPlace(
                        index
                      )},on:{"keyup":function (event) {
                        _vm.updateAddress(
                          event,
                          'place',
                          'updatePartnerBirthAddress',
                          index
                        );
                        _vm.searchAddress(event.target.value, 'place');
                      },"input":function (event) { return _vm.updateAddress(
                          event,
                          'place',
                          'updatePartnerBirthAddress',
                          index
                        ); }}})],1),_c('v-col',{attrs:{"cols":"8","md":"3","sm":"7"}},[_c('v-combobox',{attrs:{"outlined":"","id":"department","value":_vm.additionalInformations.partners[index].birthAddress
                        .department,"items":_vm.searchItemsDepartmentList,"label":"Département de naissance","hint":"Utiliser '99' pour un pays étranger","persistent-hint":"","type":"number","rules":_vm.additionalInformations.rules.partnerBirthAddressDepartment(
                        index
                      )},on:{"keyup":function (event) {
                        _vm.updateAddress(
                          event,
                          'department',
                          'updatePartnerBirthAddress',
                          index
                        );
                        _vm.searchAddress(event.target.value, 'department');
                      },"input":function (event) { return _vm.updateAddress(
                          event,
                          'department',
                          'updatePartnerBirthAddress',
                          index
                        ); }}})],1),_c('v-col',{attrs:{"cols":"4","md":"3","sm":"5"}},[_c('v-select',{attrs:{"items":_vm.additionalInformations.countryISOcodes,"item-text":"text","item-value":"value","outlined":""},model:{value:(
                      _vm.additionalInformations.partners[index].birthAddress
                        .country
                    ),callback:function ($$v) {_vm.$set(_vm.additionalInformations.partners[index].birthAddress
                        , "country", $$v)},expression:"\n                      additionalInformations.partners[index].birthAddress\n                        .country\n                    "}})],1)],1)],1):_vm._e(),_c('p',[_vm._v("Adresse")]),_c('div',[_c('v-row',{staticClass:"gap-10",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-combobox',{attrs:{"id":"street","value":_vm.additionalInformations.partnerInformations[index]
                        .address.street,"items":_vm.searchItemsStreetList,"label":"Rue","outlined":"","type":"text","rules":_vm.additionalInformations.rules.partnerInformationsStreet(
                        index
                      )},on:{"keyup":function (event) {
                        _vm.updateAddress(
                          event,
                          'street',
                          'updatePartnerInformations',
                          index
                        );
                        _vm.searchAddress(event.target.value, 'street');
                      },"input":function (event) { return _vm.updateAddress(
                          event,
                          'street',
                          'updatePartnerInformations',
                          index
                        ); }}})],1),_c('v-col',{attrs:{"cols":"8","md":"3","sm":"3"}},[_c('v-combobox',{attrs:{"id":"city","value":_vm.additionalInformations.partnerInformations[index]
                        .address.city,"items":_vm.searchItemsCityList,"label":"Ville","outlined":"","type":"text","rules":_vm.additionalInformations.rules.partnerInformationsCity(
                        index
                      )},on:{"keyup":function (event) {
                        _vm.updateAddress(
                          event,
                          'city',
                          'updatePartnerInformations',
                          index
                        );
                        _vm.searchAddress(event.target.value, 'city');
                      },"input":function (event) { return _vm.updateAddress(
                          event,
                          'city',
                          'updatePartnerInformations',
                          index
                        ); }}})],1),_c('v-col',{attrs:{"cols":"4","sm":"5","md":"3"}},[_c('v-combobox',{attrs:{"id":"zip","value":_vm.additionalInformations.partnerInformations[index]
                        .address.zip,"items":_vm.searchItemsZipList,"label":"Code Postal","outlined":"","type":"text","rules":_vm.additionalInformations.rules.partnerInformationsZip(
                        index
                      )},on:{"keyup":function (event) {
                        _vm.updateAddress(
                          event,
                          'zip',
                          'updatePartnerInformations',
                          index
                        );
                        _vm.searchAddress(event.target.value, 'zip');
                      },"input":function (event) { return _vm.updateAddress(
                          event,
                          'zip',
                          'updatePartnerInformations',
                          index
                        ); }}})],1)],1)],1),(
                _vm.currentAccountingPeriod &&
                _vm.currentAccountingPeriod.taxRegime === _vm.TaxRegime.IS_2065 &&
                (_vm.initialPartners[index].pctDetention === undefined ||
                  _vm.initialPartners[index].partsSocial === undefined)
              )?_c('div',[_c('v-row',{staticClass:"gap-10",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-text-field',{attrs:{"id":"partner-add-detention","rules":[
                      function () { return _vm.additionalInformations.partnerInformations[index]
                          .pctDetention > 0 ||
                        'Le champ ne peut pas être vide'; },
                      function () { return _vm.additionalInformations.partnerInformations[index]
                          .pctDetention <= 100 ||
                        'Un pourcentage ne peut pas être supérieur à 100'; },
                      function () { return typeof _vm.additionalInformations.partnerInformations[
                          index
                        ].pctDetention === 'number' ||
                        'Le champ doit etre un nombre'; } ],"label":"Détention","min":"0","outlined":"","required":"","prefix":"%","type":"number"},model:{value:(
                      _vm.additionalInformations.partnerInformations[index]
                        .pctDetention
                    ),callback:function ($$v) {_vm.$set(_vm.additionalInformations.partnerInformations[index]
                        , "pctDetention", _vm._n($$v))},expression:"\n                      additionalInformations.partnerInformations[index]\n                        .pctDetention\n                    "}})],1),_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-text-field',{attrs:{"id":"partner-add-detention","rules":[
                      function () { return _vm.additionalInformations.partnerInformations[index]
                          .partsSocial > 0 ||
                        'Le champ ne peut pas être vide'; },
                      function () { return typeof _vm.additionalInformations.partnerInformations[
                          index
                        ].pctDetention === 'number' ||
                        'Le champ doit etre un nombre'; } ],"label":"Parts sociales","min":"0","outlined":"","required":"","type":"number"},model:{value:(
                      _vm.additionalInformations.partnerInformations[index]
                        .partsSocial
                    ),callback:function ($$v) {_vm.$set(_vm.additionalInformations.partnerInformations[index]
                        , "partsSocial", _vm._n($$v))},expression:"\n                      additionalInformations.partnerInformations[index]\n                        .partsSocial\n                    "}})],1)],1)],1):_vm._e()])})]:_vm._e()],2),_c('v-card-actions',[_c('v-row',{staticClass:"submit-btn"},[_c('v-btn',{attrs:{"depressed":"","color":"primary","type":"submit","loading":_vm.loadingAdditionalInformations}},[_vm._v(" Valider ")])],1)],1)],1)]:_vm._e(),(_vm.steps === 2)?[_c('v-card-text',{style:({ height: '90%' })},[_c('v-skeleton-loader',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"type":"image"}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],style:({ height: '100%' })},[_c('div',{staticClass:"section-body"},[_c('v-alert',{attrs:{"type":"info","outlined":"","text":""}},[_vm._v(" Nous n'arrivons pas à afficher le PDF, il sera téléchargé automatiquement sur Ownily et votre ordinateur. Veuillez valider en bas à droite pour continuer votre clôture. ")])],1),(_vm.pdf && _vm.pdf.url)?_c('div',{staticStyle:{"height":"100%","width":"100%"}},[_c('PDFViewer',{attrs:{"source":_vm.pdf.url,"controls":['download', 'zoom', 'catalog']},on:{"download":function($event){return _vm.handleDownload()},"loading-failed":_vm.logErr,"rendering-failed":_vm.logErr}})],1):_vm._e()])],1),_c('v-card-actions',[_c('v-row',{staticClass:"submit-btn"},[(
            _vm.taskLocal &&
            (!_vm.taxDeclaration ||
              (_vm.taxDeclaration.status !== _vm.TaxDeclarationStatus.Processing &&
                _vm.taxDeclaration.status !== _vm.TaxDeclarationStatus.Success))
          )?_c('CustomLabelButton',{staticClass:"mt-2",attrs:{"loading":_vm.loadingValidate},on:{"click":_vm.validate,"loading-failed":_vm.logErr,"rendering-failed":_vm.logErr}},[_vm._v(" Valider ma déclaration ")]):_vm._e()],1)],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }