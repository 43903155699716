import { render, staticRenderFns } from "./Events.vue?vue&type=template&id=27f74766&scoped=true&"
import script from "./Events.vue?vue&type=script&lang=ts&"
export * from "./Events.vue?vue&type=script&lang=ts&"
import style0 from "./Events.vue?vue&type=style&index=0&id=27f74766&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27f74766",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VTimeline } from 'vuetify/lib/components/VTimeline';
import { VTimelineItem } from 'vuetify/lib/components/VTimeline';
installComponents(component, {VBadge,VDivider,VFlex,VPagination,VTimeline,VTimelineItem})
