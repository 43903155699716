




























































import { defineComponent, PropType } from "@vue/composition-api";
import { extension } from "mime-types";
import { Document } from "@edmp/api";
import {
  activitiesStore,
  documentsStore,
  realEstateAssetsStore,
  regularizationsStore,
  rentIndexationsStore,
} from "@/store";
import { VConfirmDialog } from "@/models";
import Confirm from "@/components/core/modals/Confirm.vue";

export default defineComponent({
  name: "DocumentCard",
  components: { Confirm },
  props: {
    document: {
      type: Object as PropType<Document>,
      required: true,
    },
    isEnableDelete: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, context) {
    function showDocument() {
      const doc = props.document;
      window.open(doc.href, "_blank");
    }

    const deleteDocument = async () => {
      if (props.document) {
        const openConfirm: boolean = await (
          context.refs.confirmDialog as VConfirmDialog
        ).open(
          `Êtes-vous sûr de vouloir supprimer le document ${props.document.name} ?`
        );
        if (openConfirm) {
          try {
            const realEstateAssetWithImage =
              realEstateAssetsStore.realEstateAssets.find(
                (realEstateAsset) =>
                  realEstateAsset.documentId === props.document.id
              );
            const realEstateWithAsbestos =
              realEstateAssetsStore.realEstateAssets.find(
                (realEstateAsset) =>
                  realEstateAsset.asbestos?.documentAsbestosId ===
                  props.document.id
              );
            const realEstateWithDpe =
              realEstateAssetsStore.realEstateAssets.find(
                (realEstateAsset) =>
                  realEstateAsset.dpe.documentDpeId === props.document.id
              );
            const rentIndexation = rentIndexationsStore.rentIndexations.find(
              (rentIndexation) =>
                rentIndexation.noticeRentIndexationId === props.document.id
            );
            const regularization = regularizationsStore.regularizations.find(
              (rentIndexation) => rentIndexation.noticeId === props.document.id
            );

            if (
              activitiesStore.currentActivity &&
              activitiesStore.currentActivity.signature?.signatureId ===
                props.document.id
            ) {
              await activitiesStore.deleteImage({
                id: activitiesStore.currentActivity.id,
                signature: activitiesStore.currentActivity.signature,
              });
            } else if (
              activitiesStore.currentActivity &&
              activitiesStore.currentActivity.logoId === props.document.id
            ) {
              await activitiesStore.deleteImage({
                id: activitiesStore.currentActivity.id,
                logoId: activitiesStore.currentActivity.logoId,
              });
            } else if (realEstateAssetWithImage) {
              await realEstateAssetsStore.deleteDocument({
                id: realEstateAssetWithImage.id,
                documentId: props.document.id,
                dpe: realEstateAssetWithImage.dpe,
              });
            } else if (realEstateWithAsbestos) {
              await realEstateAssetsStore.deleteDocument({
                id: realEstateWithAsbestos.id,
                asbestos: realEstateWithAsbestos.asbestos,
                dpe: realEstateWithAsbestos.dpe,
              });
            } else if (realEstateWithDpe) {
              await realEstateAssetsStore.deleteDocument({
                id: realEstateWithDpe.id,
                dpe: realEstateWithDpe.dpe,
              });
            } else if (
              rentIndexation &&
              rentIndexation.id &&
              rentIndexation.noticeRentIndexationId
            ) {
              await rentIndexationsStore.deleteNoticeRentIndexation({
                id: rentIndexation.id,
                noticeRentIndexationId: rentIndexation.noticeRentIndexationId,
              });
            } else if (regularization && regularization.noticeId) {
              await regularizationsStore.deleteNoticeRegularization({
                id: regularization.id,
                noticeRegularization: regularization.noticeId,
              });
            } else {
              await documentsStore.deleteDocument(props.document.id);
            }
          } catch (error) {
            console.error(error);
          }
        }
      }
    };

    return { showDocument, deleteDocument, extension };
  },
});
