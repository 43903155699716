















































import {
  defineComponent,
  ref,
  computed,
  onBeforeMount,
  Ref,
} from "@vue/composition-api";
import GenericTable, {
  TableHeaderItem,
} from "@/components/atom/table/GenericTable.vue";
import { RentIndexation } from "@edmp/api";
import {
  documentsStore,
  rentalAgreementsStore,
  rentIndexationsStore,
} from "@/store";

export default defineComponent({
  name: "IndexationHistory",
  components: { GenericTable },
  props: {
    rentalAgreementId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const rentIndexationsArray: Ref<RentIndexation[]> = ref([]);

    const rentalAgreeemnt = rentalAgreementsStore.getRentalAgreement(
      props.rentalAgreementId
    );

    const historyTableHeaders: TableHeaderItem[] = [
      { text: "Date", value: "revisionDate" },
      { text: "Ancien loyer", value: "currentRent" },
      { text: "Nouveau loyer", value: "newRent" },
    ];

    switch (rentalAgreeemnt?.financialConditions.indexation?.type) {
      case "ilc":
        historyTableHeaders.push({ text: "ILC appliqué", value: "newIrl" });
        break;
      case "ilat":
        historyTableHeaders.push({ text: "ILAT appliqué", value: "newIrl" });
        break;
      case "icc":
        historyTableHeaders.push({ text: "ICC appliqué", value: "newIrl" });
        break;

      default:
        historyTableHeaders.push({ text: "IRL appliqué", value: "newIrl" });
        break;
    }

    const deleteRentIndexation = (id: string) => {
      rentIndexationsStore.deleteIndexation(id).then(() => {
        init();
      });
    };

    const noticeRentIndexationFile = computed(() => {
      const rentIndexationWithNotice = rentIndexationsArray.value
        .filter(
          (rentIndexation) =>
            rentIndexation.noticeRentIndexationId &&
            typeof rentIndexation.noticeRentIndexationId === "string"
        )
        .map((r) => r.noticeRentIndexationId as string);

      return rentIndexationWithNotice.map((noticeRentIndexationId) =>
        documentsStore.getDocument(noticeRentIndexationId)
      );
    });
    function getNoticeRentIndexationFile(id: string) {
      return documentsStore.getDocument(id);
    }
    // Init
    async function init() {
      const rentIndexations = await rentIndexationsStore.fetchRentIndexations(
        props.rentalAgreementId
      );
      rentIndexationsArray.value = rentIndexations.reverse();
    }

    onBeforeMount(() => init());

    return {
      historyTableHeaders,
      deleteRentIndexation,
      getNoticeRentIndexationFile,
      rentIndexationsArray,
      noticeRentIndexationFile,
    };
  },
});
