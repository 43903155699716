import { Document, model, Schema } from "mongoose";
import { ulid } from "ulid";
import { Address, addressSchema } from "./Common.model";
import { TaxDeclarationHeaderData } from "./TaxDeclaration.model";

export interface TaxDeclaration2044RentalBuilding {
  rentalBuildingId: string;
  name: string;
  address?: Address;
  rentalUnitTotal: string;
  taxDeduction: string;
  totalDeclaration: string;
  line111: string;
  line112: string;
  line113: string;
  line114: string;
}

export interface TaxDeclaration2044 {
  id?: string;
  productId: string;
  accountingPeriodId: string;
  userId: string;
  partnerId: string;
  companyName: string;
  companyAddress: Address;
  partnerAddress?: Address;
  partnerLastName: string;
  partnerFirstName: string;
  yearPeriod: string;
  userLastName: string;
  userFirstName: string;
  partsPP: number;
  partsNP: number;
  partsUF: number;
  partsF: string;
  caseA: string;
  caseB: string;
  caseC: string;
  caseD: string;
  rentalBuildings: TaxDeclaration2044RentalBuilding[];
  dataArchived?:TaxDeclarationHeaderData
}

const rentalBuildingSchema = new Schema<TaxDeclaration2044RentalBuilding>(
  {
    rentalBuildingId: { type: String, index: true },
    name: String,
    address: addressSchema,
    rentalUnitTotal: String,
    taxDeduction: String,
    totalDeclaration: String,
    line111: String,
    line112: String,
    line113: String,
    line114: String,
  },
  { _id: false }
);

const TaxDeclaration2044Schema = new Schema<TaxDeclaration2044Document>(
  {
    _id: { type: String, default: (): string => ulid() },
    productId: { type: String, index: true },
    accountingPeriodId: { type: String, index: true },
    userId: { type: String, index: true },
    partnerId: { type: String, index: true },
    companyName: String,
    companyAddress: addressSchema,
    partnerAddress: addressSchema,
    partnerLastName: String,
    partnerFirstName: String,
    yearPeriod: String,
    userLastName: String,
    userFirstName: String,
    partsPP: Number,
    partsNP: Number,
    partsUF: Number,
    partsF: String,
    caseA: String,
    caseB: String,
    caseC: String,
    caseD: String,
    rentalBuildings: [rentalBuildingSchema],
    dataArchived: {
      user: {
        id: { type: String },
        firstName: { type: String },
        lastName: { type: String },
        email: { type: String },
      },
      product: {
        id: { type: String },
        email: { type: String },
      },
      activity: {
        id: { type: String },
        type: { type: String },
        address: addressSchema,
        addressPrevious: addressSchema,
        siret: { type: String },
        name: { type: String },
        operatorType: { type: String },
        firstName: { type: String },
        lastName: { type: String },
        denomination: { type: String },
        legalStatus: { type: String },
      },
      accountingPeriod: {
        id: { type: String },
        startAt: { type: String },
        endAt: { type: String },
        taxRegime: { type: String },
        firstYear: { type: Boolean },
      },
      accountingPeriodPrevious: {
        startAt: { type: String },
        endAt: { type: String },
      },
      partnerOwned: {
        id: { type: String },
        type: { type: String },
        firstName: { type: String },
        lastName: { type: String },
        denomination: { type: String },
        role: { type: String },
        createdAt: { type: String },
      }
    }
  },
  {
    timestamps: true,
    toJSON: {
      versionKey: false,
      virtuals: true,
      transform(_, ret: TaxDeclaration2044Document): unknown {
        ret.id = ret._id;
        delete ret._id;
        return ret;
      },
    },
  }
);

export type TaxDeclaration2044Document = TaxDeclaration2044 & Document<string>;

// Name of the collection in third argument
export const TaxDeclaration2044Model = model<TaxDeclaration2044Document>(
  "TaxDeclaration2044",
  TaxDeclaration2044Schema,
  "TaxDeclarations2044"
);
