





































































































































































import {
  computed,
  defineComponent,
  PropType,
  ref,
  Ref,
} from "@vue/composition-api";

import { TransactionState, useTransaction } from "./transaction.usable";
import { useCategorization } from "../categorization/categorization.usable";
import { cloneDeep } from "lodash";
import {
  LedgerAccountEnum,
  TransactionImportType,
  TransactionImportTypeUser,
  TransactionType,
} from "@edmp/api";
import RentReceipt from "../../rent/RentReceipt.vue";
import {
  accountingPeriodsStore,
  coreStore,
  documentsStore,
  productsStore,
  tenantsStore,
} from "@/store";
import Decimal from "decimal.js-light";
import { ForbiddenError, subject } from "@casl/ability";
import { ability, rentalsService } from "@/services";
import { FeedbackTypeEnum } from "@/store/modules/Core.store";
import Confirm from "@/components/core/modals/Confirm.vue";
import { VConfirmDialog } from "@/models";
import DialogRight from "@/components/core/DialogRight.vue";
import TenantUpdate from "@/components/core/rent/TenantUpdate.vue";

export default defineComponent({
  name: "TransactionActions",
  components: {
    RentReceipt,
    Confirm,
    DialogRight,
    TenantUpdate,
  },
  props: {
    transactionState: {
      type: Object as PropType<TransactionState>,
      required: true,
    },
    readonly: {
      default: false,
      type: Boolean,
    },
  },
  setup(props, context) {
    const categorizationUse = computed(() =>
      useCategorization(props.transactionState, context)
    );
    const transactionIsRent = computed(() => {
      // To secure, get the first line with Rent Receipt

      const test = props.transactionState?.lines?.find(
        ({ account, amount }) =>
          account.startsWith(LedgerAccountEnum.N706000) &&
          new Decimal(amount).isPositive()
      );
      if (test) {
        return true;
      } else return false;
    });

    const tenant = computed(() => {
      const id = props.transactionState?.lines?.find(
        (line) => line.tenant
      )?.tenant;
      if (id) {
        const tenant = tenantsStore.getTenant(id);
        return tenant;
      }
    });
    const realEstateAssetId = computed(() => {
      return props.transactionState?.lines?.find((line) => line.realEstateAsset)
        ?.realEstateAsset;
    });
    const showErrorModal: Ref<boolean> = ref(false);

    const computedSuggestionCategories = computed(() =>
      cloneDeep(props.transactionState.suggestedLines)
    );
    const isOpenRentReceiptStep: Ref<number> = ref(0);
    const tenantUpdate: Ref<boolean> = ref(false);

    const attachments = computed(() =>
      documentsStore.getDocumentByTransaction(
        props.transactionState.transaction.id
      )
    );

    const transactionDontHaveReceipt = computed(() => {
      if (attachments.value) {
        let exist = attachments.value;

        exist = attachments.value.filter((doc) => {
          if (doc.tags) {
            if (doc.tags.includes("receipt")) {
              return doc;
            }
          }
        });
        if (exist.length > 0) {
          return false;
        }
      }

      return true;
    });
    const product = computed(() => {
      return productsStore.getProduct({ id: productsStore.currentId });
    });
    const { refreshTransaction } = useTransaction(
      props.transactionState,
      context
    );
    const checkPermissions = async () => {
      try {
        ForbiddenError.from(ability).throwUnlessCan(
          "send",
          subject("RentReceipt", {})
        );
        if (!tenant.value?.email) {
          tenantUpdate.value = true;
        } else {
          try {
            await rentalsService.rents.sendRentReceiptFromTransaction({
              tenantId: tenant.value.id,
              transactionId: props.transactionState.transaction.id,
              realEstateAssetId: realEstateAssetId.value ?? "",
            });
            coreStore.displayFeedback({
              message: "Votre quittance a bien été envoyée",
            });
            documentsStore.fetchDocuments();
            refreshTransaction();
          } catch (error) {
            console.error(error);
            coreStore.displayFeedback({
              message: "Une erreur s'est produite",
            });
          }
        }
      } catch (error) {
        if (error instanceof ForbiddenError) {
          coreStore.displayFeedback({
            type: FeedbackTypeEnum.WARNING,
            message: error.message,
          });
        }
      }
    };
    const isExpenseReport = (type: TransactionType) =>
      type === TransactionImportTypeUser.EXPENSE_REPORT;
    const isAmortization = (type: TransactionType) =>
      type === TransactionImportType.AMORTIZATION;
    const isAcquisition = (type: TransactionType) =>
      type === TransactionImportType.ACQUISITION;

    const validateSuggestion = async () => {
      if (categorizationUse.value.checkOpenReconciliation()) {
        const openConfirm: boolean = await (
          context.refs.confirmDialog as VConfirmDialog
        ).open("Souhaitez-vous réconcilier avec un engagement ?");
        if (openConfirm) {
          categorizationUse.value.isOpenReconciliation.value = true;
          return;
        }
      }
      categorizationUse.value.validateSuggestion();
    };

    return {
      transactionDontHaveReceipt,
      transactionIsRent,
      isOpenRentReceiptStep,
      categorizationUse,
      computedSuggestionCategories,
      showErrorModal,
      isExpenseReport,
      isAmortization,
      isAcquisition,
      validateSuggestion,
      isIS: accountingPeriodsStore.isIS,
      isLMNP: accountingPeriodsStore.isLMNP,
      tenant,
      checkPermissions,
      tenantUpdate,
      product,
      realEstateAssetId,
    };
  },
});
