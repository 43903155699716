var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"categorizationStep2"},[_c('h4',{staticClass:"categorization-sumup-title mb-3"},[_vm._v("Récapitulatif")]),_c('div',[_vm._l((_vm.transactionState.lines),function(line,index){return _c('div',{key:(index + "." + (line.accountName)),staticClass:"categorization-line"},[_c('div',{staticClass:"categorization-sumup py-3 pl-1"},[_c('span',[_vm._v(" "+_vm._s(line.accountName)+" "),_c('span',{staticClass:"categorization-attribut d-flex flex-row flex-wrap mb-0"},[(line.realEstateAsset)?_c('v-chip',{staticClass:"chip",attrs:{"small":"","outlined":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"size":"20"}},'v-icon',attrs,false),on),[_vm._v("mdi-home-outline")]),(line.realEstateAsset)?_c('span',_vm._g(_vm._b({staticClass:"textOverflow"},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.categorizationUse.getAttribute( "realEstateAsset", line.realEstateAsset ))+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.categorizationUse.getAttribute( "realEstateAsset", line.realEstateAsset )))])])],1):_vm._e(),(line.fixedAsset)?_c('v-chip',{staticClass:"chip",attrs:{"small":"","outlined":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"size":"20"}},'v-icon',attrs,false),on),[_vm._v("mdi-home-clock-outline")]),(line.fixedAsset)?_c('span',_vm._g(_vm._b({staticClass:"textOverflow"},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.categorizationUse.getAttribute( "fixedAsset", line.fixedAsset ))+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.categorizationUse.getAttribute( "fixedAsset", line.fixedAsset )))])])],1):_vm._e(),(line.rentalAgreement)?_c('v-chip',{staticClass:"chip",attrs:{"small":"","outlined":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"size":"20"}},'v-icon',attrs,false),on),[_vm._v("mdi-account-key-outline")]),(line.rentalAgreement)?_c('span',_vm._g(_vm._b({staticClass:"textOverflow"},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.categorizationUse.getAttribute( "rentalAgreement", line.rentalAgreement ))+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.categorizationUse.getAttribute( "rentalAgreement", line.rentalAgreement ))+" ")])])],1):_vm._e(),(line.tenant)?_c('v-chip',{staticClass:"chip",attrs:{"small":"","outlined":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"size":"20"}},'v-icon',attrs,false),on),[_vm._v("mdi-account-key-outline")]),(line.tenant)?_c('span',_vm._g(_vm._b({staticClass:"textOverflow"},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.categorizationUse.getAttribute("tenant", line.tenant))+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.categorizationUse.getAttribute("tenant", line.tenant))+" ")])])],1):_vm._e(),(line.partner)?_c('v-chip',{staticClass:"chip",attrs:{"small":"","outlined":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"size":"20"}},'v-icon',attrs,false),on),[_vm._v("mdi-account-outline")]),(line.partner)?_c('span',_vm._g(_vm._b({staticClass:"textOverflow"},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.categorizationUse.getAttribute("partner", line.partner))+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.categorizationUse.getAttribute("partner", line.partner))+" ")])])],1):_vm._e(),(line.realEstateLoan)?_c('v-chip',{staticClass:"chip",attrs:{"small":"","outlined":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"size":"20"}},'v-icon',attrs,false),on),[_vm._v("mdi-home-percent-outline")]),(line.realEstateLoan)?_c('span',_vm._g(_vm._b({staticClass:"textOverflow"},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.categorizationUse.getAttribute( "realEstateLoan", line.realEstateLoan ))+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.categorizationUse.getAttribute( "realEstateLoan", line.realEstateLoan )))])])],1):_vm._e(),(line.supportingDocument)?_c('v-chip',{staticClass:"chip",attrs:{"small":"","outlined":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"size":"20"}},'v-icon',attrs,false),on),[_vm._v("mdi-folder-outline")]),(line.supportingDocument)?_c('span',_vm._g(_vm._b({staticClass:"textOverflow"},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.categorizationUse.getAttribute( "supportingDocument", line.supportingDocument ))+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.categorizationUse.getAttribute( "supportingDocument", line.supportingDocument ))+" ")])])],1):_vm._e(),(line.beneficiary)?_c('v-chip',{staticClass:"chip",attrs:{"small":"","outlined":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"size":"20"}},'v-icon',attrs,false),on),[_vm._v("mdi-hand-extended")]),(line.beneficiary)?_c('span',_vm._g(_vm._b({staticClass:"textOverflow"},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.categorizationUse.getAttribute( "beneficiary", line.beneficiary ))+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.categorizationUse.getAttribute( "beneficiary", line.beneficiary )))])])],1):_vm._e()],1)]),_c('span',[_c('v-text-field',{staticClass:"categorize-amount-input",attrs:{"value":line.amount.toFixed(2),"rules":[
              function () { return !!line.amount ||
                line.amount === 0 ||
                'Le champ ne peut pas être vide'; },
              function () { return _vm.countDecimals(line.amount) <= 2 ||
                'Le montant doit contenir au maximum deux décimales'; } ],"dense":"","hide-details":"auto","outlined":"","placeholder":"Montant","required":"","step":"0.01","suffix":"€","type":"number"},on:{"change":function (amount) { return _vm.categorizationUse.updateCategory(index, Object.assign({}, line,
                  {amount: Number(amount)})); }}})],1)]),_c('v-btn',{staticClass:"ml-6",attrs:{"depressed":"","fab":"","height":"16","width":"16","x-small":"","title":"Supprimer cette opération"},on:{"click":function($event){return _vm.categorizationUse.deleteCategory(index)}}},[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)],1)}),(_vm.categorizationUse.getMissingAmount() !== 0)?_c('div',{staticClass:"mt-5"},[_c('v-alert',{attrs:{"outlined":"","text":"","type":"info"}},[_vm._v(" Il reste "+_vm._s(_vm.$n(_vm.categorizationUse.getMissingAmount(), "currency", "fr-FR"))+" à affecter ")])],1):_vm._e(),(_vm.categorizationUse.isTvaCollectedIncorrect())?_c('div',{staticClass:"mt-5"},[_c('v-alert',{attrs:{"outlined":"","text":"","type":"info"}},[_vm._v(" Le montant de la TVA n'est pas égal à "+_vm._s(_vm.categorizationUse.getApplicableTaxRateTVA())+"% du loyer encaissé. ")])],1):_vm._e(),(_vm.categorizationUse.isTvaCollectedIncorrect() && _vm.hasCharges)?_c('div',{staticClass:"mt-5"},[_c('v-alert',{attrs:{"outlined":"","text":"","type":"info"}},[_vm._v(" Le montant de la TVA n'est pas égal à "+_vm._s(_vm.categorizationUse.getApplicableTaxRateTVA())+"% du loyer encaissé et des charges. ")])],1):_vm._e()],2),_c('Anomalies',{attrs:{"type":"transaction","checkNewAnomaliesType":_vm.CheckNewAnomaliesType.transaction,"transactions":[_vm.categorizationUse.getTransactionWithNewCategories()]}}),_c('div',{staticClass:"categorizationStep2-btns mt-5"},[_c('v-btn',{attrs:{"color":"primary","depressed":""},on:{"click":function($event){return _vm.categorizationUse.saveCategorization()}}},[_vm._v(" Valider ")]),_c('v-btn',{staticClass:"subdivide-btn ml-5",attrs:{"id":"track-subdivide_step2","color":"secondary","depressed":"","text-color":"primary"},on:{"click":function($event){return _vm.categorizationUse.subDivide()}}},[_vm._v(" Subdiviser ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }