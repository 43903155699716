





















































































































































































































































































import {
  computed,
  defineComponent,
  PropType,
  ref,
  Ref,
  watch,
} from "@vue/composition-api";
import { VForm } from "@/models";
import { AccountingPeriodCreateInternal, TaxRegime, UserTags } from "@edmp/api";
import { accountingPeriodsStore, productsStore, usersStore } from "@/store";
import DatePicker from "@/components/atom/DatePicker.vue";
import { dispatchOnBoardingEvent, OnBoardingEventSteps } from "@/events";
import { onlyNumber } from "@/utils";
import { ProductsModel } from "@edmp/api";
import { ProductsService } from "@edmp/api";
import { ActivitiesModel } from "@edmp/api";
import { User } from "@edmp/api";
import { usersService } from "@/services";

export default defineComponent({
  name: "RegisterActivityDetailsLMNP",
  components: {
    DatePicker,
  },
  props: {
    step: {
      type: String,
      required: true,
    },
    activityRegistered: {
      type: Boolean,
    },
    type: {
      type: String as PropType<
        ProductsModel.Tags.LRP | ProductsModel.Tags.IMMOXYGEN
      >,
      require: false,
    },
  },
  setup(props, context) {
    const validateInProgress: Ref<boolean> = ref(false);
    const previousYearDeclared: Ref<boolean | undefined> = ref(undefined);
    const declarationType: Ref<
      "MICRO" | "REAL_SIMPLIFIED" | "UNKNOWN" | undefined
    > = ref();
    const isOpenCalendly = ref(false);
    const exerciceStartChoices = ref([
      new Date().getFullYear() - 1,
      new Date().getFullYear(),
    ]);
    const exerciceStartSelect: Ref<number | undefined> = ref();
    const startExerciceDateValue = ref(
      `${exerciceStartSelect.value ?? new Date().getFullYear()}-01-01`
    );
    const endExerciceDateValue = ref(
      `${exerciceStartSelect.value ?? new Date().getFullYear()}-12-31`
    );
    const getCalendlyUrl = computed(() => {
      let result: String = "";
      switch (declarationType.value) {
        case "MICRO":
        case "UNKNOWN":
          result =
            "https://calendly.com/ownily-contact/demo-ownily-lmnp-passer-au-reel";
          break;
      }
      isOpenCalendly.value = false;
      return result;
    });

    const isCalendlyLmnpMeetingDialogOpen = ref(false);

    const getCalendlyLmnpUrl = () =>
      `https://calendly.com/ownily-contact/demo-ownily-lmnp-passer-au-reel/?name=${
        user.firstName
      }%20${user.lastName}&a1=${
        user.phone ? "+33" + user.phone.slice(1, user.phone.length).trim() : ""
      }&email=${user.email}&a2=${
        productForm.value.activity.siret ? "1" : "2"
      }&a3=${declarationType.value === "MICRO" ? "2" : "3"}`;

    const onChangeExercice = () => {
      if (previousYearDeclared.value === false) {
        exerciceStartSelect.value = new Date().getFullYear();
      }
      startExerciceDateValue.value = `${exerciceStartSelect.value}-01-01`;
      endExerciceDateValue.value = `${exerciceStartSelect.value}-12-31`;
    };
    watch([exerciceStartSelect, previousYearDeclared], () =>
      onChangeExercice()
    );

    const rules = ref({
      exerciceDateMin: (): string => {
        const [year] = startExerciceDateValue.value?.split("-") ?? [undefined];
        const [month, day] = `${new Date().getFullYear()}-01-01`
          .split("-")
          .slice(1);
        return `${exerciceStartSelect.value ?? year}-${month}-${day}`;
      },
      exerciceDateMax: (): string => {
        const [year] = endExerciceDateValue.value?.split("-") ?? [undefined];
        const [month, day] = `${new Date().getFullYear()}-12-31`
          .split("-")
          .slice(1);
        return `${exerciceStartSelect.value ?? year}-${month}-${day}`;
      },
    });

    const user: User = usersStore.loggedInUser;
    const productForm = ref<
      Omit<
        ProductsModel.ProductCreate<ProductsModel.ProductTypes.LMNP>,
        "type" | "accountingPeriods" | "dedicatedBankAccount" | "activity"
      > & {
        activity: Omit<
          ActivitiesModel.ActivityCreate<
            ActivitiesModel.ActivityTypes.OPERATOR,
            ActivitiesModel.ActivityOperatorTypes.NATURAL_PERSON
          >,
          "type" | "operatorType" | "hasCga"
        >;
      }
    >({
      name: "",
      activity: {
        lastName: user.lastName,
        firstName: user.firstName,
        siret: "",
        indieChecked: false,
        address: { street: "", city: "", zip: "" },
        activityAddress: { street: "", city: "", zip: "" },
      },
    });

    const createAccountingPeriod = async (
      data: AccountingPeriodCreateInternal
    ) => {
      await accountingPeriodsStore.createAccountingPeriod(data);
    };

    // Send
    async function validate(e: Event): Promise<void> {
      e.preventDefault();
      if ((context.refs.form as VForm).validate()) {
        validateInProgress.value = true;
        try {
          try {
            if (declarationType.value) {
              await usersService.updateUser({
                id: "me",
                tags: [
                  ...user.tags,
                  UserTags[`DECLARATION_TYPE_${declarationType.value}`],
                ],
              });
            }

            const product: ProductsService.CreateIn = {
              type: ProductsModel.ProductTypes.LMNP,
              name: productForm.value.name,
              activity: {
                type: ActivitiesModel.ActivityTypes.OPERATOR,
                operatorType:
                  ActivitiesModel.ActivityOperatorTypes.NATURAL_PERSON,
                firstName: productForm.value.activity.firstName,
                lastName: productForm.value.activity.lastName,
                siret: productForm.value.activity.siret,
                indieChecked: false,
                hasCga: false,
              },
              accountingPeriods: [],
              ...(props.type ? { tags: [props.type] } : {}),
            };

            await productsStore.createProduct(product).then(async () => {
              const data: AccountingPeriodCreateInternal = {
                productId: productsStore.currentId,
                taxRegime: TaxRegime.LMNP_2031,
                firstYear: previousYearDeclared.value === false,
                startAt: startExerciceDateValue.value,
                endAt: endExerciceDateValue.value,
              };
              if (exerciceStartSelect.value == new Date().getFullYear() - 1) {
                // Create year-1
                await createAccountingPeriod(data);

                // Create year
                data.startAt = `${new Date().getFullYear()}-01-01`;
                data.endAt = `${new Date().getFullYear()}-12-31`;
                data.firstYear = false;
                await createAccountingPeriod(data);
              } else {
                // Create year
                await createAccountingPeriod(data);
              }
            });
          } catch (err) {
            validateInProgress.value = false;
          }
          dispatchOnBoardingEvent({
            step: OnBoardingEventSteps.ACTIVITY_DETAILS,
            userId: user.id,
            userEmail: user.email,
            userPhone: user.phone || "",
            productId: productsStore.currentId,
            productType: ProductsModel.ProductTypes.LMNP,
            taxRegime: "LMNP",
            declarationType: declarationType.value,
          });
          validateInProgress.value = false;
          context.emit("validate");
        } catch (err) {
          validateInProgress.value = false;
        }
      }
    }

    return {
      productForm,
      previousYearDeclared,
      declarationType,
      exerciceStartChoices,
      exerciceStartSelect,
      isOpenCalendly,
      getCalendlyUrl,
      startExerciceDateValue,
      endExerciceDateValue,
      rules,
      validate,
      validateInProgress,
      onlyNumber,
      user,
      getCalendlyLmnpUrl,
      isCalendlyLmnpMeetingDialogOpen,
    };
  },
});
