var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"pt-2 mt-md-8 primary darken-2"},[_c('v-list',{class:{ 'primary darken-2': true },attrs:{"dense":""}},[(_vm.mobileDisplay)?_c('v-btn',{staticStyle:{"position":"absolute","top":"4px","right":"4px"},attrs:{"icon":""},nativeOn:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',{attrs:{"x-large":"","color":"white"}},[_vm._v("mdi-close")])],1):_vm._e(),_c('router-link',{attrs:{"to":"/","exact":"","id":"logo-app"}},[_c('img',{staticClass:"d-md-block pl-4",attrs:{"id":"img-logo","height":"45px","src":require("@/assets/logo-text-white.svg")}})]),_c('div',{staticClass:"switch-product mx-6 mb-4"},[_c('v-select',{attrs:{"dense":"","solo":"","items":_vm.products,"item-value":"id","item-text":"name","menu-props":{ offsetY: true },"return-object":"","hide-details":""},scopedSlots:_vm._u([(_vm.currentAccountingPeriodIsLMNP)?{key:"selection",fn:function(){return [_vm._v(" "+_vm._s(_vm.product.name)+" ")]},proxy:true}:null,(!_vm.currentAccountingPeriodIsLMNP)?{key:"append-outer",fn:function(){return [_c('v-btn',{staticClass:"ma-0",attrs:{"id":"productTour-settingsSCI","dense":"","color":"primary"},on:{"click":function($event){_vm.$router.push({ name: _vm.ROUTE_NAMES.Activities });
              _vm.$emit('close');}}},[_c('v-icon',[_vm._v("mdi-cog")])],1)]},proxy:true}:null,{key:"append-item",fn:function(){return [_c('v-divider',{staticClass:"mb-2"}),_c('v-btn',{staticStyle:{"width":"-webkit-fill-available"},attrs:{"text":""},on:{"click":function($event){return _vm.goCreate()}}},[_c('v-icon',{attrs:{"dense":"","left":""}},[_vm._v("mdi-plus")]),_vm._v(" Ajouter une Activité ")],1)]},proxy:true}],null,true),model:{value:(_vm.product),callback:function ($$v) {_vm.product=$$v},expression:"product"}})],1),_vm._l((_vm.filteredNavigationItems),function(navlink){return _c('div',{key:navlink.name},[(navlink.children && navlink.children.length > 0)?_c('NavigationSubItem',{class:{
          'primary darken-2': true,
          'menu-disabled': _vm.menuDisabled,
        },attrs:{"disabled":_vm.menuDisabled,"name":navlink.name,"icon":navlink.icon,"children":navlink.children},on:{"navigate":_vm.navigate}}):_c('NavigationItem',{class:{
          'primary darken-2': true,
          'menu-disabled':
            _vm.menuDisabled &&
            navlink.name !== _vm.NAVIGATION_TITLES.RealEstateAssets,
        },attrs:{"disabled":_vm.menuDisabled && navlink.name !== _vm.NAVIGATION_TITLES.RealEstateAssets,"routeName":navlink.routeName,"name":navlink.name,"icon":navlink.icon,"new":navlink.new,"isButton":navlink.isButton},on:{"navigate":_vm.navigate}})],1)})],2),_c('ActivityCreateInfosModal',{attrs:{"openModal":_vm.openActivityCreateInfosModal},on:{"update:openModal":function($event){_vm.openActivityCreateInfosModal=$event},"update:open-modal":function($event){_vm.openActivityCreateInfosModal=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }