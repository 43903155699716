var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"documents-view"},[_c('PageHeader',{attrs:{"displayHelperWithId":_vm.accountingPeriodsStore.isIS || _vm.accountingPeriodsStore.isLMNP
        ? _vm.ArticleEnum.DOCUMENT_SAVING_IS
        : _vm.ArticleEnum.DOCUMENT_SAVING,"displayAccountingPeriod":"","addElementText":"Ajouter un document"},on:{"onAccountingPeriodChanged":function($event){return _vm.getDocuments()},"add":function($event){_vm.openUploadFlowDialog = true}}},[_vm._v(" Mon espace documentaire ")]),_c('UploadFlowDialog',{attrs:{"isOpen":_vm.openUploadFlowDialog},on:{"close":function($event){_vm.openUploadFlowDialog = false}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('TitleNew',{attrs:{"hLevel":"h2"}},[_vm._v("Ajouter vos documents")])]},proxy:true}])}),_c('HelpingMessage',{staticClass:"mt-3 mb-5",attrs:{"title":"En savoir plus"}},[_c('p',{staticClass:"mb-4"},[_vm._v(" Ajoutez ici vos documents et retrouvez tous les documents archivés pour vous et vos associés par Ownily dans cet espace sécurisé. ")])]),(_vm.subscription.plan.type !== _vm.PlanType.LMNPEssential)?_c('v-row',{staticClass:"mt-2 mb-10"},[_c('v-col',{attrs:{"cols":_vm.$can('show', 'ModelsLibrary') ? '6' : '12',"md":_vm.$can('show', 'ModelsLibrary') ? '8' : '12'}},[_c('div',{staticClass:"documents-row fill-height"},[_c('NewDocumentsTable',{staticClass:"component",attrs:{"lastDocuments":_vm.documents.slice(0, 3)}})],1)]),(_vm.$can('show', 'ModelsLibrary'))?_c('v-col',{attrs:{"cols":"6","md":"4"}},[_c('div',{staticClass:"card-outline mx-5"},[_c('v-card',{staticClass:"models-library-card fill-height",on:{"click":_vm.checkPermissions}},[_c('img',{staticClass:"attachment-img",attrs:{"src":require("@/assets/attachment-solid.png")}}),_c('v-card-title',[_vm._v("Bibliothèque de modèles")]),_c('v-card-text',[_vm._v("Ownily vous propose des modèles préconçus pour simplifier votre gestion administrative")])],1)],1)]):_vm._e()],1):_c('div',{staticClass:"documents-row"},[_c('NewDocumentsTable',{staticClass:"mb-4",attrs:{"lastDocuments":_vm.documents.slice(0, 3)}})],1),_c('DocumentsPanel',{attrs:{"id":"sci","sectionName":_vm.accountingPeriodsStore.isLMNP
        ? 'Mon activité LMNP'
        : 'Ma SCI et ses associés',"documents":_vm.productAndPartners,"headers":_vm.headers,"opened":_vm.focus === 'sci' ||
      _vm.productAndPartners.some(function (document) { return document.new === true; })}}),_c('DocumentsPanel',{attrs:{"id":"real-estate-asset","sectionName":"Mes biens","documents":_vm.realEstateAsset,"headers":_vm.headers,"opened":_vm.focus === 'real-estate-asset' ||
      _vm.realEstateAsset.some(function (document) { return document.new === true; })}}),(_vm.$can('showTenantServices', 'RentalManagement'))?_c('DocumentsPanel',{attrs:{"id":"rent","sectionName":"Mes loyers","documents":_vm.rent,"headers":_vm.headersRent,"opened":_vm.focus === 'rent' || _vm.rent.some(function (document) { return document.new === true; })}}):_vm._e(),_c('DocumentsPanel',{attrs:{"id":"accounting","sectionName":"Ma comptabilité","documents":_vm.accounting,"headers":_vm.headers,"opened":_vm.focus === 'accounting' ||
      _vm.accounting.some(function (document) { return document.new === true; })}}),_c('DocumentsPanel',{attrs:{"id":"tax","sectionName":"Mes déclarations fiscales","documents":_vm.fiscality,"headers":_vm.headers,"opened":_vm.focus === 'tax' || _vm.fiscality.some(function (document) { return document.new === true; })}}),_c('DocumentsPanel',{attrs:{"id":"events","sectionName":"Mes événements","documents":_vm.events,"headers":_vm.headers,"opened":_vm.focus === 'events' || _vm.events.some(function (document) { return document.new === true; })}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }