






















































































































































































































































































































import DialogRight from "@/components/core/DialogRight.vue";
import ActivityUpdate from "@/components/core/rent/ActivityUpdate.vue";
import RealEstateAssetUpdate from "@/components/core/rent/RealEstateAssetUpdate.vue";
import TenantUpdate from "@/components/core/rent/TenantUpdate.vue";
import { rentalsService } from "@/services";
import {
  productsStore,
  documentsStore,
  realEstateAssetsStore,
  rentalAgreementsStore,
  tenantsStore,
  activitiesStore,
} from "@/store";
import {
  Address,
  LedgerAccountEnum,
  RequireField,
  TenantTypeEnum,
  TypeReference,
} from "@edmp/api";
import {
  computed,
  defineComponent,
  PropType,
  Ref,
  ref,
} from "@vue/composition-api";
import { Decimal } from "decimal.js-light";
import moment from "moment";
import {
  useTransaction,
  TransactionState,
} from "../transactions/transaction/transaction.usable";

export default defineComponent({
  name: "RentReceipt",
  components: {
    DialogRight,
    TenantUpdate,
    RealEstateAssetUpdate,
    ActivityUpdate,
  },
  props: {
    transactionState: {
      type: Object as PropType<TransactionState>,
      required: true,
    },
    isOpenRentReceiptStep: { type: Number, required: true },
    action: { type: String },
  },
  setup(props, context) {
    const { refreshTransaction } = useTransaction(
      props.transactionState,
      context
    );
    const updateOpenRentReceiptStep = (isOpen: number) =>
      context.emit("update:isOpenRentReceiptStep", isOpen);

    const lines = computed(
      () =>
        props.transactionState.transaction.operations?.journalEntry?.lines || []
    );
    const activity = computed(() => {
      return activitiesStore.currentActivity;
    });
    const imageSignature = computed(() => {
      if (activity.value?.signature?.typeSignature === "base64") {
        return activity.value?.signature?.data;
      } else if (
        activity.value?.signature?.typeSignature === "image" &&
        activity.value.signature.signatureId
      ) {
        return documentsStore.getDocument(activity.value.signature.signatureId)
          ?.href;
      }
    });
    const imageLogo = computed(() => {
      if (activity.value?.logoId) {
        return documentsStore.getDocument(activity.value.logoId)?.href;
      }
    });
    // Retrieve RentalAgreementId & chargeAmount & rentalAmount in journalEntryLine

    const tenantId = computed(() => {
      const line = lines.value.find(
        (line) => line.account.substring(0, 6) === LedgerAccountEnum.N706000
      );
      if (line && line.refs) {
        return line.refs.find((value) => value.type === TypeReference.tenant)
          ?.referredId;
      }
    });

    const tenant = computed(() => {
      if (tenantId.value) return tenantsStore.getTenant(tenantId.value);
    });

    // Model needed to retrieve data
    const rentalAgreement = computed(() => {
      if (tenant.value?.rentalAgreementId) {
        return rentalAgreementsStore.rentalAgreements.find(
          (rentalAgreement) =>
            rentalAgreement.id === tenant.value?.rentalAgreementId
        );
      }
    });
    const realEstateAsset = computed(() => {
      const line = lines.value.find(
        (line) => line.account.substring(0, 6) === LedgerAccountEnum.N706000
      );
      if (line && line.refs) {
        const realEstateAssetId = line.refs.find(
          (value) => value.type === TypeReference.realEstateAsset
        )?.referredId;

        if (realEstateAssetId) {
          return realEstateAssetsStore.realEstateAssets.find(
            (realEstateAsset) => realEstateAsset.id === realEstateAssetId
          );
        }
      }
    });

    // Data needed in Transaction to Edit rent receipt
    const rentAmount = computed(() => {
      const lineCharge = lines.value.find(
        (line) => line.account.substring(0, 6) === LedgerAccountEnum.N706000
      );
      if (lineCharge) {
        return Number(lineCharge.amount);
      }
      return Number(0);
    });
    const rentAmountCharge = computed(() => {
      const lineCharge = lines.value.find(
        (line) => line.account.substring(0, 6) === LedgerAccountEnum.N708399
      );
      if (lineCharge) {
        return Number(lineCharge.amount);
      }
      return Number(0);
    });
    const rentAmountTVA = computed(() => {
      const lineTVA = lines.value.find(
        (line) => line.account.substring(0, 6) === LedgerAccountEnum.N445720
      );
      if (lineTVA) {
        return Number(lineTVA.amount);
      }
      return undefined; // `undefined` For generate rent receipt without TVA
    });
    const rentPeriod: Ref<string> = ref(
      props.transactionState.transaction.date.operation
    );
    const dateOperation: Ref<string> = ref(new Date().toISOString());

    // For DataPicker
    const pickerMenu: Ref<boolean> = ref(false);
    const picker = ref();

    // Boolean to open Model for update data
    const tenantUpdate: Ref<boolean> = ref(false);
    const realEstateAssetUpdate: Ref<boolean> = ref(false);
    const productUpdate: Ref<boolean> = ref(false);

    const formatDate = (date) => {
      if (date === "") {
        return "";
      } else {
        const [year, month, day] = date.split("-");
        return `${day}-${month}-${year}`;
      }
    };

    dateOperation.value = props.transactionState.transaction.date.operation;

    const computedRentalPeriod = computed(() =>
      moment(rentPeriod.value, "YYYY-MM-DD").format("MM-YYYY")
    );

    const formatAddress = (
      address: Address = { street: "", city: "", zip: "" }
    ) => {
      if (address.street) {
        return address.street + ", " + address.zip + " " + address.city;
      } else {
        return null;
      }
    };

    const product = computed(() => {
      return productsStore.getProduct({
        id: productsStore.currentId,
      });
    });

    const tenants = computed(() => {
      if (tenant.value?.rentalAgreementId)
        return tenantsStore.getTenantsByRentalAgreementId(
          tenant.value?.rentalAgreementId
        );
      else if (tenantId.value) return [tenantsStore.getTenant(tenantId.value)];
      else return [];
    });

    const rentAmountTotal = computed(() => {
      let rentAmountTotalTemp = new Decimal(rentAmount.value).plus(
        rentAmountCharge.value
      );
      if (rentAmountTVA.value) {
        rentAmountTotalTemp = rentAmountTotalTemp.plus(rentAmountTVA.value);
      }
      return rentAmountTotalTemp.toNumber();
    });

    async function downloadPDF() {
      try {
        if (!product.value?.id) {
          throw new Error("Cannot find product id in `product.value.id`");
        }

        if (tenantId.value)
          await rentalsService.agreements.downloadReceipt({
            id: tenantId.value,
            productId: product.value.id,
            bankAccountId: props.transactionState.transaction.bankAccountId,
            transactionId: props.transactionState.transaction.id,
            rentPeriod: rentPeriod.value,
            rentAmount: rentAmount.value,
            rentAmountCharge: rentAmountCharge.value,
            rentAmountTVA: rentAmountTVA.value,
            rentAmountTotal: rentAmountTotal.value,
            dateOperation: dateOperation.value,
          });
      } finally {
        // Close popup (error is managed in Axios)
        updateOpenRentReceiptStep(0);
        documentsStore.fetchDocuments();
        refreshTransaction();
      }
    }

    async function refreshRealEstateAsset(address: Address): Promise<void> {
      if (rentalAgreement.value?.expand?.realEstateAsset) {
        rentalAgreement.value.expand.realEstateAsset.address = address;
      }
    }

    async function refreshProduct(
      address: RequireField<Address, "street" | "zip" | "city">
    ): Promise<void> {
      if (rentalAgreement.value?.expand?.product) {
        rentalAgreement.value.expand.product.activity.address = address;
      }
    }
    const goToRentalAgreementsHelp = () => {
      window.open(
        `https://ownily.crisp.help/fr/article/comment-automatiser-le-suivi-des-loyers-1bwwshn/`,
        "_blank"
      );
    };

    return {
      goToRentalAgreementsHelp,
      rentPeriod,
      computedRentalPeriod,
      product,
      rentalAmount: rentAmount,
      chargeAmount: rentAmountCharge,
      tvaAmount: rentAmountTVA,
      totalAmount: rentAmountTotal,
      dueDate: formatDate(dateOperation.value),
      picker,
      pickerMenu,
      downloadPDF,
      rentalAgreement,
      tenantUpdate,
      realEstateAsset,
      realEstateAssetUpdate,
      refreshRealEstateAsset,
      productUpdate,
      refreshProduct,
      updateOpenRentReceiptStep,
      imageSignature,
      imageLogo,
      tenants,
      formatAddress,
      TenantTypeEnum,
    };
  },
});
