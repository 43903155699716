







































import EditableTable from "@/components/atom/table/EditableTable.vue";
import moment from "moment";
import { realEstateAssetsStore } from "@/store";
import { computed, defineComponent } from "@vue/composition-api";
import { cloneDeep } from "lodash";

export default defineComponent({
  name: "RealEstateTVAHistory",
  components: { EditableTable },
  props: {
    realEstateAssetId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const rentalUnit = computed(() =>
      cloneDeep(
        realEstateAssetsStore.getRentalUnitByRealEstateAssetId(
          props.realEstateAssetId
        )
      )
    );
    const headers = [
      { text: "Date fin d'application", value: "dateChanged" },
      { text: "Taux", value: "rate" },
    ];
    const items = computed(() => {
      const sortedHistory = rentalUnit.value.history
        ? rentalUnit.value.history.sort((taxA, taxB) =>
            moment(taxB.dateChanged).diff(moment(taxA.dateChanged))
          )
        : [];
      if (!sortedHistory.length && !rentalUnit.value.taxTvaEnable) {
        return [];
      }
      return [
        ...(rentalUnit.value.taxTvaEnable
          ? [{ dateChanged: undefined, rate: rentalUnit.value.taxRateTVA }]
          : []),
        ...sortedHistory,
      ];
    });
    return { headers, items, moment };
  },
});
