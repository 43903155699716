import { transactionsStore } from "@/store";
import {
  AnomalyError,
  JournalEntryLine,
  Transaction,
  TransactionImportType,
  TypeReference,
} from "@edmp/api";
import { isAfter, isBefore, parse } from "date-fns";
import { flatMap, intersectionWith, isEqual } from "lodash";
import { Dictionary } from "vue-router/types/router";

/* Defining the keys of the filters. */
export enum FilterKeys {
  ONLY_UNCATEGORIZED = "ONLY_UNCATEGORIZED",
  ONLY_SUGGESTED = "ONLY_SUGGESTED",
  ONLY_ANOMALIZED = "ONLY_ANOMALIZED",
  ONLY_IMPORTED = "ONLY_IMPORTED",
  AMOUNT_RANGE = "AMOUNT_RANGE",
  DATE_RANGE = "DATE_RANGE",
  CATEGORIES = "CATEGORIES",
  BANK_ACCOUNTS = "BANK_ACCOUNTS",
  REAL_ESTATE_ASSETS = "REAL_ESTATE_ASSETS",
  PARTNERS = "PARTNERS",
  SUMMARY_SEARCH = "SUMMARY_SEARCH",
  CERFA = "CERFA",
  ACCRUAL = "ACCRUAL",
  SUGGESTIONS = "SUGGESTIONS",
  TYPES = "TYPES",
}

/* Defining the keys of the filters. */
export interface FiltersParameters {
  [FilterKeys.ONLY_UNCATEGORIZED]: {
    label?: string;
  };
  [FilterKeys.ONLY_SUGGESTED]: {
    label?: string;
  };
  [FilterKeys.ONLY_ANOMALIZED]: {
    label?: string;
    anomaly?: AnomalyError;
    transactionsIds?: string[];
  };
  [FilterKeys.ONLY_IMPORTED]: {
    label: string;
  };
  [FilterKeys.AMOUNT_RANGE]: {
    minimalAmount?: number;
    maximalAmount?: number;
    label?: string;
  };
  [FilterKeys.DATE_RANGE]: {
    startDate?: Date;
    endDate?: Date;
    label?: string;
  };
  [FilterKeys.CATEGORIES]: {
    categories: Array<string>;
    label?: string;
    withSupportingDocument?: boolean;
  };
  [FilterKeys.REAL_ESTATE_ASSETS]: {
    ids: Array<string>;
    label: string;
  };
  [FilterKeys.BANK_ACCOUNTS]: {
    ids: Array<string>;
    label: string;
  };
  [FilterKeys.SUMMARY_SEARCH]: {
    search?: string;
    label?: string;
  };
  [FilterKeys.CERFA]: {
    ids?: Array<string>;
    label?: string;
  };
  [FilterKeys.ACCRUAL]: {
    id?: string;
    label?: string;
  };
  [FilterKeys.SUGGESTIONS]: {
    label: string;
  };
  [FilterKeys.TYPES]: {
    types?: Array<
      | "SYNC"
      | TransactionImportType.MANUAL
      | TransactionImportType.IMPORT
      | TransactionImportType.EXPENSE_REPORT
      | string
    >;
    label: string;
  };
  [FilterKeys.PARTNERS]: {
    ids: Array<string>;
    label: string;
  };
}

/**
 * `Filters` is an object whose keys are the keys of `FiltersParameters` and whose values are the
 * values of `FiltersParameters` for the corresponding key.
 */
export type Filters = {
  [Keys in FilterKeys]?: FiltersParameters[Keys];
};

/**
 * Return true if the transaction has no journal entries
 * @param {Transaction} transaction - Transaction
 * @returns A boolean value
 */
export function filteredTransactionsGroupByUncategorized(
  transaction: Transaction
): boolean {
  return (
    !transaction.operations?.journalEntry?.lines ||
    transaction.operations?.journalEntry?.lines?.length === 0
  );
}

/**
 * `filteredTransactionsGroupBySuggested` returns true if the transaction has no lines and has suggested
 * lines
 * @param {Transaction} transaction - Transaction - the transaction object
 * @returns A boolean
 */
export function filteredTransactionsGroupBySuggested(
  transaction: Transaction
): boolean {
  return (
    !transaction.operations?.journalEntry?.lines?.length &&
    !!transaction.operations?.journalEntry?.suggestedLines?.length
  );
}

export function filteredTransactionsGroupByAnomalized(
  transaction: Transaction,
  filterParams: FiltersParameters[FilterKeys.ONLY_ANOMALIZED]
): boolean {
  if (
    filterParams.transactionsIds?.length &&
    filterParams.transactionsIds.includes(transaction.id)
  ) {
    return true;
  } else {
    if (filterParams.anomaly) {
      return !!transaction.operations?.journalEntry?.lines?.find(
        (line) =>
          filterParams.anomaly &&
          !!line.anomalies?.find(
            (anomaly) => anomaly.anomalyError === filterParams.anomaly
          )
      );
    } else {
      return !!transaction.operations?.journalEntry?.lines?.find(
        (line) => !!line.anomalies?.length
      );
    }
  }
}

/**
 * It returns true if the transaction's amount is between the minimal and maximal amount specified in
 * the filter parameters
 * @param {Transaction} transaction - Transaction - the transaction we're filtering
 * @param {FiltersParameters[FilterKeys.AMOUNT_RANGE]} filterParams - FiltersParameters
 * @returns A boolean value
 */
export function filteredTransactionsByAmount(
  transaction: Transaction,
  filterParams: FiltersParameters[FilterKeys.AMOUNT_RANGE]
): boolean {
  return (
    !!transaction.value.amount &&
    (!filterParams.maximalAmount ||
      transaction.value.amount <= filterParams.maximalAmount) &&
    (!filterParams.minimalAmount ||
      transaction.value.amount >= filterParams.minimalAmount)
  );
}

/**
 * It returns true if the transaction's operation date is between the start and end date of the
 * filterParams.dateFilter object
 * @param {Transaction} transaction - Transaction - the transaction we're filtering
 * @param {FiltersParameters[FilterKeys.DATE_RANGE]} filterParams - FiltersParameters
 * @returns A boolean value
 */
export function filteredTransactionByDate(
  transaction: Transaction,
  filterParams: FiltersParameters[FilterKeys.DATE_RANGE]
): boolean {
  const parsedOperationDate = parse(
    transaction.date.operation,
    "yyyy-MM-dd",
    new Date()
  );

  return (
    (!filterParams.startDate ||
      isEqual(parsedOperationDate, filterParams.startDate) ||
      isAfter(parsedOperationDate, filterParams.startDate)) &&
    (!filterParams.endDate ||
      isEqual(parsedOperationDate, filterParams.endDate) ||
      isBefore(parsedOperationDate, filterParams.endDate))
  );
}

/**
 * It returns true if the transaction summary contains the search string
 * @param {Transaction} transaction - Transaction - the transaction we're checking
 * @param {FiltersParameters[FilterKeys.SUMMARY_SEARCH]} filterParams - FiltersParameters
 * @returns A boolean
 */
export function filteredTransactionBySearch(
  transaction: Transaction,
  filterParams: FiltersParameters[FilterKeys.SUMMARY_SEARCH]
): boolean {
  return (
    !!filterParams.search &&
    !!transaction.summary &&
    transaction.summary
      .toUpperCase()
      .includes(filterParams.search.toUpperCase())
  );
}

/**
 * It returns true if the transaction has at least one category in common with the categories filter
 * @param {Transaction} transaction - Transaction
 * @param {FiltersParameters[FilterKeys.CATEGORIES]} filterParams - FiltersParameters
 * @returns A boolean
 */
export function filteredTransactionByCategories(
  transaction: Transaction,
  filterParams: FiltersParameters[FilterKeys.CATEGORIES]
): boolean {
  let categoriesName: Array<string> | undefined;

  let lines: JournalEntryLine[] | undefined =
    transaction.operations?.journalEntry?.lines ?? [];

  // retrieve all lines that don't have a supporting document yet
  if (filterParams.withSupportingDocument) {
    lines = lines?.filter(
      (l: JournalEntryLine) =>
        !l.refs?.some((r) => r.type === TypeReference.supportingDocument)
    );
  }
  if (lines) {
    categoriesName = lines.map((element: JournalEntryLine) => element.account);
  }

  return (
    !!filterParams.categories &&
    !!categoriesName &&
    intersectionWith(categoriesName, filterParams.categories, isEqual)
      .length !== 0
  );
}

/**
 * It returns true if the transaction has a journal entry line that refers to a real estate asset that
 * is in the filter parameters
 * @param {Transaction} transaction - Transaction
 * @param {FiltersParameters[FilterKeys.REAL_ESTATE_ASSETS]} filterParams - FiltersParameters
 * @returns A boolean
 */
export function filteredTransactionByRealEstateAssets(
  transaction: Transaction,
  filterParams: FiltersParameters[FilterKeys.REAL_ESTATE_ASSETS]
): boolean {
  const lines = transaction.operations?.journalEntry?.lines;
  if (lines) {
    return lines.some((journalEntryLine: JournalEntryLine) => {
      return journalEntryLine.refs?.some((journalEntryReference) => {
        return filterParams.ids.includes(journalEntryReference.referredId);
      });
    });
  } else {
    return false;
  }
}

/**
 * It returns true if the transaction has a journal entry line that refers to a real estate asset that
 * is in the filter parameters
 * @param {Transaction} transaction - Transaction
 * @param {FiltersParameters[FilterKeys.BANK_ACCOUNTS]} filterParams - FiltersParameters
 * @returns A boolean
 */
export function filteredTransactionByBankAccounts(
  transaction: Transaction,
  filterParams: FiltersParameters[FilterKeys.BANK_ACCOUNTS]
): boolean {
  return !!filterParams.ids.includes(transaction.bankAccountId);
}

/**
 * It returns true if the transaction has a journal entry line that refers to a partner that
 * is in the filter parameters
 * @param {Transaction} transaction - Transaction
 * @param {FiltersParameters[FilterKeys.PARTNERS]} filterParams - FiltersParameters
 * @returns A boolean
 */
export function filteredTransactionByPartners(
  transaction: Transaction,
  filterParams: FiltersParameters[FilterKeys.PARTNERS]
): boolean {
  let result = false;
  const lines = transaction.operations?.journalEntry?.lines;
  if (lines) {
    lines.map((journalEntryLine: JournalEntryLine) => {
      journalEntryLine.refs?.map((journalEntryReference) => {
        if (
          filterParams.ids.includes(journalEntryReference.referredId) &&
          journalEntryReference.type === TypeReference.partner
        ) {
          result = true;
        }
      });
    });
  }
  return result;
}

/**
 * If the transaction's id is in the list of ids, return true.
 * @param {Transaction} transaction - Transaction - the transaction we're filtering
 * @param {FiltersParameters[FilterKeys.CERFA]} filterParams - FiltersParameters
 * @returns A boolean value
 */
export function filteredTransactionByIds(
  transaction: Transaction,
  filterParams: FiltersParameters[FilterKeys.CERFA]
): boolean {
  return !!filterParams.ids && filterParams.ids.includes(transaction.id);
}

/**
 * Returns true if the transaction's id matches the provided id in the filter parameters.
 * @param {Transaction} transaction - The transaction being filtered.
 * @param {FiltersParameters[FilterKeys.ACCRUAL]} filterParams - The filter parameters containing the id to match.
 * @returns A boolean value indicating whether the transaction's id matches the filter id.
 */
export function filteredTransactionById(
  transaction: Transaction,
  filterParams: FiltersParameters[FilterKeys.ACCRUAL]
): boolean {
  return !!filterParams.id && filterParams.id === transaction.id;
}

/**
 * It returns true if the transaction's source.provider is included in the
 * filterParams.typesFilter.types array, or if the filterParams.typesFilter.types array is empty
 * @param {Transaction} transaction - Transaction
 * @param {FiltersParameters} filterParams - FiltersParameters
 */
export function filteredTransactionByTypes(
  transaction: Transaction,
  filterParams: FiltersParameters[FilterKeys.TYPES]
): boolean {
  let result = false;
  if (filterParams.types) {
    for (const type of filterParams.types) {
      if (type == "SYNC") {
        if (
          transaction.source.provider != TransactionImportType.MANUAL &&
          transaction.source.provider != TransactionImportType.IMPORT
        ) {
          result = true;
        }
      } else {
        result = filterParams.types.includes(transaction.source.provider); // filtre MANUAL ou IMPORT
      }
      if (result) break;
    }
  } else {
    result = true;
  }

  return result;
}

/**
 * If the transaction's source provider is 'IMPORT', return true, otherwise return false.
 * @param {Transaction} transaction - The transaction object that is being imported.
 * @returns A boolean value.
 */
export function filteredTransactionImported(transaction: Transaction): boolean {
  return TransactionImportType.IMPORT === transaction.source.provider;
}

/* A dictionary of functions. */
export const CODE_FILTERS_FUNCTION: {
  [Keys in FilterKeys]: (
    transaction: Transaction,
    params: FiltersParameters[Keys]
  ) => boolean;
} = {
  [FilterKeys.ONLY_UNCATEGORIZED]: filteredTransactionsGroupByUncategorized,
  [FilterKeys.ONLY_SUGGESTED]: filteredTransactionsGroupBySuggested,
  [FilterKeys.ONLY_ANOMALIZED]: filteredTransactionsGroupByAnomalized,
  [FilterKeys.ONLY_IMPORTED]: filteredTransactionImported,
  [FilterKeys.AMOUNT_RANGE]: filteredTransactionsByAmount,
  [FilterKeys.DATE_RANGE]: filteredTransactionByDate,
  [FilterKeys.CATEGORIES]: filteredTransactionByCategories,
  [FilterKeys.BANK_ACCOUNTS]: filteredTransactionByBankAccounts,
  [FilterKeys.REAL_ESTATE_ASSETS]: filteredTransactionByRealEstateAssets,
  [FilterKeys.SUMMARY_SEARCH]: filteredTransactionBySearch,
  [FilterKeys.CERFA]: filteredTransactionByIds,
  [FilterKeys.ACCRUAL]: filteredTransactionById,
  [FilterKeys.SUGGESTIONS]: filteredTransactionByIds,
  [FilterKeys.TYPES]: filteredTransactionByTypes,
  [FilterKeys.PARTNERS]: filteredTransactionByPartners,
};

/**
 * > Utilisé pour filtrer sans cliquer sur "appliquer" les filtres
 * It takes a list of transactions and filters them based on the filters passed in
 * @param {Dictionary<Transaction> | Transaction[]} [transactions] - The transactions to filter. If not
 * provided, the transactions from the store will be used.
 * @param [filters] - The filters to apply to the transactions. If not provided, the filter from the store will be used.
 * @returns array of filtered transactions
 */
export const filteredTransactions = (
  transactions?: Dictionary<Transaction> | Transaction[],
  filters?: Filters
): Transaction[] => {
  let transactionsToFilter: Transaction[];
  if (transactions instanceof Array) {
    transactionsToFilter = transactions;
  } else if (transactions instanceof Object) {
    transactionsToFilter = flatMap(transactions);
  } else {
    transactionsToFilter = flatMap(transactionsStore.transactions);
  }

  return transactionsToFilter.filter((t) => {
    return Object.entries(filters ?? transactionsStore.filters).reduce(
      (acc: boolean, [key, value]) => {
        return acc && CODE_FILTERS_FUNCTION[key](t, value ? value : {});
      },
      true
    );
  });
};
