var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"partnerModal"},[_c('v-card-title',[_c('h4',[_vm._v(_vm._s(_vm.modalTitle))]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},nativeOn:{"click":function($event){return _vm.closeDialog($event)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('div',{staticClass:"f-flex flex-row",staticStyle:{"text-align":"end"}},[(!_vm.isEditing)?_c('v-btn',{attrs:{"color":"primary","depressed":""},on:{"click":function($event){_vm.isEditing = !_vm.isEditing}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","id":"track-modify_partner"}},[_vm._v(" mdi-pencil-outline ")]),_vm._v(" Modifier ")],1):_vm._e()],1),_c('v-form',{ref:"form",staticClass:"partnerModal-form"},[_c('h4',{staticClass:"modal-subtitle"},[_c('v-icon',{staticClass:"mr-3",attrs:{"color":"#999999"}},[_vm._v("mdi-gavel")]),_c('span',[_vm._v("Forme juridique")])],1),_c('v-divider',{staticClass:"mt-3 mb-6"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"disabled":!_vm.isEditing,"items":_vm.legalFormList,"item-text":"text","item-value":"value","label":"","outlined":""},model:{value:(_vm.partner.type),callback:function ($$v) {_vm.$set(_vm.partner, "type", $$v)},expression:"partner.type"}})],1)],1),_c('h4',{staticClass:"partnerModal-subtitle"},[_c('v-icon',{staticClass:"mr-3",attrs:{"color":"#999999"}},[_vm._v("mdi-account")]),_c('span',[_vm._v(" Coordonnées ")])],1),_c('v-divider',{staticClass:"mt-3 mb-6"}),_c('v-row',[(_vm.partner.type === _vm.LEGAL_PERSON)?_c('v-col',{attrs:{"cols":"6","sm":"6"}},[_c('v-text-field',{attrs:{"rules":[
              function () { return !!_vm.partner.legalStatus || 'Le champ ne peut pas être vide'; } ],"id":"partner-add-legalStatus","label":"Statut juridique","outlined":"","type":"text","disabled":!_vm.isEditing},model:{value:(_vm.partner.legalStatus),callback:function ($$v) {_vm.$set(_vm.partner, "legalStatus", $$v)},expression:"partner.legalStatus"}},[(_vm.isEditing)?_c('v-tooltip',{attrs:{"slot":"append","top":""},slot:"append",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-help-circle-outline")])]}}],null,false,2575987712)},[_c('span',[_vm._v("SAS, SARL, SCI...")])]):_vm._e()],1)],1):_vm._e(),(_vm.partner.type === _vm.LEGAL_PERSON)?_c('v-col',{attrs:{"cols":"6","sm":"6"}},[_c('v-text-field',{attrs:{"rules":[
              function () { return !!_vm.partner.denomination || 'Le champ ne peut pas être vide'; } ],"id":"partner-add-denomination","label":"Dénomination","outlined":"","type":"text","disabled":!_vm.isEditing},model:{value:(_vm.partner.denomination),callback:function ($$v) {_vm.$set(_vm.partner, "denomination", $$v)},expression:"partner.denomination"}})],1):_vm._e(),(_vm.partner.type === _vm.LEGAL_PERSON)?_c('v-col',{attrs:{"cols":"6","sm":"6"}},[_c('v-text-field',{attrs:{"id":"partner-add-siret","rules":[
              function () { return !_vm.partner.siret ||
                (!!_vm.partner.siret &&
                  _vm.onlyNumber(_vm.partner.siret) &&
                  _vm.partner.siret.length === 14) ||
                'Le format de votre SIRET est incorrect'; } ],"label":"Numéro siret","outlined":"","required":"","type":"string","disabled":!_vm.isEditing},model:{value:(_vm.partner.siret),callback:function ($$v) {_vm.$set(_vm.partner, "siret", $$v)},expression:"partner.siret"}})],1):_vm._e(),(_vm.partner.type === _vm.NATURAL_PERSON)?_c('v-col',{attrs:{"cols":"6","sm":"6"}},[_c('v-text-field',{attrs:{"id":"partner-add-lastname","rules":[
              function () { return !!_vm.partner.lastName || 'Le champ ne peut pas être vide'; } ],"label":"Nom","outlined":"","required":"","type":"text","disabled":!_vm.isEditing},model:{value:(_vm.partner.lastName),callback:function ($$v) {_vm.$set(_vm.partner, "lastName", $$v)},expression:"partner.lastName"}})],1):_vm._e(),(_vm.partner.type === _vm.NATURAL_PERSON)?_c('v-col',{attrs:{"cols":"6","sm":"6"}},[_c('v-text-field',{attrs:{"id":"partner-add-firstName","rules":[
              function () { return !!_vm.partner.firstName || 'Le champ ne peut pas être vide'; } ],"label":"Prénom","outlined":"","required":"","type":"text","disabled":!_vm.isEditing},model:{value:(_vm.partner.firstName),callback:function ($$v) {_vm.$set(_vm.partner, "firstName", $$v)},expression:"partner.firstName"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"6","sm":"6"}},[_c('v-text-field',{attrs:{"id":"partner-add-email","rules":[
              function () { return _vm.validEmailRule(_vm.emailLowerCase) || 'Format invalide'; } ],"append-icon":"mdi-at","label":"Email","outlined":"","type":"text","disabled":!_vm.isEditing},model:{value:(_vm.emailLowerCase),callback:function ($$v) {_vm.emailLowerCase=$$v},expression:"emailLowerCase"}})],1),(_vm.partner.type === _vm.NATURAL_PERSON)?_c('v-col',{attrs:{"cols":"6","sm":"6"}},[_c('DatePicker',{attrs:{"disabled":!_vm.isEditing,"label":"Date de naissance","refId":"picker","rules":[
              function () { return !!_vm.partner.birthDate || 'Le champ ne peut pas être vide'; } ],"max":_vm.today,"alwaysReopenOnYear":true},model:{value:(_vm.partner.birthDate),callback:function ($$v) {_vm.$set(_vm.partner, "birthDate", $$v)},expression:"partner.birthDate"}})],1):_vm._e()],1),(_vm.partner.type === _vm.NATURAL_PERSON)?_c('p',{staticClass:"mb-5"},[_vm._v(" Lieu de naissance ")]):_vm._e(),(_vm.partner.type === _vm.NATURAL_PERSON)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-combobox',{staticClass:"position-left",attrs:{"id":"partner-add-place","value":_vm.partner.birthAddress.place,"items":_vm.searchItemsPlaceList,"label":"Commune de naissance","outlined":"","type":"text","disabled":!_vm.isEditing,"rules":[
              function () { return !!_vm.partner.birthAddress.place ||
                'Le champ ne peut pas être vide'; } ]},on:{"keyup":function (event) {
                _vm.updateAddress(event, 'place', 'updateBirthAddress');
                _vm.searchAddress(
                  event.target.value,
                  'place',
                  'updateBirthAddress'
                );
              },"input":function (event) { return _vm.updateAddress(event, 'place', 'updateBirthAddress'); }}})],1),_c('v-col',{attrs:{"cols":"8","md":"3","sm":"7"}},[_c('v-combobox',{staticClass:"position-left",attrs:{"id":"partner-add-department","value":_vm.partner.birthAddress.department,"items":_vm.searchItemsDepartmentList,"label":"Département de naissance","hint":"Utiliser '99' pour un pays étranger","outlined":"","type":"text","disabled":!_vm.isEditing,"rules":[
              function () { return !!_vm.partner.birthAddress.department ||
                'Le champ ne peut pas être vide'; } ]},on:{"keyup":function (event) {
                _vm.updateAddress(event, 'department', 'updateBirthAddress');
                _vm.searchAddress(
                  event.target.value,
                  'department',
                  'updateBirthAddress'
                );
              },"input":function (event) { return _vm.updateAddress(event, 'department', 'updateBirthAddress'); }}})],1),_c('v-col',{attrs:{"cols":"4","md":"3","sm":"5"}},[_c('v-select',{attrs:{"id":"partner-add-country","items":_vm.countryISOcodes.countryISOcodes,"item-text":"text","item-value":"value","outlined":"","disabled":!_vm.isEditing,"rules":[
              function () { return !!_vm.partner.birthAddress.country ||
                'Le champ ne peut pas être vide'; } ]},model:{value:(_vm.partner.birthAddress.country),callback:function ($$v) {_vm.$set(_vm.partner.birthAddress, "country", $$v)},expression:"partner.birthAddress.country"}})],1)],1):_vm._e(),_c('p',{staticClass:"mb-5"},[_vm._v("Adresse postale")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-combobox',{staticClass:"position-left",attrs:{"id":"partner-add-street","value":_vm.partner.address.street,"items":_vm.searchItemsStreetList,"label":"Rue","outlined":"","type":"text","disabled":!_vm.isEditing,"rules":[
              function () { return !!_vm.partner.address.street || 'Le champ ne peut pas être vide'; } ]},on:{"keyup":function (event) {
                _vm.updateAddress(event, 'street', 'updateAddress');
                _vm.searchAddress(event.target.value, 'street');
              },"input":function (event) { return _vm.updateAddress(event, 'street', 'updateAddress'); }}})],1),_c('v-col',{attrs:{"cols":"8","md":"3","sm":"7"}},[_c('v-combobox',{staticClass:"position-left",attrs:{"id":"partner-add-city","value":_vm.partner.address.city,"items":_vm.searchItemsCityList,"label":"Ville","outlined":"","type":"text","disabled":!_vm.isEditing,"rules":[
              function () { return !!_vm.partner.address.city || 'Le champ ne peut pas être vide'; } ]},on:{"keyup":function (event) {
                _vm.updateAddress(event, 'city', 'updateAddress');
                _vm.searchAddress(event.target.value, 'city');
              },"input":function (event) { return _vm.updateAddress(event, 'city', 'updateAddress'); }}})],1),_c('v-col',{attrs:{"cols":"4","md":"3","sm":"5"}},[_c('v-combobox',{staticClass:"position-right",attrs:{"id":"partner-add-zip","value":_vm.partner.address.zip,"items":_vm.searchItemsZipList,"label":"Code Postal","outlined":"","type":"text","disabled":!_vm.isEditing,"rules":[
              function () { return !!_vm.partner.address.zip || 'Le champ ne peut pas être vide'; } ]},on:{"keyup":function (event) {
                _vm.updateAddress(event, 'zip', 'updateAddress');
                _vm.searchAddress(event.target.value, 'zip');
              },"input":function (event) { return _vm.updateAddress(event, 'zip', 'updateAddress'); }}})],1)],1),(_vm.partner.type === _vm.LEGAL_PERSON && _vm.isTaxRegimeIr)?_c('v-alert',{attrs:{"outlined":"","text":"","type":"warning"}},[_vm._v(" Attention, Ownily ne gère pas encore la déclinaison de la déclaration CERFA 2044 pour les personnes morales mais c'est pour bientôt ! ")]):_vm._e(),_c('h4',{staticClass:"partnerModal-subtitle"},[_c('v-icon',{staticClass:"mr-3",attrs:{"color":"#999999"}},[_vm._v("mdi-home-city")]),_c('span',[_vm._v(" Rôle et parts ")])],1),_c('v-divider',{staticClass:"mt-3 mb-4"}),_c('v-col',{staticClass:"select-insurance d-flex align-center",attrs:{"cols":"5"}},[_c('h4',{staticClass:"partner-add-role-title"},[_c('span',[_vm._v("L'associé est-il gérant ?")])]),_c('div',{staticClass:"partner-add-role"},[_c('v-btn',{staticClass:"partner-add-role-onChange-btn",class:{ active: _vm.partner.role === _vm.PartnerRole.OWNER },attrs:{"id":"partner-add-role-owner","depressed":"","disabled":!_vm.isEditing},on:{"click":function($event){_vm.partner.role = _vm.PartnerRole.OWNER}}},[_vm._v(" Oui ")]),_c('v-btn',{staticClass:"partner-add-role-onChange-btn",class:{ active: _vm.partner.role === _vm.PartnerRole.GRANTEE },attrs:{"id":"partner-add-role-grantee","depressed":"","disabled":!_vm.isEditing},on:{"click":function($event){_vm.partner.role = _vm.PartnerRole.GRANTEE}}},[_vm._v(" Non ")])],1)]),_c('h5',{staticClass:"mb-6"},[_vm._v("Nombre de parts")]),_c('v-row',[(!_vm.isTaxRegimeIs && !_vm.isTaxRegimeLmnp)?_c('v-col',{attrs:{"cols":"6","md":"3"}},[_c('v-text-field',{attrs:{"rules":[
              function (partsPP) { return _vm.partner.partsPP >= 0 || 'Le champ ne peut pas être vide'; },
              function (partsPP) { return typeof _vm.partner.partsPP === 'number' ||
                'Le champ doit etre un nombre'; } ],"label":"Pleine propriété","min":"0","outlined":"","required":"","type":"number","disabled":!_vm.isEditing},model:{value:(_vm.partner.partsPP),callback:function ($$v) {_vm.$set(_vm.partner, "partsPP", _vm._n($$v))},expression:"partner.partsPP"}})],1):_vm._e(),(!_vm.isTaxRegimeIs && !_vm.isTaxRegimeLmnp)?_c('v-col',{attrs:{"cols":"6","md":"3"}},[_c('v-text-field',{attrs:{"rules":[
              function (partsUF) { return _vm.partner.partsUF >= 0 || 'Le champ ne peut pas être vide'; },
              function (partsUF) { return typeof _vm.partner.partsUF === 'number' ||
                'Le champ doit etre un nombre'; } ],"label":"Usufruits","min":"0","outlined":"","required":"","type":"number","disabled":!_vm.isEditing},model:{value:(_vm.partner.partsUF),callback:function ($$v) {_vm.$set(_vm.partner, "partsUF", _vm._n($$v))},expression:"partner.partsUF"}})],1):_vm._e(),(!_vm.isTaxRegimeIs && !_vm.isTaxRegimeLmnp)?_c('v-col',{attrs:{"cols":"6","md":"3"}},[_c('v-text-field',{attrs:{"rules":[
              function (partsNP) { return _vm.partner.partsNP >= 0 || 'Le champ ne peut pas être vide'; },
              function (partsNP) { return typeof _vm.partner.partsNP === 'number' ||
                'Le champ doit etre un nombre'; } ],"label":"Nue propriété","min":"0","outlined":"","required":"","type":"number","disabled":!_vm.isEditing},model:{value:(_vm.partner.partsNP),callback:function ($$v) {_vm.$set(_vm.partner, "partsNP", _vm._n($$v))},expression:"partner.partsNP"}})],1):_vm._e(),(!_vm.isTaxRegimeIs && !_vm.isTaxRegimeLmnp)?_c('v-col',{attrs:{"cols":"6","md":"3"}},[_c('v-text-field',{attrs:{"disabled":"","label":"Parts fiscales","outlined":"","type":"number"},model:{value:(_vm.partsFiscales),callback:function ($$v) {_vm.partsFiscales=$$v},expression:"partsFiscales"}})],1):_vm._e(),(_vm.isTaxRegimeIs || _vm.isTaxRegimeLmnp)?_c('v-col',{attrs:{"cols":"6","md":"3"}},[_c('v-text-field',{attrs:{"rules":[
              function (partsSocial) { return _vm.partner.partsSocial > 0 || 'Le champ ne peut pas être vide'; },
              function (partsSocial) { return typeof _vm.partner.partsSocial === 'number' ||
                'Le champ doit etre un nombre'; } ],"label":"Parts Sociales","min":"0","outlined":"","required":"","type":"number","disabled":!_vm.isEditing},model:{value:(_vm.partner.partsSocial),callback:function ($$v) {_vm.$set(_vm.partner, "partsSocial", _vm._n($$v))},expression:"partner.partsSocial"}},[(_vm.isEditing)?_c('v-tooltip',{attrs:{"slot":"append","top":""},slot:"append",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-help-circle-outline")])]}}],null,false,2575987712)},[_c('span',[_vm._v("Ces informations sont disponibles dans vos status.")])]):_vm._e()],1)],1):_vm._e(),_c('v-col',{attrs:{"cols":"6","md":"3"}},[_c('v-text-field',{attrs:{"id":"partner-add-detention","rules":[
              function (pctDetention) { return _vm.partner.pctDetention > 0 || 'Le champ ne peut pas être vide'; },
              function (pctDetention) { return _vm.partner.pctDetention <= 100 ||
                'Un pourcentage ne peut pas être supérieur à 100'; },
              function (pctDetention) { return typeof _vm.partner.pctDetention === 'number' ||
                'Le champ doit etre un nombre'; } ],"label":"Détention","min":"0","outlined":"","required":"","prefix":"%","type":"number","disabled":!_vm.isEditing},model:{value:(_vm.partner.pctDetention),callback:function ($$v) {_vm.$set(_vm.partner, "pctDetention", _vm._n($$v))},expression:"partner.pctDetention"}},[(_vm.isEditing)?_c('v-tooltip',{attrs:{"slot":"append","top":""},slot:"append",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-help-circle-outline")])]}}],null,false,2575987712)},[_c('span',[_vm._v("Ces informations sont disponibles dans vos status.")])]):_vm._e()],1)],1)],1),_c('h4',{staticClass:"partnerModal-subtitle"},[_c('v-icon',{staticClass:"mr-3",attrs:{"color":"#999999"}},[_vm._v("mdi-piggy-bank")]),_c('span',[_vm._v(" Compte courant d'associé ")])],1),_c('v-divider',{staticClass:"mb-4 mt-2"}),_c('div',{staticClass:"mb-6"},[_c('span',[_vm._v("Solde du compte au "+_vm._s(_vm.todayFormated)+" : "),_c('b',[_vm._v(_vm._s(_vm.$n(_vm.getBalance(_vm.partner.id), "currency", "fr-FR")))])])]),_c('v-row',{staticClass:"mb-2"},[(_vm.balanceSheetRecoveryLinePartnerAccountBalanceEndDate)?_c('v-col',{attrs:{"cols":"3","md":"4"}},[(_vm.isAllowEditingPartnerAccountBalanceRecovery)?_c('v-text-field',{attrs:{"id":"partner-add-detention","rules":[
              function (balanceSheetRecoveryLinePartnerAccountBalanceAmount) { return balanceSheetRecoveryLinePartnerAccountBalanceAmount !== '' ||
                'Le champ ne peut pas être vide'; } ],"label":("Solde du compte au " + _vm.balanceSheetRecoveryLinePartnerAccountBalanceEndDate),"outlined":"","required":"","prefix":"€","type":"number"},model:{value:(
              _vm.balanceSheetRecoveryLinePartnerAccountBalanceAmount
            ),callback:function ($$v) {
              _vm.balanceSheetRecoveryLinePartnerAccountBalanceAmount
            =_vm._n($$v)},expression:"\n              balanceSheetRecoveryLinePartnerAccountBalanceAmount\n            "}}):_c('span',[_vm._v(" Solde du compte au "+_vm._s(_vm.balanceSheetRecoveryLinePartnerAccountBalanceEndDate)+" : "),_c('b',[_vm._v(" "+_vm._s(_vm.$n( _vm.balanceSheetRecoveryLinePartnerAccountBalanceAmount, "currency", "fr-FR" ))+" ")])])],1):_vm._e()],1),(_vm.isEditing)?_c('v-btn',{staticClass:"partnerModal-btn mt-1",attrs:{"id":"partner-add-validate","color":"primary","depressed":""},on:{"click":_vm.validate}},[_vm._v(" Valider ")]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }