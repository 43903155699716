




















































































































































































import DatePicker from "@/components/atom/DatePicker.vue";
import Confirm from "@/components/core/modals/Confirm.vue";
import Next from "@/components/core/modals/Next.vue";
import { VForm } from "@/models";
import i18n from "@/plugins/i18n";
import {
  accountingBalanceSheetsStore,
  accountingPeriodsStore,
  coreStore,
  operationAccrualsStore,
  transactionsStore,
} from "@/store";
import { FeedbackTypeEnum } from "@/store/modules/Core.store";
import { countDecimals } from "@/utils";
import {
  AccountingPeriod,
  Direction,
  OperationAccrualLib,
  OperationAccrualsModel,
  Suggestion,
} from "@edmp/api";
import { AccountingPeriodDefinition, LedgerAccountEnum } from "@edmp/api/";
import { AccountingBalanceSheet } from "@edmp/api/src";
import {
  computed,
  defineComponent,
  PropType,
  reactive,
  ref,
  Ref,
} from "@vue/composition-api";
import { flatMap } from "lodash";
import DialogRight from "../../../DialogRight.vue";
import { TransactionState } from "../../../transactions/transaction/transaction.usable";
import Categories from "../../categorization/Categories.vue";
import Categorization from "../../categorization/Categorization.vue";
import CategorizationStep1 from "../../categorization/CategorizationStep1.vue";
import CategorizationStep2 from "../../categorization/CategorizationStep2.vue";
import { AccrualState, useAccruals } from "../accruals.usable";

export default defineComponent({
  name: "AccrualForm",
  props: {
    type: {
      type: String as PropType<
        | OperationAccrualsModel.Tags.RECOVERY
        | OperationAccrualsModel.Tags.CLOSURE
      >,
    },
    account: {
      type: String as PropType<OperationAccrualsModel.DoubleEntryAccounts>,
      required: false,
    },
    transactionState: {
      type: Object as PropType<TransactionState>,
      required: false,
    },
    accountingPeriod: {
      type: Object as PropType<AccountingPeriod>,
      required: false,
    },
  },
  components: {
    Next,
    Confirm,
    DatePicker,
    DialogRight,
    Categories,
    Categorization,
    CategorizationStep1,
    CategorizationStep2,
  },
  setup(props, context) {
    const accountingPeriod = computed(
      () =>
        props.accountingPeriod ??
        (accountingPeriodsStore.currentAccountingPeriod as AccountingPeriod &
          AccountingPeriodDefinition)
    );
    const { initAccrualState } = useAccruals();
    const transaction = computed(() => props.transactionState?.transaction);

    const accrualState = reactive<AccrualState>(
      initAccrualState(accountingPeriod.value, props.type, transaction.value)
    );

    const categories = computed(
      () => transactionsStore.getCategoriesListWithoutDisabled
    );
    const categoriesWithDoubleEntry = computed<Suggestion[]>(() =>
      flatMap(categories.value).filter((categorie) => {
        const doubleEntryAccount = OperationAccrualLib.getDoubleEntryAccount(
          categorie.number,
          true
        );
        return props.account
          ? doubleEntryAccount === props.account
          : doubleEntryAccount !== LedgerAccountEnum.UNKNOWN;
      })
    );

    const firstStep: Ref<boolean> = ref(true);
    const secondStep: Ref<boolean> = ref(false);
    const currency: Ref<string | undefined> = ref(
      transaction.value
        ? transaction.value.value.amount > 0
          ? "crédit"
          : "débit"
        : props.account
        ? OperationAccrualLib.getDirectionByDoubleEntryAccount(
            props.account
          ) === Direction.credit
          ? "crédit"
          : "débit"
        : ""
    );
    const computedAvatar = computed(() => {
      if (accrualState.amount >= 0) {
        return "#b8d7fb";
      } else {
        return "#7085d1";
      }
    });

    const validateRecoveryAmount = (): boolean => {
      if (props.account) {
        const operationAccrualToAdd = {
          amount: accrualState.amount,
          doubleEntryAccount: props.account,
        };
        const result = OperationAccrualLib.validateRecoveryAmount(
          operationAccrualToAdd,
          operationAccrualsStore.recoveryOperationAccrualsByAccountingPeriodId(
            accountingPeriod.value.id
          ),
          accountingBalanceSheetsStore.getPreviousYearAccountingBalanceSheet as AccountingBalanceSheet
        );
        return result;
      }
      return false;
    };
    async function validateFirstStep() {
      if ((context.refs.form as VForm).validate()) {
        try {
          if (props.type === OperationAccrualsModel.Tags.RECOVERY) {
            if (!validateRecoveryAmount()) {
              coreStore.displayFeedback({
                type: FeedbackTypeEnum.WARNING,
                message: i18n.t(
                  "errors.accruals.amount-greater-than-balance-sheet-amount"
                ) as string,
              });
              return;
            }
          }
          if (currency.value === "débit") {
            accrualState.amount = accrualState.amount * -1;
          }
          firstStep.value = false;
          secondStep.value = true;
          accrualState.isOpenCategorizationList = true;
        } catch (error) {
          console.error(error);
        }
      }
    }

    return {
      countDecimals,
      validateFirstStep,
      accrualState,
      transaction,
      firstStep,
      secondStep,
      currency,
      categoriesWithDoubleEntry,
      computedAvatar,
    };
  },
});
