





































































































































































































import {
  computed,
  defineComponent,
  onMounted,
  ref,
  watch,
} from "@vue/composition-api";
import EditableTable from "@/components/atom/table/EditableTable.vue";
import { VForm } from "@/models";
import TitleNew from "../title/TitleNew.vue";
import {
  ActivitiesService,
  GenericChargesModes,
  getMoment,
  RealEstateAssetTypes,
  RealEstateAssetUpdate,
} from "@edmp/api";
import {
  activitiesStore,
  productsStore,
  realEstateAssetsStore,
  transactionsStore,
  usersStore,
} from "@/store";
import { DecimalPrecision2, onlyNumber } from "@/utils";
import router from "@/router";
import { dispatchGenericChargesEvent, GenericChargesEventCode } from "@/events";

export default defineComponent({
  name: "GenericChargesDialog",
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    EditableTable,
    TitleNew,
  },
  setup: (props, context) => {
    const genericChargesModes = [
      {
        text: "Équipondéré",
        value: GenericChargesModes.EQUAL_WEIGHT,
      },
      {
        text: "Surface habitable",
        value: GenericChargesModes.SURFACE_AREA,
      },
      {
        text: "Tantièmes",
        value: GenericChargesModes.TENTHS,
        disabled: true,
      },
      {
        text: "Manuel",
        value: GenericChargesModes.MANUAL_PERCENTAGE,
      },
    ];

    const selectedMode = ref(
      activitiesStore.currentActivity?.genericChargesMode ??
        GenericChargesModes.EQUAL_WEIGHT
    );

    const modeSpecificHeader = computed(() => {
      if (selectedMode.value === GenericChargesModes.SURFACE_AREA) {
        return {
          text: "Surface habitable",
          value: "surfaceArea",
          width: "30%",
        };
      } else if (selectedMode.value === GenericChargesModes.TENTHS) {
        return {
          text: "Tantièmes",
          value: "tenths",
          width: "30%",
        };
      } else return null;
    });

    const headersGenericCharges = computed(() =>
      [
        { text: "Lot", value: "realEstateAsset" },
        {
          text: "Type (facultatif)",
          value: "type",
          width: "15%",
        },
        { ...modeSpecificHeader.value },
        { text: "Répartition", value: "percentage", width: "30%" },
      ].filter((el) => !!el)
    );

    const mapItemsGenericCharges = computed(() =>
      realEstateAssetsStore.realEstateAssets.map((realEstateAsset) => {
        return {
          realEstateAsset: {
            text: realEstateAsset.name,
            value: realEstateAsset.id,
          },
          type: realEstateAsset.type,
          surfaceArea: realEstateAsset.surfaceArea ?? 0,
          tenths: realEstateAsset.tenths ?? 0,
          percentage: realEstateAsset.genericChargesPercentage ?? 0,
        };
      })
    );

    const itemsGenericCharges = ref([
      ...JSON.parse(JSON.stringify(mapItemsGenericCharges.value)),
    ]);

    const realEstateAssetTypesList = computed(() => {
      return Object.keys(RealEstateAssetTypes).map((key) => {
        return {
          text: RealEstateAssetTypes[key],
          value: RealEstateAssetTypes[key],
        };
      });
    });

    const updateRealEstateAssetField = (value, index, field) => {
      let convertedValue;
      if (["surfaceArea", "tenths", "percentage"].includes(field)) {
        convertedValue = Number(value);
      } else convertedValue = value;

      itemsGenericCharges.value.splice(index, 1, {
        ...itemsGenericCharges.value[index],
        [field]: convertedValue,
      });
      adjustPercentages();
    };

    const adjustPercentages = () => {
      switch (selectedMode.value) {
        case GenericChargesModes.EQUAL_WEIGHT:
          itemsGenericCharges.value.forEach((_, index) => {
            let result = 100 / realEstateAssetsStore.realEstateAssets.length;

            // Handles rounding issues caused by decimals and odd numbers by adding
            // how much is missing to the last realEstateAsset percentage
            if (index === itemsGenericCharges.value.length - 1) {
              const totalBeforeLastPercent = itemsGenericCharges.value.reduce(
                (prev, current, counter) => {
                  if (counter !== itemsGenericCharges.value.length - 1)
                    return prev + current.percentage;
                  else return prev;
                },
                0
              );
              result = DecimalPrecision2.trunc(100 - totalBeforeLastPercent, 2);
            }

            itemsGenericCharges.value.splice(index, 1, {
              ...itemsGenericCharges.value[index],
              percentage: DecimalPrecision2.trunc(result, 2),
            });
          });
          break;
        case GenericChargesModes.SURFACE_AREA:
          if (itemsGenericCharges.value.every((item) => item.surfaceArea)) {
            const totalSurfaceArea = itemsGenericCharges.value.reduce(
              (prev, current) => prev + current.surfaceArea,
              0
            );

            itemsGenericCharges.value.forEach((_, index) => {
              let result = DecimalPrecision2.trunc(
                (itemsGenericCharges.value[index].surfaceArea /
                  totalSurfaceArea) *
                  100,
                2
              );

              if (index === itemsGenericCharges.value.length - 1) {
                const totalPercentage = DecimalPrecision2.trunc(
                  itemsGenericCharges.value.reduce((prev, current, counter) => {
                    if (counter !== itemsGenericCharges.value.length - 1)
                      return prev + current.percentage;
                    else return prev;
                  }, 0),
                  2
                );

                result = DecimalPrecision2.round(100 - totalPercentage, 2);
              }

              itemsGenericCharges.value.splice(index, 1, {
                ...itemsGenericCharges.value[index],
                percentage: DecimalPrecision2.trunc(result, 2),
              });
            });
          }
          break;
        case GenericChargesModes.TENTHS:
          if (itemsGenericCharges.value.every((item) => item.tenths)) {
            const totalTenths = itemsGenericCharges.value.reduce(
              (prev, current) => prev + current.tenths,
              0
            );

            itemsGenericCharges.value.forEach((_, index) => {
              let result =
                (itemsGenericCharges.value[index].surfaceArea / totalTenths) *
                100;

              itemsGenericCharges.value.splice(index, 1, {
                ...itemsGenericCharges.value[index],
                percentage: DecimalPrecision2.trunc(result, 2),
              });
            });
          }
          break;
      }
      if (context.refs.form) (context.refs.form as VForm).validate();
    };

    watch(selectedMode, () => {
      itemsGenericCharges.value = [
        ...JSON.parse(JSON.stringify(mapItemsGenericCharges.value)),
      ];
      (context.refs.form as VForm).resetValidation();
      adjustPercentages();
      dispatchGenericChargesEvent({
        code: GenericChargesEventCode.SELECTED_MODE,
        userId: usersStore.loggedInUser.id,
        productId: productsStore.currentId,
        genericChargesMode: selectedMode.value,
        date: getMoment().toISOString(),
      });
    });

    const totalPercentage = computed(() =>
      DecimalPrecision2.trunc(
        itemsGenericCharges.value.reduce(
          (prev, current) => prev + current.percentage,
          0
        ),
        2
      )
    );

    const totalPercentageRule = computed(() => totalPercentage.value === 100);

    const validateGenericCharges = async () => {
      const previousMode = activitiesStore.currentActivity?.genericChargesMode;
      if ((context.refs.form as VForm).validate()) {
        await activitiesStore.updateActivity({
          ...activitiesStore.currentActivity,
          genericChargesMode: selectedMode.value,
        } as ActivitiesService.UpdateIn);
        for (const item of itemsGenericCharges.value) {
          const realEstateAsset = realEstateAssetsStore.realEstateAssets.find(
            (asset) => item.realEstateAsset.value === asset.id
          );
          await realEstateAssetsStore.updateRealEstateAsset({
            ...realEstateAsset,
            type: item.type,
            surfaceArea: item.surfaceArea,
            tenths: item.tenths,
            genericChargesPercentage: item.percentage,
          } as RealEstateAssetUpdate);
        }

        context.emit("close");
        if (
          realEstateAssetsStore.realEstateAssets.length > 0 &&
          Object.keys(transactionsStore.transactions).length > 0 &&
          previousMode
        ) {
          context.emit("openGenericChargesApplicationDialog");
        }

        dispatchGenericChargesEvent({
          code: GenericChargesEventCode.CONFIRMED_MODE,
          userId: usersStore.loggedInUser.id,
          productId: productsStore.currentId,
          genericChargesMode: selectedMode.value,
          date: getMoment().toISOString(),
        });
      }
    };

    onMounted(() => {
      adjustPercentages();
    });

    watch(
      () => props.isOpen,
      () => {
        if (props.isOpen) router.push({ query: { "generic-charges": "open" } });
        else router.push({ query: {} });
      }
    );

    return {
      genericChargesModes,
      GenericChargesModes,
      selectedMode,
      headersGenericCharges,
      itemsGenericCharges,
      realEstateAssetTypesList,
      updateRealEstateAssetField,
      onlyNumber,
      totalPercentage,
      totalPercentageRule,
      validateGenericCharges,
      DecimalPrecision2,
      realEstateAssetsStore,
    };
  },
});
