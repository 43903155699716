














































































































































































































































































import { computed, defineComponent, PropType, ref } from "@vue/composition-api";
import confetti from "canvas-confetti";
import SectionTitle from "@/components/atom/SectionTitle.vue";
import DatePicker from "@/components/atom/DatePicker.vue";
import YesNoSwitch from "@/components/atom/switch/YesNoSwitch.vue";
import Dialog from "@/components/atom/Dialog.vue";
import HelpingMessage from "@/components/atom/helping/HelpingMessage.vue";
import {
  CommonCharges,
  DocumentModelType,
  getMoment,
  IndividualCharges,
  NewRegularization,
} from "@edmp/api";
import {
  productsStore,
  regularizationsStore,
  rentalAgreementsStore,
  usersStore,
} from "@/store";
import { rentalsService } from "@/services";
import EditableTable from "@/components/atom/table/EditableTable.vue";
import { dispatchRegularizationEvent, RegularizationEventCode } from "@/events";
import RegularizationMail from "./RegularizationMail.vue";
import RentalAgreementsPreviewMail from "@/components/core/realEstate/RentalAgreementsPreviewMail.vue";

export default defineComponent({
  name: "RegularizationResult",
  components: {
    Dialog,
    YesNoSwitch,
    DatePicker,
    SectionTitle,
    HelpingMessage,
    EditableTable,
    RegularizationMail,
    RentalAgreementsPreviewMail,
  },
  props: {
    regularization: {
      type: Object as PropType<NewRegularization>,
      required: true,
    },
    isFullYear: {
      type: Boolean,
      required: true,
    },
  },

  setup(props, context) {
    const goBack = () => {
      context.emit("goBack");
    };

    const months = computed(
      () =>
        getMoment(props.regularization.periodEnd).month() +
        1 -
        getMoment(props.regularization.periodStart).month() +
        (getMoment(props.regularization.periodEnd).year() -
          getMoment(props.regularization.periodStart).year()) *
          12
    );

    const rentalAgreement = computed(() =>
      rentalAgreementsStore.getRentalAgreement(
        props.regularization.rentalAgreementId
      )
    );

    const provisionsOverTheYear = computed(() => {
      if (rentalAgreement.value?.financialConditions) {
        return (
          rentalAgreement.value?.financialConditions.amountOfCharges *
          months.value
        ).toFixed(2);
      } else {
        return "0.00";
      }
    });
    const commonCharges = computed(() => {
      return props.regularization.commonCharges as CommonCharges;
    });

    const individualCharges = computed(() => {
      return props.regularization.individualCharges as IndividualCharges;
    });

    //#region Modal
    const openPreviewModalRegularization = ref(false);
    const openSendRegularizationModal = ref(false);

    function closePreviewModal() {
      openPreviewModalRegularization.value = false;
    }
    function closeSendRegularizationModal() {
      openSendRegularizationModal.value = false;

      context.emit("goHistory");
    }
    //#endregion

    const totalCharges = computed(() => {
      const totalCommonCharges = Object.keys(commonCharges.value)
        .reduce((total, currentProperty) => {
          return (
            total + Number(commonCharges.value[currentProperty].totalAmount)
          );
        }, 0)
        .toFixed(2);

      const totalIndividualCharges = Object.keys(individualCharges.value)
        .reduce((total, currentProperty) => {
          return (
            total + Number(individualCharges.value[currentProperty].totalAmount)
          );
        }, 0)
        .toFixed(2);
      return {
        commonCharges: Number(totalCommonCharges),
        individualCharges: Number(totalIndividualCharges),
        calcul: Math.abs(
          Number(totalCommonCharges) + Number(totalIndividualCharges)
        ).toFixed(2),
      };
    });

    const result = computed(() => {
      if (provisionsOverTheYear.value && totalCharges.value.calcul) {
        return (
          Number(provisionsOverTheYear.value) -
          Number(totalCharges.value.calcul)
        ).toFixed(2);
      } else {
        return "0.00";
      }
    });

    const isResultNegative = computed(() => result.value.slice(0, 1) === "-");

    const resultText = computed(() => {
      return Math.abs(Number(result.value)).toString();
    });

    const headersRegularizationResult = [
      { text: "Rubrique", value: "section" },
      { text: "Montant (€)", value: "amount" },
    ];

    const itemsRegularizationResult = computed(() => [
      {
        section: "Montant des provisions mensuelles",
        amount: `${rentalAgreement.value?.financialConditions.amountOfCharges} €`,
      },
      {
        section: `Total des provisions pour la période (${months.value} mois)`,
        amount: `${provisionsOverTheYear.value} €`,
      },
      {
        section: `Total des charges réelles sur la période (${months.value} mois)`,
        amount: `${totalCharges.value.calcul} €`,
      },
      {
        section: "Montant de la régularisation",
        amount: `<strong class="${
          resultText.value === "0"
            ? ""
            : isResultNegative.value
            ? "greenText"
            : "redText"
        }">${resultText.value} € ${
          resultText.value === "0"
            ? ""
            : isResultNegative.value
            ? "à récupérer"
            : "à payer"
        }</strong>`,

        highlight: true,
      },
    ]);

    const isLoading = ref(false);

    const isOpenCongratsModal = ref(false);

    const validateRegularization = async () => {
      isLoading.value = true;

      function castCharges(charges: CommonCharges[] | IndividualCharges[]) {
        return Object.values(charges).map((charge) => {
          let formattedCharge = {};

          for (let [key, value] of Object.entries(charge)) {
            if (key === "totalAmount" || key === "type") {
              formattedCharge[key] = String(value);
            } else {
              formattedCharge[key] = Number(value);
            }
          }

          return formattedCharge;
        });
      }

      if (props.regularization.commonCharges) {
        props.regularization.commonCharges = castCharges(
          props.regularization.commonCharges
        );
      }
      if (props.regularization.individualCharges) {
        props.regularization.individualCharges = castCharges(
          props.regularization.individualCharges
        );
      }
      props.regularization.result = result.value;

      await regularizationsStore.createRegularization({
        rentalAgreementId: props.regularization.rentalAgreementId,
        productId: productsStore.currentId,
        periodStart: props.regularization.periodStart,
        periodEnd: props.regularization.periodEnd,
        propertyType: props.regularization.propertyType,
        date: getMoment().format("DD/MM/YYYY"),
        provisions: provisionsOverTheYear.value,
        charges: (
          totalCharges.value.commonCharges +
          totalCharges.value.individualCharges
        ).toString(),
        result: result.value,
        commonCharges: props.regularization.commonCharges,
        individualCharges: props.regularization.individualCharges,
      });

      dispatchRegularizationEvent({
        userId: usersStore.loggedInUser.id,
        productId: productsStore.currentId,
        date: getMoment().toISOString(),
        code: RegularizationEventCode.SUBMIT_REGULARIZATION,
      });

      isLoading.value = false;
      confetti({ zIndex: 1000 });

      const defaults = {
        origin: { y: 0.7 },
      };

      isOpenCongratsModal.value = true;

      function fire(particleRatio, opts) {
        confetti({
          ...defaults,
          ...opts,
          particleCount: Math.floor(200 * particleRatio),
        });
      }

      fire(0.25, {
        spread: 26,
        startVelocity: 55,
      });
      fire(0.2, {
        spread: 60,
      });
      fire(0.35, {
        spread: 100,
        decay: 0.91,
        scalar: 0.8,
      });
      fire(0.1, {
        spread: 120,
        startVelocity: 25,
        decay: 0.92,
        scalar: 1.2,
      });
      fire(0.1, {
        spread: 120,
        startVelocity: 45,
      });
    };

    const downloadRegularizationMailModel = (fileType) => {
      if (
        Number(provisionsOverTheYear.value) >
        totalCharges.value.commonCharges + totalCharges.value.individualCharges
      ) {
        if (props.isFullYear) {
          rentalsService.agreements.downloadDocumentModel({
            modelType: DocumentModelType.NOTICE_REGULARIZATION_TENANT_FULL_YEAR,
            fileType,
          });
        } else {
          rentalsService.agreements.downloadDocumentModel({
            modelType:
              DocumentModelType.NOTICE_REGULARIZATION_TENANT_PARTIAL_YEAR,
            fileType,
          });
        }
      } else {
        if (props.isFullYear) {
          rentalsService.agreements.downloadDocumentModel({
            modelType: DocumentModelType.NOTICE_REGULARIZATION_LESSOR_FULL_YEAR,
            fileType,
          });
        } else {
          rentalsService.agreements.downloadDocumentModel({
            modelType:
              DocumentModelType.NOTICE_REGULARIZATION_LESSOR_PARTIAL_YEAR,
            fileType,
          });
        }
      }

      dispatchRegularizationEvent({
        userId: usersStore.loggedInUser.id,
        productId: productsStore.currentId,
        date: getMoment().toISOString(),
        code:
          fileType === "docx"
            ? RegularizationEventCode.DOWNLOAD_REGULARIZATION_TEMPLATE_WORD
            : RegularizationEventCode.DOWNLOAD_REGULARIZATION_TEMPLATE_PDF,
      });
    };

    return {
      goBack,
      provisionsOverTheYear,
      validateRegularization,
      totalCharges,
      result,
      resultText,
      isResultNegative,
      isOpenCongratsModal,
      productsStore,
      downloadRegularizationMailModel,
      headersRegularizationResult,
      itemsRegularizationResult,
      openSendRegularizationModal,
      openPreviewModalRegularization,
      closeSendRegularizationModal,
      closePreviewModal,
      rentalAgreement,
    };
  },
});
