



















































































































































































































import {
  computed,
  ComputedRef,
  defineComponent,
  onBeforeMount,
  PropType,
  reactive,
  Ref,
  ref,
  watch,
} from "@vue/composition-api";
import {
  SubscriptionsModel,
  ProductsModel,
  TaxRegime,
  ActivitiesModel,
} from "@edmp/api";
import {
  accountingPeriodsStore,
  productsStore,
  subscriptionsStore,
} from "@/store";
import { VConfirmDialog } from "@/models";
import { useSubscription } from "../subscription.usable";
import ActivityChoice from "./ActivityChoice.vue";
import ActivityInfos from "@/components/core/activities/ActivityInfos.vue";
import RegisterMyBusinessLMNP from "@/components/core/myBusiness/RegisterMyBusinessLMNP.vue";
import MyBusinessGeneralInfo from "@/components/core/myBusiness/MyBusinessGeneralInfo.vue";
import MyBusinessOperatorInfo from "@/components/core/myBusiness/MyBusinessOperatorInfo.vue";
import ProductMail from "@/components/core/activities/ProductMail.vue";
import Accounting from "@/components/core/activities/Accounting.vue";
import DedicatedBankAccount from "./DedicateBankAccount.vue";
import SubscriptionsFeatures from "../SubscriptionsFeatures.vue";
import SubscriptionsPlan from "../SubscriptionsPlan.vue";
import PaymentChoice from "./PaymentChoice.vue";
import Payment from "./Payment.vue";
import SubscriptionStripe, {
  VSubscriptionStripe,
} from "./SubscriptionStripe.vue";
import Next from "@/components/core/modals/Next.vue";
import { ROUTE_NAMES } from "@/router/routes";

export default defineComponent({
  name: "SubscriptionFlowHorizontal",
  components: {
    ActivityChoice,
    ActivityInfos,
    RegisterMyBusinessLMNP,
    MyBusinessGeneralInfo,
    MyBusinessOperatorInfo,
    ProductMail,
    Accounting,
    DedicatedBankAccount,
    SubscriptionsPlan,
    SubscriptionsFeatures,
    PaymentChoice,
    Payment,
    SubscriptionStripe,
    Next,
  },
  props: {
    productId: { type: String, required: false }, // `undefined` for new product
    stripeReturnStatus: {
      type: String as PropType<
        | SubscriptionsModel.PaymentStatusParams.succeeded
        | SubscriptionsModel.PaymentStatusParams.canceled
        | "portal"
      >,
      required: false,
    },
  },
  setup(props, context) {
    const product = computed(() => {
      if (props.productId) {
        return productsStore.getProduct({ id: props.productId });
      }
      return null;
    });
    // const accountingPeriod = computed(() => {
    //   if (props.productId) {
    //     const accountingPeriods =
    //       accountingPeriodsStore.accountingPeriods.filter(
    //         (accountingPeriod) => accountingPeriod.productId === props.productId
    //       );
    //     return accountingPeriods[accountingPeriods.length - 1];
    //   }
    //   return undefined;
    // });

    const subscriptionUse = ref<ReturnType<typeof useSubscription>>();
    const subscription = computed(() => {
      if (product.value?.id) {
        const subscription = subscriptionsStore.getSubscriptionByProduct(
          product.value.id
        );
        if (subscription) {
          subscriptionUse.value = subscription
            ? (subscriptionUse.value = useSubscription(subscription.id))
            : undefined;
        }
        return subscription;
      }
    });

    const actionType = computed(() => {
      if (!product.value) {
        return "create";
      }
      if (
        product.value.status === ProductsModel.ProductStatus.pending &&
        !product.value.tags.includes(ProductsModel.Tags.LRP)
      ) {
        return "create";
      }

      return "update";
    });

    const stepperStates = reactive<{
      show: ComputedRef<boolean>;
      stepSelected: number;
      buttonStepOneErrorMessage: string | undefined;
      validate: {
        activityType: ComputedRef<boolean>;
        productInfos: ComputedRef<boolean>;
        generalInfoInfos: ComputedRef<boolean>;
        activityInfos: ComputedRef<boolean>;
        productMail: ComputedRef<boolean>;
        accounting: ComputedRef<boolean>;
        dedicatedBankAccount: ComputedRef<boolean>;
        address: ComputedRef<boolean>;
        activityAddress: ComputedRef<boolean>;
        siret: ComputedRef<boolean>;
      };
      nextStepRules: () => Promise<void>;
    }>({
      show: computed(
        () =>
          !(
            context.root.$route.name === "SubscriptionDetailsContinue" ||
            context.root.$route.name === "SubscriptionDetails"
          )
      ),
      stepSelected: 1,
      buttonStepOneErrorMessage: undefined,
      validate: {
        activityType: computed(() => !!activityType.value),
        productInfos: computed(() => !!product.value?.id),
        generalInfoInfos: computed(() => !!product.value?.id),
        activityInfos: computed(() => !!product.value?.activity.id),
        productMail: computed(() => product.value?.emailStatus === "confirmed"),
        accounting: computed(
          () =>
            !!accountingPeriodsStore.accountingPeriods.find(
              ({ productId }) => productId === product.value?.id
            )
        ),
        dedicatedBankAccount: computed(
          () => isDedicatedBankAccount.value !== undefined
        ),
        address: computed(
          () =>
            !!product.value?.activity.address?.street &&
            !!product.value?.activity.address?.city &&
            !!product.value?.activity.address?.zip
        ),
        activityAddress: computed(() => {
          if (activityType.value === "LMNP") {
            const activity = product.value
              ?.activity as ActivitiesModel.Activity<ActivitiesModel.ActivityTypes.OPERATOR>;
            return (
              !!activity.activityAddress?.street &&
              !!activity.activityAddress?.city &&
              !!activity.activityAddress?.zip
            );
          }
          return true;
        }),
        siret: computed(() => !!product.value?.activity.siret),
      },
      nextStepRules: async () => {
        if (stepperStates.stepSelected === 1) {
          stepperStates.buttonStepOneErrorMessage = undefined;
          await productsStore.fetchProducts({});
          if (product.value?.emailStatus === "confirmed") {
            stepperStates.stepSelected = 2;
          } else {
            stepperStates.buttonStepOneErrorMessage =
              "Votre adresse email doit être vérifiée.";
          }
        }
      },
    });

    const activityType: Ref<"SCI" | "LMNP"> = ref("LMNP");

    const isDedicatedBankAccount = ref();
    const handleUpdate = (newValue) => {
      isDedicatedBankAccount.value = newValue;
    };

    const subscriptionStates = reactive({
      subscriptionCreateIsLoading: false,
      planSelected:
        accountingPeriodsStore.currentAccountingPeriod?.taxRegime ===
        TaxRegime.IR_2072
          ? {
              type: SubscriptionsModel.PlanTypeParams.Premium,
              durationType: SubscriptionsModel.DurationTypeParams.year,
              couponUse: false,
            }
          : accountingPeriodsStore.currentAccountingPeriod?.taxRegime ===
            TaxRegime.IS_2065
          ? {
              type: SubscriptionsModel.PlanTypeParams.Optimum,
              durationType: SubscriptionsModel.DurationTypeParams.year,
              couponUse: false,
            }
          : {
              type: SubscriptionsModel.PlanTypeParams.LMNPOptimum,
              durationType: SubscriptionsModel.DurationTypeParams.year,
              couponUse: false,
            },
      paymentSelected: {
        paymentMethod: SubscriptionsModel.PaymentMethodTypeParams.sepaDebit,
      },
      selectPlan: async (
        selectPlan: SubscriptionsModel.PlanParams & {
          type: SubscriptionsModel.PlanParams["type"];
        }
      ) => {
        if (
          subscriptionUse.value &&
          (await subscriptionUse.value.checkUpdatePermission(
            context.refs.confirmDialog as VConfirmDialog,
            selectPlan.type
          ))
        ) {
          subscriptionStates.planSelected = selectPlan;
          stepperStates.stepSelected = 3;
        }
      },
      selectPayment: (selectPayment: SubscriptionsModel.PaymentParams) => {
        subscriptionStates.paymentSelected = selectPayment;
      },
    });

    // Create subscription
    const subscribe = async () => {
      if (
        product.value?.id &&
        subscription.value?.id &&
        subscriptionStates.planSelected &&
        subscriptionStates.paymentSelected &&
        subscriptionUse.value?.stripeStates.redirectUri
      ) {
        subscriptionStates.subscriptionCreateIsLoading = true;

        const subscriptionUpdated = await subscriptionsStore.updateSubscription(
          {
            id: subscription.value.id,
            plan: subscriptionStates.planSelected as {
              type: SubscriptionsModel.PlanTypeParams;
              durationType: SubscriptionsModel.DurationTypeParams;
              couponUse: boolean;
            },
            payment: subscriptionStates.paymentSelected,
            successRedirectUri:
              subscriptionUse.value.stripeStates.redirectUri.success,
            cancelRedirectUri:
              subscriptionUse.value.stripeStates.redirectUri.cancel,
          }
        );

        if (subscriptionUpdated.payment.sessionCheckoutId) {
          (
            context.refs.subscriptionStripe as unknown as VSubscriptionStripe
          ).redirectToCheckout(subscriptionUpdated.payment.sessionCheckoutId);
        }
        subscriptionStates.subscriptionCreateIsLoading = false;
      }
    };

    const init = async () => {
      stepperStates.stepSelected = 1;
      activityType.value =
        product.value?.type === ProductsModel.ProductTypes.SCI ? "SCI" : "LMNP";
    };

    watch(
      () => [props, subscription, subscriptionUse, product],
      () => init(),
      { deep: true }
    );
    onBeforeMount(() => init());

    /**
     * Return data
     */
    return {
      ROUTE_NAMES,
      actionType,
      product,
      subscription,
      stepperStates,
      activityType,
      PlanType: SubscriptionsModel.PlanType,
      PlanTypeParams: SubscriptionsModel.PlanTypeParams,
      PaymentStatusParams: SubscriptionsModel.PaymentStatusParams,
      DurationTypeParams: SubscriptionsModel.DurationTypeParams,
      PaymentMethodTypeParams: SubscriptionsModel.PaymentMethodTypeParams,
      PaymentStatus: SubscriptionsModel.PaymentStatus,
      subscriptionStates,
      subscriptionUse,
      SubscriptionStatus: SubscriptionsModel.SubscriptionStatus,
      subscribe,
      handleUpdate,
    };
  },
});
