import Vue from "vue";
import store from "@/store/root";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import {
  AccountingBalanceSheet,
  AccountingBalanceSheetCreate,
  AccountingBalanceSheetUpdate,
  getMoment,
} from "@edmp/api";
import { accountingPeriodsStore, productsStore } from "..";
import { accountingsService } from "@/services";
import { cloneDeep } from "lodash";

export interface AccountingBalanceSheetState {
  accountingBalanceSheets: AccountingBalanceSheet[];
  loading: boolean;
}

@Module({
  name: "accounting-balance-sheet-store",
  dynamic: true,
  namespaced: true,
  store,
})
export class AccountingBalanceSheetStore
  extends VuexModule
  implements AccountingBalanceSheetState
{
  accountingBalanceSheets: AccountingBalanceSheetState["accountingBalanceSheets"] =
    [];
  loading = false;

  @Mutation
  reset(): void {
    this.loading = false;
  }

  @Mutation
  setLoading(isLoading: boolean): void {
    this.loading = isLoading;
  }

  // Getters
  get getAccountingBalanceSheet() {
    return (accountingBalanceSheetId) =>
      this.accountingBalanceSheets.find(
        ({ id }) => id === accountingBalanceSheetId
      );
  }

  get getAccountingBalanceSheetByType() {
    return (type: AccountingBalanceSheet["type"]) =>
      this.accountingBalanceSheets.filter(
        (accountingBalanceSheet) => accountingBalanceSheet.type === type
      );
  }

  get getAccountingBalanceSheetByDate() {
    return (startAt: string, endAt: string) =>
      this.accountingBalanceSheets.filter((accountingBalanceSheet) => {
        if (accountingBalanceSheet.type === "recovery") {
          return (
            getMoment(accountingBalanceSheet.startAt).isSameOrAfter(startAt) &&
            getMoment(accountingBalanceSheet.endAt).isSameOrBefore(endAt)
          );
        }
        if (accountingBalanceSheet.type === "closure") {
          const accountingPeriods =
            accountingPeriodsStore.accountingPeriods.filter(
              (accountingPeriod) =>
                getMoment(accountingPeriod.startAt).isSameOrAfter(startAt) &&
                getMoment(accountingPeriod.endAt).isSameOrBefore(endAt)
            );
          return accountingPeriods.some(
            (accountingPeriod) =>
              accountingPeriod.id === accountingBalanceSheet.accountingPeriodId
          );
        }
      });
  }

  get getFirstAccountingBalanceSheetByRecovery() {
    return this.getAccountingBalanceSheetByType("recovery").find(
      (accountingBalanceSheet) =>
        accountingBalanceSheet.type === "recovery" &&
        getMoment(accountingBalanceSheet.endAt).year() ===
          getMoment(accountingPeriodsStore.firstAccountingPeriod.endAt).year() -
            1
    );
  }

  get getCurrentAccountingBalanceSheet() {
    return this.accountingBalanceSheets.find(
      (accountingBalanceSheet) =>
        accountingBalanceSheet.type === "closure" &&
        accountingBalanceSheet.accountingPeriodId ===
          accountingPeriodsStore.currentId
    );
  }

  get getPreviousYearAccountingBalanceSheet() {
    const accountingPeriods = cloneDeep(
      accountingPeriodsStore.accountingPeriods
    ).sort((acc1, acc2) => getMoment(acc1.endAt).diff(acc2.endAt));
    const accountingPeriodIndex = accountingPeriods.findIndex(
      (accountingPeriod) =>
        accountingPeriod.id === accountingPeriodsStore.currentId
    );
    if (accountingPeriodIndex !== -1) {
      if (accountingPeriodIndex === 0) {
        return this.getAccountingBalanceSheetByType("recovery")[0] as
          | AccountingBalanceSheet
          | undefined;
      } else if (accountingPeriods[accountingPeriodIndex - 1]) {
        return this.getAccountingBalanceSheetByType("closure").find(
          (accountingBalanceSheet) =>
            accountingBalanceSheet.type === "closure" &&
            accountingBalanceSheet.accountingPeriodId ===
              accountingPeriods[accountingPeriodIndex - 1].id
        );
      }
    }
  }

  get isSkippedAccountingBalanceSheetRecovery() {
    if (
      this.getPreviousYearAccountingBalanceSheet &&
      this.getPreviousYearAccountingBalanceSheet.type === "recovery"
    )
      return this.getPreviousYearAccountingBalanceSheet.isSkipped;
    else return true;
  }

  get hasPreviousYearBalanceSheetRecovery() {
    return this.accountingBalanceSheets.some((accountingBalanceSheet) => {
      if (accountingBalanceSheet.type === "recovery") {
        return (
          getMoment(accountingBalanceSheet.endAt).year() ===
          getMoment(
            accountingPeriodsStore.currentAccountingPeriod?.endAt
          ).year() -
            1
        );
      }
    });
  }

  // Mutations
  @Mutation
  setAccountingBalanceSheets(
    accountingBalanceSheets: AccountingBalanceSheetState["accountingBalanceSheets"]
  ): void {
    this.accountingBalanceSheets = accountingBalanceSheets;
  }

  @Mutation
  setAccountingBalanceSheet(
    accountingBalanceSheet: AccountingBalanceSheet
  ): void {
    const accountingBalanceSheetIndex = this.accountingBalanceSheets.findIndex(
      ({ id }) => id === accountingBalanceSheet.id
    );
    if (accountingBalanceSheetIndex !== -1) {
      Vue.set(
        this.accountingBalanceSheets,
        accountingBalanceSheetIndex,
        accountingBalanceSheet
      );
    } else {
      this.accountingBalanceSheets.push(accountingBalanceSheet);
    }
  }

  @Mutation
  delAccountingBalanceSheet(
    accountingBalanceSheetId: AccountingBalanceSheet["id"]
  ): void {
    const accountingBalanceSheetIndex = this.accountingBalanceSheets.findIndex(
      ({ id }) => id === accountingBalanceSheetId
    );
    if (accountingBalanceSheetIndex !== -1) {
      this.accountingBalanceSheets.splice(accountingBalanceSheetIndex, 1);
    }
  }

  // Actions

  @Action
  async createAccountingBalanceSheet(
    accountingBalanceSheetCreate: AccountingBalanceSheetCreate
  ): Promise<AccountingBalanceSheet> {
    const accountingBalanceSheetCreated =
      await accountingsService.balanceSheets.create(
        accountingBalanceSheetCreate
      );
    this.setAccountingBalanceSheet(accountingBalanceSheetCreated);
    return accountingBalanceSheetCreated;
  }

  @Action
  async fetchAccountingBalanceSheets(): Promise<AccountingBalanceSheet[]> {
    const accountingBalanceSheets = await accountingsService.balanceSheets.list(
      {
        productId: productsStore.currentId,
      }
    );
    this.setAccountingBalanceSheets(accountingBalanceSheets);
    return accountingBalanceSheets;
  }

  @Action
  async reportAccountingBalanceSheets(
    accountingBalanceSheetId: AccountingBalanceSheet["id"]
  ): Promise<void> {
    await accountingsService.balanceSheets.reporting({
      id: accountingBalanceSheetId,
    });
  }

  @Action
  async updateAccountingBalanceSheet(
    accountingBalanceSheetUpdate: AccountingBalanceSheetUpdate
  ): Promise<AccountingBalanceSheet> {
    const accountingBalanceSheetUpdated =
      await accountingsService.balanceSheets.update(
        accountingBalanceSheetUpdate
      );
    this.setAccountingBalanceSheet(accountingBalanceSheetUpdated);
    return accountingBalanceSheetUpdated;
  }

  @Action
  async validateAccountingBalanceSheet(params: {
    accountingBalanceSheetId: AccountingBalanceSheet["id"];
    isValidated: boolean;
  }): Promise<boolean> {
    const accountingBalanceSheet = cloneDeep(
      this.getAccountingBalanceSheet(params.accountingBalanceSheetId)
    );
    if (accountingBalanceSheet) {
      accountingBalanceSheet.isValidated = params.isValidated;
      return !!(await this.updateAccountingBalanceSheet(
        accountingBalanceSheet
      ));
    }
    return false;
  }

  @Action
  async deleteAccountingBalanceSheet(
    accountingBalanceSheetId: AccountingBalanceSheet["id"]
  ): Promise<boolean> {
    const isDeleted = await accountingsService.balanceSheets.remove({
      id: accountingBalanceSheetId,
    });
    if (isDeleted) {
      this.delAccountingBalanceSheet(accountingBalanceSheetId);
    }
    return isDeleted;
  }
}
